import * as React from 'react';
import {
  LockedOrdersList,
  TLockedOrdersProps,
} from './LockedOrdersList';
import { Strings } from '../../../../core/src/locale/Strings';
import {
  Text,
  View,
} from 'react-native';
import { Touchable } from '../../lib-components/Touchable';
import { Underline } from '../../lib-components/Underline';
import Collapsible from 'react-native-collapsible';
import styled from 'styled-components/native';
import { useCurrentLocale } from "../../hooks/hooks";

export type TLockedOrdersCollapsibleProps = TLockedOrdersProps & {
  ordersCollapsed: boolean;
  toggleOrdersCollapsed: () => void;
  showCollapseButton: boolean;
};

export const LockedOrdersCollapsible = ({
  ordersCollapsed,
  toggleOrdersCollapsed,
  showCollapseButton,
  ...recapOrderCheckoutProps
}: TLockedOrdersCollapsibleProps) => {
  const locale = useCurrentLocale();
  return (
    <Root>
      <Collapsible
        collapsed={ordersCollapsed}>
        <LockedOrdersList
          {...recapOrderCheckoutProps}/>
      </Collapsible>
      {showCollapseButton && (
        <Touchable
          onPress={toggleOrdersCollapsed}>
          <PreviousButtonCont>
            <HeaderContainer>
              <HeaderText>
                {Strings.gOrderSummaryPreviousOrders()(locale)}
              </HeaderText>
            </HeaderContainer>
            <Underline/>
          </PreviousButtonCont>
        </Touchable>
      )}
    </Root>
  );
};

const Root = styled(View)`
`;

const HeaderContainer = styled(View)`
  padding: 20px;
  text-align: center;
`;

const HeaderText = styled(Text)`
  color: ${({ theme }) => theme.t.col.accentLight};
  font-family: ${({ theme }) => theme.t.font.nunitoSans};
  font-size: ${({ theme }) => theme.scaler.moderateScale(10)}px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: ${({ theme }) => theme.scaler.moderateScale(14)}px;
  text-align: center;
  text-transform: uppercase;
`;

const PreviousButtonCont = styled(View)`

`;
