import { DbRes } from '../../../db/DbResources';
import {
  EIngredientType,
  TIngredient,
} from './IngredientTypes';
import { IngredientBuilder } from './IngredientBuilder';
import { MObj } from '../../../lib/model/MObj';
import { TMObjDataCreator } from '../../../lib/model/ModelTypes';

type TIngWithType = TMObjDataCreator<typeof DbRes.Ingredient>;
type TIng = Omit<TIngWithType, 'type'> & {
  type?: EIngredientType;
};

type TIngGroups<T extends TIng> = {
  extraAddables: T[];
  extraRemovables: T[];
  extraOptions: T[];
};

export class Ingredient extends MObj<typeof DbRes.Ingredient> {
  static areIngredientsTheSame(ing1: TIngredient, ing2: TIngredient): boolean {
    if (ing1.name.toLowerCase() != ing2.name.toLowerCase()) {
      return false;
    }
    if (ing1.type.toLowerCase() != ing2.type.toLowerCase()) {
      return false;
    }
    return true;
  }

  static groupByIngredientType<T extends TIngWithType>(ings: T[]): TIngGroups<T> {
    return {
      extraAddables: ings.filter(({ type }) => type === EIngredientType.ADDABLE),
      extraRemovables: ings.filter(({ type }) => type === EIngredientType.REMOVABLE),
      extraOptions: ings.filter(({ type }) => type === EIngredientType.OPTION),
    };
  }

  static unGroupFromIngredientType<T extends TIngWithType>(groups: TIngGroups<T>): T[] {
    return [
      ...groups.extraAddables.map((ing) => ({
        ...ing,
        type: EIngredientType.ADDABLE,
      })),
      ...groups.extraRemovables.map((ing) => ({
        ...ing,
        type: EIngredientType.REMOVABLE,
      })),
      ...groups.extraOptions.map((ing) => ({
        ...ing,
        type: EIngredientType.OPTION,
      })),
    ];
  }

  static filterType(type: EIngredientType) {
    return (ingredient: TIngredient) => {
      return ingredient.type === type;
    };
  }

  constructor(station: TIngredient) {
    super(IngredientBuilder, station);
  }
}
