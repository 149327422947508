// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, G, Path, Defs, ClipPath } from "react-native-svg"


function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg
      width={50}
      height={50}
      fill="none"
      viewBox={"0 0 60 60"}
      {...props}
    >
      <G clipPath="url(#a)">
        <Path fill="#A87D55" stroke="#A87D55" d="M.5.5h59v59H.5z" />
        <Path
          d="M9.41 51.072c1.11 0 1.938-.654 2.094-1.644h-1.008c-.12.438-.522.708-1.068.708-.714 0-1.158-.522-1.158-1.356 0-.846.438-1.356 1.158-1.356.534 0 .912.252 1.044.696h1.026c-.138-.996-.936-1.632-2.046-1.632-1.326 0-2.208.912-2.208 2.298 0 1.404.834 2.286 2.166 2.286ZM12.55 51l.317-.936h1.434l.312.936h1.032l-1.578-4.44h-.948L11.535 51h1.014Zm.917-2.7c.054-.162.102-.33.12-.432.018.108.072.282.12.432l.318.948h-.876l.318-.948Zm5.227.966v-.876h-1.56v-.93h1.854v-.9h-2.826V51h.972v-1.734h1.56Zm3.492 0v-.876h-1.56v-.93h1.854v-.9h-2.826V51h.972v-1.734h1.56ZM25.966 51v-.9H24.12v-.918h1.638v-.852h-1.638v-.87h1.848v-.9h-2.82V51h2.82Zm1.768-4.44h-.972V51h.972v-4.44ZM29.62 51v-2.82L31.492 51h.96v-4.44h-.96v2.826L29.62 46.56h-.96V51h.96Zm6.577 0v-.9h-1.848v-.918h1.638v-.852h-1.638v-.87h1.848v-.9h-2.82V51h2.82Zm4.915-1.734v-.876h-1.56v-.93h1.854v-.9H38.58V51h.972v-1.734h1.56ZM43.044 51v-1.554h.75L44.46 51h1.062l-.78-1.764c.492-.252.738-.66.738-1.236 0-.918-.612-1.44-1.692-1.44h-1.716V51h.972Zm0-3.576h.744c.432 0 .666.204.666.576 0 .378-.246.594-.672.594h-.738v-1.17ZM49.024 51v-.9h-1.849v-.918h1.638v-.852h-1.638v-.87h1.848v-.9h-2.82V51h2.82Zm3.614 0v-.9H50.79v-.918h1.638v-.852H50.79v-.87h1.848v-.9h-2.82V51h2.82Z"
          fill="#fff"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.247 12.168c3.102 3.367 3.836 6.785 4.614 10.406l.092.43c.82 3.801 1.789 7.776 5.811 11.735-3.821 1.227-9.166-1.964-12.376-7.57-3.445-6.017-3.125-12.538.716-14.565a5.102 5.102 0 0 1 1.143-.436Zm1.66-.163c2.825 3.408 3.57 6.88 4.29 10.243v.001l.104.482c.826 3.833 1.754 7.566 5.699 11.32l-.263.258c3.692-2.12 3.957-8.544.56-14.479-2.684-4.686-6.86-7.685-10.39-7.825Z"
          fill="#fff"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30 39c8.837 0 16-7.163 16-16S38.837 7 30 7s-16 7.163-16 16 7.163 16 16 16Zm0 2c9.941 0 18-8.059 18-18S39.941 5 30 5s-18 8.059-18 18 8.059 18 18 18Z"
          fill="#fff"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m43.986 5.185 2.828 2.829-30.8 30.8-2.83-2.828 30.802-30.8Z"
          fill="#A87D55"
        />
        <Path
          d="M43.986 7.6 45.4 9.014 16.013 38.4 14.6 36.986 43.986 7.6Z"
          fill="#fff"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M0 0h60v60H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export const SvgCaffeineFreeFullIcon = React.memo(SvgComponent);
