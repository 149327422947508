import { TState } from './Store';

export function selectHostPbp(store: TState) {
  const { hostId } = store.host;

  if (!hostId) {
    return undefined;
  }

  return { hostId };
}

export function selectGuestSessionPbp(store: TState) {
  const { hostId } = store.host;
  const { tableId } = store.guestSession;

  if (!hostId || !tableId) {
    return undefined;
  }

  return {
    hostId,
    tableId,
  };
}

export function selectLocale(store: TState) {
  return store.app.locale;
}