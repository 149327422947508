import * as React from 'react';
import { Num } from '../../../../core/src/lib/formatters/Num';
import { SvgCart } from '../../img/SvgCart';
import { Text, View } from 'react-native';
import { AlaTouchableNativeFeedback } from '../../lib-components/Touchable';
import styled from 'styled-components/native';
import { ShadowView2 } from '../../lib-components/ShadowView';
import { useCurrentHost } from "../../hooks/hooks";

export type TOrderUnderlayBaseProps = {
  price: number;
  itemCount: number;
  rightText: string;
  onPress: () => void;
  backgroundColor: string;
};

export const OrderUnderlayBase = ({
  price,
  rightText,
  itemCount,
  onPress,
  backgroundColor,
}: TOrderUnderlayBaseProps) => {
  const host = useCurrentHost();
  const svgCartWithBadge = React.useMemo(() => {
    if (itemCount <= 0) {
      return <SvgCart/>;
    }

    return (
      <CartCont>
        <SvgCartCont>
          <SvgCart/>
        </SvgCartCont>
        <BadgeCont
          backgroundColor={backgroundColor}>
          <Badge>
            {itemCount}
          </Badge>
        </BadgeCont>
      </CartCont>
    );
  }, [itemCount, backgroundColor]);

  return (
    <Root>
      <StyledAlaTouchableNativeFeedback
        onPress={onPress}>
        <Content
          backgroundColor={backgroundColor}>
          <Row>
            <LeftTextContainer>
              {svgCartWithBadge}
              <LeftText>
                {Num.formatPrice2(price, host?.currency)}
              </LeftText>
            </LeftTextContainer>
            <RightText>
              {rightText}
            </RightText>
          </Row>
        </Content>
      </StyledAlaTouchableNativeFeedback>
    </Root>
  );
};

const textMarginTop = 14;
const textLineHeight = 22;
const cartSize = 31;
const cartBadgeSize = 20;

type TStyledRootProps = {
  backgroundColor: string;
};

const Root = styled(ShadowView2)<TStyledRootProps>`
  flex: 1;
`;

const StyledAlaTouchableNativeFeedback = styled(AlaTouchableNativeFeedback)<TStyledRootProps>`
  flex: 1;
`;

const Content = styled(View)<TStyledRootProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  flex: 1;
  flex-direction: row;
  padding: 18px 20px;
`;

const Row = styled(View)`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`;

const LeftTextContainer = styled(View)`
  flex: 1;
  flex-direction: row;
`;

const LeftText = styled(Text)`
  color: ${({ theme }) => theme.t.col.white};
  font-family: ${({ theme }) => theme.t.font.nunitoSans};
  font-size: ${({ theme }) => theme.scaler.verticalScale(18)}px;
  line-height: ${({ theme }) => theme.scaler.verticalScale(textLineHeight)}px;
  margin-left: 6px;
  margin-top: ${({ theme }) => theme.scaler.verticalScale(textMarginTop)}px;
  text-align: left;
  text-transform: uppercase;
`;

const RightText = styled(Text)`
  color: ${({ theme }) => theme.t.col.white};
  font-family: ${({ theme }) => theme.t.font.nunitoSans};
  font-size: ${({ theme }) => theme.scaler.verticalScale(16)}px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: ${({ theme }) => theme.scaler.verticalScale(textLineHeight)}px;
  margin-top: ${({ theme }) => theme.scaler.verticalScale(textMarginTop)}px;
  text-align: right;
  text-transform: uppercase;
`;

const CartCont = styled(View)`
  height: ${({ theme }) => theme.scaler.verticalScale(cartSize)}px;
  position: relative;
  width: ${({ theme }) => theme.scaler.verticalScale(cartSize)}px;
`;

const SvgCartCont = styled(View)`
  bottom: 0;
  position: absolute;
`;

const BadgeCont = styled(View)<{ backgroundColor: string }>`
  align-items: center;
  background-color: ${({ theme }) => theme.t.col.white};
  border-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${cartBadgeSize / 2}px;
  border-width: 2px;
  height: ${({ theme }) => theme.scaler.verticalScale(cartBadgeSize)}px;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: ${({ theme }) => theme.scaler.verticalScale(cartBadgeSize)}px;
`;

const Badge = styled(Text)`
  color: ${({ theme }) => theme.t.col.accentDark};
  font-family: ${({ theme }) => theme.t.font.nunitoSans};
  font-size: ${({ theme }) => theme.scaler.verticalScale(11)}px;
  line-height: ${({ theme }) => theme.scaler.verticalScale(15)}px;
  text-align: center;
`;
