import * as React from 'react';
import {
  TouchableHighlight,
  TouchableNativeFeedback,
  TouchableOpacity as RnTouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useToggle } from '../../../lib-react/src/hooks/useToggle';
import { selectPlatform } from '../lib/Platform';

type TFunc<Item = undefined> = (item: Item) => any;
type TTouchableProps<Item = undefined, OnPress extends TFunc<Item> = () => any> = {
  children: React.ReactNode;
  onPress?: OnPress | null;
  onLongPress?: OnPress | null;
  item?: Item;
  backgroundColor?: string;
  disabled?: boolean;
  style?: any;
  Component?: TouchableWithoutFeedback | RnTouchableOpacity | TouchableHighlight | TouchableNativeFeedback;
};

export function Touchable<Item = any, OnPress extends (item: Item) => any = (...any) => any>({
  children,
  onPress,
  onLongPress,
  item,
  Component = RnTouchableOpacity as any,
  disabled = false,
  style,
}: TTouchableProps<Item, OnPress>) {
  const [loading, , setLoadingTrue, setLoadingFalse] = useToggle(false);

  const onTouchablePress = React.useCallback(async () => {
    if (loading) {
      return;
    }

    setLoadingTrue();
    try {
      return await onPress?.(item as Item);
    } finally {
      setLoadingFalse();
    }
  }, [loading, setLoadingTrue, setLoadingFalse, onPress, item]);

  const onTouchableLongPress = React.useCallback(() => onLongPress?.(item as Item), [onLongPress, item]);

  if ((onPress == null && onLongPress == null) || disabled) {
    return (
      <View style={style}>
        {children}
      </View>
    );
  }

  return (
    // @ts-ignore
    <Component
      onPress={onTouchablePress}
      onLongPress={onTouchableLongPress}
      style={style}>
      {children}
    </Component>
  );
}

export function AlaTouchableWithoutFeedback<Item = any, OnPress extends (item: Item) => any = (...any) => any>(props: Omit<TTouchableProps<Item, OnPress>, 'Component'>) {
  return (
    <Touchable
      {...props}
      Component={TouchableWithoutFeedback as any}
    />
  );
}

export function AlaTouchableOpacity<Item = any, OnPress extends (item: Item) => any = (...any) => any>(props: Omit<TTouchableProps<Item, OnPress>, 'Component'>) {
  return (
    <Touchable
      {...props}
      Component={RnTouchableOpacity as any}
    />
  );
}

export function AlaTouchableHighlight<Item = any, OnPress extends (item: Item) => any = (...any) => any>(props: Omit<TTouchableProps<Item, OnPress>, 'Component'>) {
  return (
    <Touchable
      {...props}
      Component={TouchableHighlight as any}
    />
  );
}

export function AlaTouchableNativeFeedback<Item = any, OnPress extends (item: Item) => any = (...any) => any>(props: Omit<TTouchableProps<Item, OnPress>, 'Component'>) {
  const Component = selectPlatform<any>({
    ios: TouchableHighlight,
    android: TouchableNativeFeedback,
    web: TouchableHighlight,
    other: TouchableHighlight,
  });
  return (
    <Touchable
      {...props}
      Component={Component}
    />
  );
}

export function getDefaultTouchable() {
  return RnTouchableOpacity;
}
