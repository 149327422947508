import * as React from 'react';
import { Colors } from '../../config/Theme';
import { Screen } from '../../lib-components/screen/Screen';
import { Strings } from '../../../../core/src/locale/Strings';
import { SvgALaCarteLogoText } from '../../img/SvgALaCarteLogoText';
import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { ELocale } from '../../../../core/src/locale/Locale';

type TFullScreenSplashProps = {
  locale: ELocale;
  topLogo: React.ReactNode;
  backgroundColorIsLight: boolean;
  backgroundColor: string;
};

export const FullScreenSplash = ({
  locale,
  topLogo,
  backgroundColorIsLight,
  backgroundColor,
}: TFullScreenSplashProps) => (
  <Screen
    backgroundColor={backgroundColor}
    usesReactNavigationHeader={false}
    hasStatusBar={true}
    statusBarBackgroundColor={backgroundColor}
    statusBarBackgroundColorIsLight={backgroundColorIsLight}
  >
    <Content>
      <TopLogoCont>
        {topLogo}
      </TopLogoCont>
      <PoweredBy>
        <PoweredByText
          backgroundColorIsLight={backgroundColorIsLight}
        >
          {Strings.gPoweredBy()(locale)}
        </PoweredByText>
        <PoweredByCont>
          <SvgALaCarteLogoText/>
        </PoweredByCont>
      </PoweredBy>
    </Content>
  </Screen>
);

const bottomPadding = 60;
const Content = styled(View)`
  flex: 1;
  margin-bottom: ${bottomPadding}px;
`;

const TopLogoCont = styled(View)`
  align-items: center;
  flex: 3;
  justify-content: center;
`;

const PoweredBy = styled(View)`
  align-items: center;
  bottom: 0;
  flex: 1;
  flex-direction: column;
  margin-bottom: ${bottomPadding}px;
  position: absolute;
  width: 100%;
`;

const PoweredByText = styled(Text)<{ backgroundColorIsLight: boolean }>`
  color: ${({ backgroundColorIsLight }) => (backgroundColorIsLight ? Colors.black : Colors.white)};
  flex: 1;
  font-size: 11px;
  letter-spacing: 2px;
  line-height: 15px;
  margin: 0 20px 12px 20px;
  text-transform: uppercase;
`;

const PoweredByCont = styled(View)`
  align-items: center;
  flex: 5;
  flex-direction: row;
  justify-content: center;
`;
