import * as React from 'react';
import { GenericInfoView } from '../../lib-components/GenericInfoView';
import { Strings } from '../../../../core/src/locale/Strings';
import { SvgNoResult } from '../../img/SvgNoResult';
import { useCurrentLocale } from "../../hooks/hooks";

type TMenuDefaultEmptyStateProps = {
  //
};

export const MenuDefaultEmptyState = (props: TMenuDefaultEmptyStateProps) => {
  const locale = useCurrentLocale();
  return (
    <GenericInfoView
      text={Strings.gSearchBarInfoPhd()(locale)}
      icon={<SvgNoResult/>}/>
  )
};
