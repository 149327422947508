import * as React from 'react';
import { SvgDevSettings } from '../img/SvgDevSettings';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { AlaTouchableWithoutFeedback } from '../lib-components/Touchable';
import { Navigation } from '../lib/navigation/Navigation';
import { RoutesCliGuest } from '../../../core/src/lib/apis/routes/RoutableCliGuest';

type TDevFunctionsButtonProps = {
  //
};

export const DevFunctionsButton = (props: TDevFunctionsButtonProps) => {
  const onPress = React.useCallback(() => {
    Navigation.routePush(RoutesCliGuest.ScreenDevFunctions.name);
  }, []);
  return (
    <StyledRoot>
      <AlaTouchableWithoutFeedback onPress={onPress}>
        <StyledIconCont>
          <SvgDevSettings/>
        </StyledIconCont>
      </AlaTouchableWithoutFeedback>
    </StyledRoot>
  );
};

const size = 24;
const StyledRoot = styled(View)`
  bottom: ${size / 2}px;
  elevation: 99999;
  height: ${size}px;
  margin-top: ${({ theme }) => theme.dim.statusBarHeight}px;
  position: absolute;
  right: ${size / 2}px;
  width: ${size}px;
  z-index: 99999;
`;

const StyledIconCont = styled(View)`
  elevation: 99999;
  height: ${size}px;
  width: ${size}px;
  z-index: 99999;
`;
