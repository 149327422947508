// @ts-nocheck
import * as React from 'react';
import Svg,
{ Path } from 'react-native-svg';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg width={50} height={50} viewBox="0 0 50 50" fill="none" {...props}>
      <Path fill="#CE412D" stroke="#CE412D" d="M.5.5h49v49H.5z"/>
      <Path
        d="M17.054 41.081c-.486 0-.942-.066-1.368-.198a3.17 3.17 0 01-1.08-.558l.387-.846c.306.234.627.405.963.513.342.108.711.162 1.107.162.438 0 .774-.075 1.008-.225a.733.733 0 00.36-.648c0-.24-.11-.423-.333-.549-.216-.126-.579-.246-1.089-.36-.528-.114-.957-.246-1.287-.396-.33-.15-.579-.342-.747-.576-.168-.234-.252-.531-.252-.891 0-.372.102-.705.306-.999.204-.294.492-.522.864-.684.372-.168.801-.252 1.287-.252.444 0 .867.069 1.27.207.401.132.731.315.99.549l-.388.846c-.564-.45-1.188-.675-1.872-.675-.408 0-.732.084-.972.252a.784.784 0 00-.35.684c0 .246.104.435.314.567.21.132.567.255 1.071.369.534.12.966.255 1.296.405.336.144.591.33.765.558.18.228.27.516.27.864 0 .378-.102.711-.306.999-.198.282-.489.501-.873.657-.378.15-.825.225-1.34.225zm6.019-4.59c.378 0 .713.096 1.008.288.3.192.53.465.692.819.169.348.253.75.253 1.206 0 .456-.084.855-.253 1.197-.162.342-.39.606-.683.792a1.861 1.861 0 01-1.017.279c-.313 0-.591-.066-.838-.198a1.39 1.39 0 01-.566-.549v2.295h-1.125v-6.021h1.107v.666a1.36 1.36 0 01.567-.567 1.75 1.75 0 01.855-.207zm-.297 3.708c.354 0 .63-.12.828-.36.198-.246.297-.591.297-1.035 0-.456-.1-.81-.297-1.062-.198-.252-.474-.378-.828-.378-.36 0-.64.123-.837.369-.192.246-.288.597-.288 1.053 0 .45.096.798.288 1.044.198.246.477.369.837.369zm3.173-3.6h1.125V41h-1.125v-4.401zm1.179-2.043v1.107h-1.242v-1.107h1.242zm3.113 6.516c-.462 0-.864-.093-1.206-.279a1.894 1.894 0 01-.774-.792c-.18-.342-.27-.741-.27-1.197 0-.456.093-.858.279-1.206a2.01 2.01 0 01.81-.819c.348-.192.75-.288 1.206-.288.312 0 .615.051.909.153.3.096.54.228.72.396l-.324.792a2.119 2.119 0 00-.594-.342 1.703 1.703 0 00-.612-.117c-.39 0-.693.123-.91.369-.215.24-.323.588-.323 1.044 0 .45.108.798.324 1.044.216.24.519.36.909.36.204 0 .408-.039.612-.117.21-.078.408-.192.594-.342l.324.783a2.08 2.08 0 01-.738.414c-.3.096-.612.144-.936.144zm6.581-4.464l-2.646 6.012h-1.134l.801-1.791-1.836-4.221h1.189l1.242 3.105 1.277-3.105h1.107z"
        fill="#fff"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.875 10C33.151 10 34 10 34 11.125c0 .09-.029 1.498-1.19 3.52A4.789 4.789 0 0134 17.802c0 .001-.003.736-.038 1.053C32.959 27.896 17.172 28 16.5 28a.5.5 0 01-.5-.5c0-1.393.81-2.683 2.063-3.286 4.856-2.343 5.736-3.733 5.917-6.722.088-1.487.831-2.801 2.037-3.604l.122-.08A4.784 4.784 0 0128.803 13h.394c.419 0 .828.063 1.227.168.02-.031.04-.06.058-.09.024-.04.049-.079.08-.123.135-.196.307-.342.478-.487.358-.303.71-.602.71-1.343C31.75 10 32.599 10 32.875 10zm-5.901 7.672c.032-.54.29-1.01.706-1.287a.5.5 0 00-.555-.832c-.679.453-1.098 1.203-1.15 2.06a.5.5 0 10.999.059z"
        fill="#fff"
      />
    </Svg>
  );
}

export const SvgSpicyTag = React.memo(SvgComponent);
