import * as React from 'react';
import { BackHandler, Text, View } from 'react-native';
import { Button } from '../lib-components/buttons/Button';
import { Colors, Theme } from '../config/Theme';
import { Screen } from '../lib-components/screen/Screen';
import { Strings } from '../../../core/src/locale/Strings';
import { SvgAlacarteErrorState } from '../img/SvgAlacarteErrorState';
import { shadowViewStyle1 } from '../lib-components/ShadowView';

import styled from 'styled-components/native';
import { RCComp } from '../lib/routeContract/RCComp';

export class OfflineOverlayRCComp extends RCComp<'ModalOfflineOverlay'> {
  protected readonly routeName = 'ModalOfflineOverlay' as const;

  componentDidMount() {
    super.componentDidMount();
    BackHandler.addEventListener('hardwareBackPress', this.handleBackButton);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    BackHandler.removeEventListener('hardwareBackPress', this.handleBackButton);
  }

  handleBackButton = () => {
    // Return true to disable back button
    return true;
  };

  render() {
    const { locale } = this.props;
    return (
      <Screen
        backgroundColor={Theme.col.modalBackgroundOverlay}
        usesReactNavigationHeader={false}
        hasStatusBar={false}
        statusBarBackgroundColor={Colors.transparent}
        statusBarBackgroundColorIsLight={false}
      >
        <OfflineStateCont style={shadowViewStyle1}>
          <IconCont>
            <SvgAlacarteErrorState/>
          </IconCont>
          <HeaderText>{Strings.gOops()(locale)}</HeaderText>
          <SubTextCont>
            <StyledText>{Strings.gPhoneOffline()(locale)}</StyledText>
            <StyledText>{Strings.gPhoneOfflineSubtext()(locale)}</StyledText>
          </SubTextCont>
          {false && (
            <SettingsButton
              block={true}
              height={45}
              color={Colors.white}
              backgroundColor={Colors.red}
              title={Strings.gSettings()(locale).toUpperCase()}
            />
          )}
        </OfflineStateCont>
      </Screen>
    );
  }
}

const OfflineStateCont = styled(View)`
  background-color: ${({ theme }) => theme.t.col.white};
  border-radius: 2px;
  margin: ${({ theme }) => theme.scaler.verticalScale(150)}px 10px;
  padding: 40px;
`;

const IconCont = styled(View)`
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const HeaderText = styled(Text)`
  color: ${({ theme }) => theme.t.col.accentDark};
  font-family: ${({ theme }) => theme.t.font.nunitoSans};
  font-size: 24px;
  font-weight: bold;
  line-height: 33px;
  margin-top: 40px;
  text-align: center;
  text-align: center;
  text-transform: uppercase;
`;

const SubTextCont = styled(View)`
  margin-top: 10px;
`;

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.t.col.accentDark};
  font-family: ${({ theme }) => theme.t.font.nunitoSans};
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  text-align: center;
`;

const SettingsButton = styled(Button)`
  margin-bottom: 20px;
  margin-top: 40px;
`;
