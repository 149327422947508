import { DbRes } from '../../../db/DbResources';
import { MObjCreatorById } from '../../../lib/model/MObjCreator';
import { OrderItemBuilder } from './OrderItemBuilder';

class OrderItemCreatorBase extends MObjCreatorById<typeof DbRes.OrderItem, 'orderItemId'> {
  constructor() {
    super(OrderItemBuilder);
  }

  protected readonly idKey = 'orderItemId';
}

export const OrderItemCreator = new OrderItemCreatorBase();
