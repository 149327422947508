import * as React from 'react';
import { useCancelableAsyncEffect } from './useCancelableAsyncEffect';

export function usePromiseLoader<T = undefined>(run: () => Promise<T>): [boolean, T | null] {
  const value = React.useRef<T | null>(null);
  const [loading, setLoading] = React.useState(true);
  useCancelableAsyncEffect(async () => {
    value.current = await run();
    setLoading(false);
  }, []);
  return [loading, value.current];
}
