import * as React from 'react';
import { FIELD_NAME } from '../../../../core/src/db/DbDefs';
import { RatioedImage } from '../RatioedImage';
import { StyledComponentsTheme } from '../../lib-components/theme/StyledComponentsTheme';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { FakeSearchBar } from '../../lib-components/FakeSearchBar';
import { Strings } from '../../../../core/src/locale/Strings';
import { LangButton } from '../hooks/useAppLocale';
import { calculateRatio, TCalculateRatioParams, } from '../../lib/calculateRatio';
import { AlaTextBold } from '../Text';
import { useCurrentLocale } from "../../hooks/hooks";

const hostPlaceholderPng = require('../../img/host-placeholder.png');

type THomeHeaderProps = {
  hostData: THost;
  onSearchPress: () => void;
};

export const HomeHeader = ({
                             hostData,
                             onSearchPress,
                           }: THomeHeaderProps) => {
  const locale = useCurrentLocale();
  const { theme } = React.useContext(StyledComponentsTheme.Context);
  const scale: TCalculateRatioParams = {
    width: theme.dim.width,
    ratio: 7 / 5,
    maxHeight: theme.dim.drawableHeight / 3,
  };
  return (
    <Header scale={scale}>
      <RatioedImage
        scale={scale}
        source={{ uri: hostData.coverImg }}
        defaultSource={hostPlaceholderPng}
        resizeMode={'cover'}
        overlayOpacity={0.4}
      />
      <HeaderOverlay scale={scale}>
        <HeaderOverlayHostInfo>
          <HeaderHostName numberOfLines={1}>
            {hostData[FIELD_NAME]}
          </HeaderHostName>
          <HeaderHostDesc numberOfLines={1}>
            {hostData.description}
          </HeaderHostDesc>
        </HeaderOverlayHostInfo>
        <HeaderOverlayActionsCont>
          <HeaderOverlayActionsWrapper>
            <HeaderOverlayActionsSearchCont>
              <FakeSearchBar
                height={headerActionsHeight}
                text={Strings.gSearchBtn()(locale)}
                onPress={onSearchPress}
              />
            </HeaderOverlayActionsSearchCont>
            <LangButton
              height={headerActionsHeight}
            />
          </HeaderOverlayActionsWrapper>
        </HeaderOverlayActionsCont>
      </HeaderOverlay>
    </Header>
  );
};

const headerActionsHeight = 30;

type THeaderProps = {
  scale: TCalculateRatioParams;
};

const Header = styled(View)<THeaderProps>`
  flex: 1;
  height: ${(props) => calculateRatio(props.scale).height}px;
  max-height: ${(props) => calculateRatio(props.scale).height}px;
  width: 100%;
`;

const HeaderOverlay = styled(View)<THeaderProps>`
  elevation: 2;
  height: ${(props) => calculateRatio(props.scale).height}px;
  justify-content: center;
  left: 0;
  max-height: ${(props) => calculateRatio(props.scale).height}px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
`;

const HeaderOverlayHostInfo = styled(View)`
  align-items: center;
  width: 100%;
`;

const HeaderOverlayActionsCont = styled(View)`
  bottom: 0;
  position: absolute;
  width: 100%;
`;

const HeaderOverlayActionsWrapper = styled(View)`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.scaler.verticalScale(22)}px;
  padding: 0 ${({ theme }) => theme.scaler.scale(22)}px;
`;

const HeaderOverlayActionsSearchCont = styled(View)`
  flex: 1;
`;

const HeaderHostName = styled(AlaTextBold)`
  color: ${({ theme }) => theme.t.col.white};
  font-size: 30px;
  max-width: 80%;
`;

const HeaderHostDesc = styled(AlaTextBold)`
  color: ${({ theme }) => theme.t.col.white};
  font-size: 12px;
  letter-spacing: 2px;
  margin-top: 6px;
  max-width: 90%;
  text-transform: uppercase;
`;
