import * as React from 'react';
import { OmitFirstParameter } from '../../../core/src/lib/Types';
import { useSyncedDataRef } from './useSyncedDataRef';

export function useItemCallback<I, CB extends (item: I, ...args: any) => any>(item: I, callback: CB) {
  const itemRef = useSyncedDataRef(item);
  const callbackRef = useSyncedDataRef(callback);
  return React.useCallback((...args: any) => {
    return callbackRef.current(itemRef.current, ...args);
  }, []) as OmitFirstParameter<CB>;
}
