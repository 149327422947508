import {
  FIELD_NAME,
  TLocalizableObj,
  TObjList,
  TSortable,
} from '../../../db/DbDefs';
import {
  TIngredient,
  TIngredientId,
} from '../ingredient/IngredientTypes';
import {
  TMObj,
  TMObjDataCreator,
  TMObjMaybeDataCreator,
} from '../../../lib/model/ModelTypes';
import { TMenuItemId } from '../menuItem/MenuItemTypes';
import { TRKOrderItem } from '../../../db/DbResources';
import { TStationId } from '../station/StationTypes';

export const orderItemLocalizedFields = [
  FIELD_NAME,
  'description',
] as const;

export type TOrderItemLocalizedFields = {
  [key in ((typeof orderItemLocalizedFields)[number])]: string
};

export type TOrderItemUpdate = TMObjMaybeDataCreator<TRKOrderItem>;
export type TOrderItemCreate = TMObjDataCreator<TRKOrderItem>;

export type TOrderItemId = string;
export type TOrderItem = TMObj<TRKOrderItem> & TSortable & TLocalizableObj<TOrderItemLocalizedFields> & {
  menuItemId: TMenuItemId;
  allergens?: string;
  tags?: string;
  stationId: TStationId;
  itemImg: string;
  precedenceNumber: number;
  shareCount: number;
  price: number;
  discount: number;
  paid: boolean;
  ingredients?: TObjList<TIngredientId, TIngredient>;

  /**
   * Batching start-ms that will by applied to this order-item
   * If not set Host.guestOrderBatchingTTLMs will be used
   * Overrides Order.orderBatchingTTLMs
   */
  // orderItemBatchingTTLMs?: number; todo feature of having custom batching per order item dropped
};
