import * as React from 'react';
import { Class } from '../../../../core/src/lib/Types';
import { RCCompContracts } from '../navigation/routes/RCCompContracts';
import { connect } from 'react-redux';
import { TState } from '../../redux/Store';
import { RouteContract, TRCDispatchProps, TRCOwnProps, TRCStateProps } from './RouteContract';
import { TDispatch } from '../../../../lib-react/src/redux/redux';
import { withRCComp } from './withRCComp';
import { RCComp } from './RCComp';
import { TRoutesCliGuestNames } from '../../../../core/src/lib/apis/routes/RoutableCliGuest';

type TBuildRCCompState<S> = S & {
  __state: TState;
};

export function buildRCComp<RK extends TRoutesCliGuestNames, C extends RCComp<RK>>(name: RK, Component: Class<C>) {
  const contract = RCCompContracts[name] as RouteContract<any>;
  return connect(
    function mapStateToProps(state: TState, ownProps: TRCOwnProps<RK>) {
      const nav = RouteContract.ownPropsToNavParams(name, ownProps);
      return <TBuildRCCompState<TRCStateProps<RK>>>{
        ...contract.mapStateToProps(state, nav),
        __state: state,
      };
    },

    function mapDispatchToProps(dispatch, ownProps: TRCOwnProps<RK>) {
      return { ...contract.mapDispatchToProps(dispatch as TDispatch, ownProps) };
    },

    function mergeProps(
      stateProps: TBuildRCCompState<TRCStateProps<RK>>,
      dispatchProps: TRCDispatchProps<RK>,
      ownProps: TRCOwnProps<RK>,
    ) {
      const {
        __state,
        ...otherStateProps
      } = stateProps;

      return {
        ...otherStateProps,
        ...dispatchProps,
        ...ownProps,
        shouldSkipToRoute: contract.shouldSkipToRoute(__state, ownProps as any),
      };
    },
  )(withRCComp(name, Component) as any) as any as React.ComponentType;
}
