import * as _ from 'lodash';
import { DbRes } from '../../../db/DbResources';
import { MObjCreator } from '../../../lib/model/MObjCreator';
import {
  MenuBuilder,
  TMenuPathBuilderParams,
} from './MenuBuilder';
import { TMObjDataCreator } from '../../../lib/model/ModelTypes';
import { TMenu } from './MenuTypes';
import { uuid } from '../../../lib/UUID';

class MenuCreatorBase extends MObjCreator<typeof DbRes.Menu> {
  constructor() {
    super(MenuBuilder);
  }

  buildNew(pbp: TMenuPathBuilderParams, data: TMObjDataCreator<typeof DbRes.Menu>, id: string = uuid()): TMenu {
    const newPbp: TMenuPathBuilderParams = {
      hostId: pbp.hostId,
      menuIds: [..._.dropRight((pbp.menuIds || [])), id],
    };
    const newMenu = super.buildNew(newPbp, data, id);
    return newMenu;
  }
}

export const MenuCreator = new MenuCreatorBase();
