import { TState } from '../redux/Store';
import {
  RCStackScreenProps,
  RouteContract,
  TOutNavParams,
  TRCMergedCheckedProps,
  TRCOwnProps,
} from '../lib/routeContract/RouteContract';
import { CardStyleInterpolators, StackNavigationOptions } from '@react-navigation/stack';
import { TNavigationParamsWithMethod } from '../lib/navigation/Navigation';
import { NavConst } from '../lib/navigation/NavConst';
import { CheckPropsResultBuild } from '../lib/routeContract/RouteContractCheckProps';

export class RouteHostMenuItemViewContract extends RouteContract<'ScreenHostMenuItemView'> {
  protected readonly routeName = 'ScreenHostMenuItemView' as const;

  onBeforeRemoveShouldAllowBack(props: TRCMergedCheckedProps<'ScreenHostMenuItemView'>) {
    return true;
  }

  navigationOptions(props: RCStackScreenProps<TOutNavParams<'ScreenHostMenuItemView'>>, navParams: TOutNavParams<'ScreenHostMenuItemView'>): StackNavigationOptions {
    return {
      headerShown: true,
      ...NavConst.stackNavOptionsForCenteredUppercaseTitle,
      cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
    };
  }

  shouldSkipToRoute(state: TState, ownProps: TOutNavParams<'ScreenHostMenuItemView'>): TNavigationParamsWithMethod<any> | undefined {
    return undefined;
  }

  mapStateToProps(state: TState, navParams: TOutNavParams<'ScreenHostMenuItemView'>) {
    const menuItemId = navParams.pathParams.menuItemId;
    const { hostData } = state.host;
    return {
      menuItemId,
      locale: state.app.locale,
      language: state.app.language,
      openOrder: state.guestSession.openOrder,
      menuItem: hostData?.items?.[menuItemId],
    };
  }

  mapDispatchToProps(dispatch: any, ownProps: TRCOwnProps<'ScreenHostMenuItemView'>) {
    return {};
  }

  checkProps(props: ReturnType<this['mapStateToProps']>) {
    const { menuItem } = props;

    if (menuItem == null) {
      return CheckPropsResultBuild.WAIT();
    }

    return CheckPropsResultBuild.READY({
      ...props,
      menuItem,
    });
  }
}
