import * as React from 'react';
import { useWindowDimensions } from 'react-native';
import RenderHtml from 'react-native-render-html';

type TProps =  {
  html: string,
  tagsStyles: any;
  disableLinks?: boolean
};

export const ReactNativeHTMLRenderer = ({ html, tagsStyles, disableLinks = false }: TProps) => {
  const { width } = useWindowDimensions();

  const renderersProps = React.useMemo(() => {
    return {
      ...(disableLinks ? {
        a: {
          onPress: () => undefined
        }
      } : {})
    };
  }, [disableLinks])

  return (
    <RenderHtml
      contentWidth={width * 0.8}
      source={{ html }}
      tagsStyles={tagsStyles}
      renderersProps={renderersProps}
    />
  )
};