/**
 * The react-native-collapsible sometimes gets the
 * height of it's children wrong, to workaround this
 * we need to a fixed and pre-calculated height
 */
import * as _ from 'lodash';
import { TOrderItem } from '../../../../core/src/models/db/orderItem/OrderItemTypes';

export const LockedOrdersListConst = {
  nameHeight: 42,
  padding: 10,
  ingredientLineHeight: 22,
  ingredientPadding: 2,

  calcIngredientsHeight(orderItem: TOrderItem) {
    if (_.size(orderItem.ingredients) <= 0) {
      return 0;
    }
    return _.sum([
      this.ingredientLineHeight * _.size(orderItem.ingredients),
      this.ingredientPadding,
    ]);
  },

  calcSingleItemHeight(orderItem: TOrderItem) {
    if (_.size(orderItem.ingredients) <= 0) {
      return this.nameHeight;
    }

    return _.sum([
      this.nameHeight,
      this.calcIngredientsHeight(orderItem),
      this.padding,
    ]);
  },

  calcContainerHeight(orderItems: TOrderItem[]) {
    if (_.size(orderItems) <= 0) {
      return 0;
    }

    return _.sum(orderItems.map((orderItem) => this.calcSingleItemHeight(orderItem)));
  },

  calcSingleItemOffset(orderItems: TOrderItem[], itemIndex: number) {
    return orderItems.reduce((offset, orderItem, index) => {
      return index < itemIndex
        ? offset + this.calcSingleItemHeight(orderItem)
        : offset;
    }, 0);
  },
};

