import * as React from 'react';
import { PropsWithChildren } from 'react';
import { FIELD_ID, TObjList } from '../../../../core/src/db/DbDefs';
import { TIngredient, TIngredientId } from '../../../../core/src/models/db/ingredient/IngredientTypes';
import * as fp from 'lodash/fp';

const defaultOrderItemIngredientContext = {
  availableIngredients: {} as TObjList<TIngredientId, TIngredient>,
  selectedIngredients: {} as TObjList<TIngredientId, TIngredient>,
  onIngredientToggle: (() => undefined) as ((ingredient: TIngredient) => void),
};

export const OrderItemIngredientContext = React.createContext(defaultOrderItemIngredientContext);

type TOrderItemIngredientContextProviderProps = {
  availableIngredients: TObjList<TIngredientId, TIngredient>;
  initialSelectedIngredients: TObjList<TIngredientId, TIngredient>;
};

export const OrderItemIngredientContextProvider = ({
  children,
  availableIngredients,
  initialSelectedIngredients,
}: PropsWithChildren<TOrderItemIngredientContextProviderProps>) => {
  const [selectedIngredients, setSelectedIngredients] = React.useState(initialSelectedIngredients);

  const onIngredientToggle = React.useCallback((ingredient: TIngredient) => {
    const id = ingredient[FIELD_ID];
    const selected = selectedIngredients[id];

    const update = selected
      ? fp.omit(id, selectedIngredients)
      : fp.set(id, ingredient, selectedIngredients);

    setSelectedIngredients(update);
  }, [selectedIngredients, setSelectedIngredients]);

  return (
    <OrderItemIngredientContext.Provider value={{
      availableIngredients,
      selectedIngredients,
      onIngredientToggle,
    }}>
      {children}
    </OrderItemIngredientContext.Provider>
  );
};
