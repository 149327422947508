import { Config } from './Config';
import { KeySubscriber as KeySubscriberBase } from '../lib/KeySubscriber';
import { Logger } from '../lib/Logger';

export const Log = new Logger({
  name: Config.appName,
  env: Config.env,
  isCI: Config.isCI,
});

export const KeySubscriber = new KeySubscriberBase();
