import { DbRes } from '../../../db/DbResources';
import { MObj } from '../../../lib/model/MObj';
import { RoomBuilder } from './RoomBuilder';
import { TRoom } from './RoomTypes';

export class Room extends MObj<typeof DbRes.Room> {
  constructor(room: TRoom) {
    super(RoomBuilder, room);
  }
}
