import { DbRes } from '../../../db/DbResources';
import {
  EOrderCreatedBy,
  EOrderStatus,
  TOrder,
  TOrderCreate,
} from './OrderTypes';
import { MObjCreatorById } from '../../../lib/model/MObjCreator';
import {
  OrderBuilder,
  TOrderPathBuilderParams,
} from './OrderBuilder';

class OrderCreatorBase extends MObjCreatorById<typeof DbRes.Order, 'orderId'> {
  buildOpenOrderBase(): TOrderCreate {
    return {
      status: EOrderStatus.SELECTING,
      createdBy: EOrderCreatedBy.GUEST,
      items: {},
    };
  }

  buildGuestOpenOrder(pbp: Omit<TOrderPathBuilderParams, 'orderId'>): TOrder {
    return this.buildNew(pbp, this.buildOpenOrderBase());
  }

  buildHostOpenOrder(pbp: Omit<TOrderPathBuilderParams, 'orderId'>): TOrder {
    return this.buildNew(pbp, {
      ...this.buildOpenOrderBase(),
      createdBy: EOrderCreatedBy.HOST,
    });
  }

  constructor() {
    super(OrderBuilder);
  }

  protected readonly idKey = 'orderId';
}

export const OrderCreator = new OrderCreatorBase();
