// @ts-nocheck
import * as React from 'react';
import Svg, { Circle, ClipPath, Defs, G, Path } from "react-native-svg"


function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg
      width={50}
      height={50}
      fill="none"
      viewBox={"0 0 60 60"}
      {...props}
    >
      <G clipPath="url(#a)">
        <Path fill="#fff" d="M0 0h60v60H0z"/>
        <Path fill="#D1D1D1" stroke="#D1D1D1" d="M.5.5h59v59H.5z"/>
        <Path
          d="M14.709 51c1.266 0 2.148-.912 2.148-2.208 0-1.32-.906-2.232-2.208-2.232h-1.68V51h1.74Zm-.156-3.54c.804 0 1.278.492 1.278 1.332 0 .822-.45 1.308-1.218 1.308h-.672v-2.64h.612ZM17.899 51l.318-.936h1.434l.312.936h1.032l-1.578-4.44h-.948L16.885 51H17.9Zm.918-2.7c.054-.162.102-.33.12-.432.018.108.072.282.12.432l.318.948H18.5l.318-.948Zm3.667-1.74h-.972V51h.972v-4.44ZM24.382 51v-1.554h.75L25.798 51h1.062l-.78-1.764c.492-.252.738-.66.738-1.236 0-.918-.612-1.44-1.692-1.44H23.41V51h.972Zm0-3.576h.744c.432 0 .666.204.666.576 0 .378-.246.594-.672.594h-.738v-1.17Zm4.14 1.89V51h.972v-1.686l1.5-2.754h-1.056l-.744 1.434c-.072.144-.12.258-.174.366a4.521 4.521 0 0 0-.168-.36l-.744-1.44h-1.086l1.5 2.754Zm7.112-.048v-.876h-1.56v-.93h1.854v-.9h-2.826V51h.972v-1.734h1.56ZM37.566 51v-1.554h.75L38.982 51h1.062l-.78-1.764c.492-.252.738-.66.738-1.236 0-.918-.612-1.44-1.692-1.44h-1.716V51h.972Zm0-3.576h.744c.432 0 .666.204.666.576 0 .378-.246.594-.672.594h-.738v-1.17ZM43.545 51v-.9h-1.848v-.918h1.638v-.852h-1.638v-.87h1.848v-.9h-2.82V51h2.82Zm3.615 0v-.9h-1.848v-.918h1.638v-.852h-1.638v-.87h1.848v-.9h-2.82V51h2.82ZM33 13h1c.352 0 .654-.249.654-.5v-2.025c0-.252-.313-.475-.665-.475H26.05c-.352 0-.665.223-.665.475V12.5c0 .251.313.5.665.5H27c-.156 1.76-1.106 2.78-2.592 4.177l-.548.503A4.167 4.167 0 0 0 23 20.22V33.85c0 1.172 1.33 2.15 3.011 2.15h7.978C35.63 36 37 35.05 37 33.85V20.333c0-.95-.313-1.9-.9-2.765l-.938-.894C33.832 15.446 33.039 14.592 33 13Z"
          fill="#fff"
        />
        <Circle cx={30} cy={23} r={17} stroke="#fff" strokeWidth={2}/>
        <Path
          fill="#fff"
          stroke="#D1D1D1"
          d="m43.986 6.892 2.122 2.122-30.094 30.093-2.122-2.121z"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M0 0h60v60H0z"/>
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export const SvgDairyFreeFullIcon = React.memo(SvgComponent);
