import { ConnectedClass, TNavigationProp } from '../redux/ConnectedClass';
import { TDispatch } from '../../../../lib-react/src/redux/redux';
import { Log } from '../../config/Log';
import * as _ from 'lodash';
import { TInjectedNavParams } from '../../redux/sagas/navigation/sagaDefaultNavigationParams';
import { actionNavigate } from '../../redux/navigation/actionNavigate';
import { NavigationState } from '@react-navigation/routers/src/types';
import { actions } from '../../redux/Actions';
import { UndefinedOptional } from '../../../../core/src/lib/Types';
import {
  TRoutesCliGuestInParams,
  TRoutesCliGuestInPathParams,
  TRoutesCliGuestNames,
} from '../../../../core/src/lib/apis/routes/RoutableCliGuest';

export type TInNavParams<RK extends TRoutesCliGuestNames> = UndefinedOptional<{
  pathParams: Omit<TRoutesCliGuestInPathParams[RK], keyof TInjectedNavParams>;
  params: Omit<TRoutesCliGuestInParams[RK], keyof TInjectedNavParams>;
}>;

export type TNavigationParams<RK extends TRoutesCliGuestNames> = {
  routeName: RK;
  params: TInNavParams<RK>;
};

export type TNavigationParamsWithMethod<R extends TRoutesCliGuestNames> = TNavigationParams<R> & {
  method: 'push' | 'replace' | 'reset' | 'navigate';
};

// todo "= defaultParams" as any is a workaround to allow us to not have to specify
// empty parameters all over, however the API needs to be fixed
const defaultParams = {
  params: {},
  pathParams: {},
};

const stubbedNavigation = {
  dispatch: _.noop,
  navigate: _.noop,
  canGoBack: () => false,
  goBack: _.noop,
  setOptions: _.noop,
  setParams: _.noop,
  dangerouslyGetState() {
    return {
      name: '',
      routes: [],
    };
  },
} as any as TNavigationProp;

class NavigationBase implements ConnectedClass {
  private readonly previousRouteTitleKey = '__pt';

  navigation: TNavigationProp = null as any;

  dispatch: TDispatch = null as any;

  get nav() {
    if (this.navigation == null) {
      Log.v('Navigation2', 'nav', 'navigation is not set, returning stub');
      return stubbedNavigation;
    }

    return this.navigation;
  }

  get disp() {
    if (this.dispatch == null) {
      Log.v('Navigation2', 'disp', 'dispatch is not set, returning stub');
      return () => undefined;
    }

    return this.dispatch;
  }

  readonly setNavigation = (navigation: TNavigationProp) => {
    this.navigation = navigation;
    return this;
  };

  readonly setDispatch = (dispatch: TDispatch) => {
    this.dispatch = dispatch;
    return this;
  };

  readonly goBack = <R extends TRoutesCliGuestNames>() => {
    const action = actions.actionTypeNavigationBack();
    Log.v('Navigation', 'routeReplace', `Navigating back with`, action);
    this.disp(action);
  };

  readonly routeReplace = async <RK extends TRoutesCliGuestNames>(routeName: RK, params: TInNavParams<RK>) => {
    const action = actionNavigate({
      routeName,
      params,
      method: 'replace' as const,
    });
    Log.v('Navigation', 'routeReplace', `Navigating to ${routeName}`);
    await this.disp(action);
  };

  readonly routePush = async <RK extends TRoutesCliGuestNames>(routeName: RK, params: TInNavParams<RK> = defaultParams as any) => {
    const action = actionNavigate({
      routeName,
      params,
      method: 'push' as const,
    });
    Log.v('Navigation', 'routeReplace', `Navigating to ${routeName}`);
    await this.disp(action);
  };

  readonly routeReset = async <RK extends TRoutesCliGuestNames>(routeName: RK, params: TInNavParams<RK> = defaultParams as any) => {
    const action = actionNavigate({
      routeName,
      params,
      method: 'reset' as const,
    });
    Log.v('Navigation', 'routeReplace', `Navigating to ${routeName}`);
    await this.disp(action);
  };

  readonly navigate = async <RK extends TRoutesCliGuestNames>(routeName: RK, params: TInNavParams<RK> = defaultParams as any) => {
    const action = actionNavigate({
      routeName,
      params,
      method: 'navigate' as const,
    });
    Log.v('Navigation', 'navigate', `Navigating to ${routeName}`);
    await this.disp(action);
  };

  readonly hideRouteIfCurrent = <RK extends TRoutesCliGuestNames>(routeName: RK) => {
    if (this.isCurrentRoute(routeName)) {
      this.goBack();
    }
  };

  readonly isCurrentRoute = <RK extends TRoutesCliGuestNames>(routeName: RK) => {
    return this.getCurrentRouteName() === routeName;
  };

  readonly getCurrentRouteName = () => {
    const state = this.nav.dangerouslyGetState();
    const navRoutes = state.routes;
    const route = navRoutes.length >= 1
      ? navRoutes[navRoutes.length - 1]
      : state;
    return _.get(route, `name`);
  };

  readonly getRouteParams = () => {
    const state = this.nav.dangerouslyGetState();

    // Traverse navigation stack to find route params
    // We're looking for our TInjectedNavParams parameters
    const params = {} as Partial<TInjectedNavParams>;

    function traverseRoute(route: NavigationState['routes'][number]) {
      _.forOwn(_.get(route, 'params'), (value, key) => {
        params[key] = value;
      });

      _.forEach(_.get(route, 'state.routes', []), traverseRoute);
    }

    _.forEach(state.routes, traverseRoute);

    return params;
  };

  readonly previousRouteTitle = () => {
    const state = this.nav.dangerouslyGetState();

    const navRoutes = state.routes;
    const route = navRoutes.length >= 2
      ? navRoutes[navRoutes.length - 2]
      : state;
    return _.get(route, `params.${this.previousRouteTitleKey}`);
  };

  readonly setScreenTitle = (title: string) => {
    this.nav.setOptions({
      title,
      headerTitle: title,
    });
    this.nav.setParams({ [this.previousRouteTitleKey]: title });
  };
}

export const Navigation = new NavigationBase();
