import {
  TMObj,
  TMObjDataCreator,
  TMObjMaybeDataCreator,
} from '../../../lib/model/ModelTypes';
import { TObjList } from '../../../db/DbDefs';
import {
  TOrderItem,
  TOrderItemId,
} from '../orderItem/OrderItemTypes';
import { TRKOrder } from '../../../db/DbResources';

export enum EOrderStatus {
  /**
   * Guest scanned QR code and is adding elements to the menu
   * but has not ordered yet
   */
  SELECTING = 0,

  /**
   * Guest has ordered elements,
   * Batching has started but the period that counts
   * is only after the first item has been selected
   */
  BATCHING = 1,

  /**
   * Printing is completed, order now is waiting for fullfillment
   */
  PENDING = 2,

  /**
   * Order has been Fulfilled
   */
  FULFILLED = 3,

  /**
   * Order has been paid
   */
  CLOSED = 4,

  /**
   * Order was canceled at any point after SELECTING and before CANCELED
   */
  CANCELED = 5
}

export enum EOrderCreatedBy {
  HOST = 'HOST',
  GUEST = 'GUEST',
}

export type TOrderId = string;
export type TOrder = TMObj<TRKOrder> & {
  status: EOrderStatus;
  orderedOn?: number;
  items: TObjList<TOrderItemId, TOrderItem>;
  createdBy?: EOrderCreatedBy;
};

export type TOrderUpdate = TMObjMaybeDataCreator<TRKOrder>;
export type TOrderCreate = TMObjDataCreator<TRKOrder>;
