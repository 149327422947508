// @ts-nocheck
import * as React from 'react';
import { Colors } from '../config/Theme';
import Svg,
{
  G,
  Path,
} from 'react-native-svg';

export const SvgOrderPlus = React.memo((props: React.SVGProps<SVGSVGElement>) => {
  return (
    <Svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <G opacity={1} fill={Colors.accentDark}>
        <Path d="M10 0h1v20h-1z"/>
        <Path d="M0 10h20v1H0z"/>
      </G>
    </Svg>
  );
});
