import * as React from 'react';
import { FIELD_ID } from '../../../../core/src/db/DbDefs';
import { FlatList, ListRenderItemInfo, View, } from 'react-native';
import { OrderItemExtrasList } from './OrderItemExtrasList';
import { OrderItemHeader } from './OrderItemHeader';
import { TOrder } from '../../../../core/src/models/db/order/OrderTypes';
import { TOrderItem } from '../../../../core/src/models/db/orderItem/OrderItemTypes';
import { MarginedUnderline } from '../../lib-components/Underline';
import { dbItemIdableMap } from '../../../../core/src/db/DbLib';
import { mergeAndSortOrderItems } from '../../lib/order/OrderPrecedenceSorter';
import styled from 'styled-components/native';
import { LockedOrdersListConst } from './LockedOrdersListConst';

export type TLockedOrdersProps = {
  orderDatas: TOrder[];
};

export const LockedOrdersList = ({ orderDatas }: TLockedOrdersProps) => {
  const allItems = mergeAndSortOrderItems(orderDatas);

  const renderLockedItem = React.useCallback(({ item }: ListRenderItemInfo<TOrderItem>) => {
    return (
      <LockedOrderItem
        key={item[FIELD_ID]}
        orderItem={item}
      />
    );
  }, []);
  const marginBottom = 24;
  return (
    <Root height={LockedOrdersListConst.calcContainerHeight(allItems) + marginBottom}>
      <StyledFlatList
        data={allItems}
        keyExtractor={dbItemIdableMap}
        renderItem={renderLockedItem}
        ItemSeparatorComponent={MarginedUnderline}
        getItemLayout={(data: TOrderItem[], index) => {
          return {
            index,
            length: LockedOrdersListConst.calcSingleItemHeight(data[index]),
            offset: LockedOrdersListConst.calcSingleItemOffset(data, index),
          };
        }}
      />
    </Root>
  );
};

/**
 * LockedOrderItem
 */

type TLockedOrderItemProps = {
  orderItem: TOrderItem;
};

const LockedOrderItem = ({ orderItem }: TLockedOrderItemProps) => {
  return (
    <LockedOrderItemRoot
      height={LockedOrdersListConst.calcSingleItemHeight(orderItem)}>
      <OrderItemHeaderContainer>
        <OrderItemHeader
          orderItem={orderItem}
        />
      </OrderItemHeaderContainer>
      <OrderItemExtrasListCont>
        <OrderItemExtrasList
          orderItem={orderItem}
        />
      </OrderItemExtrasListCont>
    </LockedOrderItemRoot>
  );
};

const Root = styled(View)<{ height: number }>`
  flex-grow: 0;
  height: ${({ height }) => height}px;
  max-height: ${({ height }) => height}px;
  opacity: 0.4;
`;

const StyledFlatList = styled(FlatList as new () => FlatList<TOrderItem>)`

`;

const LockedOrderItemRoot = styled(View)<{ height: number }>`
  height: ${({ height }) => height}px;
`;

const OrderItemHeaderContainer = styled(View)`
  flex: 1;
  height: ${LockedOrdersListConst.nameHeight}px;
  justify-content: center;
  max-height: ${LockedOrdersListConst.nameHeight}px;
  min-height: ${LockedOrdersListConst.nameHeight}px;
`;

const OrderItemExtrasListCont = styled(View)`
  flex: 1;
  padding-bottom: ${LockedOrdersListConst.padding};
`;
