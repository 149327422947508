import * as _ from 'lodash';
import { Log } from '../../config/Instance';
import { isBoolean } from '../HelperFunctions';

type TDeepLinkObj = {
  [k: string]: {
    Paths: string[];
    Params: any;
    Receiver: any;
  };
};

export type TDeepLinkReceivers<TDeepLinks extends TDeepLinkObj> = {
  [k in keyof TDeepLinks]?: (params: TDeepLinks[k]['Receiver']) => Promise<boolean>;
};

export type TDeepLinkParserBase<TDeepLinks extends TDeepLinkObj> = {
  [k in keyof TDeepLinks]: (dl: string) => TDeepLinks[k]['Receiver'] | undefined;
};

export type TDeepLinkParsers<TDeepLinks extends TDeepLinkObj> = TDeepLinkParserBase<TDeepLinks> & {
  keys: (keyof TDeepLinks)[];
};

export enum EDlType {
  USE_B64_PARAM = 'USE_B64_PARAM',
  DIRECT = 'DIRECT',
  REDIRECT = 'REDIRECT',
  DEFERRED = 'DEFERRED',
}

export type TResolvers = {
  type: EDlType;
  match: RegExp;
  parse: (link: string) => Promise<string | boolean | undefined | null>;
};

export type TParseRes = {
  type: EDlType;
};

export type TResolverResult = TParseRes & {
  dl: string;
};

type TDeepLinkParserParams<TDeepLinks extends TDeepLinkObj> = {
  resolvers: () => TResolvers[];
  parsers: TDeepLinkParsers<TDeepLinks>;
};

export class DeepLinkParser<TDeepLinks extends TDeepLinkObj> {
  static parserToObj(parser: TResolvers) {
    return [parser.type, parser.match.source];
  }

  static readonly maxResolutionRecursion = 10;

  private readonly getResolvers: () => TResolvers[];

  private cachedResolvers: TResolvers[] | null = null;

  private get parsers(): TResolvers[] {
    if (this.cachedResolvers == null) {
      this.cachedResolvers = this.getResolvers();
      const logVal = this.cachedResolvers.map(DeepLinkParser.parserToObj);
      Log.v('DeepLinkParser', 'parsers', `Loaded with ${JSON.stringify(logVal)}`);
      return this.cachedResolvers;
    }

    return this.cachedResolvers;
  }

  private readonly mapper: TDeepLinkParsers<TDeepLinks>;

  constructor(params: TDeepLinkParserParams<TDeepLinks>) {
    this.getResolvers = params.resolvers;
    this.mapper = params.parsers;
    this.parseOrThrow = this.parseOrThrow.bind(this);
    this.resolveDl = this.resolveDl.bind(this);
  }

  async parseOrThrow(unresolvedDl: string, receivers: TDeepLinkReceivers<TDeepLinks>): Promise<boolean> {
    const dl = await this.resolveDl(unresolvedDl);
    if (!dl) {
      return false;
    }

    Log.v('DeepLinkParserBase', `'parseOrThrow', 'Matched ${dl}`);

    // Find handler for this deeplink
    let deepLinkName: any = null;
    let deepLinkParsedParams: any = null;
    for (let i = 0; i < this.mapper.keys.length; i++) {
      const dlKey = this.mapper.keys[i];
      const result = this.mapper[dlKey](dl);
      if (result != null) {
        deepLinkName = dlKey;
        deepLinkParsedParams = result;
        break;
      }
    }

    if (deepLinkName == null) {
      Log.v('DeepLinkParserBase', `'parseOrThrow', 'deepLinkName not found for ${dl}`);
      return false;
    }

    const deepLinkReceiver = receivers[deepLinkName];
    if (deepLinkReceiver == null) {
      Log.v('DeepLinkParserBase', `'parseOrThrow', 'deepLinkReceiver not provided ${deepLinkName}`);
      return true;
    }

    Log.v('DeepLinkParserBase', `'parseOrThrow', 'Matched ${dl} with name ${deepLinkName}`);
    return deepLinkReceiver(deepLinkParsedParams);
  }

  async resolveDl(_dl: string, recursionCount: number = DeepLinkParser.maxResolutionRecursion): Promise<string | undefined> {
    const dl = `${_dl}`.trim();

    if (recursionCount <= 0) {
      Log.wtf('DeepLinkParserBase', 'resolveDlBase', `resolveLoopFailsafe reached 0 with dl ${dl}`);
      return undefined;
    }

    Log.v('DeepLinkParserBase', 'resolveDlBase', `Parsing deeplink ${dl}`);

    const parsers = this.parsers;
    for (let i = 0; i < parsers.length; i++) {
      const parser = parsers[i];
      if (parser.match.exec(dl) == null) {
        Log.v('DeepLinkParserBase', 'resolveDlBase', `No match ${dl}`);
        continue;
      }

      const result = await parser.parse(dl);
      if (_.isString(result)) {
        Log.v('DeepLinkParserBase', 'resolveDlBase', `Deeplink result is string ${result}`);
        return this.resolveDl(result, recursionCount - 1);
      }

      if (isBoolean(result)) {
        Log.v('DeepLinkParserBase', 'resolveDlBase', `Deeplink result is boolean ${result}`);
        return dl;
      }
    }

    Log.v('DeepLinkParserBase', 'resolveDlBase', `Deeplink result is undefined`);
    return undefined;
  }
}
