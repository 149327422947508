// @ts-ignore no types
import BluebirdPromise from 'bluebird';

BluebirdPromise.config({
  warnings: false,
  longStackTraces: false,
  cancellation: true,
  monitoring: false,
});

export const Bluebird = BluebirdPromise;
