import * as _ from 'lodash';
import { EIngredientType } from '../../models/db/ingredient/IngredientTypes';
import { FIELD_NAME } from '../../db/DbDefs';
import { Host } from '../../models/db/host/Host';
import { Ingredient } from '../../models/db/ingredient/Ingredient';
import { OrderItem } from '../../models/db/orderItem/OrderItem';
import { PrintBuilder } from './PrintBuilder';
import { PrintFormat } from './PrintFormat';
import {
  PrintOps,
  TPrintOp,
} from '../PrintOps';
import { Strings } from '../../locale/Strings';
import { TGuestSession } from '../../models/db/guestSession/GuestSessionTypes';
import { THost } from '../../models/db/host/HostTypes';
import { TOrderItem } from '../../models/db/orderItem/OrderItemTypes';
import { TRoom } from '../../models/db/room/RoomTypes';
import { TStationId } from '../../models/db/station/StationTypes';
import { TTable } from '../../models/db/table/TableTypes';
import { localize } from '../../lib/formatters/LocaleLib';
import { GuestSessionBuilder } from '../../models/db/guestSession/GuestSessionBuilder';
import { addIfTrueArr } from '../../lib/HelperFunctions';

type TPrintBuilderForStationOrderParams = {
  host: THost;
  guestSession: TGuestSession;
  orderItemsToPrint: TOrderItem[];
  destinationStationId: TStationId;
};

export class PrintBuilderForStationOrder extends PrintBuilder {
  private readonly guestSession: TGuestSession;

  private readonly orderItemsToPrint: TOrderItem[];

  private readonly table: TTable;

  private readonly room: TRoom;

  private readonly orderItemsByPrecedence: Record<string, TOrderItem[]>;

  constructor(params: TPrintBuilderForStationOrderParams) {
    super(params);
    this.guestSession = params.guestSession;
    this.orderItemsToPrint = params.orderItemsToPrint;

    const { tableId } = GuestSessionBuilder.getPathBuilderParamsFromDataPath(this.guestSession);
    this.table = Host.findTableByIdOrThrow(this.host, tableId);

    this.room = Host.findRoomWithTableOrThrow(this.host, this.table.roomId);
    this.orderItemsByPrecedence = _.groupBy(this.orderItemsToPrint, 'precedenceNumber');
  }

  readonly buildPrintOps = (): TPrintOp[] => {
    return _.flattenDeep([
      this.buildTableIndicatorHeaderFull({
        destinationStationName: this.destinationStation[FIELD_NAME],
        tableName: this.table[FIELD_NAME],
        roomName: this.room[FIELD_NAME],
        note: this.table.internalNote,
      }),
      PrintOps.printOpDrawLine(),
      this.buildSubHeader(),
      PrintOps.printOpNewLine(1),
      this.buildOrderItemsByPrecedence(),
    ]);
  };

  readonly buildSubHeader = (): TPrintOp[] => {
    return _.flattenDeep([
      PrintOps.printOpAlign('CT'),
      PrintOps.printOpText(`${Strings.printerClients()(this.locale)}: ${this.guestSession.numberOfGuests}`.toUpperCase()),
      PrintOps.printOpAlign('LT'),
    ]);
  };

  readonly buildOrderItemsByPrecedence = (): TPrintOp[] => {
    return _.flattenDeep(_.keys(this.orderItemsByPrecedence).map((precedenceNumber: string, index: number): TPrintOp[] => {
      const orderItemsInPrecedence = this.orderItemsByPrecedence[precedenceNumber];
      if (orderItemsInPrecedence.length <= 0) {
        return [];
      }

      // Use index + 1 to stabilize across precedence numbers
      const ps = parseInt(precedenceNumber, 10) + 1;

      const block = PrintFormat.strBlock(3);
      const symbol = Strings.printerPrecedenceSymbol()(this.locale);
      return _.flattenDeep([
        PrintOps.printOpNewLine(1),
        PrintOps.printOpSize(1, 1),
        PrintFormat.bold(`${block} ${symbol}${ps} ${block}`),
        PrintOps.printOpSize(0, 0),
        PrintOps.printOpNewLine(1),
        this.buildOrderItems(orderItemsInPrecedence),
        PrintOps.printOpNewLine(1),
      ]);
    }));
  };

  readonly buildOrderItems = (orderItems: TOrderItem[]): TPrintOp[] => {
    const groupedByEquality = OrderItem.groupByEquality(orderItems);
    return _.flattenDeep(groupedByEquality.map((orderItemsInGroup): TPrintOp[] => {
      const count = orderItemsInGroup.length;
      if (count <= 0) {
        return [];
      }

      const orderItem = orderItemsInGroup[0];
      return _.flattenDeep([
        PrintOps.printOpSize(1, 1),
        PrintFormat.bold(`${count}x ${localize(orderItem, FIELD_NAME, this.locale)}`),

        _.values(orderItem.ingredients)
          .filter(Ingredient.filterType(EIngredientType.ADDABLE))
          .sort()
          .map((ingredient) => this.printIngredient(ingredient)),

        _.values(orderItem.ingredients)
          .filter(Ingredient.filterType(EIngredientType.REMOVABLE))
          .sort()
          .map((ingredient) => this.printIngredient(ingredient)),

        _.values(orderItem.ingredients)
          .filter(Ingredient.filterType(EIngredientType.OPTION))
          .sort()
          .map((ingredient) => this.printIngredient(ingredient)),

        ...addIfTrueArr(orderItem.shareCount > 1, [
          PrintOps.printOpNewLine(1),
          PrintOps.printOpText(Strings.hOrderItemShareCount({ n: `${orderItem.shareCount}` })(this.locale)),
        ]),
        PrintOps.printOpNewLine(1),

        PrintOps.printOpSize(0, 0),
        PrintOps.printOpDrawLine(),
      ]);
    }));
  };
}
