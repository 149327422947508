import { ScreenDevFunctions } from '../../../screens/RouteDevFunctionsComp';
import { ScreenSetupScanHostQR } from '../../../screens/RouteSetupScanHostQRComp';
import { ScreenSetupGuestSession } from '../../../screens/RouteSetupGuestSessionComp';
import { RouteHostHomeComp } from '../../../screens/RouteHostHomeComp';
import { ScreenHostMenuItemSearch } from '../../../screens/RouteHostMenuItemSearchComp';
import { ScreenHostMenu } from '../../../screens/RouteHostMenuComp';
import { ScreenHostMenuItemView } from '../../../screens/RouteHostMenuItemViewComp';
import { ScreenHostOrderItemEdit } from '../../../screens/RouteHostOrderItemEditComp';
import { ScreenHostBillRequestComplete } from '../../../screens/RouteHostBillRequestCompleteComp';
import { RouteHostOrderCheckoutComp } from '../../../screens/RouteHostOrderCheckoutComp';
import { HostOrderItemModifyOptionsComp } from '../../../screens/HostOrderItemModifyOptionsComp';
import { RouteHostOrderRequestCompleteComp } from '../../../screens/RouteHostOrderRequestCompleteComp';
import { OfflineOverlayRCComp } from '../../../screens/OfflineOverlayRCComp';
import { LockOverlayRCComp } from '../../../screens/LockOverlayRCComp';
import { buildRCComp } from '../../routeContract/buildRCComp';
import { ConfirmationOverlayRCComp } from '../../../screens/ConfirmationOverlayRCComp';

export const RouteComponents = {
  ScreenDevFunctions: buildRCComp('ScreenDevFunctions', ScreenDevFunctions),
  ScreenSetupScanHostQR: buildRCComp('ScreenSetupScanHostQR', ScreenSetupScanHostQR),
  ScreenSetupGuestSession: buildRCComp('ScreenSetupGuestSession', ScreenSetupGuestSession),
  ScreenHostHome: buildRCComp('ScreenHostHome', RouteHostHomeComp),
  ScreenHostMenuItemSearch: buildRCComp('ScreenHostMenuItemSearch', ScreenHostMenuItemSearch),
  ScreenHostMenu: buildRCComp('ScreenHostMenu', ScreenHostMenu),
  ScreenHostMenuItemView: buildRCComp('ScreenHostMenuItemView', ScreenHostMenuItemView),
  ScreenHostOrderItemEdit: buildRCComp('ScreenHostOrderItemEdit', ScreenHostOrderItemEdit),
  ScreenHostOrderItemModifyOptions: buildRCComp('ScreenHostOrderItemModifyOptions', HostOrderItemModifyOptionsComp),
  ScreenHostOrderCheckout: buildRCComp('ScreenHostOrderCheckout', RouteHostOrderCheckoutComp),
  ScreenHostOrderRequestComplete: buildRCComp('ScreenHostOrderRequestComplete', RouteHostOrderRequestCompleteComp),
  ScreenHostBillRequestComplete: buildRCComp('ScreenHostBillRequestComplete', ScreenHostBillRequestComplete),
  ModalOfflineOverlay: buildRCComp('ModalOfflineOverlay', OfflineOverlayRCComp),
  ModalLockScreenOverlay: buildRCComp('ModalLockScreenOverlay', LockOverlayRCComp),
  ModalConfirmationDialog: buildRCComp('ModalConfirmationDialog', ConfirmationOverlayRCComp),
};
