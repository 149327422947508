import { FIELD_ID } from '../../../../core/src/db/DbDefs';
import {
  THost,
  THostId,
} from '../../../../core/src/models/db/host/HostTypes';
import { actions } from '../Actions';
import { defineReducer } from '../../../../lib-react/src/redux/redux';
import { THostPublicSubscription } from '../../../../core/src/models/db/HostPublicSubscription/HostPublicSubscriptionTypes';

export type TStateHost = {
  hostId: THostId | undefined;
  hostData: THost | undefined;
  hostPublicSubscription: THostPublicSubscription | undefined;
};

const initialState: TStateHost = {
  hostId: undefined,
  hostData: undefined,
  hostPublicSubscription: undefined,
};

export const {
  actionTypeHostSetHost,
  actionTypeHostSetHostPublicSubscription,
  reducer: reducerHost,
} = defineReducer<TStateHost, typeof actions>({
  actions,
  initialState,
  reducer: (state, action, getType) => {
    switch (action.type) {
      case getType(actions.pureActionTypeResetSession):
        return initialState;
      case getType(actions.pureActionTypeHostSetHostId):
        return {
          ...state,
          hostId: action.payload.hostId,
        };
      case getType(actions.actionTypeHostSetHost):
        const host = action.payload.hostData;
        return {
          ...state,
          hostId: host[FIELD_ID],
          hostData: host,
        };
      case getType(actions.actionTypeHostSetHostPublicSubscription):
        return {
          ...state,
          hostPublicSubscription: action.payload.hostPublicSubscription,
        };
    }
    return state;
  },
});
