import { EDlType, TDeepLinkParsers, TDeepLinkReceivers, TResolvers } from './DeepLinkParser';
import { THostId } from '../../models/db/host/HostTypes';
import { TTableId } from '../../models/db/table/TableTypes';
import { AlacarteUrls } from '../AlacarteUrls';
import { CommonRoutes } from '../apis/routes/common/CommonRoutes';
import { RoutableRoute } from '../define/routable/RoutableRoute';
import { AppQrManagerRoutes_STANDARD_API } from '../apis/AppQrManagerRoutes';
import fetch from 'node-fetch';
import { URLToolbox } from '../define/url-toolbox/URLToolbox';

export type TALCDeepLinks = {
  /**
   * ScreenSetupGuestSession: Navigates to a host setup
   */
  ScreenSetupGuestSession: {
    Paths: [
      THostId
    ];
    Params: {
      tableId?: TTableId;
      tableNumber?: number;
      skipGuestsCheck?: string;
    };
    Receiver: {
      hostId: THostId;
      tableId?: TTableId;
      tableNumber?: number;
      skipGuestsCheck?: boolean;
    };
  };
};

const defaultProtocols = [
  'http',
  'https',
];

/**
 * Important, keep DIRECT last as matches occur in order
 * and some may overlap
 */
export function resolveAlcDlParsers(): TResolvers[] {
  const deferredLinkURLToolbox = RoutableRoute.buildURLToolbox(CommonRoutes.DeferredDeeplink);
  const redirectURLToolbox = RoutableRoute.buildURLToolbox(AppQrManagerRoutes_STANDARD_API.redirect);
  const b64ParamURLToolbox = RoutableRoute.buildURLToolbox(AppQrManagerRoutes_STANDARD_API.defer);
  return [
    {
      type: EDlType.DEFERRED,
      async parse(link: string) {
        const parsed = deferredLinkURLToolbox.deconstructURL(link);
        return parsed.searchParams && parsed.searchParams.data;
      },
      match: deferredLinkURLToolbox.matcher({
        protocols: defaultProtocols,
        baseUrls: [
          AlacarteUrls.BASE_URL_CLI_GUEST_RN.domain,
        ],
      }),
    },
    {
      type: EDlType.REDIRECT,
      async parse(link: string) {
        const readonlyUrl = redirectURLToolbox.updateURL(link, { searchParams: { readonly: true } });
        const response = await fetch(readonlyUrl);
        const { content } = await response.json();
        return content;
      },
      match: redirectURLToolbox.matcher({
        protocols: defaultProtocols,
        baseUrls: [
          AlacarteUrls.BASE_URL_QR_MANAGER.raw,
        ],
      }),
    },
    {
      type: EDlType.USE_B64_PARAM,
      async parse(link: string) {
        const parsed = b64ParamURLToolbox.deconstructURL(link);
        return parsed.searchParams && parsed.searchParams.b64Link;
      },
      match: b64ParamURLToolbox.matcher({
        protocols: defaultProtocols,
        baseUrls: [
          AlacarteUrls.BASE_URL_QR_MANAGER.raw,
        ],
      }),
    },
    {
      type: EDlType.DIRECT,
      async parse(link: string) {
        return this.match.test(link);
      },
      match: URLToolbox.buildDomainMatcherRegexp({
        protocols: defaultProtocols,
        baseUrls: [
          AlacarteUrls.BASE_URL_CLI_GUEST_RN.raw,
          AlacarteUrls.BASE_URL_CLI_GUEST_WEB.raw,
        ],
        postfix: '(?:\\/([^/]*))([^?]*)\\??(.*)',
      }),
    },
  ];
}

export type TALCDLReceiver = TDeepLinkReceivers<TALCDeepLinks>;
export type TALCDLParser = TDeepLinkParsers<TALCDeepLinks>;
