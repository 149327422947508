import { THostId } from '../../../../../core/src/models/db/host/HostTypes';
import { TTableId } from '../../../../../core/src/models/db/table/TableTypes';
import { select } from 'redux-saga/effects';
import { TState } from '../../Store';
import { ELocale } from '../../../../../core/src/locale/Locale';

export type TInjectedNavParams = {
  storeLocale?: ELocale;
  storeHostId: THostId;
  storeTableId: TTableId;
  storeTableNumber: string;
  storeSkipGuestsCheck: string;
};

export function* defaultNavParams() {
  return <TInjectedNavParams>{
    storeLocale: yield select((store: TState) => store.app.locale),
    storeHostId: yield select((store: TState) => store.host.hostId),
    storeTableId: yield select((store: TState) => store.guestSession.tableId),
  };
}
