import { DbPaths } from '../../../db/DbRefs';
import {
  DbRes,
  TDbResData,
  TDbResPbp,
} from '../../../db/DbResources';
import { FIELD_PATH } from '../../../db/DbDefs';
import { MObjDef } from '../../../lib/model/MObjDef';
import { Menu } from './Menu';
import { TMenu } from './MenuTypes';
import { defMenu } from '../ModelDefaults';

const pathBuilderKeys = [
  'hostId',
  'menuIds',
] as const;

export type TMenuPathBuilderParams = Omit<{ [key in ((typeof pathBuilderKeys)[number])]: string; }, 'menuIds'> & {
  menuIds?: string[];
};

class MenuBuilderBase extends MObjDef<typeof DbRes.Menu> {
  readonly resourceKey = DbRes.Menu;

  protected readonly pathBuilderKeys = [...pathBuilderKeys];

  protected readonly _default = defMenu;

  protected newChild(item: TMenu) {
    return new Menu(item);
  }

  pathBuilder(params: TMenuPathBuilderParams): string {
    return DbPaths.hostMenus(params);
  }

  getPathBuilderParamsFromDataPath(data: TDbResData[typeof DbRes.Menu]): TDbResPbp[typeof DbRes.Menu] {
    const path = data[FIELD_PATH];
    const match = /\/hosts\/([^\/]*)*\/menu(.*)/.exec(path);
    if (!match) {
      throw new Error('MenuBuilder, getPathBuilderParamsFromDataPath, not a menu path');
    }

    const pbp = {
      hostId: match[1],
      menuIds: [] as string[],
    };

    let remainingPath = match[2] || '';
    while (remainingPath.length > 0) {
      const parse = /\/subMenus\/([^\/]*)*/.exec(remainingPath);
      if (!parse) {
        return pbp;
      }
      pbp.menuIds.push(parse[1]);
      remainingPath = remainingPath.replace(parse[0], '');
    }

    return pbp;
  }
}

export const MenuBuilder = new MenuBuilderBase();

