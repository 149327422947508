import {
  buildScaler,
  TDimensions,
  TScaler,
  useGetDimensions,
} from './Dim';
import { ThemeProvider } from 'styled-components/native';
import React from 'react';

export type TStyledComponentsTheme<T> = {
  t: T;
  dim: TDimensions;
  scaler: TScaler;
};

const StyledComponentsThemeContext = React.createContext({
  theme: ({} as TStyledComponentsTheme<any>),
  setTheme<T = any>(theme: T) {
  },
});

type TThemeContextProviderProps<T> = {
  initialTheme: T;
  children: React.ReactNode;
};

function useBuildThemeBuilder<T>() {
  const dimensions = useGetDimensions();
  return React.useCallback((theme: T) => {
    return {
      t: theme,
      dim: dimensions,
      scaler: buildScaler(dimensions),
    };
  }, [dimensions]);
}

function StyledComponentsThemeProvider<T>({
  initialTheme,
  children,
}: TThemeContextProviderProps<T>) {
  const buildTheme = useBuildThemeBuilder<T>();
  const [theme, setTheme] = React.useState<TStyledComponentsTheme<T>>(buildTheme(initialTheme));

  const updateTheme = React.useCallback((updatedTheme: T) => {
    setTheme(buildTheme(updatedTheme));
  }, [setTheme, buildTheme]);

  return (
    <StyledComponentsThemeContext.Provider
      value={{
        theme,
        setTheme: updateTheme as any,
      }}>
      <ThemeProvider
        theme={theme as any}>
        {children}
      </ThemeProvider>
    </StyledComponentsThemeContext.Provider>
  );
}

export const StyledComponentsTheme = {
  Provider: StyledComponentsThemeProvider,
  Context: StyledComponentsThemeContext,
};
