import * as _ from 'lodash';
import {
  EMenuItemState,
  TMenuItem,
  TMenuItemId,
} from './MenuItemTypes';
import {
  FIELD_CREATED_ON,
  FIELD_ID,
  FIELD_NAME,
  FIELD_PATH,
  FIELD_SORTABLE_INDEX,
  FIELD_UPDATED_ON,
  TObjList,
} from '../../../db/DbDefs';
import { MObj } from '../../../lib/model/MObj';
import { MenuItemBuilder } from './MenuItemBuilder';
import { MenuItemCreator } from './MenuItemCreator';
import { StRefs } from '../../../db/StRefs';
import { TRKMenuItem } from '../../../db/DbResources';
import { TTag } from '../../Tag';
import { buildRawUpdate } from '../../dbUpdaters/lib/buildRawUpdate';
import { menuItemUpdate } from '../../dbUpdaters/menuItemUpdate';
import { TOrderItemCreate } from '../orderItem/OrderItemTypes';
import { dbItemLocalizeableNameableUncasedIncludeCompare } from '../../../db/DbLib';
import { THost } from '../host/HostTypes';
import {TLanguage} from '../../../locale/Languages';

export class MenuItem extends MObj<TRKMenuItem> {
  static readonly tags = [
    '#frozen',
    '#spicy',
    '#vegan',
    '#vegetarian',
    '#caffeinefree',
    '#dairyfree',
    '#glutenfree',
    '#new',
    '#nutsfree',
    '#recommended',
    '#sesamefree',
  ];

  static getTagAsSvgUrl(tag: TTag): Promise<string> {
    return StRefs.privateTag({ tagId: tag }).getDownloadUrl();
  }

  static filterByString({ items }: Pick<THost, 'items'>, filterString = '', language?: TLanguage): TObjList<TMenuItemId, TMenuItem> {
    if (filterString.length <= 0) {
      return items || {};
    }

    return _.chain(items)
      .values()
      .filter((menuItem) => dbItemLocalizeableNameableUncasedIncludeCompare(menuItem, filterString, language))
      .keyBy(FIELD_ID)
      .value();
  }

  static toLocalOrderItemData(item: TMenuItem): TOrderItemCreate {
    return _.cloneDeep({
      ..._.omit(item, [
        FIELD_ID,
        FIELD_PATH,
        FIELD_CREATED_ON,
        FIELD_UPDATED_ON,
        'shareable',
        'status',
        'menuIds',
      ]),
      menuItemId: item[FIELD_ID],
      ingredients: {},
      precedenceNumber: 0,
      discount: 0,
      shareCount: 0,
      paid: false,
      [FIELD_SORTABLE_INDEX]: 0,
    });
  }

  readonly updateMenuItem = buildRawUpdate(this, 'MenuItem/*', menuItemUpdate, (update) => {
    return this.updateFields(update);
  });

  constructor(menuItem: TMenuItem) {
    super(MenuItemBuilder, menuItem);
  }

  duplicate = async () => {
    await MenuItemCreator.remoteSaveNewToPath(this.pbp(), this.item);
  };

  publish = async () => {
    await this.updateFields({ status: EMenuItemState.ACTIVE });
  };

  unPublish = async () => {
    await this.updateFields({ status: EMenuItemState.INACTIVE });
  };

  updateName = async (newName: string) => {
    await this.updateFields({ [FIELD_NAME]: newName });
  };
}
