import * as React from 'react';
import { HeaderBackButton as RnHeaderBackButton } from '@react-navigation/stack';
import { isWeb } from '../lib/Platform';
import styled from 'styled-components/native';
import { View } from 'react-native';

type THeaderBackButtonProps = {
  label: string | undefined;
  onPress: () => void;
};

export const HeaderBackButton = ({
  label,
  onPress,
}: THeaderBackButtonProps) => {
  if (isWeb) {
    return null;
  }

  return (
    <Container>
      <RnHeaderBackButton
        label={label || ''}
        onPress={onPress}
      />
    </Container>
  );
};

const Container = styled(View)`
  
`;
