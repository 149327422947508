import * as React from 'react';
import { Colors } from '../config/Theme';
import { Config } from '../config/Config';
import { ConnectedDeepLinkHandler } from '../lib/redux/ConnectedDeepLinkHandler';
import { DeepLinkHandler } from '../../../core/src/lib/deeplinks/DeepLinkHandler';
import { Screen } from '../lib-components/screen/Screen';
import { Log } from '../config/Log';
import { QRScanSimulator } from '../components/debug/QRScanSimulator';
import { View } from 'react-native';
import { isDevelopment } from '../../../core/src/lib/Environment';

import styled from 'styled-components/native';
import { QRScanner } from '../lib-components/QRScanner';
import { RCComp } from '../lib/routeContract/RCComp';
import { Navigation } from '../lib/navigation/Navigation';
import { RoutesCliGuest } from '../../../core/src/lib/apis/routes/RoutableCliGuest';

export class ScreenSetupScanHostQR extends RCComp<'ScreenSetupScanHostQR'> {
  protected routeName = 'ScreenSetupScanHostQR' as const;

  onScanData = async (data: any) => {
    Log.v('ScreenSetupScanHostQR', 'onScanData', 'Data scanned', data);
    if (await DeepLinkHandler.parse(data, ConnectedDeepLinkHandler)) {
      await Navigation.routeReset(RoutesCliGuest.ScreenSetupGuestSession.name);
    }
  };

  render() {
    const { locale } = this.props;
    return (
      <Screen
        backgroundColor={Colors.black}
        usesReactNavigationHeader={false}
        hasStatusBar={true}
        statusBarBackgroundColor={Colors.black}
        statusBarBackgroundColorIsLight={false}
      >
        {isDevelopment(Config.env) && (
          <DevQRScanSimulatorCont>
            <QRScanSimulator
              onScanData={this.onScanData}
            />
          </DevQRScanSimulatorCont>
        )}
        <QRScannerCont>
          <QRScanner
            onScanData={this.onScanData}
            locale={locale}
          />
        </QRScannerCont>
      </Screen>
    );
  }
}

const DevQRScanSimulatorCont = styled(View)`
  background-color: ${({ theme }) => theme.t.col.white};
  padding: 12px;
  z-index: 9999;
`;

const QRScannerCont = styled(View)`
  flex: 1;
`;
