import { TGenericRoutableRoute } from './RoutableTypes';
import * as _ from 'lodash';
import { UndefinedOptional } from '../../Types';
import { URLToolbox } from '../url-toolbox/URLToolbox';

export type TRouteRunnerParams<Route extends TGenericRoutableRoute> = {
  secondaryPath?: Route['secondaryPaths'][keyof Route['secondaryPaths']];
  hash?: Route['hashes'][keyof Route['hashes']];
  params: UndefinedOptional<Route['params']['in']>;
  pathParams: UndefinedOptional<Route['pathParams']['in']>;
};

export class RoutableRoute<Def extends TGenericRoutableRoute> {
  static buildURLToolbox<Route extends TGenericRoutableRoute>(route: Route) {
    return new URLToolbox<Route['pathParams'], Route['params']>({
      pathTemplate: route.path,
      pathParams: route.pathParams,
      searchParams: route.params,
    });
  }

  private readonly baseUrl: string;

  private readonly route: TGenericRoutableRoute;

  readonly inParams: Record<keyof Def['params']['in'], string>;

  constructor(baseUrl: string, route: Def) {
    this.baseUrl = baseUrl;
    this.route = route;
    this.inParams = _.keys(this.route.params.in).reduce((acc, key) => {
      acc[key] = key;
      return acc;
    }, {} as any);
  }

  readonly searchParams = (params: Def['params']['in']) => {
    return this.route.params.map != null
      ? this.route.params.map(params || {})
      : params;
  };

  readonly url = (_opts: UndefinedOptional<TRouteRunnerParams<Def>>) => {
    const opts = _opts as TRouteRunnerParams<Def>;
    return new URLToolbox({
      pathTemplate: opts.secondaryPath != null
        ? opts.secondaryPath as string
        : this.route.path,
      pathParams: this.route.pathParams,
      searchParams: this.route.params,
    }).constructURL({
      baseURL: this.baseUrl,
      pathParams: opts.pathParams,
      searchParams: opts.params,
    });
  };
}
