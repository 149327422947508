import * as _ from 'lodash';
import { DbRes } from '../../../db/DbResources';
import {
  FIELD_DEFAULT_LOCALE,
  FIELD_ID,
  FIELD_NAME,
  FIELD_SORTABLE_INDEX,
  TObjList,
} from '../../../db/DbDefs';
import { Host } from '../host/Host';
import { KnownError } from '../../../lib/error/KnownError';
import { LocalError } from '../../../lib/error/LocalError';
import { MObj } from '../../../lib/model/MObj';
import {
  MenuBuilder,
  TMenuPathBuilderParams,
} from './MenuBuilder';
import { MenuCreator } from './MenuCreator';
import { Refs } from '../../../db/DbRefs';
import { THost } from '../host/HostTypes';
import {
  TMenu,
  TMenuCreate,
  TMenuUpdate,
  TMenuId,
} from './MenuTypes';
import {
  TMenuItem,
  TMenuItemId,
} from '../menuItem/MenuItemTypes';
import {
  boolToInt, enumToArray,
  filterObj,
  fpPush,
  mapAndFilterNull,
  selectNotEmpty2,
} from '../../../lib/HelperFunctions';
import { buildRawUpdate } from '../../dbUpdaters/lib/buildRawUpdate';
import {
  dbItemLocalizableSupportedLocalesGet,
  dbItemLocalizeableNameableUncasedIncludeCompare,
  dbItemSortableNextIndex,
} from '../../../db/DbLib';
import { menuSubMenusSortableIndexSort } from '../../dbUpdaters/menuSubMenusSortableIndexSort';
import { menuUpdate } from '../../dbUpdaters/menuUpdate';
import { menuUpdateSubMenuPublishStatus } from '../../dbUpdaters/menuUpdateSubMenuPublishStatus';
import { ELocale, ELOCALE_GLOBAL_DEFAULT } from '../../../locale/Locale';
import {LanguageCodes, TLanguage} from '../../../locale/Languages';

export class Menu extends MObj<typeof DbRes.Menu> {
  static sortSubMenusByHiddenStatus({ subMenus }: Pick<TMenu, 'subMenus'>): TMenu[] {
    return _.values(subMenus).sort((a, b) => {
      const aIsHidden = _.get(a, 'isHidden', false);
      const bIsHidden = _.get(b, 'isHidden', false);
      return boolToInt(aIsHidden) - boolToInt(bIsHidden);
    });
  }

  static menuItemsInMenu(menu: Pick<TMenu, typeof FIELD_ID>, hostData: Pick<THost, 'items'>): TMenuItem[] {
    return _.values(hostData.items)
      .filter((menuItem) => _.values(menuItem.menuIds).includes(menu[FIELD_ID]));
  }

  static getAllMenuLanguages(menu: TMenu): TLanguage[] {
    return [
      menu.defaultLocale,
      ..._.uniq(enumToArray<TLanguage>(ELocale).concat(LanguageCodes))
        .filter((lang) => _.isObject(menu?.[lang])),
    ];
  }

  static filterByString(menu: TMenu, filterString = '', language?: TLanguage): TMenu {
    if (filterString.length <= 0) {
      return menu;
    }

    function buildMenu(
      inMenu: TMenu,
      subMenus: TObjList<TMenuId, TMenu> = {},
      items: TObjList<TMenuItemId, TMenuItem> = {},
    ) {
      return {
        ...inMenu,
        subMenus,
        ...(_.get(inMenu, 'items') ? { items } : {}),
      };
    }

    function menuIsEmpty(emptyMenu: TMenu) {
      return _.size(emptyMenu.subMenus) <= 0
        && _.size(_.get(emptyMenu, 'items')) <= 0;
    }

    function filterName(data: TMenu | TMenuItem) {
      return dbItemLocalizeableNameableUncasedIncludeCompare(data, filterString, language);
    }

    function filterByStringWithRemove(menuToFilter: TMenu): TMenu | undefined {
      const filteredMenu = buildMenu(
        menuToFilter,
        mapAndFilterNull<TMenu>(menuToFilter.subMenus as any, filterByStringWithRemove),
        filterObj<TMenuItem>(_.get(menuToFilter, 'items'), filterName),
      );

      // If !menuIsEmpty then there was either a subMenu or menuItem name match
      // in the lower level
      return filterName(filteredMenu) || !menuIsEmpty(filteredMenu)
        ? filteredMenu
        : undefined;
    }

    const filteredMenu = filterByStringWithRemove(menu);
    return buildMenu(
      filteredMenu || menu,
      _.get(filteredMenu, 'subMenus') as any,
      _.get(filteredMenu, 'items'),
    );
  }

  static findMenuById(menu: TMenu, menuId: TMenuId): TMenu | undefined {
    if (menu[FIELD_ID] === menuId) {
      return menu;
    }//
    const subMenus = _.values(menu.subMenus);
    for (let i = 0; i < subMenus.length; i++) {
      const found = Menu.findMenuById(subMenus[i], menuId);
      if (found) {
        return found;
      }
    }
    return undefined;
  }

  static allMenuIds(menu: TMenu): TMenuId[] {
    const allMenuIds = [] as TMenuId[];
    const populateMenuIds = (currentMenu: TMenu) => {
      allMenuIds.push(currentMenu[FIELD_ID]);
      _.values(currentMenu.subMenus)
        .forEach((currentSubMenu: TMenu) => populateMenuIds(currentSubMenu));
    };
    populateMenuIds(menu);
    return allMenuIds;
  }

  static getSecondaryMenu(menu: TMenu) {
    return _.values(menu.subMenus)
      .find(({ isHidden }) => !isHidden);
  }

  readonly updateMenu = buildRawUpdate(this, 'Menu/*', menuUpdate, (update) => {
    return this.updateFields(update);
  });

  readonly updateSubMenuPublishStatus = buildRawUpdate(this, 'Menu/subMenuPublishStatus', menuUpdateSubMenuPublishStatus, (update) => {
    return Refs.hostMenuSubMenus(this.pbp()).ref().update(update);
  });

  readonly resortSubMenus = buildRawUpdate(this, 'Menu/subMenuSortableIndex', menuSubMenusSortableIndexSort, (update) => {
    return this.updateFields(update);
  });

  constructor(menu: TMenu) {
    super(MenuBuilder, menu);
  }

  async subMenuUpdate(menu: TMenuUpdate) {
    const defaultLocale = selectNotEmpty2<TLanguage>(
      ELOCALE_GLOBAL_DEFAULT,
      _.get(menu.locales, '[0]'),
      this.item[FIELD_DEFAULT_LOCALE],
    );
    return this.subMenuAdd({
      [FIELD_NAME]: menu.name,
      coverCharge: menu.coverCharge,
      [FIELD_DEFAULT_LOCALE]: defaultLocale,
      [FIELD_SORTABLE_INDEX]: dbItemSortableNextIndex(_.values(this.item.subMenus)),
      isHidden: true,
      description: '',
      subMenus: {},
      ...menu.locales
        .filter((locale) => locale != defaultLocale)
        .reduce((acc, eLocale) => {
          return _.set(acc, eLocale, { [FIELD_NAME]: menu.name });
        }, {} as any),
    });
  }

  async subMenuAdd(menu: TMenuCreate) {
    const pbp = MenuBuilder.getPathBuilderParamsFromDataPath(this.item);
    const subMenuPbp = {
      hostId: pbp.hostId,
      menuIds: fpPush(pbp.menuIds, 0),
    };

    return MenuCreator.remoteSaveNewToPath(subMenuPbp, {
      ...menu,
      [FIELD_SORTABLE_INDEX]: dbItemSortableNextIndex(_.values(this.item.subMenus)),
      isHidden: true,
    });
  }

  findMenuById(menuId: TMenuId): TMenu | undefined {
    return Menu.findMenuById(this.item, menuId);
  }

  parentMenuPbp = (): TMenuPathBuilderParams | null => {
    const pbp = MenuBuilder.getPathBuilderParamsFromDataPath(this.item);
    if (!pbp.menuIds || _.size(pbp.menuIds) < 0) {
      return null;
    }
    return {
      hostId: pbp.hostId,
      menuIds: pbp.menuIds.slice(0, pbp.menuIds.length - 1),
    };
  };

  parentMenu = async (): Promise<TMenu | null> => {
    const parentMenuPbp = this.parentMenuPbp();
    return parentMenuPbp
      ? MenuBuilder.remoteFromPath(parentMenuPbp)
      : null;
  };

  duplicate = async (hostData: THost) => {
    const parentMenu = await this.parentMenu();
    if (parentMenu == null) {
      throw new Error('Menu, duplicate, can\'t duplicate root menu');
    }

    const duplicatedMenu = await MenuBuilder.fromItem(parentMenu)
      .subMenuAdd(this.item);

    // For each MenuItem that is part of this menu
    // add this menu that MenuItem's menus
    return MenuBuilder.fromItem(duplicatedMenu)
      .addMenuItems(hostData, Host.getActiveMenuItemsForMenu(hostData, this.item));
  };

  addMenuItems = async (hostData: THost, menuItems: TMenuItem[]) => {
    // Ensure that hostData already contains those menuItems
    menuItems.forEach((menuItem) => {
      const menuItemId = menuItem[FIELD_ID];
      const hostDataItems = _.get(hostData, 'items', []);

      if (hostDataItems[menuItemId] == null) {
        throw new Error(`Menu, addMenuItems, MenuItem ${menuItemId} doesn't exist on host`);
      }
    });

    const updateObj = menuItems.reduce((acc, menuItem) => {
      const pbp = {
        hostId: hostData[FIELD_ID],
        menuItemId: menuItem[FIELD_ID],
        menuId: this.item[FIELD_ID],
      };

      // Get the update path
      const path = Refs.menuItemMenusMenuId(pbp).path();
      acc[path] = this.item[FIELD_ID];
      return acc;
    }, {} as any);
    return this.ref().update(updateObj);
  };

  async archive() {
    if (!this.item.isHidden) {
      throw new LocalError('Menu', 'archive', KnownError.cantDeletePublishedMenu);
    }
    return this.moveToDbArchive();
  }

  async removeLocale(locale: ELocale) {
    const currentLocales = dbItemLocalizableSupportedLocalesGet(this.item);
    if (currentLocales.length < 1) {
      throw new LocalError('Menu', 'removeLocale', KnownError.cantDeleteLastLocale);
    }

    if (locale == this.item[FIELD_DEFAULT_LOCALE]) {
      throw new LocalError('Menu', 'removeLocale', KnownError.errCantDeleteDefaultLocale);
    }

    return this.updateFields({ [locale]: null });
  }
}
