import { Refs } from '../../../../core/src/db/DbRefs';
import { actions } from '../Actions';
import { put } from 'redux-saga/effects';
import { sagaBindRefByKey } from '../../../../lib-react/src/sagas/sagaBindRefByKey';
import {
  sagaMonitorSelector,
  selectUndefinedAsClearTask,
} from '../../../../lib-react/src/sagas/sagaMonitorSelector';
import { selectHostPbp } from '../selectors';
import { LocalError } from '../../../../core/src/lib/error/LocalError';
import { KnownError } from '../../../../core/src/lib/error/KnownError';
import { Log } from '../../config/Log';
import { HostPublicSubscriptionBuilder } from '../../../../core/src/models/db/HostPublicSubscription/HostPublicSubscriptionBuilder';

export function* sagaSubscribeToHostPublicSubscription() {
  Log.v('sagaSubscribeToHostPublicSubscription', 'sagaSubscribeToHostPublicSubscription', `Starting`);
  yield sagaMonitorSelector(['*'], selectUndefinedAsClearTask(selectHostPbp), function* (pbp) {
    const subscriptionExists = yield HostPublicSubscriptionBuilder.dataExistsById(pbp);
    if (!subscriptionExists) {
      const message = [
        'sagaSubscribeToHostPublicSubscription',
        'sagaSubscribeToHostPublicSubscription',
        `A Host subscription should always exist, hostId=${pbp.hostId}`,
      ];
      Log.wtf(message[0], message[1], message[2]);
      throw new LocalError(message[0], message[1], KnownError.hostPublicSubscriptionNotFound);
    }

    const ref = Refs.hostPublicSubscription(pbp);

    yield put(actions.actionTypeHostSetHostPublicSubscription({ hostPublicSubscription: yield ref.val() }));

    yield sagaBindRefByKey('sagaSubscribeToHostPublicSubscription', ref, function* (hostPublicSubscription) {
      yield put(actions.actionTypeHostSetHostPublicSubscription({ hostPublicSubscription }));
    });
  });
}
