import * as _ from 'lodash';
import { RequestableRoute, TRequestableBaseParams } from './RequestableRoute';
import { TGenericRequestableRoutes } from './RequestableTypes';

export type TRequestableParams<Routes extends TGenericRequestableRoutes> = TRequestableBaseParams & {
  routes: Routes;
};

export type TWrapApiReturnType<Routes extends TGenericRequestableRoutes> = Requestable<Routes> & {
  [K in keyof Routes]: RequestableRoute<Routes[K]>;
};

export class Requestable<Routes extends TGenericRequestableRoutes> {
  static wrapApi<Routes extends TGenericRequestableRoutes>(params: TRequestableParams<Routes>) {
    return new Requestable(params) as TWrapApiReturnType<Routes>;
  }

  private readonly baseUrl: string;

  private readonly routes: Routes;

  private constructor(params: TRequestableParams<Routes>) {
    this.baseUrl = params.baseUrl;
    this.routes = params.routes;

    const keys = _.keys(this.routes);
    for (let i = 0; i < keys.length; i++) {
      // @ts-ignore
      this[keys[i]] = new RequestableRoute({
        baseUrl: this.baseUrl,
        route: this.routes[keys[i]],
      });
    }
  }
}
