import * as _ from 'lodash';
import { TBuilderParamsBase } from './lib/buildRawUpdate';
import { TRKGuestSession } from '../../db/DbResources';
import { dbItemIdableMap } from '../../db/DbLib';

type TStripEmptyOrdersUpdate = {
  //
};

export async function guestSessionStripEmptyOrders({ item }: TBuilderParamsBase<TRKGuestSession> & TStripEmptyOrdersUpdate) {
  return _.values(item.orders)
    .filter((order) => _.size(order.items) <= 0)
    .map(dbItemIdableMap)
    .reduce((acc, id) => _.set(acc, id, null), {});
}
