import { Config } from '../../config/Config';
import { Log } from '../../config/Log';
import { TState } from '../Store';
import { actions } from '../Actions';
import { all, delay, fork, put, race, select } from 'redux-saga/effects';
import { safeWait } from '../../../../core/src/lib/HelperFunctions';
import fetch from 'node-fetch';
import { selectPlatform } from '../../lib/Platform';
import { Navigation } from '../../lib/navigation/Navigation';
import { RoutesCliGuest } from '../../../../core/src/lib/apis/routes/RoutableCliGuest';

export function* sagaOfflineListenerRoot() {
  Log.v('sagaOfflineListenerRoot', 'sagaOfflineListenerRoot', 'starting');
  yield all([
    fork(sagaNetworkCheck),
  ]);
}

function* sagaNetworkCheck() {
  Log.v('sagaOfflineListenerRoot', 'sagaNetworkCheck', 'starting');
  while (true) {
    const { response } = yield race({
      response: safeWait(() => fetch(Config.networkSagaConfig.pingServerUrl, {
        timeout: Config.networkSagaConfig.pingTimeout,
        ...selectPlatform({
          android: {},
          ios: {},
          web: { mode: 'no-cors' },
          other: {},
        }),
      })),
      timeout: delay(Config.networkSagaConfig.pingTimeout + 2000),
    });
    yield sagaSyncStatus({ online: !!response });
    yield delay(Config.networkSagaConfig.pingInterval);
  }
}

type TSagaSyncStatusParams = {
  online: boolean;
};

function* sagaSyncStatus({ online }: TSagaSyncStatusParams) {
  const isModalOfflineVisible = Navigation.isCurrentRoute(RoutesCliGuest.ModalOfflineOverlay.name);
  const isConnected = yield select((state: TState) => state.session.isConnected);

  if (!online) {
    if (!isModalOfflineVisible) {
      Log.v('sagaOfflineListenerRoot', 'sagaSyncStatus', 'Offline, Showing offline modal');
      Navigation.navigate(RoutesCliGuest.ModalOfflineOverlay.name);
    }
    if (isConnected) {
      Log.v('sagaOfflineListenerRoot', 'sagaSyncStatus', 'Offline, Setting state offline');
      yield put(actions.actionTypeSessionSetConnectionState({ isConnected: false }));
    }
    return;
  }

  if (isModalOfflineVisible) {
    Log.v('sagaOfflineListenerRoot', 'sagaSyncStatus', 'Online, Hiding offline modal');
    Navigation.hideRouteIfCurrent(RoutesCliGuest.ModalOfflineOverlay.name);
  }
  if (!isConnected) {
    Log.v('sagaOfflineListenerRoot', 'sagaSyncStatus', 'Online, Setting state online');
    yield put(actions.actionTypeSessionSetConnectionState({ isConnected: true }));
  }
}
