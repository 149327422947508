/* eslint-disable no-tabs */
import { Log } from '../../config/Log';
import { call } from 'redux-saga/effects';
import { ensureGuestSessionExists } from '../functions/ensureGuestSessionExists';
import {
  sagaMonitorSelector,
  selectUndefinedAsClearTask,
} from '../../../../lib-react/src/sagas/sagaMonitorSelector';
import { selectGuestSessionPbp } from '../selectors';

export function* sagaOnTableIdChange() {
  Log.v('sagaOnTableIdChange', 'onTableIdChangeSaga', `starting`);
  yield sagaMonitorSelector(['*'], selectUndefinedAsClearTask(selectGuestSessionPbp), function* (params) {
    const { hostId, tableId } = params;
    Log.v('onTableIdChangeSaga', 'onTableIdChangeSaga', `tableId for hostId=${hostId} changed to ${tableId}`);

    yield call(ensureGuestSessionExists, 'onTableIdChangeSaga', {
      hostId,
      tableId,
    });
  });
}
