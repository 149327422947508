import * as React from 'react';
import * as _ from 'lodash';
import {
  EMenuItemState,
  TMenuItem,
} from '../../../core/src/models/db/menuItem/MenuItemTypes';
import { MenuDefaultEmptyState } from './menu/MenuDefaultEmptyState';
import { MenuItemList } from './MenuItemList';
import { TOrder } from '../../../core/src/models/db/order/OrderTypes';
import { dbItemLocalizeableNameableUncasedIncludeCompare } from '../../../core/src/db/DbLib';
import { TLanguage } from '../../../core/src/locale/Languages';
import { useCurrentLanguage } from "../hooks/hooks";

function filterItems(
  language: TLanguage,
  menuItems: TMenuItem[],
  filterString: string,
) {
  if (_.isEmpty(filterString)) {
    return [];
  }

  return menuItems
    .filter(({ status }) => status === EMenuItemState.ACTIVE)
    .filter((menuItem) => dbItemLocalizeableNameableUncasedIncludeCompare(menuItem, filterString, language));
}

type MenuItemListSearchProps = {
  filterString: string;
  openOrder?: TOrder;
  menuItems: TMenuItem[];
  onMenuItemPress?: (menuItem: TMenuItem) => void;
};

export const MenuItemListSearch = ({
  filterString,
  openOrder,
  menuItems,
  onMenuItemPress,
}: MenuItemListSearchProps) => {
  const language = useCurrentLanguage();
  const filteredItems = filterItems(language, menuItems, filterString);

  if (_.isEmpty(filterString)) {
    return (
      <MenuDefaultEmptyState/>
    );
  }

  return (
    <MenuItemList
      orderData={openOrder}
      menuItems={filteredItems}
      onMenuItemPress={onMenuItemPress}
    />
  );
};
