// @ts-nocheck
import * as React from 'react';
import Svg,
{ Path } from 'react-native-svg';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg width={294} height={182} viewBox="0 0 294 182" fill="none" {...props}>
      <Path fill="#2B2C33" d="M200.653 71.537H294v93.347h-93.347z"/>
      <Path
        fill="#A6A7B3"
        d="M187.274 16.573L94.947.293 78.604 92.98l92.327 16.28z"
      />
      <Path
        stroke="#FFDECD"
        strokeWidth={3}
        d="M230.901 90.2L141.53 74.44l-15.759 89.373 89.373 15.759z"
      />
      <Path
        fill="#F15B40"
        d="M34.317 63.682l92.327-16.28 16.28 92.327-92.327 16.28z"
      />
      <Path
        stroke="#2B2C33"
        strokeWidth={3}
        d="M107.49 26.542L17.82 10.731 2.007 100.403l89.672 15.811z"
      />
    </Svg>
  );
}

export const SvgAlacarteErrorState = React.memo(SvgComponent);
