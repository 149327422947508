import * as React from 'react';
import * as _ from 'lodash';
import { StatusBar, View } from 'react-native';
import styled from 'styled-components/native';
import { ScreenContextProvider, TScreenContextParams } from './ScreenContext';
import { StyledComponentsTheme } from '../theme/StyledComponentsTheme';
import * as TransparentStatusAndNavigationBar from 'react-native-transparent-status-and-navigation-bar';

TransparentStatusAndNavigationBar.init();

type TScreenParams = {
  backgroundColor: string;
  usesReactNavigationHeader: boolean;

  hasStatusBar: boolean;
  statusBarBackgroundColor: string;
  statusBarBackgroundColorIsLight: boolean;
};

type TScreenProps = TScreenParams & {
  children?: React.ReactNode;
  underlay?: React.ReactNode;
  underlayHeight?: number;
};

export const Screen = (props: TScreenProps) => {
  const {
    children,
    underlay,
    ...params
  } = props;
  const {
    usesReactNavigationHeader,
    underlayHeight,
    hasStatusBar,
    statusBarBackgroundColor,
    statusBarBackgroundColorIsLight,
  } = params;

  const { theme } = React.useContext(StyledComponentsTheme.Context);

  function contentTopOffset() {
    if (hasStatusBar && !usesReactNavigationHeader) {
      // react-navigation handles the status bar offset,
      // since we're not using that header we need to handle it manually
      return theme.dim.statusBarHeight;
    }

    return 0;
  }

  const screenContextParams: TScreenContextParams['screen'] = {
    contentTopOffset: contentTopOffset(),
    contentHeight: (() => {
      let contentHeight = theme.dim.drawableHeight;

      // theme.dim.drawableHeight contains the status bar height so if
      // we don't have the status bar here, remove that difference from it
      contentHeight -= contentTopOffset();

      if (usesReactNavigationHeader) {
        // theme.dim.height doesn't contain the action bar or the status bar
        // added from react-navigation so if we add the react-navigation action bar
        // we should remove the difference from the drawable (content) height
        contentHeight -= theme.dim.actionBarHeight;
        contentHeight -= theme.dim.statusBarHeight;
      }

      if (!!underlay && _.isNumber(underlayHeight)) {
        contentHeight -= underlayHeight;
      }

      return contentHeight;
    })(),
  };

  return (
    <ScreenContextProvider screen={screenContextParams}>
      <Root {...params} {...screenContextParams}>
        <StatusBar
          translucent
          barStyle={statusBarBackgroundColorIsLight
            ? 'dark-content'
            : 'light-content'}
          backgroundColor={statusBarBackgroundColor}
        />
        <Content {...params} {...screenContextParams}>
          {children}
        </Content>
        {!!underlay && (
          <Underlay {...params} {...screenContextParams}>
            {underlay}
          </Underlay>
        )}
      </Root>
    </ScreenContextProvider>
  );
};

const Root = styled(View)<TScreenParams & TScreenContextParams>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  flex: 1;
`;

const Content = styled(View)<TScreenParams & TScreenContextParams>`
  flex: 1;
  height: ${({ contentHeight }) => contentHeight}px;
  margin-top: ${({ contentTopOffset }) => contentTopOffset}px;
  max-height: ${({ contentHeight }) => contentHeight}px;
  min-height: ${({ contentHeight }) => contentHeight}px;
`;

const Underlay = styled(View)<TScreenParams & TScreenContextParams>`
  display: flex;
  flex: 1;
  height: ${({ underlayHeight }) => underlayHeight || 0}px;
  min-height: ${({ underlayHeight }) => underlayHeight || 0}px;
`;

