import { DbPaths } from '../../../db/DbRefs';
import {
  DbRes,
  TRKOrderItem,
} from '../../../db/DbResources';
import { MObjDef } from '../../../lib/model/MObjDef';
import { OrderItem } from './OrderItem';
import { TMObjDataCreator } from '../../../lib/model/ModelTypes';
import { TOrderItem } from './OrderItemTypes';
import { defOrderItem } from '../ModelDefaults';

export type TLocalOrderItem = TMObjDataCreator<TRKOrderItem>;

const pathBuilderKeys = [
  'hostId',
  'tableId',
  'orderId',
  'orderItemId',
] as const;

export type TOrderItemPathBuilderParams = {
  [key in ((typeof pathBuilderKeys)[number])]: string
};

class OrderItemBuilderBase extends MObjDef<typeof DbRes.OrderItem> {
  readonly resourceKey = DbRes.OrderItem;

  protected readonly pathBuilderKeys = [...pathBuilderKeys];

  protected readonly _default = defOrderItem;

  protected newChild(item: TOrderItem) {
    return new OrderItem(item);
  }

  pathBuilder(params: TOrderItemPathBuilderParams): string {
    return DbPaths.orderItem(params);
  }
}

export const OrderItemBuilder = new OrderItemBuilderBase();

