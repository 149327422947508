import * as _ from 'lodash';
import { XOR } from '../../Types';
import { memoizeFunc } from '../../HelperFunctions';

type TURLParamsBasic<In> = {
  in: In;
};

type TURLParamsMappable<In, Out, Map extends (i: In) => Out = (p: any) => any, Invert extends (o: Out) => In = (p: any) => any> = {
  in: In;
  out: Out;
  map: Map;
  invert: Invert;
};

type TURLParamsInverted<P extends TURLParams<any, any, any, any>> = P['in'];
type TURLParamsMapped<P extends TURLParams<any, any, any, any>> = P['out'] extends undefined ? P['in'] : P['out'];

export type TURLParams<In, Out, Map extends (i: In) => Out = (p: any) => any, Invert extends (o: Out) => In = (p: any) => any> =
  XOR<TURLParamsBasic<In>, TURLParamsMappable<In, Out, Map, Invert>>;

export class URLParams<P extends TURLParams<any, any, any, any>> {
  private readonly def: TURLParams<P['in'], P['out'], P['map'], P['invert']>;

  constructor(def: TURLParams<P['in'], P['out'], P['map'], P['invert']>) {
    this.def = def;
  }

  get keysIn() {
    return this.calcKeysIn();
  }

  get keysOut() {
    return this.calcKeysOut();
  }

  readonly map = (params: TURLParamsInverted<P>): TURLParamsMapped<P> => {
    return this.def.map != null
      ? this.def.map(params || {} as any)
      : params;
  };

  readonly invert = (params: TURLParamsMapped<P>): TURLParamsInverted<P> => {
    return this.def.invert != null
      ? this.def.invert(params || {} as any)
      : params;
  };

  readonly invertFromURLSearchParams = (params: URLSearchParams): TURLParamsInverted<P> => {
    const searchParamsOut = {};
    for (const key of params.keys()) {
      searchParamsOut[key] = params.get(key);
    }
    return this.invert(searchParamsOut as any);
  };

  private readonly calcKeysIn = memoizeFunc(() => {
    return _.keys(this.def.in);
  });

  private readonly calcKeysOut = memoizeFunc(() => {
    const out = this.def.out != null
      ? this.def.out
      : this.def.in;
    return _.keys(out);
  });
}
