import * as _ from 'lodash';

export const Languages = {
  'af-NA': {
    'locale': 'af-NA',
    'language': 'Afrikaans',
    'emoji': '🇳🇦',
    'country': 'Namibia',
    'related': {
      'af-ZA': {
        'locale': 'af-ZA',
        'language': 'Afrikaans',
        'emoji': '🇿🇦',
        'country': 'South Africa'
      }
    },
    'emojis': '🇳🇦 🇿🇦'
  },
  'ak-GH': {
    'locale': 'ak-GH',
    'language': 'Akan',
    'emoji': '🇬🇭',
    'country': 'Ghana',
    'related': {},
    'emojis': '🇬🇭'
  },
  'sq-AL': {
    'locale': 'sq-AL',
    'language': 'Albanian',
    'emoji': '🇦🇱',
    'country': 'Albania',
    'related': {
      'sq-XK': {
        'locale': 'sq-XK',
        'language': 'Albanian',
        'emoji': '🇽🇰',
        'country': 'Kosovo'
      },
      'sq-MK': {
        'locale': 'sq-MK',
        'language': 'Albanian',
        'emoji': '🇲🇰',
        'country': 'Macedonia'
      }
    },
    'emojis': '🇦🇱 🇽🇰 🇲🇰'
  },
  'am-ET': {
    'locale': 'am-ET',
    'language': 'Amharic',
    'emoji': '🇪🇹',
    'country': 'Ethiopia',
    'related': {},
    'emojis': '🇪🇹'
  },
  'ar-DZ': {
    'locale': 'ar-DZ',
    'language': 'Arabic',
    'emoji': '🇩🇿',
    'country': 'Algeria',
    'related': {
      'ar-BH': {
        'locale': 'ar-BH',
        'language': 'Arabic',
        'emoji': '🇧🇭',
        'country': 'Bahrain'
      },
      'ar-TD': {
        'locale': 'ar-TD',
        'language': 'Arabic',
        'emoji': '🇹🇩',
        'country': 'Chad'
      },
      'ar-KM': {
        'locale': 'ar-KM',
        'language': 'Arabic',
        'emoji': '🇰🇲',
        'country': 'Comoros'
      },
      'ar-DJ': {
        'locale': 'ar-DJ',
        'language': 'Arabic',
        'emoji': '🇩🇯',
        'country': 'Djibouti'
      },
      'ar-EG': {
        'locale': 'ar-EG',
        'language': 'Arabic',
        'emoji': '🇪🇬',
        'country': 'Egypt'
      },
      'ar-ER': {
        'locale': 'ar-ER',
        'language': 'Arabic',
        'emoji': '🇪🇷',
        'country': 'Eritrea'
      },
      'ar-IQ': {
        'locale': 'ar-IQ',
        'language': 'Arabic',
        'emoji': '🇮🇶',
        'country': 'Iraq'
      },
      'ar-IL': {
        'locale': 'ar-IL',
        'language': 'Arabic',
        'emoji': '🇮🇱',
        'country': 'Israel'
      },
      'ar-JO': {
        'locale': 'ar-JO',
        'language': 'Arabic',
        'emoji': '🇯🇴',
        'country': 'Jordan'
      },
      'ar-KW': {
        'locale': 'ar-KW',
        'language': 'Arabic',
        'emoji': '🇰🇼',
        'country': 'Kuwait'
      },
      'ar-LB': {
        'locale': 'ar-LB',
        'language': 'Arabic',
        'emoji': '🇱🇧',
        'country': 'Lebanon'
      },
      'ar-LY': {
        'locale': 'ar-LY',
        'language': 'Arabic',
        'emoji': '🇱🇾',
        'country': 'Libya'
      },
      'ar-MR': {
        'locale': 'ar-MR',
        'language': 'Arabic',
        'emoji': '🇲🇷',
        'country': 'Mauritania'
      },
      'ar-MA': {
        'locale': 'ar-MA',
        'language': 'Arabic',
        'emoji': '🇲🇦',
        'country': 'Morocco'
      },
      'ar-OM': {
        'locale': 'ar-OM',
        'language': 'Arabic',
        'emoji': '🇴🇲',
        'country': 'Oman'
      },
      'ar-PS': {
        'locale': 'ar-PS',
        'language': 'Arabic',
        'emoji': '🇵🇸',
        'country': 'Palestinian Territories'
      },
      'ar-QA': {
        'locale': 'ar-QA',
        'language': 'Arabic',
        'emoji': '🇶🇦',
        'country': 'Qatar'
      },
      'ar-SA': {
        'locale': 'ar-SA',
        'language': 'Arabic',
        'emoji': '🇸🇦',
        'country': 'Saudi Arabia'
      },
      'ar-SO': {
        'locale': 'ar-SO',
        'language': 'Arabic',
        'emoji': '🇸🇴',
        'country': 'Somalia'
      },
      'ar-SS': {
        'locale': 'ar-SS',
        'language': 'Arabic',
        'emoji': '🇸🇸',
        'country': 'South Sudan'
      },
      'ar-SD': {
        'locale': 'ar-SD',
        'language': 'Arabic',
        'emoji': '🇸🇩',
        'country': 'Sudan'
      },
      'ar-SY': {
        'locale': 'ar-SY',
        'language': 'Arabic',
        'emoji': '🇸🇾',
        'country': 'Syria'
      },
      'ar-TN': {
        'locale': 'ar-TN',
        'language': 'Arabic',
        'emoji': '🇹🇳',
        'country': 'Tunisia'
      },
      'ar-AE': {
        'locale': 'ar-AE',
        'language': 'Arabic',
        'emoji': '🇦🇪',
        'country': 'United Arab Emirates'
      },
      'ar-EH': {
        'locale': 'ar-EH',
        'language': 'Arabic',
        'emoji': '🇪🇭',
        'country': 'Western Sahara'
      },
      'ar-YE': {
        'locale': 'ar-YE',
        'language': 'Arabic',
        'emoji': '🇾🇪',
        'country': 'Yemen'
      }
    },
    'emojis': '🇩🇿 🇧🇭 🇹🇩 🇰🇲 🇩🇯 🇪🇬 🇪🇷 🇮🇶 🇮🇱 🇯🇴 🇰🇼 🇱🇧 🇱🇾 🇲🇷 🇲🇦 🇴🇲 🇵🇸 🇶🇦 🇸🇦 🇸🇴 🇸🇸 🇸🇩 🇸🇾 🇹🇳 🇦🇪 🇪🇭 🇾🇪'
  },
  'hy-AM': {
    'locale': 'hy-AM',
    'language': 'Armenian',
    'emoji': '🇦🇲',
    'country': 'Armenia',
    'related': {},
    'emojis': '🇦🇲'
  },
  'as-IN': {
    'locale': 'as-IN',
    'language': 'Assamese',
    'emoji': '🇮🇳',
    'country': 'India',
    'related': {},
    'emojis': '🇮🇳'
  },
  'az-AZ': {
    'locale': 'az-AZ',
    'language': 'Azerbaijani',
    'emoji': '🇦🇿',
    'country': 'Azerbaijan',
    'related': {
      'az-Cyrl_AZ': {
        'locale': 'az-Cyrl_AZ',
        'language': 'Azerbaijani',
        'emoji': '🇦🇿',
        'country': 'Cyrillic, Azerbaijan'
      },
      'az-Cyrl': {
        'locale': 'az-Cyrl',
        'language': 'Azerbaijani',
        'emoji': '🇦🇿',
        'country': 'Cyrillic'
      },
      'az-Latn_AZ': {
        'locale': 'az-Latn_AZ',
        'language': 'Azerbaijani',
        'emoji': '🇦🇿',
        'country': 'Latin, Azerbaijan'
      },
      'az-Latn': {
        'locale': 'az-Latn',
        'language': 'Azerbaijani',
        'emoji': '🇦🇿',
        'country': 'Latin'
      }
    },
    'emojis': '🇦🇿 🇦🇿 🇦🇿 🇦🇿 🇦🇿'
  },
  'bm-Latn_ML': {
    'locale': 'bm-Latn_ML',
    'language': 'Bambara',
    'emoji': '🇲🇱',
    'country': 'Latin, Mali',
    'related': {
      'bm-Latn': {
        'locale': 'bm-Latn',
        'language': 'Bambara',
        'emoji': '🇲🇱',
        'country': 'Latin'
      }
    },
    'emojis': '🇲🇱'
  },
  'eu-ES': {
    'locale': 'eu-ES',
    'language': 'Basque',
    'emoji': '🇪🇸',
    'country': 'Spain',
    'related': {},
    'emojis': '🇪🇸'
  },
  'be-BY': {
    'locale': 'be-BY',
    'language': 'Belarusian',
    'emoji': '🇧🇾',
    'country': 'Belarus',
    'related': {},
    'emojis': '🇧🇾'
  },
  'bn-BD': {
    'locale': 'bn-BD',
    'language': 'Bengali',
    'emoji': '🇧🇩',
    'country': 'Bangladesh',
    'related': {
      'bn-IN': {
        'locale': 'bn-IN',
        'language': 'Bengali',
        'emoji': '🇮🇳',
        'country': 'India'
      }
    },
    'emojis': '🇧🇩 🇮🇳'
  },
  'bs-BA': {
    'locale': 'bs-BA',
    'language': 'Bosnian',
    'emoji': '🇧🇦',
    'country': 'Bosnia & Herzegovina',
    'related': {
      'bs-Cyrl_BA': {
        'locale': 'bs-Cyrl_BA',
        'language': 'Bosnian',
        'emoji': '🇧🇦',
        'country': 'Cyrillic, Bosnia & Herzegovina'
      },
      'bs-Cyrl': {
        'locale': 'bs-Cyrl',
        'language': 'Bosnian',
        'emoji': '🇧🇦',
        'country': 'Cyrillic'
      },
      'bs-Latn_BA': {
        'locale': 'bs-Latn_BA',
        'language': 'Bosnian',
        'emoji': '🇧🇦',
        'country': 'Latin, Bosnia & Herzegovina'
      },
      'bs-Latn': {
        'locale': 'bs-Latn',
        'language': 'Bosnian',
        'emoji': '🇧🇦',
        'country': 'Latin'
      }
    },
    'emojis': '🇧🇦 🇧🇦 🇧🇦 🇧🇦 🇧🇦'
  },
  'br-FR': {
    'locale': 'br-FR',
    'language': 'Breton',
    'emoji': '🇫🇷',
    'country': 'France',
    'related': {},
    'emojis': '🇫🇷'
  },
  'bg-BG': {
    'locale': 'bg-BG',
    'language': 'Bulgarian',
    'emoji': '🇧🇬',
    'country': 'Bulgaria',
    'related': {},
    'emojis': '🇧🇬'
  },
  'my-MM': {
    'locale': 'my-MM',
    'language': 'Burmese (Myanmar',
    'emoji': '🇲🇲',
    'country': 'Burma)',
    'related': {},
    'emojis': '🇲🇲'
  },
  'ca-AD': {
    'locale': 'ca-AD',
    'language': 'Catalan',
    'emoji': '🇦🇩',
    'country': 'Andorra',
    'related': {
      'ca-FR': {
        'locale': 'ca-FR',
        'language': 'Catalan',
        'emoji': '🇫🇷',
        'country': 'France'
      },
      'ca-IT': {
        'locale': 'ca-IT',
        'language': 'Catalan',
        'emoji': '🇮🇹',
        'country': 'Italy'
      },
      'ca-ES': {
        'locale': 'ca-ES',
        'language': 'Catalan',
        'emoji': '🇪🇸',
        'country': 'Spain'
      }
    },
    'emojis': '🇦🇩 🇫🇷 🇮🇹 🇪🇸'
  },
  'zh-CN': {
    'locale': 'zh-CN',
    'language': 'Chinese',
    'emoji': '🇨🇳',
    'country': 'China',
    'related': {
      'zh-HK': {
        'locale': 'zh-HK',
        'language': 'Chinese',
        'emoji': '🇭🇰',
        'country': 'Hong Kong SAR China'
      },
      'zh-MO': {
        'locale': 'zh-MO',
        'language': 'Chinese',
        'emoji': '🇲🇴',
        'country': 'Macau SAR China'
      },
      'zh-Hans_CN': {
        'locale': 'zh-Hans_CN',
        'language': 'Chinese',
        'emoji': '🇨🇳',
        'country': 'Simplified, China'
      },
      'zh-Hans_HK': {
        'locale': 'zh-Hans_HK',
        'language': 'Chinese',
        'emoji': '🇭🇰',
        'country': 'Simplified, Hong Kong SAR China'
      },
      'zh-Hans_MO': {
        'locale': 'zh-Hans_MO',
        'language': 'Chinese',
        'emoji': '🇲🇴',
        'country': 'Simplified, Macau SAR China'
      },
      'zh-Hans_SG': {
        'locale': 'zh-Hans_SG',
        'language': 'Chinese',
        'emoji': '🇸🇬',
        'country': 'Simplified, Singapore'
      },
      'zh-Hans': {
        'locale': 'zh-Hans',
        'language': 'Chinese',
        'emoji': '🇨🇳',
        'country': 'Simplified'
      },
      'zh-SG': {
        'locale': 'zh-SG',
        'language': 'Chinese',
        'emoji': '🇸🇬',
        'country': 'Singapore'
      },
      'zh-TW': {
        'locale': 'zh-TW',
        'language': 'Chinese',
        'emoji': '🇹🇼',
        'country': 'Taiwan'
      },
      'zh-Hant_HK': {
        'locale': 'zh-Hant_HK',
        'language': 'Chinese',
        'emoji': '🇭🇰',
        'country': 'Traditional, Hong Kong SAR China'
      },
      'zh-Hant_MO': {
        'locale': 'zh-Hant_MO',
        'language': 'Chinese',
        'emoji': '🇲🇴',
        'country': 'Traditional, Macau SAR China'
      },
      'zh-Hant_TW': {
        'locale': 'zh-Hant_TW',
        'language': 'Chinese',
        'emoji': '🇹🇼',
        'country': 'Traditional, Taiwan'
      },
      'zh-Hant': {
        'locale': 'zh-Hant',
        'language': 'Chinese',
        'emoji': '🇨🇳',
        'country': 'Traditional'
      }
    },
    'emojis': '🇨🇳 🇭🇰 🇲🇴 🇨🇳 🇭🇰 🇲🇴 🇸🇬 🇨🇳 🇸🇬 🇹🇼 🇭🇰 🇲🇴 🇹🇼 🇨🇳'
  },
  'kw-GB': {
    'locale': 'kw-GB',
    'language': 'Cornish',
    'emoji': '🇬🇧',
    'country': 'United Kingdom',
    'related': {},
    'emojis': '🇬🇧'
  },
  'hr-BA': {
    'locale': 'hr-BA',
    'language': 'Croatian',
    'emoji': '🇧🇦',
    'country': 'Bosnia & Herzegovina',
    'related': {
      'hr-HR': {
        'locale': 'hr-HR',
        'language': 'Croatian',
        'emoji': '🇭🇷',
        'country': 'Croatia'
      }
    },
    'emojis': '🇧🇦 🇭🇷'
  },
  'cs-CZ': {
    'locale': 'cs-CZ',
    'language': 'Czech',
    'emoji': '🇨🇿',
    'country': 'Czech Republic',
    'related': {},
    'emojis': '🇨🇿'
  },
  'da-DK': {
    'locale': 'da-DK',
    'language': 'Danish',
    'emoji': '🇩🇰',
    'country': 'Denmark',
    'related': {
      'da-GL': {
        'locale': 'da-GL',
        'language': 'Danish',
        'emoji': '🇬🇱',
        'country': 'Greenland'
      }
    },
    'emojis': '🇩🇰 🇬🇱'
  },
  'nl-AW': {
    'locale': 'nl-AW',
    'language': 'Dutch',
    'emoji': '🇦🇼',
    'country': 'Aruba',
    'related': {
      'nl-BE': {
        'locale': 'nl-BE',
        'language': 'Dutch',
        'emoji': '🇧🇪',
        'country': 'Belgium'
      },
      'nl-BQ': {
        'locale': 'nl-BQ',
        'language': 'Dutch',
        'emoji': '🇧🇶',
        'country': 'Caribbean Netherlands'
      },
      'nl-CW': {
        'locale': 'nl-CW',
        'language': 'Dutch',
        'emoji': '🇨🇼',
        'country': 'Curaçao'
      },
      'nl-NL': {
        'locale': 'nl-NL',
        'language': 'Dutch',
        'emoji': '🇳🇱',
        'country': 'Netherlands'
      },
      'nl-SX': {
        'locale': 'nl-SX',
        'language': 'Dutch',
        'emoji': '🇸🇽',
        'country': 'Sint Maarten'
      },
      'nl-SR': {
        'locale': 'nl-SR',
        'language': 'Dutch',
        'emoji': '🇸🇷',
        'country': 'Suriname'
      }
    },
    'emojis': '🇦🇼 🇧🇪 🇧🇶 🇨🇼 🇳🇱 🇸🇽 🇸🇷'
  },
  'dz-BT': {
    'locale': 'dz-BT',
    'language': 'Dzongkha',
    'emoji': '🇧🇹',
    'country': 'Bhutan',
    'related': {},
    'emojis': '🇧🇹'
  },
  'en-US': {
    'locale': 'en-US',
    'language': 'English',
    'emoji': '🇺🇸',
    'country': 'United States',
    'emojis': '🇺🇸 🇦🇸 🇦🇮 🇦🇬 🇦🇺 🇧🇸 🇧🇧 🇧🇪 🇧🇿 🇧🇲 🇧🇼 🇮🇴 🇻🇬 🇨🇲 🇨🇦 🇰🇾 🇨🇽 🇨🇰 🇩🇬 🇩🇲 🇪🇷 🇫🇰 🇫🇯 🇬🇲 🇬🇭 🇬🇮 🇬🇩 🇬🇺 🇬🇬 🇬🇾 🇭🇰 🇮🇳 🇮🇪 🇮🇲 🇯🇲 🇯🇪 🇰🇪 🇰🇮 🇱🇸 🇱🇷 🇲🇴 🇲🇬 🇲🇼 🇲🇾 🇲🇹 🇲🇭 🇲🇺 🇫🇲 🇲🇸 🇳🇦 🇳🇷 🇳🇿 🇳🇬 🇳🇺 🇳🇫 🇲🇵 🇵🇰 🇵🇼 🇵🇬 🇵🇭 🇵🇳 🇵🇷 🇷🇼 🇼🇸 🇸🇨 🇸🇱 🇸🇬 🇸🇽 🇸🇧 🇿🇦 🇸🇸 🇸🇭 🇰🇳 🇱🇨 🇻🇨 🇸🇩 🇸🇿 🇹🇿 🇹🇰 🇹🇴 🇹🇹 🇹🇨 🇹🇻 🇺🇲 🇻🇮 🇺🇬 🇬🇧 🇻🇺 🇿🇲 🇿🇼 🇨🇨',
    'related': {
      'en-AS': {
        'locale': 'en-AS',
        'language': 'English',
        'emoji': '🇦🇸',
        'country': 'American Samoa',
      },
      'en-AI': {
        'locale': 'en-AI',
        'language': 'English',
        'emoji': '🇦🇮',
        'country': 'Anguilla'
      },
      'en-AG': {
        'locale': 'en-AG',
        'language': 'English',
        'emoji': '🇦🇬',
        'country': 'Antigua & Barbuda'
      },
      'en-AU': {
        'locale': 'en-AU',
        'language': 'English',
        'emoji': '🇦🇺',
        'country': 'Australia'
      },
      'en-BS': {
        'locale': 'en-BS',
        'language': 'English',
        'emoji': '🇧🇸',
        'country': 'Bahamas'
      },
      'en-BB': {
        'locale': 'en-BB',
        'language': 'English',
        'emoji': '🇧🇧',
        'country': 'Barbados'
      },
      'en-BE': {
        'locale': 'en-BE',
        'language': 'English',
        'emoji': '🇧🇪',
        'country': 'Belgium'
      },
      'en-BZ': {
        'locale': 'en-BZ',
        'language': 'English',
        'emoji': '🇧🇿',
        'country': 'Belize'
      },
      'en-BM': {
        'locale': 'en-BM',
        'language': 'English',
        'emoji': '🇧🇲',
        'country': 'Bermuda'
      },
      'en-BW': {
        'locale': 'en-BW',
        'language': 'English',
        'emoji': '🇧🇼',
        'country': 'Botswana'
      },
      'en-IO': {
        'locale': 'en-IO',
        'language': 'English',
        'emoji': '🇮🇴',
        'country': 'British Indian Ocean Territory'
      },
      'en-VG': {
        'locale': 'en-VG',
        'language': 'English',
        'emoji': '🇻🇬',
        'country': 'British Virgin Islands'
      },
      'en-CM': {
        'locale': 'en-CM',
        'language': 'English',
        'emoji': '🇨🇲',
        'country': 'Cameroon'
      },
      'en-CA': {
        'locale': 'en-CA',
        'language': 'English',
        'emoji': '🇨🇦',
        'country': 'Canada'
      },
      'en-KY': {
        'locale': 'en-KY',
        'language': 'English',
        'emoji': '🇰🇾',
        'country': 'Cayman Islands'
      },
      'en-CX': {
        'locale': 'en-CX',
        'language': 'English',
        'emoji': '🇨🇽',
        'country': 'Christmas Island'
      },
      'en-CK': {
        'locale': 'en-CK',
        'language': 'English',
        'emoji': '🇨🇰',
        'country': 'Cook Islands'
      },
      'en-DG': {
        'locale': 'en-DG',
        'language': 'English',
        'emoji': '🇩🇬',
        'country': 'Diego Garcia'
      },
      'en-DM': {
        'locale': 'en-DM',
        'language': 'English',
        'emoji': '🇩🇲',
        'country': 'Dominica'
      },
      'en-ER': {
        'locale': 'en-ER',
        'language': 'English',
        'emoji': '🇪🇷',
        'country': 'Eritrea'
      },
      'en-FK': {
        'locale': 'en-FK',
        'language': 'English',
        'emoji': '🇫🇰',
        'country': 'Falkland Islands'
      },
      'en-FJ': {
        'locale': 'en-FJ',
        'language': 'English',
        'emoji': '🇫🇯',
        'country': 'Fiji'
      },
      'en-GM': {
        'locale': 'en-GM',
        'language': 'English',
        'emoji': '🇬🇲',
        'country': 'Gambia'
      },
      'en-GH': {
        'locale': 'en-GH',
        'language': 'English',
        'emoji': '🇬🇭',
        'country': 'Ghana'
      },
      'en-GI': {
        'locale': 'en-GI',
        'language': 'English',
        'emoji': '🇬🇮',
        'country': 'Gibraltar'
      },
      'en-GD': {
        'locale': 'en-GD',
        'language': 'English',
        'emoji': '🇬🇩',
        'country': 'Grenada'
      },
      'en-GU': {
        'locale': 'en-GU',
        'language': 'English',
        'emoji': '🇬🇺',
        'country': 'Guam'
      },
      'en-GG': {
        'locale': 'en-GG',
        'language': 'English',
        'emoji': '🇬🇬',
        'country': 'Guernsey'
      },
      'en-GY': {
        'locale': 'en-GY',
        'language': 'English',
        'emoji': '🇬🇾',
        'country': 'Guyana'
      },
      'en-HK': {
        'locale': 'en-HK',
        'language': 'English',
        'emoji': '🇭🇰',
        'country': 'Hong Kong SAR China'
      },
      'en-IN': {
        'locale': 'en-IN',
        'language': 'English',
        'emoji': '🇮🇳',
        'country': 'India'
      },
      'en-IE': {
        'locale': 'en-IE',
        'language': 'English',
        'emoji': '🇮🇪',
        'country': 'Ireland'
      },
      'en-IM': {
        'locale': 'en-IM',
        'language': 'English',
        'emoji': '🇮🇲',
        'country': 'Isle of Man'
      },
      'en-JM': {
        'locale': 'en-JM',
        'language': 'English',
        'emoji': '🇯🇲',
        'country': 'Jamaica'
      },
      'en-JE': {
        'locale': 'en-JE',
        'language': 'English',
        'emoji': '🇯🇪',
        'country': 'Jersey'
      },
      'en-KE': {
        'locale': 'en-KE',
        'language': 'English',
        'emoji': '🇰🇪',
        'country': 'Kenya'
      },
      'en-KI': {
        'locale': 'en-KI',
        'language': 'English',
        'emoji': '🇰🇮',
        'country': 'Kiribati'
      },
      'en-LS': {
        'locale': 'en-LS',
        'language': 'English',
        'emoji': '🇱🇸',
        'country': 'Lesotho'
      },
      'en-LR': {
        'locale': 'en-LR',
        'language': 'English',
        'emoji': '🇱🇷',
        'country': 'Liberia'
      },
      'en-MO': {
        'locale': 'en-MO',
        'language': 'English',
        'emoji': '🇲🇴',
        'country': 'Macau SAR China'
      },
      'en-MG': {
        'locale': 'en-MG',
        'language': 'English',
        'emoji': '🇲🇬',
        'country': 'Madagascar'
      },
      'en-MW': {
        'locale': 'en-MW',
        'language': 'English',
        'emoji': '🇲🇼',
        'country': 'Malawi'
      },
      'en-MY': {
        'locale': 'en-MY',
        'language': 'English',
        'emoji': '🇲🇾',
        'country': 'Malaysia'
      },
      'en-MT': {
        'locale': 'en-MT',
        'language': 'English',
        'emoji': '🇲🇹',
        'country': 'Malta'
      },
      'en-MH': {
        'locale': 'en-MH',
        'language': 'English',
        'emoji': '🇲🇭',
        'country': 'Marshall Islands'
      },
      'en-MU': {
        'locale': 'en-MU',
        'language': 'English',
        'emoji': '🇲🇺',
        'country': 'Mauritius'
      },
      'en-FM': {
        'locale': 'en-FM',
        'language': 'English',
        'emoji': '🇫🇲',
        'country': 'Micronesia'
      },
      'en-MS': {
        'locale': 'en-MS',
        'language': 'English',
        'emoji': '🇲🇸',
        'country': 'Montserrat'
      },
      'en-NA': {
        'locale': 'en-NA',
        'language': 'English',
        'emoji': '🇳🇦',
        'country': 'Namibia'
      },
      'en-NR': {
        'locale': 'en-NR',
        'language': 'English',
        'emoji': '🇳🇷',
        'country': 'Nauru'
      },
      'en-NZ': {
        'locale': 'en-NZ',
        'language': 'English',
        'emoji': '🇳🇿',
        'country': 'New Zealand'
      },
      'en-NG': {
        'locale': 'en-NG',
        'language': 'English',
        'emoji': '🇳🇬',
        'country': 'Nigeria'
      },
      'en-NU': {
        'locale': 'en-NU',
        'language': 'English',
        'emoji': '🇳🇺',
        'country': 'Niue'
      },
      'en-NF': {
        'locale': 'en-NF',
        'language': 'English',
        'emoji': '🇳🇫',
        'country': 'Norfolk Island'
      },
      'en-MP': {
        'locale': 'en-MP',
        'language': 'English',
        'emoji': '🇲🇵',
        'country': 'Northern Mariana Islands'
      },
      'en-PK': {
        'locale': 'en-PK',
        'language': 'English',
        'emoji': '🇵🇰',
        'country': 'Pakistan'
      },
      'en-PW': {
        'locale': 'en-PW',
        'language': 'English',
        'emoji': '🇵🇼',
        'country': 'Palau'
      },
      'en-PG': {
        'locale': 'en-PG',
        'language': 'English',
        'emoji': '🇵🇬',
        'country': 'Papua New Guinea'
      },
      'en-PH': {
        'locale': 'en-PH',
        'language': 'English',
        'emoji': '🇵🇭',
        'country': 'Philippines'
      },
      'en-PN': {
        'locale': 'en-PN',
        'language': 'English',
        'emoji': '🇵🇳',
        'country': 'Pitcairn Islands'
      },
      'en-PR': {
        'locale': 'en-PR',
        'language': 'English',
        'emoji': '🇵🇷',
        'country': 'Puerto Rico'
      },
      'en-RW': {
        'locale': 'en-RW',
        'language': 'English',
        'emoji': '🇷🇼',
        'country': 'Rwanda'
      },
      'en-WS': {
        'locale': 'en-WS',
        'language': 'English',
        'emoji': '🇼🇸',
        'country': 'Samoa'
      },
      'en-SC': {
        'locale': 'en-SC',
        'language': 'English',
        'emoji': '🇸🇨',
        'country': 'Seychelles'
      },
      'en-SL': {
        'locale': 'en-SL',
        'language': 'English',
        'emoji': '🇸🇱',
        'country': 'Sierra Leone'
      },
      'en-SG': {
        'locale': 'en-SG',
        'language': 'English',
        'emoji': '🇸🇬',
        'country': 'Singapore'
      },
      'en-SX': {
        'locale': 'en-SX',
        'language': 'English',
        'emoji': '🇸🇽',
        'country': 'Sint Maarten'
      },
      'en-SB': {
        'locale': 'en-SB',
        'language': 'English',
        'emoji': '🇸🇧',
        'country': 'Solomon Islands'
      },
      'en-ZA': {
        'locale': 'en-ZA',
        'language': 'English',
        'emoji': '🇿🇦',
        'country': 'South Africa'
      },
      'en-SS': {
        'locale': 'en-SS',
        'language': 'English',
        'emoji': '🇸🇸',
        'country': 'South Sudan'
      },
      'en-SH': {
        'locale': 'en-SH',
        'language': 'English',
        'emoji': '🇸🇭',
        'country': 'St. Helena'
      },
      'en-KN': {
        'locale': 'en-KN',
        'language': 'English',
        'emoji': '🇰🇳',
        'country': 'St. Kitts & Nevis'
      },
      'en-LC': {
        'locale': 'en-LC',
        'language': 'English',
        'emoji': '🇱🇨',
        'country': 'St. Lucia'
      },
      'en-VC': {
        'locale': 'en-VC',
        'language': 'English',
        'emoji': '🇻🇨',
        'country': 'St. Vincent & Grenadines'
      },
      'en-SD': {
        'locale': 'en-SD',
        'language': 'English',
        'emoji': '🇸🇩',
        'country': 'Sudan'
      },
      'en-SZ': {
        'locale': 'en-SZ',
        'language': 'English',
        'emoji': '🇸🇿',
        'country': 'Swaziland'
      },
      'en-TZ': {
        'locale': 'en-TZ',
        'language': 'English',
        'emoji': '🇹🇿',
        'country': 'Tanzania'
      },
      'en-TK': {
        'locale': 'en-TK',
        'language': 'English',
        'emoji': '🇹🇰',
        'country': 'Tokelau'
      },
      'en-TO': {
        'locale': 'en-TO',
        'language': 'English',
        'emoji': '🇹🇴',
        'country': 'Tonga'
      },
      'en-TT': {
        'locale': 'en-TT',
        'language': 'English',
        'emoji': '🇹🇹',
        'country': 'Trinidad & Tobago'
      },
      'en-TC': {
        'locale': 'en-TC',
        'language': 'English',
        'emoji': '🇹🇨',
        'country': 'Turks & Caicos Islands'
      },
      'en-TV': {
        'locale': 'en-TV',
        'language': 'English',
        'emoji': '🇹🇻',
        'country': 'Tuvalu'
      },
      'en-UM': {
        'locale': 'en-UM',
        'language': 'English',
        'emoji': '🇺🇲',
        'country': 'U.S. Outlying Islands'
      },
      'en-VI': {
        'locale': 'en-VI',
        'language': 'English',
        'emoji': '🇻🇮',
        'country': 'U.S. Virgin Islands'
      },
      'en-UG': {
        'locale': 'en-UG',
        'language': 'English',
        'emoji': '🇺🇬',
        'country': 'Uganda'
      },
      'en-GB': {
        'locale': 'en-GB',
        'language': 'English',
        'emoji': '🇬🇧',
        'country': 'United Kingdom'
      },
      'en-VU': {
        'locale': 'en-VU',
        'language': 'English',
        'emoji': '🇻🇺',
        'country': 'Vanuatu'
      },
      'en-ZM': {
        'locale': 'en-ZM',
        'language': 'English',
        'emoji': '🇿🇲',
        'country': 'Zambia'
      },
      'en-ZW': {
        'locale': 'en-ZW',
        'language': 'English',
        'emoji': '🇿🇼',
        'country': 'Zimbabwe'
      },
      'en-CC': {
        'locale': 'en-CC',
        'language': 'English',
        'emoji': '🇨🇨',
        'country': 'Cocos (Keeling) Islands',
      },
    },
  },
  'et-EE': {
    'locale': 'et-EE',
    'language': 'Estonian',
    'emoji': '🇪🇪',
    'country': 'Estonia',
    'related': {},
    'emojis': '🇪🇪'
  },
  'ee-GH': {
    'locale': 'ee-GH',
    'language': 'Ewe',
    'emoji': '🇬🇭',
    'country': 'Ghana',
    'related': {
      'ee-TG': {
        'locale': 'ee-TG',
        'language': 'Ewe',
        'emoji': '🇹🇬',
        'country': 'Togo'
      }
    },
    'emojis': '🇬🇭 🇹🇬'
  },
  'fo-FO': {
    'locale': 'fo-FO',
    'language': 'Faroese',
    'emoji': '🇫🇴',
    'country': 'Faroe Islands',
    'related': {},
    'emojis': '🇫🇴'
  },
  'fi-FI': {
    'locale': 'fi-FI',
    'language': 'Finnish',
    'emoji': '🇫🇮',
    'country': 'Finland',
    'related': {},
    'emojis': '🇫🇮'
  },
  'fr-DZ': {
    'locale': 'fr-DZ',
    'language': 'French',
    'emoji': '🇩🇿',
    'country': 'Algeria',
    'related': {
      'fr-BE': {
        'locale': 'fr-BE',
        'language': 'French',
        'emoji': '🇧🇪',
        'country': 'Belgium'
      },
      'fr-BJ': {
        'locale': 'fr-BJ',
        'language': 'French',
        'emoji': '🇧🇯',
        'country': 'Benin'
      },
      'fr-BF': {
        'locale': 'fr-BF',
        'language': 'French',
        'emoji': '🇧🇫',
        'country': 'Burkina Faso'
      },
      'fr-BI': {
        'locale': 'fr-BI',
        'language': 'French',
        'emoji': '🇧🇮',
        'country': 'Burundi'
      },
      'fr-CM': {
        'locale': 'fr-CM',
        'language': 'French',
        'emoji': '🇨🇲',
        'country': 'Cameroon'
      },
      'fr-CA': {
        'locale': 'fr-CA',
        'language': 'French',
        'emoji': '🇨🇦',
        'country': 'Canada'
      },
      'fr-CF': {
        'locale': 'fr-CF',
        'language': 'French',
        'emoji': '🇨🇫',
        'country': 'Central African Republic'
      },
      'fr-TD': {
        'locale': 'fr-TD',
        'language': 'French',
        'emoji': '🇹🇩',
        'country': 'Chad'
      },
      'fr-KM': {
        'locale': 'fr-KM',
        'language': 'French',
        'emoji': '🇰🇲',
        'country': 'Comoros'
      },
      'fr-CG': {
        'locale': 'fr-CG',
        'language': 'French',
        'emoji': '🇨🇬',
        'country': 'Congo - Brazzaville'
      },
      'fr-CD': {
        'locale': 'fr-CD',
        'language': 'French',
        'emoji': '🇨🇩',
        'country': 'Congo - Kinshasa'
      },
      'fr-CI': {
        'locale': 'fr-CI',
        'language': 'French',
        'emoji': '🇨🇮',
        'country': 'Côte d’Ivoire'
      },
      'fr-DJ': {
        'locale': 'fr-DJ',
        'language': 'French',
        'emoji': '🇩🇯',
        'country': 'Djibouti'
      },
      'fr-GQ': {
        'locale': 'fr-GQ',
        'language': 'French',
        'emoji': '🇬🇶',
        'country': 'Equatorial Guinea'
      },
      'fr-FR': {
        'locale': 'fr-FR',
        'language': 'French',
        'emoji': '🇫🇷',
        'country': 'France'
      },
      'fr-GF': {
        'locale': 'fr-GF',
        'language': 'French',
        'emoji': '🇬🇫',
        'country': 'French Guiana'
      },
      'fr-PF': {
        'locale': 'fr-PF',
        'language': 'French',
        'emoji': '🇵🇫',
        'country': 'French Polynesia'
      },
      'fr-GA': {
        'locale': 'fr-GA',
        'language': 'French',
        'emoji': '🇬🇦',
        'country': 'Gabon'
      },
      'fr-GP': {
        'locale': 'fr-GP',
        'language': 'French',
        'emoji': '🇬🇵',
        'country': 'Guadeloupe'
      },
      'fr-GN': {
        'locale': 'fr-GN',
        'language': 'French',
        'emoji': '🇬🇳',
        'country': 'Guinea'
      },
      'fr-HT': {
        'locale': 'fr-HT',
        'language': 'French',
        'emoji': '🇭🇹',
        'country': 'Haiti'
      },
      'fr-LU': {
        'locale': 'fr-LU',
        'language': 'French',
        'emoji': '🇱🇺',
        'country': 'Luxembourg'
      },
      'fr-MG': {
        'locale': 'fr-MG',
        'language': 'French',
        'emoji': '🇲🇬',
        'country': 'Madagascar'
      },
      'fr-ML': {
        'locale': 'fr-ML',
        'language': 'French',
        'emoji': '🇲🇱',
        'country': 'Mali'
      },
      'fr-MQ': {
        'locale': 'fr-MQ',
        'language': 'French',
        'emoji': '🇲🇶',
        'country': 'Martinique'
      },
      'fr-MR': {
        'locale': 'fr-MR',
        'language': 'French',
        'emoji': '🇲🇷',
        'country': 'Mauritania'
      },
      'fr-MU': {
        'locale': 'fr-MU',
        'language': 'French',
        'emoji': '🇲🇺',
        'country': 'Mauritius'
      },
      'fr-YT': {
        'locale': 'fr-YT',
        'language': 'French',
        'emoji': '🇾🇹',
        'country': 'Mayotte'
      },
      'fr-MC': {
        'locale': 'fr-MC',
        'language': 'French',
        'emoji': '🇲🇨',
        'country': 'Monaco'
      },
      'fr-MA': {
        'locale': 'fr-MA',
        'language': 'French',
        'emoji': '🇲🇦',
        'country': 'Morocco'
      },
      'fr-NC': {
        'locale': 'fr-NC',
        'language': 'French',
        'emoji': '🇳🇨',
        'country': 'New Caledonia'
      },
      'fr-NE': {
        'locale': 'fr-NE',
        'language': 'French',
        'emoji': '🇳🇪',
        'country': 'Niger'
      },
      'fr-RE': {
        'locale': 'fr-RE',
        'language': 'French',
        'emoji': '🇷🇪',
        'country': 'Réunion'
      },
      'fr-RW': {
        'locale': 'fr-RW',
        'language': 'French',
        'emoji': '🇷🇼',
        'country': 'Rwanda'
      },
      'fr-SN': {
        'locale': 'fr-SN',
        'language': 'French',
        'emoji': '🇸🇳',
        'country': 'Senegal'
      },
      'fr-SC': {
        'locale': 'fr-SC',
        'language': 'French',
        'emoji': '🇸🇨',
        'country': 'Seychelles'
      },
      'fr-BL': {
        'locale': 'fr-BL',
        'language': 'French',
        'emoji': '🇧🇱',
        'country': 'St. Barthélemy'
      },
      'fr-MF': {
        'locale': 'fr-MF',
        'language': 'French',
        'emoji': '🇲🇫',
        'country': 'St. Martin'
      },
      'fr-PM': {
        'locale': 'fr-PM',
        'language': 'French',
        'emoji': '🇵🇲',
        'country': 'St. Pierre & Miquelon'
      },
      'fr-CH': {
        'locale': 'fr-CH',
        'language': 'French',
        'emoji': '🇨🇭',
        'country': 'Switzerland'
      },
      'fr-SY': {
        'locale': 'fr-SY',
        'language': 'French',
        'emoji': '🇸🇾',
        'country': 'Syria'
      },
      'fr-TG': {
        'locale': 'fr-TG',
        'language': 'French',
        'emoji': '🇹🇬',
        'country': 'Togo'
      },
      'fr-TN': {
        'locale': 'fr-TN',
        'language': 'French',
        'emoji': '🇹🇳',
        'country': 'Tunisia'
      },
      'fr-VU': {
        'locale': 'fr-VU',
        'language': 'French',
        'emoji': '🇻🇺',
        'country': 'Vanuatu'
      },
      'fr-WF': {
        'locale': 'fr-WF',
        'language': 'French',
        'emoji': '🇼🇫',
        'country': 'Wallis & Futuna'
      }
    },
    'emojis': '🇩🇿 🇧🇪 🇧🇯 🇧🇫 🇧🇮 🇨🇲 🇨🇦 🇨🇫 🇹🇩 🇰🇲 🇨🇬 🇨🇩 🇨🇮 🇩🇯 🇬🇶 🇫🇷 🇬🇫 🇵🇫 🇬🇦 🇬🇵 🇬🇳 🇭🇹 🇱🇺 🇲🇬 🇲🇱 🇲🇶 🇲🇷 🇲🇺 🇾🇹 🇲🇨 🇲🇦 🇳🇨 🇳🇪 🇷🇪 🇷🇼 🇸🇳 🇸🇨 🇧🇱 🇲🇫 🇵🇲 🇨🇭 🇸🇾 🇹🇬 🇹🇳 🇻🇺 🇼🇫'
  },
  'ff-CM': {
    'locale': 'ff-CM',
    'language': 'Fulah',
    'emoji': '🇨🇲',
    'country': 'Cameroon',
    'related': {
      'ff-GN': {
        'locale': 'ff-GN',
        'language': 'Fulah',
        'emoji': '🇬🇳',
        'country': 'Guinea'
      },
      'ff-MR': {
        'locale': 'ff-MR',
        'language': 'Fulah',
        'emoji': '🇲🇷',
        'country': 'Mauritania'
      },
      'ff-SN': {
        'locale': 'ff-SN',
        'language': 'Fulah',
        'emoji': '🇸🇳',
        'country': 'Senegal'
      }
    },
    'emojis': '🇨🇲 🇬🇳 🇲🇷 🇸🇳'
  },
  'gl-ES': {
    'locale': 'gl-ES',
    'language': 'Galician',
    'emoji': '🇪🇸',
    'country': 'Spain',
    'related': {},
    'emojis': '🇪🇸'
  },
  'lg-UG': {
    'locale': 'lg-UG',
    'language': 'Ganda',
    'emoji': '🇺🇬',
    'country': 'Uganda',
    'related': {},
    'emojis': '🇺🇬'
  },
  'ka-GE': {
    'locale': 'ka-GE',
    'language': 'Georgian',
    'emoji': '🇬🇪',
    'country': 'Georgia',
    'related': {},
    'emojis': '🇬🇪'
  },
  'de-AT': {
    'locale': 'de-AT',
    'language': 'German',
    'emoji': '🇦🇹',
    'country': 'Austria',
    'related': {
      'de-BE': {
        'locale': 'de-BE',
        'language': 'German',
        'emoji': '🇧🇪',
        'country': 'Belgium'
      },
      'de-DE': {
        'locale': 'de-DE',
        'language': 'German',
        'emoji': '🇩🇪',
        'country': 'Germany'
      },
      'de-LI': {
        'locale': 'de-LI',
        'language': 'German',
        'emoji': '🇱🇮',
        'country': 'Liechtenstein'
      },
      'de-LU': {
        'locale': 'de-LU',
        'language': 'German',
        'emoji': '🇱🇺',
        'country': 'Luxembourg'
      },
      'de-CH': {
        'locale': 'de-CH',
        'language': 'German',
        'emoji': '🇨🇭',
        'country': 'Switzerland'
      }
    },
    'emojis': '🇦🇹 🇧🇪 🇩🇪 🇱🇮 🇱🇺 🇨🇭'
  },
  'el-CY': {
    'locale': 'el-CY',
    'language': 'Greek',
    'emoji': '🇨🇾',
    'country': 'Cyprus',
    'related': {
      'el-GR': {
        'locale': 'el-GR',
        'language': 'Greek',
        'emoji': '🇬🇷',
        'country': 'Greece'
      }
    },
    'emojis': '🇨🇾 🇬🇷'
  },
  'gu-IN': {
    'locale': 'gu-IN',
    'language': 'Gujarati',
    'emoji': '🇮🇳',
    'country': 'India',
    'related': {},
    'emojis': '🇮🇳'
  },
  'ha-GH': {
    'locale': 'ha-GH',
    'language': 'Hausa',
    'emoji': '🇬🇭',
    'country': 'Ghana',
    'related': {
      'ha-Latn_GH': {
        'locale': 'ha-Latn_GH',
        'language': 'Hausa',
        'emoji': '🇬🇭',
        'country': 'Latin, Ghana'
      },
      'ha-Latn_NE': {
        'locale': 'ha-Latn_NE',
        'language': 'Hausa',
        'emoji': '🇳🇪',
        'country': 'Latin, Niger'
      },
      'ha-Latn_NG': {
        'locale': 'ha-Latn_NG',
        'language': 'Hausa',
        'emoji': '🇳🇬',
        'country': 'Latin, Nigeria'
      },
      'ha-Latn': {
        'locale': 'ha-Latn',
        'language': 'Hausa',
        'emoji': '🇳🇬',
        'country': 'Latin'
      },
      'ha-NE': {
        'locale': 'ha-NE',
        'language': 'Hausa',
        'emoji': '🇳🇪',
        'country': 'Niger'
      },
      'ha-NG': {
        'locale': 'ha-NG',
        'language': 'Hausa',
        'emoji': '🇳🇬',
        'country': 'Nigeria'
      }
    },
    'emojis': '🇬🇭 🇬🇭 🇳🇪 🇳🇬 🇳🇬 🇳🇪 🇳🇬'
  },
  'he-IL': {
    'locale': 'he-IL',
    'language': 'Hebrew',
    'emoji': '🇮🇱',
    'country': 'Israel',
    'related': {},
    'emojis': '🇮🇱'
  },
  'hi-IN': {
    'locale': 'hi-IN',
    'language': 'Hindi',
    'emoji': '🇮🇳',
    'country': 'India',
    'related': {},
    'emojis': '🇮🇳'
  },
  'hu-HU': {
    'locale': 'hu-HU',
    'language': 'Hungarian',
    'emoji': '🇭🇺',
    'country': 'Hungary',
    'related': {},
    'emojis': '🇭🇺'
  },
  'is-IS': {
    'locale': 'is-IS',
    'language': 'Icelandic',
    'emoji': '🇮🇸',
    'country': 'Iceland',
    'related': {},
    'emojis': '🇮🇸'
  },
  'ig-NG': {
    'locale': 'ig-NG',
    'language': 'Igbo',
    'emoji': '🇳🇬',
    'country': 'Nigeria',
    'related': {},
    'emojis': '🇳🇬'
  },
  'id-ID': {
    'locale': 'id-ID',
    'language': 'Indonesian',
    'emoji': '🇮🇩',
    'country': 'Indonesia',
    'related': {},
    'emojis': '🇮🇩'
  },
  'ga-IE': {
    'locale': 'ga-IE',
    'language': 'Irish',
    'emoji': '🇮🇪',
    'country': 'Ireland',
    'related': {},
    'emojis': '🇮🇪'
  },
  'it-IT': {
    'locale': 'it-IT',
    'language': 'Italian',
    'emoji': '🇮🇹',
    'country': 'Italy',
    'related': {
      'it-SM': {
        'locale': 'it-SM',
        'language': 'Italian',
        'emoji': '🇸🇲',
        'country': 'San Marino'
      },
      'it-CH': {
        'locale': 'it-CH',
        'language': 'Italian',
        'emoji': '🇨🇭',
        'country': 'Switzerland'
      }
    },
    'emojis': '🇮🇹 🇸🇲 🇨🇭'
  },
  'ja-JP': {
    'locale': 'ja-JP',
    'language': 'Japanese',
    'emoji': '🇯🇵',
    'country': 'Japan',
    'related': {},
    'emojis': '🇯🇵'
  },
  'kl-GL': {
    'locale': 'kl-GL',
    'language': 'Kalaallisut',
    'emoji': '🇬🇱',
    'country': 'Greenland',
    'related': {},
    'emojis': '🇬🇱'
  },
  'kn-IN': {
    'locale': 'kn-IN',
    'language': 'Kannada',
    'emoji': '🇮🇳',
    'country': 'India',
    'related': {},
    'emojis': '🇮🇳'
  },
  'ks-Arab_IN': {
    'locale': 'ks-Arab_IN',
    'language': 'Kashmiri',
    'emoji': '🇮🇳',
    'country': 'Arabic, India',
    'related': {
      'ks-Arab': {
        'locale': 'ks-Arab',
        'language': 'Kashmiri',
        'emoji': '🇮🇳',
        'country': 'Arabic'
      },
      'ks-IN': {
        'locale': 'ks-IN',
        'language': 'Kashmiri',
        'emoji': '🇮🇳',
        'country': 'India'
      }
    },
    'emojis': '🇮🇳 🇮🇳 🇮🇳'
  },
  'kk-Cyrl_KZ': {
    'locale': 'kk-Cyrl_KZ',
    'language': 'Kazakh',
    'emoji': '🇰🇿',
    'country': 'Cyrillic, Kazakhstan',
    'related': {
      'kk-Cyrl': {
        'locale': 'kk-Cyrl',
        'language': 'Kazakh',
        'emoji': '🇰🇿',
        'country': 'Cyrillic'
      },
      'kk-KZ': {
        'locale': 'kk-KZ',
        'language': 'Kazakh',
        'emoji': '🇰🇿',
        'country': 'Kazakhstan'
      }
    },
    'emojis': '🇰🇿 🇰🇿 🇰🇿'
  },
  'km-KH': {
    'locale': 'km-KH',
    'language': 'Khmer',
    'emoji': '🇰🇭',
    'country': 'Cambodia',
    'related': {},
    'emojis': '🇰🇭'
  },
  'ki-KE': {
    'locale': 'ki-KE',
    'language': 'Kikuyu',
    'emoji': '🇰🇪',
    'country': 'Kenya',
    'related': {},
    'emojis': '🇰🇪'
  },
  'rw-RW': {
    'locale': 'rw-RW',
    'language': 'Kinyarwanda',
    'emoji': '🇷🇼',
    'country': 'Rwanda',
    'related': {},
    'emojis': '🇷🇼'
  },
  'ko-KP': {
    'locale': 'ko-KP',
    'language': 'Korean',
    'emoji': '🇰🇵',
    'country': 'North Korea',
    'related': {
      'ko-KR': {
        'locale': 'ko-KR',
        'language': 'Korean',
        'emoji': '🇰🇷',
        'country': 'South Korea'
      }
    },
    'emojis': '🇰🇵 🇰🇷'
  },
  'ky-Cyrl_KG': {
    'locale': 'ky-Cyrl_KG',
    'language': 'Kyrgyz',
    'emoji': '🇰🇬',
    'country': 'Cyrillic, Kyrgyzstan',
    'related': {
      'ky-Cyrl': {
        'locale': 'ky-Cyrl',
        'language': 'Kyrgyz',
        'emoji': '🇰🇬',
        'country': 'Cyrillic'
      },
      'ky-KG': {
        'locale': 'ky-KG',
        'language': 'Kyrgyz',
        'emoji': '🇰🇬',
        'country': 'Kyrgyzstan'
      }
    },
    'emojis': '🇰🇬 🇰🇬 🇰🇬'
  },
  'lo-LA': {
    'locale': 'lo-LA',
    'language': 'Lao',
    'emoji': '🇱🇦',
    'country': 'Laos',
    'related': {},
    'emojis': '🇱🇦'
  },
  'lv-LV': {
    'locale': 'lv-LV',
    'language': 'Latvian',
    'emoji': '🇱🇻',
    'country': 'Latvia',
    'related': {},
    'emojis': '🇱🇻'
  },
  'ln-AO': {
    'locale': 'ln-AO',
    'language': 'Lingala',
    'emoji': '🇦🇴',
    'country': 'Angola',
    'related': {
      'ln-CF': {
        'locale': 'ln-CF',
        'language': 'Lingala',
        'emoji': '🇨🇫',
        'country': 'Central African Republic'
      },
      'ln-CG': {
        'locale': 'ln-CG',
        'language': 'Lingala',
        'emoji': '🇨🇬',
        'country': 'Congo - Brazzaville'
      },
      'ln-CD': {
        'locale': 'ln-CD',
        'language': 'Lingala',
        'emoji': '🇨🇩',
        'country': 'Congo - Kinshasa'
      }
    },
    'emojis': '🇦🇴 🇨🇫 🇨🇬 🇨🇩'
  },
  'lt-LT': {
    'locale': 'lt-LT',
    'language': 'Lithuanian',
    'emoji': '🇱🇹',
    'country': 'Lithuania',
    'related': {},
    'emojis': '🇱🇹'
  },
  'lu-CD': {
    'locale': 'lu-CD',
    'language': 'Luba-Katanga',
    'emoji': '🇨🇩',
    'country': 'Congo - Kinshasa',
    'related': {},
    'emojis': '🇨🇩'
  },
  'lb-LU': {
    'locale': 'lb-LU',
    'language': 'Luxembourgish',
    'emoji': '🇱🇺',
    'country': 'Luxembourg',
    'related': {},
    'emojis': '🇱🇺'
  },
  'mk-MK': {
    'locale': 'mk-MK',
    'language': 'Macedonian',
    'emoji': '🇲🇰',
    'country': 'Macedonia',
    'related': {},
    'emojis': '🇲🇰'
  },
  'mg-MG': {
    'locale': 'mg-MG',
    'language': 'Malagasy',
    'emoji': '🇲🇬',
    'country': 'Madagascar',
    'related': {},
    'emojis': '🇲🇬'
  },
  'ms-BN': {
    'locale': 'ms-BN',
    'language': 'Malay',
    'emoji': '🇧🇳',
    'country': 'Brunei',
    'related': {
      'ms-Latn_BN': {
        'locale': 'ms-Latn_BN',
        'language': 'Malay',
        'emoji': '🇧🇳',
        'country': 'Latin, Brunei'
      },
      'ms-Latn_MY': {
        'locale': 'ms-Latn_MY',
        'language': 'Malay',
        'emoji': '🇲🇾',
        'country': 'Latin, Malaysia'
      },
      'ms-Latn_SG': {
        'locale': 'ms-Latn_SG',
        'language': 'Malay',
        'emoji': '🇸🇬',
        'country': 'Latin, Singapore'
      },
      'ms-Latn': {
        'locale': 'ms-Latn',
        'language': 'Malay',
        'emoji': '🇲🇾',
        'country': 'Latin'
      },
      'ms-MY': {
        'locale': 'ms-MY',
        'language': 'Malay',
        'emoji': '🇲🇾',
        'country': 'Malaysia'
      },
      'ms-SG': {
        'locale': 'ms-SG',
        'language': 'Malay',
        'emoji': '🇸🇬',
        'country': 'Singapore'
      }
    },
    'emojis': '🇧🇳 🇧🇳 🇲🇾 🇸🇬 🇲🇾 🇲🇾 🇸🇬'
  },
  'ml-IN': {
    'locale': 'ml-IN',
    'language': 'Malayalam',
    'emoji': '🇮🇳',
    'country': 'India',
    'related': {},
    'emojis': '🇮🇳'
  },
  'mt-MT': {
    'locale': 'mt-MT',
    'language': 'Maltese',
    'emoji': '🇲🇹',
    'country': 'Malta',
    'related': {},
    'emojis': '🇲🇹'
  },
  'gv-IM': {
    'locale': 'gv-IM',
    'language': 'Manx',
    'emoji': '🇮🇲',
    'country': 'Isle of Man',
    'related': {},
    'emojis': '🇮🇲'
  },
  'mr-IN': {
    'locale': 'mr-IN',
    'language': 'Marathi',
    'emoji': '🇮🇳',
    'country': 'India',
    'related': {},
    'emojis': '🇮🇳'
  },
  'mn-Cyrl_MN': {
    'locale': 'mn-Cyrl_MN',
    'language': 'Mongolian',
    'emoji': '🇲🇳',
    'country': 'Cyrillic, Mongolia',
    'related': {
      'mn-Cyrl': {
        'locale': 'mn-Cyrl',
        'language': 'Mongolian',
        'emoji': '🇲🇳',
        'country': 'Cyrillic'
      },
      'mn-MN': {
        'locale': 'mn-MN',
        'language': 'Mongolian',
        'emoji': '🇲🇳',
        'country': 'Mongolia'
      }
    },
    'emojis': '🇲🇳 🇲🇳 🇲🇳'
  },
  'ne-IN': {
    'locale': 'ne-IN',
    'language': 'Nepali',
    'emoji': '🇮🇳',
    'country': 'India',
    'related': {
      'ne-NP': {
        'locale': 'ne-NP',
        'language': 'Nepali',
        'emoji': '🇳🇵',
        'country': 'Nepal'
      }
    },
    'emojis': '🇮🇳 🇳🇵'
  },
  'nd-ZW': {
    'locale': 'nd-ZW',
    'language': 'North Ndebele',
    'emoji': '🇿🇼',
    'country': 'Zimbabwe',
    'related': {},
    'emojis': '🇿🇼'
  },
  'se-FI': {
    'locale': 'se-FI',
    'language': 'Northern Sami',
    'emoji': '🇫🇮',
    'country': 'Finland',
    'related': {
      'se-NO': {
        'locale': 'se-NO',
        'language': 'Northern Sami',
        'emoji': '🇳🇴',
        'country': 'Norway'
      },
      'se-SE': {
        'locale': 'se-SE',
        'language': 'Northern Sami',
        'emoji': '🇸🇪',
        'country': 'Sweden'
      }
    },
    'emojis': '🇫🇮 🇳🇴 🇸🇪'
  },
  'no-NO': {
    'locale': 'no-NO',
    'language': 'Norwegian',
    'emoji': '🇳🇴',
    'country': 'Norway',
    'related': {},
    'emojis': '🇳🇴'
  },
  'nb-NO': {
    'locale': 'nb-NO',
    'language': 'Norwegian Bokmål',
    'emoji': '🇳🇴',
    'country': 'Norway',
    'related': {
      'nb-SJ': {
        'locale': 'nb-SJ',
        'language': 'Norwegian Bokmål',
        'emoji': '🇸🇯',
        'country': 'Svalbard & Jan Mayen'
      }
    },
    'emojis': '🇳🇴 🇸🇯'
  },
  'nn-NO': {
    'locale': 'nn-NO',
    'language': 'Norwegian Nynorsk',
    'emoji': '🇳🇴',
    'country': 'Norway',
    'related': {},
    'emojis': '🇳🇴'
  },
  'or-IN': {
    'locale': 'or-IN',
    'language': 'Oriya',
    'emoji': '🇮🇳',
    'country': 'India',
    'related': {},
    'emojis': '🇮🇳'
  },
  'om-ET': {
    'locale': 'om-ET',
    'language': 'Oromo',
    'emoji': '🇪🇹',
    'country': 'Ethiopia',
    'related': {
      'om-KE': {
        'locale': 'om-KE',
        'language': 'Oromo',
        'emoji': '🇰🇪',
        'country': 'Kenya'
      }
    },
    'emojis': '🇪🇹 🇰🇪'
  },
  'os-GE': {
    'locale': 'os-GE',
    'language': 'Ossetic',
    'emoji': '🇬🇪',
    'country': 'Georgia',
    'related': {
      'os-RU': {
        'locale': 'os-RU',
        'language': 'Ossetic',
        'emoji': '🇷🇺',
        'country': 'Russia'
      }
    },
    'emojis': '🇬🇪 🇷🇺'
  },
  'ps-AF': {
    'locale': 'ps-AF',
    'language': 'Pashto',
    'emoji': '🇦🇫',
    'country': 'Afghanistan',
    'related': {},
    'emojis': '🇦🇫'
  },
  'fa-AF': {
    'locale': 'fa-AF',
    'language': 'Persian',
    'emoji': '🇦🇫',
    'country': 'Afghanistan',
    'related': {
      'fa-IR': {
        'locale': 'fa-IR',
        'language': 'Persian',
        'emoji': '🇮🇷',
        'country': 'Iran'
      }
    },
    'emojis': '🇦🇫 🇮🇷'
  },
  'pl-PL': {
    'locale': 'pl-PL',
    'language': 'Polish',
    'emoji': '🇵🇱',
    'country': 'Poland',
    'related': {},
    'emojis': '🇵🇱'
  },
  'pt-AO': {
    'locale': 'pt-AO',
    'language': 'Portuguese',
    'emoji': '🇦🇴',
    'country': 'Angola',
    'related': {
      'pt-BR': {
        'locale': 'pt-BR',
        'language': 'Portuguese',
        'emoji': '🇧🇷',
        'country': 'Brazil'
      },
      'pt-CV': {
        'locale': 'pt-CV',
        'language': 'Portuguese',
        'emoji': '🇨🇻',
        'country': 'Cape Verde'
      },
      'pt-GW': {
        'locale': 'pt-GW',
        'language': 'Portuguese',
        'emoji': '🇬🇼',
        'country': 'Guinea-Bissau'
      },
      'pt-MO': {
        'locale': 'pt-MO',
        'language': 'Portuguese',
        'emoji': '🇲🇴',
        'country': 'Macau SAR China'
      },
      'pt-MZ': {
        'locale': 'pt-MZ',
        'language': 'Portuguese',
        'emoji': '🇲🇿',
        'country': 'Mozambique'
      },
      'pt-PT': {
        'locale': 'pt-PT',
        'language': 'Portuguese',
        'emoji': '🇵🇹',
        'country': 'Portugal'
      },
      'pt-ST': {
        'locale': 'pt-ST',
        'language': 'Portuguese',
        'emoji': '🇸🇹',
        'country': 'São Tomé & Príncipe'
      },
      'pt-TL': {
        'locale': 'pt-TL',
        'language': 'Portuguese',
        'emoji': '🇹🇱',
        'country': 'Timor-Leste'
      }
    },
    'emojis': '🇦🇴 🇧🇷 🇨🇻 🇬🇼 🇲🇴 🇲🇿 🇵🇹 🇸🇹 🇹🇱'
  },
  'pa-Arab_PK': {
    'locale': 'pa-Arab_PK',
    'language': 'Punjabi',
    'emoji': '🇵🇰',
    'country': 'Arabic, Pakistan',
    'related': {
      'pa-Arab': {
        'locale': 'pa-Arab',
        'language': 'Punjabi',
        'emoji': '🇮🇳',
        'country': 'Arabic'
      },
      'pa-Guru_IN': {
        'locale': 'pa-Guru_IN',
        'language': 'Punjabi',
        'emoji': '🇮🇳',
        'country': 'Gurmukhi, India'
      },
      'pa-Guru': {
        'locale': 'pa-Guru',
        'language': 'Punjabi',
        'emoji': '🇮🇳',
        'country': 'Gurmukhi'
      },
      'pa-IN': {
        'locale': 'pa-IN',
        'language': 'Punjabi',
        'emoji': '🇮🇳',
        'country': 'India'
      },
      'pa-PK': {
        'locale': 'pa-PK',
        'language': 'Punjabi',
        'emoji': '🇵🇰',
        'country': 'Pakistan'
      }
    },
    'emojis': '🇵🇰 🇮🇳 🇮🇳 🇮🇳 🇮🇳 🇵🇰'
  },
  'qu-BO': {
    'locale': 'qu-BO',
    'language': 'Quechua',
    'emoji': '🇧🇴',
    'country': 'Bolivia',
    'related': {
      'qu-EC': {
        'locale': 'qu-EC',
        'language': 'Quechua',
        'emoji': '🇪🇨',
        'country': 'Ecuador'
      },
      'qu-PE': {
        'locale': 'qu-PE',
        'language': 'Quechua',
        'emoji': '🇵🇪',
        'country': 'Peru'
      }
    },
    'emojis': '🇧🇴 🇪🇨 🇵🇪'
  },
  'ro-MD': {
    'locale': 'ro-MD',
    'language': 'Romanian',
    'emoji': '🇲🇩',
    'country': 'Moldova',
    'related': {
      'ro-RO': {
        'locale': 'ro-RO',
        'language': 'Romanian',
        'emoji': '🇷🇴',
        'country': 'Romania'
      }
    },
    'emojis': '🇲🇩 🇷🇴'
  },
  'rm-CH': {
    'locale': 'rm-CH',
    'language': 'Romansh',
    'emoji': '🇨🇭',
    'country': 'Switzerland',
    'related': {},
    'emojis': '🇨🇭'
  },
  'rn-BI': {
    'locale': 'rn-BI',
    'language': 'Rundi',
    'emoji': '🇧🇮',
    'country': 'Burundi',
    'related': {},
    'emojis': '🇧🇮'
  },
  'ru-BY': {
    'locale': 'ru-BY',
    'language': 'Russian',
    'emoji': '🇧🇾',
    'country': 'Belarus',
    'related': {
      'ru-KZ': {
        'locale': 'ru-KZ',
        'language': 'Russian',
        'emoji': '🇰🇿',
        'country': 'Kazakhstan'
      },
      'ru-KG': {
        'locale': 'ru-KG',
        'language': 'Russian',
        'emoji': '🇰🇬',
        'country': 'Kyrgyzstan'
      },
      'ru-MD': {
        'locale': 'ru-MD',
        'language': 'Russian',
        'emoji': '🇲🇩',
        'country': 'Moldova'
      },
      'ru-RU': {
        'locale': 'ru-RU',
        'language': 'Russian',
        'emoji': '🇷🇺',
        'country': 'Russia'
      },
      'ru-UA': {
        'locale': 'ru-UA',
        'language': 'Russian',
        'emoji': '🇺🇦',
        'country': 'Ukraine'
      }
    },
    'emojis': '🇧🇾 🇰🇿 🇰🇬 🇲🇩 🇷🇺 🇺🇦'
  },
  'sg-CF': {
    'locale': 'sg-CF',
    'language': 'Sango',
    'emoji': '🇨🇫',
    'country': 'Central African Republic',
    'related': {},
    'emojis': '🇨🇫'
  },
  'gd-GB': {
    'locale': 'gd-GB',
    'language': 'Scottish Gaelic',
    'emoji': '🇬🇧',
    'country': 'United Kingdom',
    'related': {},
    'emojis': '🇬🇧'
  },
  'sr-BA': {
    'locale': 'sr-BA',
    'language': 'Serbian',
    'emoji': '🇧🇦',
    'country': 'Bosnia & Herzegovina',
    'related': {
      'sr-Cyrl_BA': {
        'locale': 'sr-Cyrl_BA',
        'language': 'Serbian',
        'emoji': '🇧🇦',
        'country': 'Cyrillic, Bosnia & Herzegovina'
      },
      'sr-Cyrl_XK': {
        'locale': 'sr-Cyrl_XK',
        'language': 'Serbian',
        'emoji': '🇽🇰',
        'country': 'Cyrillic, Kosovo'
      },
      'sr-Cyrl_ME': {
        'locale': 'sr-Cyrl_ME',
        'language': 'Serbian',
        'emoji': '🇲🇪',
        'country': 'Cyrillic, Montenegro'
      },
      'sr-Cyrl_RS': {
        'locale': 'sr-Cyrl_RS',
        'language': 'Serbian',
        'emoji': '🇷🇸',
        'country': 'Cyrillic, Serbia'
      },
      'sr-Cyrl': {
        'locale': 'sr-Cyrl',
        'language': 'Serbian',
        'emoji': '🇷🇸',
        'country': 'Cyrillic'
      },
      'sr-XK': {
        'locale': 'sr-XK',
        'language': 'Serbian',
        'emoji': '🇽🇰',
        'country': 'Kosovo'
      },
      'sr-Latn_BA': {
        'locale': 'sr-Latn_BA',
        'language': 'Serbian',
        'emoji': '🇧🇦',
        'country': 'Latin, Bosnia & Herzegovina'
      },
      'sr-Latn_XK': {
        'locale': 'sr-Latn_XK',
        'language': 'Serbian',
        'emoji': '🇽🇰',
        'country': 'Latin, Kosovo'
      },
      'sr-Latn_ME': {
        'locale': 'sr-Latn_ME',
        'language': 'Serbian',
        'emoji': '🇲🇪',
        'country': 'Latin, Montenegro'
      },
      'sr-Latn_RS': {
        'locale': 'sr-Latn_RS',
        'language': 'Serbian',
        'emoji': '🇷🇸',
        'country': 'Latin, Serbia'
      },
      'sr-Latn': {
        'locale': 'sr-Latn',
        'language': 'Serbian',
        'emoji': '🇷🇸',
        'country': 'Latin'
      },
      'sr-ME': {
        'locale': 'sr-ME',
        'language': 'Serbian',
        'emoji': '🇲🇪',
        'country': 'Montenegro'
      },
      'sr-RS': {
        'locale': 'sr-RS',
        'language': 'Serbian',
        'emoji': '🇷🇸',
        'country': 'Serbia'
      }
    },
    'emojis': '🇧🇦 🇧🇦 🇽🇰 🇲🇪 🇷🇸 🇷🇸 🇽🇰 🇧🇦 🇽🇰 🇲🇪 🇷🇸 🇷🇸 🇲🇪 🇷🇸'
  },
  'sh-BA': {
    'locale': 'sh-BA',
    'language': 'Serbo-Croatian',
    'emoji': '🇧🇦',
    'country': 'Bosnia & Herzegovina',
    'related': {},
    'emojis': '🇧🇦'
  },
  'sn-ZW': {
    'locale': 'sn-ZW',
    'language': 'Shona',
    'emoji': '🇿🇼',
    'country': 'Zimbabwe',
    'related': {},
    'emojis': '🇿🇼'
  },
  'ii-CN': {
    'locale': 'ii-CN',
    'language': 'Sichuan Yi',
    'emoji': '🇨🇳',
    'country': 'China',
    'related': {},
    'emojis': '🇨🇳'
  },
  'si-LK': {
    'locale': 'si-LK',
    'language': 'Sinhala',
    'emoji': '🇱🇰',
    'country': 'Sri Lanka',
    'related': {},
    'emojis': '🇱🇰'
  },
  'sk-SK': {
    'locale': 'sk-SK',
    'language': 'Slovak',
    'emoji': '🇸🇰',
    'country': 'Slovakia',
    'related': {},
    'emojis': '🇸🇰'
  },
  'sl-SI': {
    'locale': 'sl-SI',
    'language': 'Slovenian',
    'emoji': '🇸🇮',
    'country': 'Slovenia',
    'related': {},
    'emojis': '🇸🇮'
  },
  'so-DJ': {
    'locale': 'so-DJ',
    'language': 'Somali',
    'emoji': '🇩🇯',
    'country': 'Djibouti',
    'related': {
      'so-ET': {
        'locale': 'so-ET',
        'language': 'Somali',
        'emoji': '🇪🇹',
        'country': 'Ethiopia'
      },
      'so-KE': {
        'locale': 'so-KE',
        'language': 'Somali',
        'emoji': '🇰🇪',
        'country': 'Kenya'
      },
      'so-SO': {
        'locale': 'so-SO',
        'language': 'Somali',
        'emoji': '🇸🇴',
        'country': 'Somalia'
      }
    },
    'emojis': '🇩🇯 🇪🇹 🇰🇪 🇸🇴'
  },
  'es-ES': {
    'locale': 'es-ES',
    'language': 'Spanish',
    'emoji': '🇪🇸',
    'country': 'Spain',
    'related': {
      'es-AR': {
        'locale': 'es-AR',
        'language': 'Spanish',
        'emoji': '🇦🇷',
        'country': 'Argentina',
      },
      'es-BO': {
        'locale': 'es-BO',
        'language': 'Spanish',
        'emoji': '🇧🇴',
        'country': 'Bolivia'
      },
      'es-IC': {
        'locale': 'es-IC',
        'language': 'Spanish',
        'emoji': '🇮🇨',
        'country': 'Canary Islands'
      },
      'es-EA': {
        'locale': 'es-EA',
        'language': 'Spanish',
        'emoji': '🇪🇦',
        'country': 'Ceuta & Melilla'
      },
      'es-CL': {
        'locale': 'es-CL',
        'language': 'Spanish',
        'emoji': '🇨🇱',
        'country': 'Chile'
      },
      'es-CO': {
        'locale': 'es-CO',
        'language': 'Spanish',
        'emoji': '🇨🇴',
        'country': 'Colombia'
      },
      'es-CR': {
        'locale': 'es-CR',
        'language': 'Spanish',
        'emoji': '🇨🇷',
        'country': 'Costa Rica'
      },
      'es-CU': {
        'locale': 'es-CU',
        'language': 'Spanish',
        'emoji': '🇨🇺',
        'country': 'Cuba'
      },
      'es-DO': {
        'locale': 'es-DO',
        'language': 'Spanish',
        'emoji': '🇩🇴',
        'country': 'Dominican Republic'
      },
      'es-EC': {
        'locale': 'es-EC',
        'language': 'Spanish',
        'emoji': '🇪🇨',
        'country': 'Ecuador'
      },
      'es-SV': {
        'locale': 'es-SV',
        'language': 'Spanish',
        'emoji': '🇸🇻',
        'country': 'El Salvador'
      },
      'es-GQ': {
        'locale': 'es-GQ',
        'language': 'Spanish',
        'emoji': '🇬🇶',
        'country': 'Equatorial Guinea'
      },
      'es-GT': {
        'locale': 'es-GT',
        'language': 'Spanish',
        'emoji': '🇬🇹',
        'country': 'Guatemala'
      },
      'es-HN': {
        'locale': 'es-HN',
        'language': 'Spanish',
        'emoji': '🇭🇳',
        'country': 'Honduras'
      },
      'es-MX': {
        'locale': 'es-MX',
        'language': 'Spanish',
        'emoji': '🇲🇽',
        'country': 'Mexico'
      },
      'es-NI': {
        'locale': 'es-NI',
        'language': 'Spanish',
        'emoji': '🇳🇮',
        'country': 'Nicaragua'
      },
      'es-PA': {
        'locale': 'es-PA',
        'language': 'Spanish',
        'emoji': '🇵🇦',
        'country': 'Panama'
      },
      'es-PY': {
        'locale': 'es-PY',
        'language': 'Spanish',
        'emoji': '🇵🇾',
        'country': 'Paraguay'
      },
      'es-PE': {
        'locale': 'es-PE',
        'language': 'Spanish',
        'emoji': '🇵🇪',
        'country': 'Peru'
      },
      'es-PH': {
        'locale': 'es-PH',
        'language': 'Spanish',
        'emoji': '🇵🇭',
        'country': 'Philippines'
      },
      'es-PR': {
        'locale': 'es-PR',
        'language': 'Spanish',
        'emoji': '🇵🇷',
        'country': 'Puerto Rico'
      },
      'es-US': {
        'locale': 'es-US',
        'language': 'Spanish',
        'emoji': '🇺🇸',
        'country': 'United States'
      },
      'es-UY': {
        'locale': 'es-UY',
        'language': 'Spanish',
        'emoji': '🇺🇾',
        'country': 'Uruguay'
      },
      'es-VE': {
        'locale': 'es-VE',
        'language': 'Spanish',
        'emoji': '🇻🇪',
        'country': 'Venezuela'
      }
    },
    'emojis': '🇪🇸 🇦🇷 🇧🇴 🇮🇨 🇪🇦 🇨🇱 🇨🇴 🇨🇷 🇨🇺 🇩🇴 🇪🇨 🇸🇻 🇬🇶 🇬🇹 🇭🇳 🇲🇽 🇳🇮 🇵🇦 🇵🇾 🇵🇪 🇵🇭 🇵🇷 🇺🇸 🇺🇾 🇻🇪'
  },
  'sw-KE': {
    'locale': 'sw-KE',
    'language': 'Swahili',
    'emoji': '🇰🇪',
    'country': 'Kenya',
    'related': {
      'sw-TZ': {
        'locale': 'sw-TZ',
        'language': 'Swahili',
        'emoji': '🇹🇿',
        'country': 'Tanzania'
      },
      'sw-UG': {
        'locale': 'sw-UG',
        'language': 'Swahili',
        'emoji': '🇺🇬',
        'country': 'Uganda'
      }
    },
    'emojis': '🇰🇪 🇹🇿 🇺🇬'
  },
  'sv-AX': {
    'locale': 'sv-AX',
    'language': 'Swedish',
    'emoji': '🇦🇽',
    'country': 'Åland Islands',
    'related': {
      'sv-FI': {
        'locale': 'sv-FI',
        'language': 'Swedish',
        'emoji': '🇫🇮',
        'country': 'Finland'
      },
      'sv-SE': {
        'locale': 'sv-SE',
        'language': 'Swedish',
        'emoji': '🇸🇪',
        'country': 'Sweden'
      }
    },
    'emojis': '🇦🇽 🇫🇮 🇸🇪'
  },
  'tl-PH': {
    'locale': 'tl-PH',
    'language': 'Tagalog',
    'emoji': '🇵🇭',
    'country': 'Philippines',
    'related': {},
    'emojis': '🇵🇭'
  },
  'ta-IN': {
    'locale': 'ta-IN',
    'language': 'Tamil',
    'emoji': '🇮🇳',
    'country': 'India',
    'related': {
      'ta-MY': {
        'locale': 'ta-MY',
        'language': 'Tamil',
        'emoji': '🇲🇾',
        'country': 'Malaysia'
      },
      'ta-SG': {
        'locale': 'ta-SG',
        'language': 'Tamil',
        'emoji': '🇸🇬',
        'country': 'Singapore'
      },
      'ta-LK': {
        'locale': 'ta-LK',
        'language': 'Tamil',
        'emoji': '🇱🇰',
        'country': 'Sri Lanka'
      }
    },
    'emojis': '🇮🇳 🇲🇾 🇸🇬 🇱🇰'
  },
  'te-IN': {
    'locale': 'te-IN',
    'language': 'Telugu',
    'emoji': '🇮🇳',
    'country': 'India',
    'related': {},
    'emojis': '🇮🇳'
  },
  'th-TH': {
    'locale': 'th-TH',
    'language': 'Thai',
    'emoji': '🇹🇭',
    'country': 'Thailand',
    'related': {},
    'emojis': '🇹🇭'
  },
  'bo-CN': {
    'locale': 'bo-CN',
    'language': 'Tibetan',
    'emoji': '🇨🇳',
    'country': 'China',
    'related': {
      'bo-IN': {
        'locale': 'bo-IN',
        'language': 'Tibetan',
        'emoji': '🇮🇳',
        'country': 'India'
      }
    },
    'emojis': '🇨🇳 🇮🇳'
  },
  'ti-ER': {
    'locale': 'ti-ER',
    'language': 'Tigrinya',
    'emoji': '🇪🇷',
    'country': 'Eritrea',
    'related': {
      'ti-ET': {
        'locale': 'ti-ET',
        'language': 'Tigrinya',
        'emoji': '🇪🇹',
        'country': 'Ethiopia'
      }
    },
    'emojis': '🇪🇷 🇪🇹'
  },
  'to-TO': {
    'locale': 'to-TO',
    'language': 'Tongan',
    'emoji': '🇹🇴',
    'country': 'Tonga',
    'related': {},
    'emojis': '🇹🇴'
  },
  'tr-CY': {
    'locale': 'tr-CY',
    'language': 'Turkish',
    'emoji': '🇨🇾',
    'country': 'Cyprus',
    'related': {
      'tr-TR': {
        'locale': 'tr-TR',
        'language': 'Turkish',
        'emoji': '🇹🇷',
        'country': 'Turkey'
      }
    },
    'emojis': '🇨🇾 🇹🇷'
  },
  'uk-UA': {
    'locale': 'uk-UA',
    'language': 'Ukrainian',
    'emoji': '🇺🇦',
    'country': 'Ukraine',
    'related': {},
    'emojis': '🇺🇦'
  },
  'ur-IN': {
    'locale': 'ur-IN',
    'language': 'Urdu',
    'emoji': '🇮🇳',
    'country': 'India',
    'related': {
      'ur-PK': {
        'locale': 'ur-PK',
        'language': 'Urdu',
        'emoji': '🇵🇰',
        'country': 'Pakistan'
      }
    },
    'emojis': '🇮🇳 🇵🇰'
  },
  'ug-Arab_CN': {
    'locale': 'ug-Arab_CN',
    'language': 'Uyghur',
    'emoji': '🇨🇳',
    'country': 'Arabic, China',
    'related': {
      'ug-Arab': {
        'locale': 'ug-Arab',
        'language': 'Uyghur',
        'emoji': '🇨🇳',
        'country': 'Arabic'
      },
      'ug-CN': {
        'locale': 'ug-CN',
        'language': 'Uyghur',
        'emoji': '🇨🇳',
        'country': 'China'
      }
    },
    'emojis': '🇨🇳 🇨🇳 🇨🇳'
  },
  'uz-AF': {
    'locale': 'uz-AF',
    'language': 'Uzbek',
    'emoji': '🇦🇫',
    'country': 'Afghanistan',
    'related': {
      'uz-Arab_AF': {
        'locale': 'uz-Arab_AF',
        'language': 'Uzbek',
        'emoji': '🇦🇫',
        'country': 'Arabic, Afghanistan'
      },
      'uz-Arab': {
        'locale': 'uz-Arab',
        'language': 'Uzbek',
        'emoji': '🇺🇿',
        'country': 'Arabic'
      },
      'uz-Cyrl_UZ': {
        'locale': 'uz-Cyrl_UZ',
        'language': 'Uzbek',
        'emoji': '🇺🇿',
        'country': 'Cyrillic, Uzbekistan'
      },
      'uz-Cyrl': {
        'locale': 'uz-Cyrl',
        'language': 'Uzbek',
        'emoji': '🇺🇿',
        'country': 'Cyrillic'
      },
      'uz-Latn_UZ': {
        'locale': 'uz-Latn_UZ',
        'language': 'Uzbek',
        'emoji': '🇺🇿',
        'country': 'Latin, Uzbekistan'
      },
      'uz-Latn': {
        'locale': 'uz-Latn',
        'language': 'Uzbek',
        'emoji': '🇺🇿',
        'country': 'Latin'
      },
      'uz-UZ': {
        'locale': 'uz-UZ',
        'language': 'Uzbek',
        'emoji': '🇺🇿',
        'country': 'Uzbekistan'
      }
    },
    'emojis': '🇦🇫 🇦🇫 🇺🇿 🇺🇿 🇺🇿 🇺🇿 🇺🇿 🇺🇿'
  },
  'vi-VN': {
    'locale': 'vi-VN',
    'language': 'Vietnamese',
    'emoji': '🇻🇳',
    'country': 'Vietnam',
    'related': {},
    'emojis': '🇻🇳'
  },
  'cy-GB': {
    'locale': 'cy-GB',
    'language': 'Welsh',
    'emoji': '🇬🇧',
    'country': 'United Kingdom',
    'related': {},
    'emojis': '🇬🇧'
  },
  'fy-NL': {
    'locale': 'fy-NL',
    'language': 'Western Frisian',
    'emoji': '🇳🇱',
    'country': 'Netherlands',
    'related': {},
    'emojis': '🇳🇱'
  },
  'yo-BJ': {
    'locale': 'yo-BJ',
    'language': 'Yoruba',
    'emoji': '🇧🇯',
    'country': 'Benin',
    'related': {
      'yo-NG': {
        'locale': 'yo-NG',
        'language': 'Yoruba',
        'emoji': '🇳🇬',
        'country': 'Nigeria'
      }
    },
    'emojis': '🇧🇯 🇳🇬'
  },
  'zu-ZA': {
    'locale': 'zu-ZA',
    'language': 'Zulu',
    'emoji': '🇿🇦',
    'country': 'South Africa',
    'related': {},
    'emojis': '🇿🇦'
  }
} as const;

export const ELANGUAGE_GLOBAL_DEFAULT = 'en-US' as TLanguage;
export const LanguageList = _.values(Languages);
export const LanguageCodes = _.keys(Languages) as (keyof typeof Languages)[];
export type TLanguage = (keyof typeof Languages);
export type TLanguageObject = {
  locale: TLanguage,
  language: string,
  country: string,
  emoji: string,
  emojis: string,
  related: {
    [locale: string]: {
      locale: string,
      language: string,
      emoji: string,
      country: string
    }
  },
}
