import * as React from 'react';
import {
  StyleSheet,
  View,
} from 'react-native';
import styled from 'styled-components/native';

type THairlineProps = {
  height?: number;
  color?: string;
  marginLeft?: number;
  marginRight?: number;
};

export const Hairline = (props: THairlineProps) => (
  <Line {...props}/>
);

const Line = styled(View)<THairlineProps>`
  background-color: ${({ color, theme }) => color ?? theme.t.col.white};
  height: ${({ height }) => height || StyleSheet.hairlineWidth}px;
  margin-left: ${({ marginLeft }) => marginLeft || 0}px;
  margin-right: ${({ marginRight }) => marginRight || 0}px;
`;
