import * as React from 'react';
import * as _ from 'lodash';
import { RCCompContracts } from '../navigation/routes/RCCompContracts';
import { RCComp } from './RCComp';
import { RouteContract, TRCMergedProps } from './RouteContract';
import { Class } from '../../../../core/src/lib/Types';
import { TNavigationParamsWithMethod } from '../navigation/Navigation';
import { FullScreenRedirect } from '../../lib-components/FullScreenRedirect';
import { CheckPropsResult, ECheckPropsResult } from './RouteContractCheckProps';
import { FullScreenLoader } from '../../lib-components/FullScreenLoader';
import { TRoutesCliGuestNames } from '../../../../core/src/lib/apis/routes/RoutableCliGuest';

type TWithRCCompState<S> = S & {
  shouldSkipToRoute: TNavigationParamsWithMethod<any> | undefined;
};

export function withRCComp<RK extends TRoutesCliGuestNames, C extends RCComp<RK>>(name: RK, Component: Class<C>) {
  const contract = RCCompContracts[name] as RouteContract<any>;

  return class WithRCComp extends React.Component<TWithRCCompState<TRCMergedProps<RK>>> {
    render = () => {
      // Check if should redirect
      const shouldSkipToRoute = this.props.shouldSkipToRoute;
      if (shouldSkipToRoute != null) {
        // This happens in cases where the user browsed directly
        // to this screen, if they had gone through actionNavigate
        // then actionNavigate would have anticipated this jump
        return (
          <FullScreenRedirect
            routeName={shouldSkipToRoute.routeName}
            params={shouldSkipToRoute.params}
            method={shouldSkipToRoute.method}
          />
        );
      }

      const {
        shouldSkipToRoute: ___,
        ...allComponentPropsIncludingMapStateToPropsResult
      } = this.props;

      // Check props
      const checkedProps = contract.checkProps(allComponentPropsIncludingMapStateToPropsResult);
      if (CheckPropsResult.checkIs(ECheckPropsResult.RENDER, checkedProps)) {
        return (
          <>
            {checkedProps.data}
          </>
        );
      }

      if (CheckPropsResult.checkIs(ECheckPropsResult.REDIRECT, checkedProps)) {
        return (
          <FullScreenRedirect
            routeName={checkedProps.to.routeName}
            params={checkedProps.to.params}
            method={checkedProps.to.method}
          />
        );
      }

      if (CheckPropsResult.checkIs(ECheckPropsResult.WAIT, checkedProps)) {
        return (
          <FullScreenLoader/>
        );
      }

      // ECheckPropsResult.Ready, render the Component
      if (CheckPropsResult.checkIs(ECheckPropsResult.READY, checkedProps)) {
        return (
          // @ts-ignore
          <Component
            {...allComponentPropsIncludingMapStateToPropsResult}
            // @ts-ignore
            {...checkedProps.props}
          />
        );
      }

      throw new Error([
        'withRCComp',
        'render',
        `checkProps result was not handled ${_.get(checkedProps, 'action')}`,
      ].join(', '));
    };
  };
}
