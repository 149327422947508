import {
  TDispatch,
  TThunkAction,
} from '../../../../lib-react/src/redux/redux';
import { TGetState } from '../Store';
import { dispatchWithErrorHandler } from '../ReduxErrorHandlers';
import { actions } from '../Actions';
import { actionGuestSessionSetTableIdByTableNumber } from '../reducers/GuestSession';
import { THostId } from '../../../../core/src/models/db/host/HostTypes';
import { TTableId } from '../../../../core/src/models/db/table/TableTypes';
import { Log } from '../../config/Log';
import { actionGuestSessionSetTableId } from '../procedures/actionGuestSessionSetTableId';

type TInitializeAppParams = {
  hostId: THostId;
  tableId?: TTableId;
  tableNumber?: number;
  skipGuestsCheck?: boolean;
};

export const actionInitializeHostSession = dispatchWithErrorHandler((params: TInitializeAppParams): TThunkAction<Promise<void>> => {
  return async (dispatch: TDispatch, getState: TGetState) => {
    Log.v('actionInitializeHostSession', 'actionInitializeHostSession', `Initialize params`, params);

    const {
      hostId,
      tableId,
      tableNumber,
      skipGuestsCheck,
    } = params;

    const storeHostId = getState().host.hostId;

    // If wanted hostId is different to the one in store, update
    if (storeHostId != hostId) {
      await dispatch(actions.pureActionTypeHostSetHostId({ hostId }));
    }

    if (tableId) {
      await dispatch(actionGuestSessionSetTableId({ tableId }));
      await dispatch(actions.actionTypeGuestSessionSetSetupFlags({ tableRequested: true }));
    } else if (tableNumber) {
      await dispatch(actionGuestSessionSetTableIdByTableNumber(tableNumber));
      await dispatch(actions.actionTypeGuestSessionSetSetupFlags({ tableRequested: true }));
    }

    if (skipGuestsCheck) {
      await dispatch(actions.actionTypeGuestSessionSetSetupFlags({ guestCountRequested: true }));
    }
  };
});

