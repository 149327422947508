import * as React from 'react';
import * as _ from 'lodash';
import { Button } from '../../lib-components/buttons/Button';
import { Colors } from '../../config/Theme';
import { FIELD_ID } from '../../../../core/src/db/DbDefs';
import { Strings } from '../../../../core/src/locale/Strings';
import { SvgArrowDown } from '../../img/SvgArrowDown';
import { SvgCheckmark2 } from '../../img/SvgCheckmark2';
import { VerticalUnderline } from '../../lib-components/Underline';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { OrderItemEditorContext } from './OrderItemEditorContext';
import { useActionSheetShow } from '../ActionSheet';
import { TOrderItemCreate } from '../../../../core/src/models/db/orderItem/OrderItemTypes';
import { TMenuItemId } from '../../../../core/src/models/db/menuItem/MenuItemTypes';
import { useCurrentLocale } from "../../hooks/hooks";

type TOrderItemEditorSectionOptionsAndShareCountProps = {
  onModifyOptions: (orderItem: TOrderItemCreate, menuItemId: TMenuItemId) => void;
};

export const OrderItemEditorSectionOptionsAndShareCount = ({
  onModifyOptions,
}: TOrderItemEditorSectionOptionsAndShareCountProps) => {
  const {
    orderItem,
    menuItem,
    orderItemSet,
  } = React.useContext(OrderItemEditorContext);

  const locale = useCurrentLocale();
  const onShareCountPick = useActionSheetShow<number>({
    title: Strings.gOrderItemModifySectionSharing()(locale).toUpperCase(),
    initialValue: orderItem.shareCount,
    options: [
      {
        label: Strings.gOrderItemModifySectionNoSharing()(locale).toUpperCase(),
        value: 0,
      },
      ..._.range(2, 6)
        .map((val) => ({
          label: Strings.gOrderItemModifySectionCount({ n: `${val}` })(locale).toUpperCase(),
          value: val,
        })),
    ],
    onValueSelect(shareCount: number): void {
      orderItemSet && orderItemSet({ shareCount });
    },
  });

  const onModifyOptionsPress = React.useCallback(() => {
    onModifyOptions(orderItem, menuItem[FIELD_ID]);
  }, [onModifyOptions, orderItem, menuItem[FIELD_ID]]);

  const hasModifiers = _.size(menuItem.ingredients) > 0;
  const modifyDisabled = !hasModifiers;
  const shareCountTitle = orderItem?.shareCount > 0
    ? `${Strings.gItemDetailSharingOptionDdl()(locale)} (${orderItem?.shareCount})`
    : Strings.gItemDetailSharingOptionDdl()(locale);
  return (
    <Root>
      <ModifyButton
        disableFeedback
        onPress={onModifyOptionsPress}
        disabled={modifyDisabled}
        color={modifyDisabled ? Colors.accentLight : Colors.accentDark}
        icon={_.size(orderItem.ingredients) > 0 && (
          <SvgCheckmark2/>
        )}
        title={Strings.gItemPreferencesXtraOptions()(locale).toUpperCase()}
        height={optionButtonHeight}
      />
      <VerticalUnderline height={20}/>
      <ModifyButton
        disableFeedback
        onPress={onShareCountPick}
        icon={<SvgArrowDown/>}
        title={shareCountTitle.toUpperCase()}
        height={optionButtonHeight}
      />
    </Root>
  );
};

const optionButtonHeight = 71;

const Root = styled(View)`
  align-items: center;
  flex-direction: row;
  min-height: ${optionButtonHeight}px;
`;

const ModifyButton = styled(Button)<{ fullWidth?: boolean }>`
  flex: ${({ fullWidth }) => (fullWidth ? 1 : 0.5)};
`;
