import * as React from 'react';
import { Strings } from '../../../../core/src/locale/Strings';
import { Navigation } from '../../lib/navigation/Navigation';
import { useDispatch } from 'react-redux';
import { actionResetSessionAndNavigate } from '../../redux/actions/NavigationActions';
import { RoutesCliGuest } from '../../../../core/src/lib/apis/routes/RoutableCliGuest';
import { useCurrentLocale } from "../../hooks/hooks";

export function useOnExitPress() {
  const locale = useCurrentLocale();
  const dispatch = useDispatch();
  return React.useCallback(() => {
    Navigation.routePush(RoutesCliGuest.ModalConfirmationDialog.name, {
      params: {
        title: Strings.gConfirmExitTitle()(locale),
        description: Strings.gConfirmExitDescription()(locale),
        positiveButtonText: Strings.gConfirmExitPositiveButtonText()(locale),
        negativeButtonText: Strings.gConfirmExitNegativeButtonText()(locale),
        positiveButtonOnClick: () => {
          Navigation.hideRouteIfCurrent(RoutesCliGuest.ModalConfirmationDialog.name);
        },
        negativeButtonOnClick: () => {
          dispatch(actionResetSessionAndNavigate());
          Navigation.hideRouteIfCurrent(RoutesCliGuest.ModalConfirmationDialog.name);
        },
      },
      pathParams: {},
    });
  }, [locale, dispatch]);
}
