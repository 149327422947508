import * as React from 'react';
import { getActiveOrInactiveUnderlay } from '../components/orderUnderlays/ActiveOrInActiveUnderlay';
import { Colors } from '../config/Theme';
import { FIELD_ID, FIELD_NAME } from '../../../core/src/db/DbDefs';
import { HeaderSearchable } from '../lib-components/HeaderSearchable';
import { HostMenuList } from '../components/menu/HostMenuList';
import { Menu } from '../../../core/src/models/db/menu/Menu';
import { NavTitleHandler } from '../lib/navigation/NavTitleHandler';
import { Strings } from '../../../core/src/locale/Strings';
import { TMenuId } from '../../../core/src/models/db/menu/MenuTypes';
import { TMenuItem } from '../../../core/src/models/db/menuItem/MenuItemTypes';
import { localize } from '../../../core/src/lib/formatters/LocaleLib';
import { MenuItem } from '../../../core/src/models/db/menuItem/MenuItem';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { Screen } from '../lib-components/screen/Screen';
import { Navigation } from '../lib/navigation/Navigation';
import { RCComp } from '../lib/routeContract/RCComp';
import { RoutesCliGuest } from '../../../core/src/lib/apis/routes/RoutableCliGuest';

export class ScreenHostMenu extends RCComp<'ScreenHostMenu'> {
  protected readonly routeName = 'ScreenHostMenu' as const;

  private readonly navTitleHandler = new NavTitleHandler((props: this['props']) => {
    return localize(props.menuData, FIELD_NAME, props.language);
  });

  constructor(props, context) {
    super(props, context);
    this.state = { filterByNameString: '' };
    this.navTitleHandler.apply(props);
  }

  componentDidUpdate() {
    this.navTitleHandler.apply(this.props);
  }

  onMenuPress = (menuId: TMenuId) => {
    Navigation.routePush(RoutesCliGuest.ScreenHostMenu.name, {
      pathParams: { menuId },
      params: {},
    });
  };

  private setFilterByNameString = (filterByNameString: string) => {
    this.setState({ filterByNameString });
  };

  private resetFilterByNameString = () => {
    this.setState({ filterByNameString: '' });
  };

  private onBackPressed = () => {
    Navigation.goBack();
  };

  private navigateToScreenHostOrderCheckout = () => {
    Navigation.routePush(RoutesCliGuest.ScreenHostOrderCheckout.name);
  };

  private onMenuItemPress = (menuItem: TMenuItem) => {
    Navigation.routePush(RoutesCliGuest.ScreenHostMenuItemView.name, {
      params: {},
      pathParams: { menuItemId: menuItem[FIELD_ID] },
    });
  };

  render() {
    const {
      locale,
      language,
      guestSession,
      openOrder,
      menuData,
    } = this.props;
    return (
      <Screen
        backgroundColor={Colors.white}
        usesReactNavigationHeader={false}
        hasStatusBar={true}
        statusBarBackgroundColor={Colors.white}
        statusBarBackgroundColorIsLight={true}
        underlayHeight={120}
        underlay={guestSession && openOrder && getActiveOrInactiveUnderlay({
          guestSession,
          onCheckoutPress: this.navigateToScreenHostOrderCheckout,
          onRecapPress: this.navigateToScreenHostOrderCheckout,
        })}
      >
        <HeaderSearchable
          title={localize(menuData, FIELD_NAME, language)}
          backLabel={Navigation.previousRouteTitle()}
          onSearchTextChanged={this.setFilterByNameString}
          onSearchClosed={this.resetFilterByNameString}
          onBackPressed={this.onBackPressed}
          searchPlaceholder={Strings.gSearchBarPhd()(locale)}
        />
        {this.renderItemList()}
      </Screen>
    );
  }

  private renderItemList = () => {
    const {
      language,
      host,
      openOrder,
      menuData,
    } = this.props;
    const { filterByNameString } = this.state;
    const filteredMenu = Menu.filterByString(menuData, filterByNameString, language);
    const filteredItems = MenuItem.filterByString(host, filterByNameString, language);
    return (
      <HostMenuListContainer>
        <HostMenuList
          openOrderData={openOrder}
          host={host}
          menu={filteredMenu}
          items={filteredItems}
          onMenuPress={this.onMenuPress}
          onMenuItemPress={this.onMenuItemPress}
        />
      </HostMenuListContainer>
    );
  };
}

export const HostMenuListContainer = styled(View)`
  flex: 1;
`;
