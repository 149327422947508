import * as React from 'react';
import { Bluebird } from '../../../core/src/lib/Bluebird';
import {
  CurryFunc,
  CurryFuncParams,
} from '../../../core/src/lib/Types';
import { safe } from '../../../core/src/lib/HelperFunctions';
import { useOnUnMount } from './useOnUnMount';

export function useCancelableAsyncCallback<T extends (...args: any[]) => any>(cb: CurryFunc<T>, deps: any[] = []) {
  const cancelRef = React.useRef<() => void>();

  useOnUnMount(() => {
    safe(() => cancelRef.current && cancelRef.current());
  });

  return React.useCallback((...params: CurryFuncParams<T>) => {
    const bluebirdPromise = Bluebird.resolve()
    // @ts-ignore
      .then(() => cb(...params));
    cancelRef.current = bluebirdPromise.cancel;
    return bluebirdPromise;
  }, deps);
}
