/**
 * Idempotent subscriptions by key
 * If a key was already used the previous subscription will be canceled
 * and the new one will be binded
 */
import { TUnSubscriber } from './Types';

type TSubscriptions = {
  [key: string]: TUnSubscriber;
};

type TSubscribeParams = {
  key: string;
  subscriber: (() => TUnSubscriber);
};

export class KeySubscriber {
  private subscriptions: TSubscriptions = {};

  subscribeByKey({
    key,
    subscriber,
  }: TSubscribeParams) {
    const wasAlreadySubscribed = this.unSubscribeByKey(key);
    this.subscriptions[key] = subscriber();
    return wasAlreadySubscribed;
  }

  unSubscribeByKey(key: string) {
    if (this.subscriptions[key]) {
      this.subscriptions[key].unsubscribe();
      return true;
    }
    return false;
  }

  clearSubscriptions() {
    Object.keys(this.subscriptions)
      .forEach(this.unSubscribeByKey);
  }
}

