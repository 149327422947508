// @ts-nocheck
import * as React from 'react';
import Svg,
{ Path } from 'react-native-svg';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg width={22} height={22} viewBox="0 0 22 22" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.032 18.74a2.343 2.343 0 114.686 0 2.343 2.343 0 01-4.686 0zm.67 0a1.674 1.674 0 103.347 0 1.674 1.674 0 00-3.347 0z"
        fill="#fff"
      />
      <Path
        d="M10.718 18.74h.25-.25zm-2.343 2.344v.25-.25zm1.674-2.343h.25-.25zm-1.674-1.674v-.25.25zm0-.92a2.593 2.593 0 00-2.593 2.594h.5c0-1.156.937-2.093 2.093-2.093v-.5zm2.593 2.594a2.593 2.593 0 00-2.593-2.593v.5c1.156 0 2.093.937 2.093 2.093h.5zm-2.592 2.593a2.596 2.596 0 002.592-2.593h-.5a2.096 2.096 0 01-2.093 2.093v.5zM5.782 18.74a2.593 2.593 0 002.593 2.593v-.5a2.093 2.093 0 01-2.093-2.093h-.5zm2.593 1.423a1.424 1.424 0 01-1.423-1.423h-.5c0 1.062.86 1.923 1.923 1.923v-.5zM9.8 18.741c0 .786-.637 1.423-1.424 1.423v.5a1.924 1.924 0 001.924-1.923h-.5zm-1.424-1.424a1.426 1.426 0 011.424 1.424h.5a1.926 1.926 0 00-1.923-1.924l-.001.5zM6.952 18.74c0-.787.637-1.424 1.423-1.424v-.5a1.924 1.924 0 00-1.923 1.924h.5z"
        fill="#fff"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.066 18.74a2.343 2.343 0 114.686 0 2.343 2.343 0 01-4.686 0zm.67 0a1.674 1.674 0 103.347 0 1.674 1.674 0 00-3.347 0z"
        fill="#fff"
      />
      <Path
        d="M18.753 18.74h.25-.25zm-2.343 2.344v.25-.25zm1.673-2.343h.25-.25zm-1.673-1.674v-.25.25zm0-.92a2.593 2.593 0 00-2.594 2.594h.5c0-1.156.938-2.093 2.094-2.093v-.5zm2.593 2.594a2.593 2.593 0 00-2.593-2.593v.5c1.155 0 2.093.937 2.093 2.093h.5zm-2.593 2.593a2.596 2.596 0 002.593-2.593h-.5a2.096 2.096 0 01-2.094 2.093v.5zm-2.594-2.593a2.593 2.593 0 002.594 2.593v-.5a2.093 2.093 0 01-2.094-2.093h-.5zm2.594 1.423a1.424 1.424 0 01-1.424-1.423h-.5c0 1.062.861 1.923 1.924 1.923v-.5zm1.423-1.423c0 .786-.637 1.423-1.423 1.423v.5a1.924 1.924 0 001.923-1.923h-.5zm-1.424-1.424a1.426 1.426 0 011.424 1.424h.5a1.926 1.926 0 00-1.923-1.924v.5zm-1.423 1.424c0-.787.637-1.424 1.424-1.424v-.5a1.924 1.924 0 00-1.924 1.924h.5z"
        fill="#fff"
      />
      <Path
        d="M20.654 5.686H4.394L3.347 1.258A.335.335 0 003.02 1H1.346a.335.335 0 000 .67h1.41L5.706 14.13c.036.151.17.258.326.258h12.72a.335.335 0 100-.67H6.296l-.475-2.008h13.563a.336.336 0 100-.67H5.665l-.476-2.008H20.02a.335.335 0 100-.669H5.028l-.475-2.008h16.1a.335.335 0 000-.67z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.5}
      />
    </Svg>
  );
}

export const SvgCart = React.memo(SvgComponent);
