import { DeepLinkParser } from './DeepLinkParser';
import { resolveAlcDlParsers, TALCDeepLinks, TALCDLParser, TALCDLReceiver } from './ALCDeepLinks';
import { THostId } from '../../models/db/host/HostTypes';
import { TTableId } from '../../models/db/table/TableTypes';
import { RoutableRoute } from '../define/routable/RoutableRoute';
import { RoutesCliGuest } from '../apis/routes/RoutableCliGuest';
import { Log } from '../../config/Instance';

export type TDeepLinks = {
  /**
   * GuestApp: Navigates to a host
   */
  ScreenSetupGuestSession: {
    Paths: [
      THostId
    ];
    Params: {
      tableId?: TTableId;
      tableNumber?: number;
      skipGuestsCheck?: string;
    };
    Receiver: {
      hostId: THostId;
      tableId?: TTableId;
      tableNumber?: number;
      skipGuestsCheck?: boolean;
    };
  };
};

class DeepLinkHandlerBase implements TALCDLParser {
  readonly parser = new DeepLinkParser<TALCDeepLinks>({
    resolvers: resolveAlcDlParsers,
    parsers: this,
  });

  readonly keys = ['ScreenSetupGuestSession'] as (keyof TALCDeepLinks)[];

  constructor() {
    this.parse = this.parse.bind(this);
    this.ScreenSetupGuestSession = this.ScreenSetupGuestSession.bind(this);
  }

  /**
   * Parse dl string and call receiver if defined
   * @param dl
   * @param receivers
   */
  async parse(dl: string, receivers: TALCDLReceiver): Promise<boolean> {
    try {
      return await this.parser.parseOrThrow(dl, receivers);
    } catch (e) {
      Log.e('DeepLinkHandler', 'parse', `threw ${e && e.message}`);
      return false;
    }
  }

  ScreenSetupGuestSession(dl: string) {
    const deconstructed = RoutableRoute.buildURLToolbox(RoutesCliGuest.ScreenSetupGuestSession).deconstructURLIfMatch(dl);
    if (!deconstructed) {
      return undefined;
    }

    return {
      hostId: deconstructed.pathParams.storeHostId,
      tableId: deconstructed.searchParams.storeTableId,
      tableNumber: deconstructed.searchParams.storeTableNumber,
      skipGuestsCheck: deconstructed.searchParams.storeSkipGuestsCheck,
    };
  }
}

export const DeepLinkHandler = new DeepLinkHandlerBase();
