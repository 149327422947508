import {
  Colors,
  Theme,
} from '../../config/Theme';
import {
  defineReducer,
  TDispatch,
} from '../../../../lib-react/src/redux/redux';
import { actions } from '../Actions';
import { SnackBarOptions } from 'react-native-snackbar';
import { ReactX } from '../../lib/ReactX';

export type TStateSnackbars = {
  snackbar: TSnackbar | undefined;
};

export type TSnackbar = Omit<SnackBarOptions, 'action'> & {
  action?: Omit<SnackBarOptions, 'onPress'> & {
    onPress: (dispatch: TDispatch) => void;
  };
};

const initialSnackbarProps = {
  fontFamily: Theme.font.nunitoSans,
  backgroundColor: Colors.black,
  duration: ReactX.Snackbar.LENGTH_SHORT,
  textColor: Colors.white,
};

const positiveSnackbarProps = {
  fontFamily: Theme.font.nunitoSans,
  backgroundColor: Colors.black,
  duration: ReactX.Snackbar.LENGTH_SHORT,
  textColor: Colors.white,
};

const negativeSnackbarProps = {
  fontFamily: Theme.font.nunitoSans,
  backgroundColor: Colors.red,
  duration: ReactX.Snackbar.LENGTH_SHORT,
  textColor: Colors.white,
};

const initialState: TStateSnackbars = { snackbar: undefined };

export const {
  actionTypeShowSnackbar,
  reducer: reducerSnackbars,
} = defineReducer<TStateSnackbars, typeof actions>({
  actions,
  initialState,
  reducer: (state, action, getType) => {
    switch (action.type) {
      case getType(actions.pureActionTypeResetSession):
        return initialState;
      case getType(actions.actionTypeShowSnackbar):
        return {
          ...state,
          snackbar: action.payload.snackbar,
        };
    }
    return state;
  },
});

function actionSnackbarsAddSnackbar(snackbar: TSnackbar) {
  return (dispatch) => {
    dispatch(actionTypeShowSnackbar({ snackbar }));

    setTimeout(() => {
      dispatch(actionTypeShowSnackbar({ snackbar: undefined }));
    }, 0);
  };
}

export function actionSnackbarsAddPositiveSnackbar(snackbar: TSnackbar) {
  return (dispatch) => {
    dispatch(actionSnackbarsAddSnackbar({
      ...initialSnackbarProps,
      ...positiveSnackbarProps,
      ...snackbar,
    }));
  };
}

export function actionSnackbarsAddNegativeSnackbar(snackbar: TSnackbar) {
  return (dispatch) => {
    dispatch(actionSnackbarsAddSnackbar({
      ...initialSnackbarProps,
      ...negativeSnackbarProps,
      ...snackbar,
    }));
  };
}
