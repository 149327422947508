import * as React from 'react';
import { ConnectedDeepLinkHandler } from '../../lib/redux/ConnectedDeepLinkHandler';
import { useDispatch } from '../../../../lib-react/src/redux/redux';
import { useNavigation } from '@react-navigation/native';
import { Navigation } from '../../lib/navigation/Navigation';

type TWithNavigationMiddleware<P extends any, ResProps extends P>
  = React.ComponentType<ResProps> & TComponentProps<P, ResProps>;

type TComponentProps<P extends any, ResProps extends P> = {
  //
};

export function withNavigationMiddleware<P extends any, ResProps extends P>(Component: TWithNavigationMiddleware<P, ResProps>) {
  return (props: P) => {
    const navigation = useNavigation();
    const dispatch = useDispatch();
    Navigation.setNavigation(navigation);
    Navigation.setDispatch(dispatch);
    ConnectedDeepLinkHandler.setNavigation(navigation);
    ConnectedDeepLinkHandler.setDispatch(dispatch);

    return (
      <Component {...props as ResProps as any}/>
    );
  };
}
