/* eslint-disable no-tabs */
import { Log } from '../../config/Log';
import {
  call,
  put,
  select,
} from 'redux-saga/effects';
import { selectGuestSessionPbp } from '../selectors';
import { TState } from '../Store';
import {
  TGuestSession,
  TGuestSessionId,
} from '../../../../core/src/models/db/guestSession/GuestSessionTypes';
import { GuestSessionBuilder } from '../../../../core/src/models/db/guestSession/GuestSessionBuilder';
import { FIELD_ID } from '../../../../core/src/db/DbDefs';
import { TGuestSessionPathBuilderParams } from '../../../../core/src/models/db/guestSession/GuestSessionBuilderBase';
import { actionResetSessionAndNavigate } from '../actions/NavigationActions';

enum LocalGuestSessionValidationResult {
  openGuestSession = 'openGuestSession',
  resetApp = 'resetApp',
}

/**
 * HOST HAS ACTIVE GS        USER HAS LOCAL      GS  LOCAL EQ REMOTE
 * TRUE                      FALSE                  FALSE                 REOPEN          Some other user already opened the table, this user is entering because they don't have anything locally
 * FALSE                     FALSE                  TRUE                  REOPEN          This is the base case where the first user scanned the QR code, the guest session doesn't exist, it's created
 * TRUE                      TRUE                   TRUE                  REOPEN          User closed the app and opened it again, nothing happened in between
 * TRUE                      TRUE                   FALSE                 RESET           Since local is not equal to remote, the host closed the session this user has locally
 * FALSE                     TRUE                   FALSE                 RESET           If the user has it locally at some point it was open on the host but it was then closed, reset
 */
export function* checkLocalGuestSessionStillValid(params: TGuestSessionPathBuilderParams) {
  const localGuestSessionId: TGuestSessionId | undefined = yield select((store: TState) => {
    return store.guestSession.guestSessionData?.[FIELD_ID];
  });

  // If no local guestSession, the user just scanned the QR, continue
  if (!localGuestSessionId) {
    Log.v('sagaVerifyGuestSessionStillValid', 'checkLocalGuestSessionStillValid', `no local guest-session, opening`);
    return LocalGuestSessionValidationResult.openGuestSession;
  }

  // Ensure GuestSession id has not changed
  const remoteGuestSession: TGuestSession | undefined = yield GuestSessionBuilder.remoteDataFromPath(params);
  const remoteGuestSessionId = remoteGuestSession?.[FIELD_ID];

  if (!remoteGuestSessionId) {
    Log.v('sagaVerifyGuestSessionStillValid', 'checkLocalGuestSessionStillValid', `no remote guest-session, resetting`);
    return LocalGuestSessionValidationResult.resetApp;
  }

  if (localGuestSessionId == remoteGuestSessionId) {
    Log.v('sagaVerifyGuestSessionStillValid', 'checkLocalGuestSessionStillValid', `localGuestSession==remoteGuestSession, opening`);
    return LocalGuestSessionValidationResult.openGuestSession;
  }

  Log.v('sagaVerifyGuestSessionStillValid', 'checkLocalGuestSessionStillValid', `localGuestSession!=remoteGuestSession, resetting`);
  return LocalGuestSessionValidationResult.resetApp;
}

export function* sagaVerifyGuestSessionStillValid() {
  Log.v('sagaVerifyGuestSessionStillValid', 'sagaVerifyGuestSessionStillValid', `starting`);

  const params = yield select(selectGuestSessionPbp);
  if (!params) {
    Log.v(
      'sagaVerifyGuestSessionStillValid',
      'sagaVerifyGuestSessionStillValid',
      `selectGuestSessionPbp undefined`,
    );
    return;
  }

  const { hostId, tableId } = params;
  Log.v(
    'sagaVerifyGuestSessionStillValid',
    'sagaVerifyGuestSessionStillValid',
    `selectGuestSessionPbp hostId=${hostId}, tableId=${tableId}`,
  );

  const localCacheValidation: LocalGuestSessionValidationResult = yield call(checkLocalGuestSessionStillValid, params);
  Log.v(
    'sagaVerifyGuestSessionStillValid',
    'sagaVerifyGuestSessionStillValid',
    `localCacheValidation=${localCacheValidation}`,
  );

  if (localCacheValidation == LocalGuestSessionValidationResult.resetApp) {
    yield put(actionResetSessionAndNavigate() as any);
  }
}
