import { all, fork, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { Log } from '../../../config/Log';
import { Navigation, TInNavParams, TNavigationParams } from '../../../lib/navigation/Navigation';
import { CommonActions, StackActions } from '@react-navigation/routers';
import { isWeb } from '../../../lib/Platform';
import { ReactX } from '../../../lib/ReactX';
import { defaultNavParams } from './sagaDefaultNavigationParams';
import { actions } from '../../Actions';
import { sagaNavigateWeb } from './sagaNavigateWeb';

export function* sagaNavigate() {
  Log.v('sagaNavigate', 'sagaNavigate', 'Starting');
  yield all([
    ...(isWeb ? [fork(sagaNavigateWeb)] : []),
    fork(sagaNavigateReplace),
    fork(sagaNavigatePush),
    fork(sagaNavigateReset),
    fork(sagaNavigateNavigate),
    fork(sagaNavigateBack),
  ]);
}

type TNavAction = {
  type: string;
  payload: TNavigationParams<any>;
};

function* getNavigationParams(params: TInNavParams<any>) {
  const defaultParams = yield defaultNavParams();
  return {
    // Add provided search params
    ...params.params,

    // Add provided path params, higher prio than search params
    ...params.pathParams,

    // Add omitted params in case of internal navigation
    ...defaultParams,
  };
}

function* sagaNavigateReplace() {
  yield takeLatest(getType(actions.actionTypeNavigationReplace), function* (params: TNavAction) {
    Log.v('sagaNavigate', 'sagaNavigateReplace', `Got ${params.type}`);
    const nav: any = yield getNavigationParams(params.payload.params);
    Navigation.nav.dispatch({
      ...StackActions.replace(params.payload.routeName, nav),
      source: undefined,
    });
  });
}

function* sagaNavigatePush() {
  yield takeLatest(getType(actions.actionTypeNavigationPush), function* (params: TNavAction) {
    Log.v('sagaNavigate', 'sagaNavigatePush', `Got ${params.type}`);
    const nav: any = yield getNavigationParams(params.payload.params);
    Navigation.nav.dispatch(StackActions.push(params.payload.routeName, nav));
  });
}

function* sagaNavigateReset() {
  yield takeLatest(getType(actions.actionTypeNavigationReset), function* (params: TNavAction) {
    Log.v('sagaNavigate', 'sagaNavigateReset', `Got ${params.type}`);
    const nav: any = yield getNavigationParams(params.payload.params);
    Navigation.nav.dispatch(CommonActions.reset({
      index: 0,
      routes: [
        {
          name: params.payload.routeName,
          params: nav,
        },
      ],
    }));
  });
}

function* sagaNavigateNavigate() {
  yield takeLatest(getType(actions.actionTypeNavigationNavigate), function* (params: TNavAction) {
    Log.v('sagaNavigate', 'sagaNavigateNavigate', `Got ${params.type}`);
    const nav: any = yield getNavigationParams(params.payload.params);
    Navigation.nav.dispatch(CommonActions.navigate({
      name: params.payload.routeName,
      params: nav,
    }));
  });
}

function* sagaNavigateBack() {
  yield takeLatest(getType(actions.actionTypeNavigationBack), function* (params) {
    Log.v('sagaNavigate', 'sagaNavigateBack', `Got ${params.type}`);

    // If web and can't go back with router, go back with browser (sagaNavigateWeb)
    if (isWeb && !Navigation.nav.canGoBack()) {
      ReactX.window.history.back();
      return;
    }

    Navigation.nav.goBack();
  });
}
