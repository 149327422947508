/**
 * Do not import from other files as this class should be a standalone
 */
import { Base64 } from 'js-base64';

export class SecretFunctions {
  // Unsafe version of mapObjVals, needed for CI
  static mapObjVals(obj: any, map: (val: any) => any) {
    return Object.keys(obj)
      .reduce((acc, key) => {
        acc[key] = map(obj[key]);
        return acc;
      }, {} as any);
  }

  static secretEnc(secDecrypted: string | undefined): string | undefined {
    if (secDecrypted == null) {
      return undefined;
    }
    // For now use only btoa/atob
    return Base64.btoa(secDecrypted);
  }

  static secretDec(secEncrypted: string | undefined): string | undefined {
    if (secEncrypted == null) {
      return undefined;
    }
    // For now use only btoa/atob
    return Base64.atob(secEncrypted);
  }
}
