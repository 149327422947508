import * as React from 'react';
import { Colors } from '../../config/Theme';
import { RadioButtonIOS } from '../../lib-components/react-native-paper/RadioButton/RadioButtonIOS';
import { Strings } from '../../../../core/src/locale/Strings';
import { Text, View } from 'react-native';
import { AlaTouchableWithoutFeedback } from '../../lib-components/Touchable';
import styled from 'styled-components/native';
import { OrderItemEditorContext } from './OrderItemEditorContext';
import { AlaText } from '../Text';
import { useCurrentLocale } from "../../hooks/hooks";

export type TOrderItemEditorSectionPrecedenceProps = {
  //
};

export const OrderItemEditorSectionPrecedence = (props: TOrderItemEditorSectionPrecedenceProps) => {
  const locale = useCurrentLocale();
  const {
    orderItem,
    orderItemSet,
  } = React.useContext(OrderItemEditorContext);

  const onPrecedenceChange = React.useCallback((precedenceNumber: number) => {
    return orderItemSet && orderItemSet({ precedenceNumber });
  }, [orderItem, orderItemSet]);

  const precedenceRadios = [0, 1, 2].map((precedence) => ({
    value: precedence,
    text: Strings.gOrderSummaryNndCourse({ n: `${precedence + 1}` })(locale),
    callback: () => onPrecedenceChange(precedence),
  }));

  return (
    <Root>
      <SectionTitle>
        {Strings.gOrderSummaryHeader()(locale)}
      </SectionTitle>
      <Row>
        {precedenceRadios.map(({
          value,
          callback,
          text,
        }, index) => (
          <AlaTouchableWithoutFeedback
            key={index}
            onPress={callback}>
            <RadioItemViewContainer>
              <RadioButtonIOS
                value={`${index}`}
                status={orderItem.precedenceNumber === value ? 'checked' : 'unchecked'}
                onPress={callback}
                checkedColor={Colors.red}
                uncheckedColor={Colors.accentLight}
              />
              <TextPrimaryIconRadio>
                {value <= 0 ? Strings.gItemDetailServiceNowRdo()(locale) : text}
              </TextPrimaryIconRadio>
            </RadioItemViewContainer>
          </AlaTouchableWithoutFeedback>
        ))}
      </Row>
    </Root>
  );
};

const Root = styled(View)`
  min-height: 97px;
  padding: 15px 0;
`;

const SectionTitle = styled(Text)`
  color: ${({ theme }) => theme.t.col.accentDark};
  font-family: ${({ theme }) => theme.t.font.nunitoSans};
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
`;

const Row = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
  padding: 0 12px;
`;

const RadioItemViewContainer = styled(View)`
  align-items: center;
  width: ${({ theme }) => (theme.dim.width / 3) * 0.8}px;
`;

const TextPrimaryIconRadio = styled(AlaText)`
  color: ${({ theme }) => theme.t.col.accentDark};
  font-size: 10px;
  letter-spacing: 1px;
  margin-top: 4px;
  text-transform: uppercase;
`;
