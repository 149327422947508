import * as _ from 'lodash';
import { TOrder } from '../../../../core/src/models/db/order/OrderTypes';
import { TOrderItem } from '../../../../core/src/models/db/orderItem/OrderItemTypes';
import { dbItemSortableItemSort } from '../../../../core/src/db/DbLib';

type TMapOrderItemsToPrecedenceSectionsParams = {
  orderItemsData: TOrderItem[];
  sectionCount: number;
};

export type TMapOrderItemsToPrecedenceSectionsParamsResObj = {
  precedenceNumber: number;
  data: TOrderItem[];
};

function sortBySortableIndex(orderItemsData: TOrderItem[]) {
  return orderItemsData.sort(dbItemSortableItemSort);
}

export function groupByPrecedence(orderItem: TOrderItem[]) {
  return _.groupBy<TOrderItem>(orderItem, (oi) => oi.precedenceNumber);
}

export function mergeAndSortOrderItems(orderDatas: TOrder[]): TOrderItem[] {
  return _.flatMap(orderDatas, (orderData: TOrder) => {
    const itemsArr = _.values(orderData.items);
    const grouped = _.values(groupByPrecedence(itemsArr));
    return _.flatMap(grouped, (items: TOrderItem[]) => items.sort(dbItemSortableItemSort));
  });
}

export function mapOrderItemsToPrecedenceSections({
  orderItemsData,
  sectionCount,
}: TMapOrderItemsToPrecedenceSectionsParams) {
  const groupedByPrecedence = groupByPrecedence(orderItemsData);
  const sections = _.range(0, sectionCount).reduce((acc, sectionTitle, index) => {
    // Push section
    acc.push(<TMapOrderItemsToPrecedenceSectionsParamsResObj>{
      precedenceNumber: index,
      data: [],
    });

    // Push items (flat)
    const orderItemsInSection = groupedByPrecedence[index] || [];
    const sortedOrderItemsInSection = sortBySortableIndex(orderItemsInSection);
    delete groupedByPrecedence[index];
    sortedOrderItemsInSection.forEach((orderItem: TOrderItem) => {
      acc[acc.length - 1].data.push(orderItem);
    });

    return acc;
  }, [] as TMapOrderItemsToPrecedenceSectionsParamsResObj[]);

  // Add all items that were out of bounds for precedenceSectionTitles
  // They were not deleted from groupedByPrecedence
  const missedItems = _.values(groupedByPrecedence);
  const flatMissedItems = _.flatten(missedItems);
  const sortedMissedItems = sortBySortableIndex(flatMissedItems);
  sortedMissedItems.forEach((orderItem: TOrderItem) => {
    sections[sections.length - 1].data.push(orderItem);
  });

  return sections;
}
