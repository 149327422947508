import * as _ from 'lodash';
import { FIELD_NAME } from '../../db/DbDefs';
import { MStObj } from '../../lib/model/StObj';
import { TBuilderParamsBase } from './lib/buildRawUpdate';
import {
  THost,
  THostId,
  THostUpdate,
} from '../db/host/HostTypes';
import { TRKHost } from '../../db/DbResources';
import {
  addIfDefined,
  addIfTrue,
} from '../../lib/HelperFunctions';
import { stripUnchangedValues } from './lib/localizedObjectUpdater';
import { HostBuilder } from '../db/host/HostBuilder';

function getNewImgPbp(hostId: THostId, data: ArrayBuffer) {
  return {
    hostId,
    itemId: MStObj.arrayBufferToId(data),
  };
}

export type THostCustomizationDataChange = Omit<Partial<THostUpdate>, 'logoImg' | 'coverImg'> & {
  logoImg?: string;
  coverImg?: string;
};

type THostCustomizationUpdate = {
  hostCustomizationDataChange: Partial<THostCustomizationDataChange>;
};

export async function hostCustomizationUpdate({
  item,
  hostCustomizationDataChange: hcdc,
}: TBuilderParamsBase<TRKHost> & THostCustomizationUpdate) {
  const { hostId } = HostBuilder.getPathBuilderParamsFromDataPath(item);

  const updatedItem: Partial<THost> = {
    ...addIfDefined(FIELD_NAME, hcdc[FIELD_NAME], hcdc.name),
    ...addIfDefined('description', hcdc.description, hcdc.description),
    ...addIfDefined('currency', hcdc.currency, hcdc.currency),
    ...addIfDefined('primaryColor', hcdc.primaryColor, hcdc.primaryColor),
    ...addIfDefined('secondaryColor', hcdc.secondaryColor, hcdc.secondaryColor),
    ...addIfTrue('denomination', !_.isEmpty(hcdc.denomination), hcdc.denomination),
    ...addIfTrue('address', !_.isEmpty(hcdc.address), hcdc.address),
    ...addIfTrue('phone', !_.isEmpty(hcdc.phone), hcdc.phone),
    ...addIfTrue('vatId', !_.isEmpty(hcdc.vatId), hcdc.vatId),
    ...addIfTrue('placeId', !_.isEmpty(hcdc.placeId), hcdc.placeId),
    ...addIfTrue('coordinates', !_.isEmpty(hcdc.coordinates), hcdc.coordinates),
    ...addIfTrue('logoImg', !_.isEmpty(hcdc.logoImg), hcdc.logoImg),
    ...addIfTrue('coverImg', !_.isEmpty(hcdc.coverImg), hcdc.coverImg),

    // ...await addAndMap('logoImg', hcdc.logoImg, async (logoImg) => {
    //   return StRefs.publicHostItem(getNewImgPbp(hostId, logoImg))
    //     .uploadAndGetDownloadUrl(logoImg);
    // }),
    //
    // ...await addAndMap('coverImg', hcdc.coverImg, async (coverImg) => {
    //   return StRefs.publicHostItem(getNewImgPbp(hostId, coverImg))
    //     .uploadAndGetDownloadUrl(coverImg);
    // }),
  };

  return stripUnchangedValues({
    initialItem: item,
    updatedItem,
  });
}
