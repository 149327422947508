import { TKnownError } from './ErrorTypes';

export class LocalError extends Error {
  readonly knownError: TKnownError;

  constructor(file: string, method: string, knownError: TKnownError) {
    super(`${file}, ${method}, knownError=${knownError.internalCode}`);
    this.knownError = knownError;
  }
}
