import {
  DbRes,
  TRKStation,
} from '../../../db/DbResources';
import {
  TEditable,
  TNameable,
} from '../../../db/DbDefs';
import {
  TMObj,
  TMObjDataCreator,
  TMObjMaybeDataCreator,
} from '../../../lib/model/ModelTypes';
import { TStationAdapter } from '../../../printer/PrintAdapters';

export enum EStationType {
  MAIN = 'MAIN',
  SECONDARY = 'SECONDARY',
}

export type TStationUpdate = TMObjMaybeDataCreator<TRKStation>;
export type TStationCreate = TMObjDataCreator<TRKStation>;

export type TStationId = string;
export type TStation = TMObj<typeof DbRes.Station> & TEditable & TNameable & {
  type: EStationType;
  adapter: TStationAdapter;
};
