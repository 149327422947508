import * as React from 'react';
import * as _ from 'lodash';
import { Text, View } from 'react-native';
import { Actionsheet, Box, } from 'native-base';
import { useSyncedDataRef } from '../../../lib-react/src/hooks/useSyncedDataRef';
import { useToggle } from '../../../lib-react/src/hooks/useToggle';
import styled from 'styled-components/native';
import { Underline } from '../lib-components/Underline';
import { StyledComponentsTheme } from '../lib-components/theme/StyledComponentsTheme';

export function useActionSheetShow<T>(params: TActionSheetParams<T>) {
  const ref = useSyncedDataRef(params);
  const { show } = React.useContext(ActionSheetContext);
  return React.useCallback(() => {
    show(ref.current);
  }, [show]);
}

type TActionSheetParams<T> = {
  title: string;
  initialValue: T;
  options: Array<{
    label: string;
    value: T;
  }>;
  onValueSelect: (value: T) => any;
};

const defActionSheetValue = { show: _.noop as (params: TActionSheetParams<any>) => void };

export const ActionSheetContext = React.createContext(defActionSheetValue);

type TActionSheetProps = {
  children: React.ReactNode;
};

export const ActionSheetProvider = ({ children }: TActionSheetProps) => {
  const [open, , onOpen, onClose] = useToggle();
  const [params, setParams] = React.useState<TActionSheetParams<any>>({
    title: '',
    initialValue: 0,
    options: [],
    onValueSelect: _.noop,
  });

  const show = React.useCallback((showParams: TActionSheetParams<any>) => {
    setParams(_.cloneDeep(showParams));
    onOpen();
  }, [onOpen, setParams]);

  const onValueSelect = React.useCallback((value: any) => {
    params.onValueSelect(value);
    onClose();
  }, [params.onValueSelect, onClose]);
  const { theme } = React.useContext(StyledComponentsTheme.Context);
  return (
    <ActionSheetContext.Provider
      value={{ show }}>
      {children}
      <Actionsheet isOpen={open} onClose={onClose}>
        <Actionsheet.Content>
          <Box w="100%" h={60} px={4} justifyContent="center">
            <ActionSheetTitle theme={theme}>
              {params.title}
            </ActionSheetTitle>
            <StyledUnderline/>
          </Box>
          {params.options.map(({ label, value }, index) => (
            <ActionSheetItem
              key={index}
              label={label}
              value={value}
              onValueSelect={onValueSelect}
            />
          ))}
          <View style={{ width: '100%', height: 48 }}/>
        </Actionsheet.Content>
      </Actionsheet>
    </ActionSheetContext.Provider>
  );
};

type TActionSheetItemProps = {
  label: string;
  value: any;
  onValueSelect: (value: any) => any;
};

const ActionSheetItem = ({
                           label,
                           value,
                           onValueSelect,
                         }: TActionSheetItemProps) => {
  const onPress = React.useCallback(() => onValueSelect(value), [value, onValueSelect]);
  return (
    <Actionsheet.Item onPress={onPress}>
      {label}
    </Actionsheet.Item>
  );
};

// Note, ActionSheetContent doesn't access StyledComponents theme
// pass as prop
const ActionSheetTitle = styled(Text)<{ theme: any }>`
  color: ${({ theme }) => theme.t.col.accentDark};
  font-size: 16px;
  font-weight: bold;
`;

const StyledUnderline = styled(Underline)`
  margin-top: 16px;
`;
