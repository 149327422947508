import * as React from 'react';
import { GenericInfoView } from '../GenericInfoView';
import { Strings } from '../../../../core/src/locale/Strings';
import { SvgNoResult } from '../../img/SvgNoResult';
import { View } from 'react-native';
import { useDispatch } from '../../../../lib-react/src/redux/redux';
import styled from 'styled-components/native';
import { actionResetSessionAndNavigate } from '../../redux/actions/NavigationActions';
import { useCurrentLocale } from "../../hooks/hooks";

export type TGenericErrorViewWithResetProps = {
  //
};

export const GenericErrorViewWithReset = (props: TGenericErrorViewWithResetProps) => {
  const locale = useCurrentLocale();

  const dispatch = useDispatch();
  const onActionPress = React.useCallback(() => {
    dispatch(actionResetSessionAndNavigate());
  }, [dispatch]);
  return (
    <Root>
      <Content>
        <GenericInfoView
          icon={<SvgNoResult/>}
          onActionPress={onActionPress}
          text={Strings.gOopsGenericError()(locale)}
          actionText={Strings.gReset()(locale)}
        />
      </Content>
    </Root>
  );
};

const Root = styled(View)`
  align-items: center;
  flex: 1;
  justify-content: center;
`;

const Content = styled(View)`
  align-items: center;
  height: 75%;
  justify-content: center;
  width: 100%;
`;
