import { HostBuilder } from '../../../../core/src/models/db/host/HostBuilder';
import { Log } from '../../config/Log';
import { Refs } from '../../../../core/src/db/DbRefs';
import { actions } from '../Actions';
import { put } from 'redux-saga/effects';
import { sagaBindRefByKey } from '../../../../lib-react/src/sagas/sagaBindRefByKey';
import {
  sagaMonitorSelector,
  selectUndefinedAsClearTask,
} from '../../../../lib-react/src/sagas/sagaMonitorSelector';
import { selectHostPbp } from '../selectors';
import { actionResetSessionAndNavigate } from '../actions/NavigationActions';

export function* sagaSubscribeToHost() {
  Log.v('sagaSubscribeToHost', 'sagaSubscribeToHost', `Starting`);
  yield sagaMonitorSelector(['*'], selectUndefinedAsClearTask(selectHostPbp), function* ({ hostId }) {
    const initialHostData = yield HostBuilder.remoteDataFromPath({ hostId });
    if (!initialHostData) {
      Log.e('sagaSubscribeToHost', 'sagaSubscribeToHost', `host doesn't exist ${hostId}`);
      return;
    }

    yield put(actions.actionTypeHostSetHost({ hostData: initialHostData }));

    Log.v('sagaSubscribeToHost', 'sagaSubscribeToHost', `subscribing to ${hostId}`);
    const ref = Refs.host({ hostId });

    yield sagaBindRefByKey('sagaSubscribeToHost', ref, function* (host) {
      if (host == null) {
        yield put(actionResetSessionAndNavigate() as any);
        return;
      }
      yield put(actions.actionTypeHostSetHost({ hostData: host }));
    });
  });
}
