import * as _ from 'lodash';
import { DbResLocalizeableFields, TDbResData, TDbResLocalizeableFields, TDbResName } from '../../db/DbResources';
import { FIELD_DEFAULT_LOCALE, TLocalizableObj } from '../../db/DbDefs';
import {
  ELang,
  ELocale,
  ELOCALE_GLOBAL_DEFAULT,
  langToELocale,
  localeToLang,
  SupportedLocales,
} from '../../locale/Locale';
import {ELANGUAGE_GLOBAL_DEFAULT, LanguageCodes, TLanguage} from '../../locale/Languages';

export function localize<RK extends TDbResName, Key extends typeof DbResLocalizeableFields[RK][any]>(
  obj: Partial<TDbResData[RK]> & TLocalizableObj<TDbResLocalizeableFields[RK]>,
  key: Key,
  locale?: ELocale | TLanguage,
): string {
  const loc = locale || obj[FIELD_DEFAULT_LOCALE];
  const localizedField = _.get(obj, `${loc}.${key}`);

  if (localizedField) {
    return localizedField;
  }

  return _.get(obj, key, '');
}

export function formatSingularPlural(
  locale: ELocale,
  count: number,
  singular: (locale: ELocale) => string,
  plural: (locale: ELocale) => string,
) {
  return count === 1
    ? singular(locale)
    : plural(locale);
}

export function isSupportedLocaleOrDefault(locale: any): ELocale {
  return SupportedLocales.includes(locale)
    ? locale
    : ELOCALE_GLOBAL_DEFAULT;
}

export function isSupportedLanguageOrDefault(locale: any): TLanguage {
  return LanguageCodes.includes(locale)
    ? locale
    : ELANGUAGE_GLOBAL_DEFAULT;
}

export function isSupportedLangOrDefault(lang: any): ELang {
  return localeToLang(langToELocale(lang));
}
