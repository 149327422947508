import * as React from 'react';
import { Badge } from './Badge';
import { Colors, Theme } from '../config/Theme';
import { Strings } from '../../../core/src/locale/Strings';
import { Touchable } from './Touchable';
import { Dimensions, View } from 'react-native';
import styled from 'styled-components/native';
import { AlaText, AlaTextBold, } from '../components/Text';
import { ReactNativeHTMLRenderer } from "./ReactNativeHTMLRenderer";
import { useCurrentLocale } from "../hooks/hooks";

type TGenericItem1<T> = {
  item: T;
  headerTextLeft: string;
  headerTopLeftNode?: React.ReactNode;
  description: string;
  headerTextRight?: string;
  badgeValue?: string;
};

type TGenericListItem1Props<T> = {
  item: TGenericItem1<T>;
  onItemPress?: (item: T) => void;
  onReadMorePress?: (item: T) => void;
  descriptionIsHTML?: boolean
};

export function GenericListItem1<T>({
                                      item,
                                      onItemPress,
                                      onReadMorePress,
                                      descriptionIsHTML = false
                                    }: TGenericListItem1Props<T>) {
  const locale = useCurrentLocale();
  const onPress = React.useCallback(() => {
    onReadMorePress?.(item.item);
  }, [onReadMorePress, item.item]);
  return (
    <Touchable
      item={item.item}
      onPress={onItemPress}>
      <Content>
        <RowHeader>
          <View style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
            <RowHeaderTextLeft numberOfLines={1}>
              {item.headerTextLeft}
            </RowHeaderTextLeft>
            {item.headerTopLeftNode && (
              <View style={{
                marginTop: -4,
                marginLeft: 5
              }}>
                {item.headerTopLeftNode}
              </View>
            )}
          </View>
          {item.headerTextRight && (
            <RowHeaderTextRight
              numberOfLines={1}>
              {item.headerTextRight}
            </RowHeaderTextRight>
          )}
        </RowHeader>
        <RowInfo>
          {!descriptionIsHTML ? (
            <RowInfoTextLeft
              numberOfLines={2}>
              {item.description}
            </RowInfoTextLeft>
          ) : (
            <ReactNativeHTMLRenderer disableLinks html={item.description} tagsStyles={descriptionTagStyles}/>
          )}
          <RowInfoBadgeRightCont>
            {item.badgeValue && (
              <Badge
                value={item.badgeValue}
                valueColor={Colors.white}
                backgroundColor={Colors.red}/>
            )}
          </RowInfoBadgeRightCont>
        </RowInfo>
        {onReadMorePress && (
          <RowFooter>
            <Touchable onPress={onPress}>
              <RowFooterTextReadMore>
                {Strings.gOrderDetails()(locale)}
              </RowFooterTextReadMore>
            </Touchable>
          </RowFooter>
        )}
      </Content>
    </Touchable>
  );
}

const descriptionTagStyles = {
  body: {
    color: Theme.col.accentDark,
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'left',
    maxWidth: Dimensions.get('screen').width * 0.85,
    flex: 1, flexWrap: 'wrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxHeight: 75,
  },
  a: {
    color: Theme.col.accentDark,
    textDecoration: 'none',
    maxWidth: Dimensions.get('screen').width * 0.85,
    flex: 1, flexWrap: 'wrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxHeight: 75,
  },
  p: {
    margin: 0,
    maxWidth: Dimensions.get('screen').width * 0.85,
    flex: 1, flexWrap: 'wrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxHeight: 75,
  }
};

const Content = styled(View)`
  background-color: ${({ theme }) => theme.t.col.white};
  display: flex;
  max-height: 130px;
  min-height: 130px;
  padding: 15px 20px 10px 20px;
`;

const Row = styled(View)`
  flex-direction: row;
`;

const RowHeader = styled(Row)`
  justify-content: space-between;
`;

const MenuHeaderText = styled(AlaText)`
  font-size: 15px;
  line-height: 20px;
  text-transform: uppercase;
`;

const RowHeaderTextLeft = styled(MenuHeaderText)`
  font-weight: 900;
`;

const RowHeaderTextRight = styled(MenuHeaderText)`
  margin-left: 12px;
`;

const RowInfo = styled(Row)`
  margin-top: 5px;
`;

const RowInfoTextLeft = styled(AlaText)`
  flex: 1;
  font-size: 14px;
  line-height: 20px;
  margin-right: 28px;
`;

const RowInfoBadgeRightCont = styled(View)`

`;

const RowFooter = styled(Row)`
  align-items: flex-end;
  flex-grow: 1;
  justify-content: flex-end;
`;

const RowFooterTextReadMore = styled(AlaTextBold)`
  color: ${({ theme }) => theme.t.col.accentDark};
  font-size: 12px;
  opacity: 0.5;
  text-align: right;
  text-transform: uppercase;
`;
