import { DbPaths } from '../../../db/DbRefs';
import {
  DbRes,
  TRKRoom,
} from '../../../db/DbResources';
import { MObjDef } from '../../../lib/model/MObjDef';
import { Room } from './Room';
import { TRoom } from './RoomTypes';
import { defRoom } from '../ModelDefaults';

const pathBuilderKeys = [
  'hostId',
  'roomId',
] as const;

export type TRoomPathBuilderParams = {
  [key in ((typeof pathBuilderKeys)[number])]: string
};

class RoomBuilderBase extends MObjDef<TRKRoom> {
  readonly resourceKey = DbRes.Room;

  protected readonly pathBuilderKeys = [...pathBuilderKeys];

  protected readonly _default = defRoom;

  protected newChild(item: TRoom) {
    return new Room(item);
  }

  pathBuilder(params: TRoomPathBuilderParams): string {
    return DbPaths.room(params);
  }
}

export const RoomBuilder = new RoomBuilderBase();
