import firebase from 'firebase';

try {
  const { Config } = require('./config/Config');
  firebase.apps.length <= 0 && firebase.initializeApp(Config.firebase);
  require('../../cli-guest-rn/src/lib/Firebase');
} catch (e) {
  // Already initialized
}

