import * as React from 'react';
import * as _ from 'lodash';
import { Colors, Theme } from '../../config/Theme';
import { Button as RnElementsButton } from 'react-native-elements';
import { StyledComponentsTheme } from '../theme/StyledComponentsTheme';
import { View, ViewProps } from 'react-native';
import { colorsOverlayHex } from '../../../../core/src/lib/Color';
import { AlaTouchableWithoutFeedback, getDefaultTouchable } from '../Touchable';
import styled from 'styled-components/native';

export type TButtonProps = ViewProps & {
  title?: string;
  titleProps?: any;
  height?: number;
  width?: number;
  color?: string;
  backgroundColor?: string;
  iconRight?: boolean;
  icon?: React.ReactNode;
  block?: boolean;
  onPress?: () => void;
  loading?: boolean;
  disabled?: boolean;
  loadingColor?: string;
  disableFeedback?: boolean;
  bold?: boolean;
  style?: any;
};

export const Button = (props: TButtonProps) => {
  const {
    title,
    titleProps = {},
    height = defaultButtonHeight,
    width,
    color = defaultTextColor,
    backgroundColor = defaultBackgroundColor,
    icon,
    iconRight = true,
    block = true,
    onPress = _.noop,
    loading = false,
    disabled = false,
    loadingColor = Colors.white,
    disableFeedback = false,
    bold = true,
    style = {},
  } = props;
  const { theme } = React.useContext(StyledComponentsTheme.Context);
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const RootComponent = block ? BlockRoot : Root;
  const finalHeight = height;// theme.scaler.verticalScale(height);
  const finalWidth = block ? '100%' : width && theme.scaler.scale(width);
  const disabledBackgroundColor = backgroundColor != 'transparent'
    ? colorsOverlayHex(backgroundColor, Colors.white, 50)
    : undefined;
  return (
    <RootComponent style={style}>
      <StyledRnElementsButton
        backgroundColor={backgroundColor}
        width={finalWidth}
        height={finalHeight}
        color={color}
        TouchableComponent={disableFeedback ? AlaTouchableWithoutFeedback : getDefaultTouchable()}
        onPress={onPress}
        loadingProps={{ color: loadingColor }}
        loading={loading}
        disabled={disabled || loading}
        iconRight={iconRight}
        icon={icon ? (
          <IconContainer>
            {icon}
          </IconContainer>
        ) : undefined}
        disabledStyle={{
          backgroundColor: disabledBackgroundColor,
          borderRadius: defaultBorderRadius,
        }}
        buttonStyle={{
          backgroundColor,
          height: finalHeight,
          minHeight: finalHeight,
          width: finalWidth,
          borderRadius: defaultBorderRadius,
        }}
        title={title}
        titleProps={{
          style: {
            color,
            fontWeight: bold ? 'bold' : 'normal',
            fontSize: 14,
            lineHeight: 19,
            textAlign: 'center',
            letterSpacing: 0.5,
            fontFamily: Theme.font.nunitoSans,
            ...titleProps,
          },
        }}
      />
    </RootComponent>
  );
};

const defaultBackgroundColor = Colors.white;
const defaultTextColor = Colors.black;
const defaultButtonHeight = 56;
const defaultBorderRadius = 2;

const Root = styled(View)`
`;

const BlockRoot = styled(View)`
  width: 100%;
`;

const StyledRnElementsButton = styled(RnElementsButton)<{
  backgroundColor?: string;
  color?: string;
  height: number;
  width?: number | string;
}>`
  background-color: ${({ backgroundColor }) => backgroundColor || defaultBackgroundColor};
  border-radius: ${defaultBorderRadius}px;
  ${({ width }) => (width ? `width: ${({ width: width2 }) => (_.isNumber(width2) ? `${width2}px` : width)};` : '')}
  color: ${({ color }) => color || defaultTextColor};
  height: ${({ height }) => height}px;
`;

const IconContainer = styled(View)`
  margin-left: ${({ theme }) => theme.scaler.scale(8)}px;
`;

