import {
  all,
  call,
  fork,
  takeEvery,
} from 'redux-saga/effects';
import { Log } from '../../../config/Log';
import { FixKeys } from '../../../../../core/src/lib/Types';
import { getType } from 'typesafe-actions';
import { actions } from '../../Actions';

const WebNavActions = {
  push: actions.actionTypeNavigationPush,
  replace: actions.actionTypeNavigationReplace,
  reset: actions.actionTypeNavigationReset,
  navigate: actions.actionTypeNavigationNavigate,
  back: actions.actionTypeNavigationBack,
};

export function* sagaNavigateWeb() {
  Log.v('sagaNavigateWeb', 'sagaNavigateWeb', 'starting');

  yield all(Object.keys(BrowserNavigationActions).map((key) => fork(function* () {
    Log.v('sagaNavigateWeb', 'sagaNavigateWeb', `${key} starting`);

    yield takeEvery(getType(WebNavActions[key]), function* () {
      Log.v('sagaNavigateWeb', 'sagaNavigateWeb', `${key} running`);
      yield call(BrowserNavigationActions[key]);
    });
  })));
}

// For each NavigationAction, specify how to handle it at the browser level
const BrowserNavigationActions: FixKeys<keyof typeof WebNavActions, () => Generator> = {
  * back() {
    // ReactX.window.history.back();
  },
  * push() {
    // ReactX.window.history.pushState(null, null as any, ReactX.window.location.href);
  },
  * replace() {
    // ReactX.window.history.replaceState(null, null as any, ReactX.window.location.href);
  },
  * reset() {
    // Not available on web
  },
  * navigate() {
    // Not available on web
    // ReactX.window.history.pushState(null, null as any, ReactX.window.location.href);
  },
};
