import moment from 'moment';
import { nowMs } from '../HelperFunctions';
import { ELocale, ELOCALE_GLOBAL_DEFAULT, localeToLang } from '../../locale/Locale';

class DtBase {
  readonly dateFormat = (ms: number, locale: ELocale = ELOCALE_GLOBAL_DEFAULT) => {
    const time = moment(ms);

    if (locale) {
      time.locale(localeToLang(locale));
    }

    return time.format('L');
  };

  readonly dateTimeFormat = (ms: number, locale: ELocale = ELOCALE_GLOBAL_DEFAULT) => {
    const time = moment(ms);

    if (locale) {
      time.locale(localeToLang(locale));
    }

    return time.format('L LT');
  };

  readonly nowMs = () => {
    return nowMs();
  };
}

export const Dt = new DtBase();
