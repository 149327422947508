import * as React from 'react';
import * as _ from 'lodash';
import { getActiveOrInactiveUnderlay } from '../components/orderUnderlays/ActiveOrInActiveUnderlay';
import { Colors } from '../config/Theme';
import { FIELD_ID } from '../../../core/src/db/DbDefs';
import { Screen } from '../lib-components/screen/Screen';
import { HeaderSearchable } from '../lib-components/HeaderSearchable';
import { Host } from '../../../core/src/models/db/host/Host';
import { MenuItemListSearch } from '../components/MenuItemListSearch';
import { NavTitleHandler } from '../lib/navigation/NavTitleHandler';
import { Strings } from '../../../core/src/locale/Strings';
import { TMenuItem } from '../../../core/src/models/db/menuItem/MenuItemTypes';

import { MenuDefaultEmptyState } from '../components/menu/MenuDefaultEmptyState';
import { Navigation } from '../lib/navigation/Navigation';
import { RCComp } from '../lib/routeContract/RCComp';
import { RoutesCliGuest } from '../../../core/src/lib/apis/routes/RoutableCliGuest';

type TScreenHostMenuItemSearchState = {
  filterByNameString: string;
};

export class ScreenHostMenuItemSearch extends RCComp<'ScreenHostMenuItemSearch', TScreenHostMenuItemSearchState> {
  protected readonly routeName = 'ScreenHostMenuItemSearch' as const;

  private readonly navTitleHandler = new NavTitleHandler((props: this['props']) => {
    return Strings.gSearchBarPhd()(props.locale);
  });

  constructor(props, context) {
    super(props, context);
    this.state = { filterByNameString: this.props.filterByNameString || '' };
    this.navTitleHandler.apply(props);
  }

  componentDidUpdate() {
    this.navTitleHandler.apply(this.props);
  }

  render() {
    const {
      locale,
      openOrder,
      guestSession,
    } = this.props;
    return (
      <Screen
        backgroundColor={Colors.white}
        usesReactNavigationHeader={false}
        hasStatusBar={true}
        statusBarBackgroundColor={Colors.white}
        statusBarBackgroundColorIsLight={true}
        underlayHeight={120}
        underlay={guestSession && openOrder && getActiveOrInactiveUnderlay({
          guestSession,
          onCheckoutPress: this.navigateToScreenHostOrderCheckout,
          onRecapPress: this.navigateToScreenHostOrderCheckout,
        })}
      >
        {this.renderHeader()}
        {this.renderItemList()}
      </Screen>
    );
  }

  private setFilterByNameString = (filterByNameString: string) => {
    this.setState({ filterByNameString });
  };

  private resetFilterByNameString = () => {
    this.setState({ filterByNameString: '' });
  };

  private onBackPressed = () => {
    Navigation.goBack();
  };

  private navigateToScreenHostOrderCheckout = async () => {
    await Navigation.routePush(RoutesCliGuest.ScreenHostOrderCheckout.name);
  };

  private onMenuItemPress = async (menuItem: TMenuItem) => {
    await Navigation.routePush(RoutesCliGuest.ScreenHostMenuItemView.name, {
      pathParams: { menuItemId: menuItem[FIELD_ID] },
      params: {},
    });
  };

  private renderHeader = () => {
    const { locale } = this.props;
    return (
      <HeaderSearchable
        title={Strings.gSearchBarPhd()(locale)}
        backLabel={Navigation.previousRouteTitle()}
        onSearchTextChanged={this.setFilterByNameString}
        onSearchClosed={this.resetFilterByNameString}
        onBackPressed={this.onBackPressed}
        searchPlaceholder={Strings.gSearchBarPhd()(locale)}
        autoSearch={true}
      />
    );
  };

  private renderItemList = () => {
    const {
      hostData,
      openOrder,
    } = this.props;
    const { filterByNameString } = this.state;

    if (_.isEmpty(filterByNameString)) {
      return (
        <MenuDefaultEmptyState/>
      );
    }

    return (
      <MenuItemListSearch
        filterString={filterByNameString}
        openOrder={openOrder}
        menuItems={Host.getActiveMenuItems(hostData)}
        onMenuItemPress={this.onMenuItemPress}
      />
    );
  };
}
