import * as React from 'react';
import { TState } from '../redux/Store';
import {
  RCStackScreenProps,
  RouteContract,
  TOutNavParams,
  TRCMergedCheckedProps,
  TRCOwnProps,
} from '../lib/routeContract/RouteContract';
import { StackNavigationOptions } from '@react-navigation/stack';
import { TNavigationParamsWithMethod } from '../lib/navigation/Navigation';
import { GenericErrorViewWithReset } from '../lib-components/error/GenericErrorViewWithReset';
import { TOrderItemCreate } from '../../../core/src/models/db/orderItem/OrderItemTypes';
import { NavConst } from '../lib/navigation/NavConst';
import { CheckPropsResultBuild } from '../lib/routeContract/RouteContractCheckProps';
import { bindActionCreators } from 'redux';
import { actions } from '../redux/Actions';

export class HostOrderItemModifyOptionsRouteContract extends RouteContract<'ScreenHostOrderItemModifyOptions'> {
  protected readonly routeName = 'ScreenHostOrderItemModifyOptions' as const;

  onBeforeRemoveShouldAllowBack(props: TRCMergedCheckedProps<'ScreenHostOrderItemModifyOptions'>) {
    return true;
  }

  navigationOptions(props: RCStackScreenProps<TOutNavParams<'ScreenHostOrderItemModifyOptions'>>, navParams: TOutNavParams<'ScreenHostOrderItemModifyOptions'>): StackNavigationOptions {
    return {
      headerShown: true,
      ...NavConst.stackNavOptionsForCenteredUppercaseTitle,
      ...NavConst.stackNavOptions,
    };
  }

  shouldSkipToRoute(state: TState, ownProps: TOutNavParams<'ScreenHostOrderItemModifyOptions'>): TNavigationParamsWithMethod<any> | undefined {
    return undefined;
  }

  mapStateToProps(state: TState, navParams: TOutNavParams<'ScreenHostOrderItemModifyOptions'>) {
    const orderItem: TOrderItemCreate = navParams.params.orderItem;
    const host = state.host.hostData;
    const menuItem = host?.items?.[orderItem.menuItemId];
    return {
      locale: state.app.locale,
      orderItem,
      menuItem,
    };
  }

  mapDispatchToProps(dispatch: any, ownProps: TRCOwnProps<'ScreenHostOrderItemModifyOptions'>) {
    return { orderItemEditorSet: bindActionCreators(actions.actionTypeOrderItemEditorSet, dispatch) };
  }

  checkProps(props: ReturnType<this['mapStateToProps']>) {
    const {
      orderItem,
      menuItem,
    } = props;

    if (menuItem == null) {
      // todo redirect back to orderItem
      return CheckPropsResultBuild.RENDER(<GenericErrorViewWithReset />);
    }

    if (orderItem == null) {
      // todo redirect back to orderItem
      return CheckPropsResultBuild.RENDER(<GenericErrorViewWithReset />);
    }

    return CheckPropsResultBuild.READY({
      ...props,
      menuItem,
      orderItem,
    });
  }
}
