import * as React from 'react';
import { FIELD_NAME } from '../../../../core/src/db/DbDefs';
import { Num } from '../../../../core/src/lib/formatters/Num';
import { OrderItem } from '../../../../core/src/models/db/orderItem/OrderItem';
import { TOrderItem } from '../../../../core/src/models/db/orderItem/OrderItemTypes';
import { View } from 'react-native';
import { localize } from '../../../../core/src/lib/formatters/LocaleLib';
import styled from 'styled-components/native';
import {
  AlaText,
  AlaTextBold,
} from '../Text';
import { useCurrentHost, useCurrentLanguage } from "../../hooks/hooks";
import { DEFAULT_CURRENCY } from '../../../../core/src/locale/Currencies';

type TOrderCheckoutOrderItemProps = {
  orderItem: TOrderItem;
};

export const OrderItemHeader = ({ orderItem }: TOrderCheckoutOrderItemProps) => {
  const language = useCurrentLanguage();
  const host = useCurrentHost();
  return (
    <Root>
      <Content>
        <OrderItemName numberOfLines={1}>
          {localize(orderItem, FIELD_NAME, language)}
        </OrderItemName>
        <OrderItemPrice>
          {Num.formatPrice2(
            OrderItem.getTotalPrice(orderItem),
            host?.currency ?? DEFAULT_CURRENCY
          )}
        </OrderItemPrice>
      </Content>
    </Root>
  );
};

const textLineHeight = 22;
const Root = styled(View)`
  align-items: center;
  flex-direction: row;
`;

const Content = styled(View)`
  align-items: center;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
`;

const OrderItemName = styled(AlaTextBold)`
  color: ${({ theme }) => theme.t.col.accentDark};
  font-size: 15px;
  line-height: ${textLineHeight}px;
  text-align: left;
  text-transform: uppercase;
`;

const OrderItemPrice = styled(AlaText)`
  color: ${({ theme }) => theme.t.col.accentDark};
  font-size: 15px;
  line-height: ${textLineHeight}px;
  text-align: right;
`;
