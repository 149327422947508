import * as React from 'react';
import {
  Button,
  TButtonProps,
} from './Button';
import { Log } from '../../config/Log';
import { useLoadingCallback } from '../../../../lib-react/src/hooks/useLoadingCallback';

type TPromiseButtonProps<Item = undefined> =
  Omit<TButtonProps, 'onPress' | 'loading'>
  & {
    item?: Item;
    onPress: (item: Item) => Promise<any>;
  };

export function PromiseButton<Item = undefined>(props: TPromiseButtonProps<Item>) {
  const {
    onPress,
    item,
    ...buttonProps
  } = props;

  const onButtonPress = React.useCallback(async () => {
    try {
      await onPress(item as Item);
    } catch (e) {
      Log.e('PromiseButton', 'PromiseButton', 'Error', e);
    }
  }, [onPress, item]);

  const [onLoadableButtonPress, loading] = useLoadingCallback(onButtonPress);
  return (
    <Button
      {...buttonProps}
      onPress={onLoadableButtonPress}
      loading={loading}
    />
  );
}

