import * as React from 'react';
import {
  SafeAreaView,
  Text,
  View,
} from 'react-native';
import { SvgCheckmark } from '../img/SvgCheckmark';
import styled from 'styled-components/native';

export type TConfirmationScreenView = TConfirmationScreenViewBase & {
  timeoutMs: number;
  onTimeoutComplete: () => void;
};

export const ConfirmationScreenView = ({
  timeoutMs,
  onTimeoutComplete,
  ...props
}: TConfirmationScreenView) => {
  React.useEffect(() => {
    const timeout = setTimeout(onTimeoutComplete, timeoutMs);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <ConfirmationScreenViewBase
      {...props}/>
  );
};

export type TConfirmationScreenViewBase = {
  backgroundColor: string;
  completeText: string;
};

const ConfirmationScreenViewBase = ({
  backgroundColor,
  completeText,
}: TConfirmationScreenViewBase) => {
  return (
    <Root
      backgroundColor={backgroundColor}>
      <SvgCheckmarkContainer>
        <SvgCheckmark
          size={'76px'}
        />
      </SvgCheckmarkContainer>
      <OrderCompleteText>
        {completeText.toUpperCase()}
      </OrderCompleteText>
    </Root>
  );
};

const Root = styled(SafeAreaView)<{ backgroundColor: string }>`
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  flex: 1;
  justify-content: center;
`;

const SvgCheckmarkContainer = styled(View)`

`;

const OrderCompleteText = styled(Text)`
  color: ${({ theme }) => theme.t.col.white};
  font-family: ${({ theme }) => theme.t.font.nunitoSans};
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.7px;
  line-height: 22px;
  margin-top: 30px;
  text-align: center;
  text-transform: uppercase;
`;
