import * as _ from 'lodash';
import {
  FIELD_CREATED_ON,
  FIELD_DEFAULT_LOCALE,
  FIELD_ID,
  FIELD_NAME,
} from '../../db/DbDefs';
import { IngredientCreator } from '../db/ingredient/IngredientCreator';
import { MenuItemBuilder } from '../db/menuItem/MenuItemBuilder';
import { TBuilderParamsBase } from './lib/buildRawUpdate';
import { TMenuItemDataChange } from '../db/menuItem/MenuItemTypes';
import { TRKMenuItem } from '../../db/DbResources';
import { addIfDefined } from '../../lib/HelperFunctions';
import {
  buildLocalizedFieldsUpdate,
  stripUnchangedValues,
  syncLocalizedItemToDefaultLocale,
} from './lib/localizedObjectUpdater';
import { ingredientLocalizedFields } from '../db/ingredient/IngredientTypes';
import { itemToPathItem } from '../../lib/PathParser';
import { menuLocalizedFields } from '../db/menu/MenuTypes';
import { ELocale, ELOCALE_GLOBAL_DEFAULT } from '../../locale/Locale';

function buildIngredientsUpdateObject({
  item,
  menuItemDataChange,
  localizedUpdateLocale,
}: TBuilderParamsBase<TRKMenuItem> & TMenuItemUpdate) {
  const { ingredients } = menuItemDataChange;
  const pbp = MenuItemBuilder.fromItem(item).pbp();
  const mappedIngredients = (ingredients || [])
    .map((ingredient) => ({
      ...IngredientCreator.buildNew(pbp, ingredient, ingredient[FIELD_ID]),
      ...addIfDefined(FIELD_CREATED_ON, ingredient[FIELD_CREATED_ON]),
    }))
    .map((ingredient) => ({
      ...ingredient,
      ...buildLocalizedFieldsUpdate(
        {
          localizedUpdateLocale,
          item: ingredient,
        },
        [
          {
            fieldName: FIELD_NAME,
            updateValue: ingredient[FIELD_NAME],
          },
        ],
      ),
    }))
    .map((ingredient) => {
      return syncLocalizedItemToDefaultLocale({
        item: ingredient,
        localizedFields: ingredientLocalizedFields as any,
        newDefaultLocale: ingredient[FIELD_DEFAULT_LOCALE]
          || ELOCALE_GLOBAL_DEFAULT,
      });
    });
  const newIngredientsKeyedById = _.keyBy(mappedIngredients, FIELD_ID);
  const newIngredientIds = _.keys(newIngredientsKeyedById);
  return {
    ...newIngredientsKeyedById,

    // Keep after updates so it can override them
    // Ingredients to remove are not in the newIngredientIds array
    ..._.values(item.ingredients)
      .filter((ingredient) => !newIngredientIds.includes(ingredient[FIELD_ID]))
      .reduce((acc, ingredientToRemove) => {
        return _.set(acc, ingredientToRemove[FIELD_ID], null);
      }, {} as any),
  };
}

/**
 * BuildMenuUpdateObject
 */
type TMenuItemUpdate = {
  menuItemDataChange: Partial<Omit<TMenuItemDataChange, 'itemImg'>> & {
    itemImg?: string;
  };
  localizedUpdateLocale: ELocale;
};

export async function menuItemUpdate({
  item,
  menuItemDataChange,
  localizedUpdateLocale,
}: TBuilderParamsBase<TRKMenuItem> & TMenuItemUpdate) {
  const update = {
    ...buildLocalizedFieldsUpdate(
      {
        localizedUpdateLocale,
        item,
      },
      [
        {
          fieldName: FIELD_NAME,
          updateValue: menuItemDataChange.name,
        },
        {
          fieldName: 'description',
          updateValue: menuItemDataChange.description,
        },
      ],
    ),

    // not localized properties
    ...addIfDefined('allergens', menuItemDataChange.allergens, menuItemDataChange.allergens),
    ...addIfDefined('price', menuItemDataChange.price, menuItemDataChange.price),
    ...addIfDefined('stationId', menuItemDataChange.stationId, menuItemDataChange.stationId),
    ...addIfDefined('shareable', menuItemDataChange.shareable, menuItemDataChange.shareable),
    ...addIfDefined('tags', menuItemDataChange.tags, menuItemDataChange.tags),
    ...addIfDefined('itemImg', menuItemDataChange.itemImg, menuItemDataChange.itemImg),

    // Ingredients
    ...addIfDefined('ingredients', menuItemDataChange.ingredients, buildIngredientsUpdateObject({
      item,
      menuItemDataChange,
      localizedUpdateLocale,
    })),
  };

  const localizedItem = syncLocalizedItemToDefaultLocale({
    item: _.merge({}, item, update),
    localizedFields: menuLocalizedFields as any,
    newDefaultLocale: update[FIELD_DEFAULT_LOCALE]
      || ELOCALE_GLOBAL_DEFAULT,
  });

  const strippedItem = stripUnchangedValues({
    initialItem: item,
    updatedItem: localizedItem,
  });

  return itemToPathItem(strippedItem);
}
