import * as React from 'react';
import { Colors } from '../config/Theme';
import {
  View,
  ViewProps,
} from 'react-native';
import { isWeb } from '../lib/Platform';

export const shadowViewStyle1 = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.30,
  shadowRadius: 4.65,
  elevation: 8,
  ...(isWeb ? { boxShadow: '0px -8px 10px rgba(0, 0, 0, 0.0954709)' } : {}),
};

export const shadowViewStyle2 = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 6,
  },
  shadowOpacity: 0.37,
  shadowRadius: 7.49,
  elevation: 12,
  ...(isWeb ? { boxShadow: '0px -8px 10px rgba(0, 0, 0, 0.0954709)' } : {}),
};

type TShadowView = ViewProps & {
  children: React.ReactNode;
};

export const ShadowView = ({ children, style, ...viewProps }: TShadowView) => (
  <View
    {...viewProps}
    style={[style, shadowViewStyle1]}>
    {children}
  </View>
);

export const ShadowView2 = ({ children, style, ...viewProps }: TShadowView) => (
  <View
    {...viewProps}
    style={[style, shadowViewStyle2]}>
    {children}
  </View>
);

