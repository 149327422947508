import { ConnectedDeepLinkHandler } from '../../lib/redux/ConnectedDeepLinkHandler';
import { DeepLinkHandler } from '../../../../core/src/lib/deeplinks/DeepLinkHandler';
import { Firebase } from '../../lib/Firebase';
import { Log } from '../../config/Log';
import {
  cancelled,
  take,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';

export function* sagaDeepLinkListener() {
  Log.v('sagaDeepLinkListener', 'sagaDeepLinkListener', 'starting');

  const channel = eventChannel((emit) => {
    const { unsubscribe } = Firebase.subscribeForLinks((link: string) => {
      emit(link);
    });
    return unsubscribe as any;
  });

  try {
    while (true) {
      const link: string = yield take(channel);
      yield DeepLinkHandler.parse(link, ConnectedDeepLinkHandler);
    }
  } finally {
    if (yield cancelled()) {
      Log.v('sagaDeepLinkListener', 'sagaDeepLinkListener', `Saga cancelled, clearing up`);
      channel.close();
    }
  }
}
