import * as _ from 'lodash';
import {
  TTag,
  TTagString,
} from '../models/Tag';
import { push } from './HelperFunctions';

const TAG_JOIN_CHAR = ',';

class TagParserBase {
  constructor() {
    this.addTag = this.addTag.bind(this);
    this.joinTags = this.joinTags.bind(this);
    this.format = this.format.bind(this);
    this.parseFormatAndSortTags = this.parseFormatAndSortTags.bind(this);
    this.formatTag = this.formatTag.bind(this);
  }

  toString(multiTagString: TTagString = '') {
    return this.parseFormatAndSortTags(multiTagString)
      .map((tag) => tag.replace(/#/gi, ''))
      .map((tag) => tag.replace(/-/gi, ' '))
      .map((tag) => `${tag.charAt(0).toUpperCase()}${tag.substring(1)}`)
      .join(', ');
  }

  addTag(tag?: TTag, multiTagString: TTagString = '') {
    if (!tag) {
      return this.format(tag);
    }
    const parsedTags = this.parseFormatAndSortTags(multiTagString);
    const addedTag = push(parsedTags, tag);
    const newTags = this.joinTags(addedTag);
    return this.format(newTags);
  }

  format(multiTagString?: TTagString) {
    const tags = this.parseFormatAndSortTags(multiTagString);
    const uniqTags = _.uniq(tags);
    const tagString = this.joinTags(uniqTags);
    return tagString;
  }

  tagIsValid(tag: TTag | undefined) {
    return /^#([a-z0-9\-]){3,}$/.test(`${tag}`);
  }

  parseFormatAndSortTags(multiTagString?: TTagString) {
    return (multiTagString || '')
      .split(TAG_JOIN_CHAR)
      .sort((a, b) => a.localeCompare(b))
      .map(this.formatTag)
      .filter((tag) => this.tagIsValid(tag));
  }

  formatTagArrToString(tags: TTag[] = []) {
    return tags
      .sort((a, b) => a.localeCompare(b))
      .map(this.formatTag)
      .filter((tag) => this.tagIsValid(tag))
      .join(TAG_JOIN_CHAR);
  }

  joinTags(tags?: TTag[]) {
    return (tags || []).join(TAG_JOIN_CHAR);
  }

  private formatTag(tag?: TTag) {
    const tagWithoutPrefix = (tag || '')
      .toLowerCase()
      .trim()
      .replace(/ /gi, '-')
      .replace(/[^a-z0-9\-]/gi, '');
    return `#${tagWithoutPrefix}`;
  }
}

export const TagParser = new TagParserBase();
