import * as _ from 'lodash';
import {
  PrintOps,
  TPrintOp,
} from '../PrintOps';

const blockChar = '|';
const indentSpaceCount = 3;

class PrintFormatBase {
  bold(text: string): TPrintOp[] {
    return [
      PrintOps.printOpStyle('B'),
      PrintOps.printOpText(text),
      PrintOps.printOpStyle('NORMAL'),
    ];
  }

  strBlock(n: number) {
    return _.range(0, n).map(() => blockChar).join('');
  }

  strIndent(indentLevel: number) {
    return _.range(0, indentLevel * indentSpaceCount).map(() => ' ').join('');
  }
}

export const PrintFormat = new PrintFormatBase();
