import { Log } from '../../config/Log';
import { Strings } from '../../../../core/src/locale/Strings';
import {
  sagaMonitorSelector,
  selectUndefinedAsClearTask,
} from '../../../../lib-react/src/sagas/sagaMonitorSelector';
import { selectLocale } from '../selectors';

export function* sagaSetLocaleInStrings() {
  yield sagaMonitorSelector(['*'], selectUndefinedAsClearTask(selectLocale), function* (locale) {
    Log.v('sagaSetLocaleInStrings', 'sagaSetLocaleInStrings', `locale changed to ${locale}`);
    Strings.setLocale(locale);
  });
}
