import * as React from 'react';
import { Colors } from '../config/Theme';
import { SvgSearch } from '../img/SvgSearch';
import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { AlaTouchableWithoutFeedback } from './Touchable';

type TFakeSearchBarProps = {
  text: string;
  height?: number;
  onPress: () => void;
};

export const FakeSearchBar = ({
  height,
  text,
  onPress,
}: TFakeSearchBarProps) => {
  return (
    <AlaTouchableWithoutFeedback
      onPress={onPress}>
      <Root
        height={height}>
        <Row>
          <IconCont>
            <SvgSearch
              width={iconSize}
              height={iconSize}
              fill={Colors.white}
            />
          </IconCont>
          <SearchText>
            {text}
          </SearchText>
        </Row>
      </Root>
    </AlaTouchableWithoutFeedback>
  );
};

const iconSize = 10;

const Root = styled(View)<{ height?: number }>`
  ${({ height }) => (height ? `height: ${height}px;` : '')};
`;

const Row = styled(View)`
  align-items: center;
  flex: 1;
  flex-direction: row;
`;

const IconCont = styled(View)`
  padding: ${({ theme }) => theme.scaler.verticalScale(4)}px 4px;
`;

const SearchText = styled(Text)`
  color: ${({ theme }) => theme.t.col.white};
  font-family: ${({ theme }) => theme.t.font.nunitoSans};
  font-size: ${({ theme }) => theme.scaler.verticalScale(11)}px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: ${({ theme }) => theme.scaler.verticalScale(15)}px;
  margin-left: 8px;
  text-transform: uppercase;
`;
