import { DbPaths } from '../../../db/DbRefs';
import { DbRes } from '../../../db/DbResources';
import { Ingredient } from './Ingredient';
import { MObjDef } from '../../../lib/model/MObjDef';
import { TIngredient } from './IngredientTypes';
import { defIngredient } from '../ModelDefaults';

const pathBuilderKeys = [
  'hostId',
  'menuItemId',
  'ingredientId',
] as const;

export type TIngredientPathBuilderParams = {
  [key in ((typeof pathBuilderKeys)[number])]: string
};

class IngredientBuilderBase extends MObjDef<typeof DbRes.Ingredient> {
  readonly resourceKey = 'Ingredient';

  protected readonly pathBuilderKeys = [...pathBuilderKeys];

  protected readonly _default = defIngredient;

  protected newChild(item: TIngredient) {
    return new Ingredient(item);
  }

  pathBuilder(params: TIngredientPathBuilderParams): string {
    return DbPaths.ingredient(params);
  }
}

export const IngredientBuilder = new IngredientBuilderBase();
