/* eslint-disable no-console */
import * as _ from 'lodash';

// Stubs
const bold = _.identity;
const green = _.identity;
const red = _.identity;
const grey = _.identity;
const cyan = _.identity;
const yellow = _.identity;
const magenta = _.identity;

type TLoggerEnv = 'production' | 'development' | 'test';

type TLoggerParams = {
  name: string;
  env: TLoggerEnv;
  isCI: boolean;
  onLog?: (line: string) => void;
};

export class Logger {
  readonly params: TLoggerParams;

  readonly logStrings: string[] = [];

  constructor(params: TLoggerParams) {
    this.params = params;
    this._log = this._log.bind(this);
  }

  /**
   * Use this when you want to go absolutely nuts with your logging.
   * If for some reason you've decided to log every little thing in
   * a particular part of your app, use the Log.v tag.
   */
  v(fileOrClass: string, method: string, ...params: any) {
    this._log(grey('v'), green(bold(this.params.name)), magenta(`${fileOrClass}@${method}`), ...params);
  }

  /**
   * Use this for debugging purposes.
   * If you want to print out a bunch of messages so you can log the
   * exact flow of your program, use this. If you want to keep a log
   * of variable values, use this.
   */
  d(fileOrClass: string, method: string, ...params: any) {
    this._log(grey('d'), green(bold(this.params.name)), magenta(`${fileOrClass}@${method}`), ...params);
  }

  /**
   * Use this to post useful information to the log.
   * For example: that you have successfully connected to a server.
   * Basically use it to report successes.
   */
  i(fileOrClass: string, method: string, ...params: any) {
    this._log(cyan('i'), green(bold(this.params.name)), magenta(`${fileOrClass}@${method}`), ...params);
  }

  /**
   * Something terribly wrong had happened, that must be investigated immediately.
   * No system can tolerate items logged on this level.
   * Example: NPE, dbUser unavailable, mission critical use case cannot be continued.
   */
  w(fileOrClass: string, method: string, ...params: any) {
    this._log(yellow('w'), green(bold(this.params.name)), magenta(`${fileOrClass}@${method}`), ...params);
  }

  /**
   * This is for when bad stuff happens.
   * Use this tag in places like inside a catch statement.
   * You know that an error has occurred and therefore you're logging an error.
   */
  e(fileOrClass: string, method: string, ...params: any) {
    this._log(red('e'), green(bold(this.params.name)), magenta(`${fileOrClass}@${method}`), ...params);
  }

  /**
   * Use this when stuff goes absolutely, horribly, holy-crap wrong.
   * You know those catch blocks where you're catching errors that you
   * never should get...yeah, if you wanna log them use Log.wtf
   */
  wtf(fileOrClass: string, method: string, ...params: any) {
    this._log(red('wtf'), green(bold(this.params.name)), magenta(`${fileOrClass}@${method}`), ...params);
  }

  _log(...params: any) {
    if (this.params.isCI || this.params.env === 'test') {
      return;
    }

    const logString = JSON.stringify(params);
    if (this.params.env === 'development') {
      this.logStrings.push(JSON.stringify(params));
    }
    console.log(...params);
    this.params.onLog && this.params.onLog(logString);
  }
}
