import * as _ from 'lodash';
import { FIELD_ID } from '../../db/DbDefs';
import { KnownError } from '../../lib/error/KnownError';
import { LocalError } from '../../lib/error/LocalError';
import { TBuilderParamsBase } from './lib/buildRawUpdate';
import {
  TMenu,
  TMenuId,
} from '../db/menu/MenuTypes';
import { TRKMenu } from '../../db/DbResources';

function getCurrentPublishedSubMenu(parentMenu: Pick<TMenu, 'subMenus'>) {
  return _.find(parentMenu.subMenus, ({ isHidden }) => !isHidden);
}

function getFirstMenuThatIsNotOfId(parentMenu: Pick<TMenu, 'subMenus'>, id: TMenuId) {
  return _.find(parentMenu.subMenus, (menu) => menu[FIELD_ID] != id);
}

type TMenuUpdateSubMenuPublishStatusResult = {
  [subMenuId: string]: {
    isHidden: boolean;
  };
};

type TMenuUpdateSubMenuPublishStatus = {
  menuIdToUpdate: TMenuId;
  newPublishStatus: boolean;
};

/**
 * In the given {menu}, only one subMenu can be active at a time
 * If we UnPublish to 0 subMenus, the next one is published
 * If we publish to 2 subMenus, the previous one is unPublished
 */
export async function menuUpdateSubMenuPublishStatus({
  item,
  menuIdToUpdate,
  newPublishStatus,
}: TBuilderParamsBase<TRKMenu> & TMenuUpdateSubMenuPublishStatus): Promise<TMenuUpdateSubMenuPublishStatusResult> {
  const { subMenus } = item;
  if (!subMenus) {
    return {};
  }

  if (!subMenus[menuIdToUpdate]) {
    throw new Error([
      'menuBuildPublishedStatusUpdateObject',
      'menuBuildPublishedStatusUpdateObject',
      `menuId=${menuIdToUpdate} is not a subMenu of ${item[FIELD_ID]}`,
    ].join(', '));
  }

  // Deactivate all menus
  const updateObject = _.keys(subMenus).reduce((acc, subMenuId) => {
    acc[subMenuId] = { isHidden: true };
    return acc;
  }, {} as any);

  if (newPublishStatus) {
    updateObject[menuIdToUpdate] = { isHidden: false };
    return updateObject;
  }

  // This menu needs to be unPublished,
  // reactivate the one that was previously published
  function getMenuIdToPublish() {
    const previouslyPublishedMenu = getCurrentPublishedSubMenu(item);
    if (previouslyPublishedMenu) {
      return previouslyPublishedMenu[FIELD_ID];
    }

    const nextMenuToPublish = getFirstMenuThatIsNotOfId(item, menuIdToUpdate);
    if (nextMenuToPublish) {
      return nextMenuToPublish[FIELD_ID];
    }

    return undefined;
  }

  const menuIdToPublish = getMenuIdToPublish();
  if (!menuIdToPublish) {
    // There was no other menu that could have been published
    // so UnPublishing this menu is not allowed
    throw new LocalError(
      'menuBuildPublishedStatusUpdateObject',
      'menuBuildPublishedStatusUpdateObject',
      KnownError.noOtherMenuToActivate,
    );
  }

  updateObject[menuIdToPublish] = { isHidden: false };
  return updateObject;
}
