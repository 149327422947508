import { TState } from '../redux/Store';
import { bindActionCreators } from '../../../lib-react/src/redux/redux';
import {
  RCStackScreenProps,
  RouteContract,
  TOutNavParams,
  TRCMergedCheckedProps,
  TRCOwnProps,
} from '../lib/routeContract/RouteContract';
import { StackNavigationOptions } from '@react-navigation/stack';
import { TNavigationParamsWithMethod } from '../lib/navigation/Navigation';
import { actionGuestSessionSetTableId } from '../redux/procedures/actionGuestSessionSetTableId';
import { actions } from '../redux/Actions';
import { NavConst } from '../lib/navigation/NavConst';
import { CheckPropsResultBuild } from '../lib/routeContract/RouteContractCheckProps';
import { HostSubscription } from '../../../core/src/models/db/hostSubscription/HostSubscription';
import { ESubscriptionFeatureFlags } from '../../../core/src/models/db/hostSubscription/HostSubscriptionTypes';
import { RoutesCliGuest } from '../../../core/src/lib/apis/routes/RoutableCliGuest';

export class RouteSetupGuestSessionContract extends RouteContract<'ScreenSetupGuestSession'> {
  protected readonly routeName = 'ScreenSetupGuestSession' as const;

  onBeforeRemoveShouldAllowBack(props: TRCMergedCheckedProps<'ScreenSetupGuestSession'>) {
    return true;
  }

  navigationOptions(props: RCStackScreenProps<TOutNavParams<'ScreenSetupGuestSession'>>, navParams: TOutNavParams<'ScreenSetupGuestSession'>): StackNavigationOptions {
    return {
      headerShown: false,
      ...NavConst.stackNavOptions,
    };
  }

  shouldSkipToRoute(state: TState, ownProps: TOutNavParams<'ScreenSetupGuestSession'>): TNavigationParamsWithMethod<any> | undefined {
    const {
      tableRequested,
      guestCountRequested,
    } = state.guestSession.setup;

    if (tableRequested && guestCountRequested) {
      return {
        routeName: RoutesCliGuest.ScreenHostHome.name,
        params: {
          params: {},
          pathParams: {},
        },
        method: 'reset',
      };
    }

    return undefined;
  }

  mapStateToProps(state: TState, navParams: TOutNavParams<'ScreenSetupGuestSession'>) {
    return {
      locale: state.app.locale,
      hostData: state.host.hostData,
      hostPublicSubscription: state.host.hostPublicSubscription,
      guestSession: state.guestSession.guestSessionData,
      tableRequested: state.guestSession.setup.tableRequested,
      guestCountRequested: state.guestSession.setup.guestCountRequested,
    };
  }

  mapDispatchToProps(dispatch: any, ownProps: TRCOwnProps<'ScreenSetupGuestSession'>) {
    return {
      guestSessionSetTableId: bindActionCreators(actionGuestSessionSetTableId, dispatch),
      guestSessionSetSetupFlags: bindActionCreators(actions.actionTypeGuestSessionSetSetupFlags, dispatch),
    };
  }

  checkProps(props: ReturnType<this['mapStateToProps']>) {
    const {
      hostData,
      hostPublicSubscription,
      tableRequested,
      guestCountRequested,
    } = props;

    if (hostData == null) {
      return CheckPropsResultBuild.WAIT();
    }

    if (hostPublicSubscription == null) {
      return CheckPropsResultBuild.WAIT();
    }

    if (!HostSubscription.hasAccessToFeature(hostPublicSubscription, ESubscriptionFeatureFlags.guestOrders)) {
      return CheckPropsResultBuild.REDIRECT({
        routeName: RoutesCliGuest.ScreenHostHome.name,
        params: {
          params: {},
          pathParams: {},
        },
        method: 'reset',
      });
    }

    if (tableRequested && guestCountRequested) {
      return CheckPropsResultBuild.REDIRECT({
        routeName: RoutesCliGuest.ScreenHostHome.name,
        params: {
          params: {},
          pathParams: {},
        },
        method: 'reset',
      });
    }

    return CheckPropsResultBuild.READY({
      ...props,
      hostData,
    });
  }
}
