import * as React from 'react';
import * as _ from 'lodash';
import { GuestSession } from '../../../../core/src/models/db/guestSession/GuestSession';
import { Order } from '../../../../core/src/models/db/order/Order';
import { TGuestSession } from '../../../../core/src/models/db/guestSession/GuestSessionTypes';
import { TOrder } from '../../../../core/src/models/db/order/OrderTypes';
import { OrderUnderlayBase } from './OrderUnderlayBase';
import { Colors } from '../../config/Theme';
import { Strings } from '../../../../core/src/locale/Strings';
import { useCurrentLocale } from "../../hooks/hooks";

export type TActiveOrInActiveUnderlayProps = {
  guestSession: TGuestSession;
  onCheckoutPress: () => void;
  onRecapPress: () => void;
};

export function getActiveOrInactiveUnderlay({
                                              guestSession,
                                              onCheckoutPress,
                                              onRecapPress,
                                            }: TActiveOrInActiveUnderlayProps) {
  const locale = useCurrentLocale();
  const openOrderWithItems = _.find(guestSession.orders, (order: TOrder) => {
    return Order.orderIsOpen(order) && Order.orderHasItems(order);
  });

  // Show Active underlay
  if (openOrderWithItems != null) {
    return (
      <OrderUnderlayBase
        price={Order.getTotalPrice(openOrderWithItems)}
        itemCount={_.size(openOrderWithItems.items)}
        onPress={onCheckoutPress}
        backgroundColor={Colors.red}
        rightText={Strings.gProceedToOrderCmd()(locale)}
      />
    );
  }

  // Show InActive (recap) underlay
  if (GuestSession.getOrderItemCount(guestSession) > 0) {
    return (
      <OrderUnderlayBase
        price={GuestSession.getTotalPrice(guestSession)}
        itemCount={GuestSession.getOrderItemCount(guestSession)}
        onPress={onRecapPress}
        backgroundColor={Colors.accentDark}
        rightText={Strings.gOrderSummaryPage()(locale)}
      />
    );
  }

  return null;
}
