import * as React from 'react';
import * as _ from 'lodash';
import { Colors } from '../config/Theme';
import {
  FIELD_ID,
  FIELD_NAME,
} from '../../../core/src/db/DbDefs';
import {
  FlatList,
  ListRenderItemInfo,
  Text,
  View,
} from 'react-native';
import { Screen } from '../lib-components/screen/Screen';
import { Hairline } from '../lib-components/Hairline';
import { NavTitleHandler } from '../lib/navigation/NavTitleHandler';
import { Strings } from '../../../core/src/locale/Strings';
import { Touchable } from '../lib-components/Touchable';

import styled from 'styled-components/native';
import { ReactX } from '../lib/ReactX';
import { RCComp } from '../lib/routeContract/RCComp';
import { Config } from '../config/Config';

type DevFunction<T = any> = {
  render: (param: T) => React.ReactNode;
  renderProps: T;
};

export class ScreenDevFunctions extends RCComp<'ScreenDevFunctions'> {
  protected readonly routeName = 'ScreenDevFunctions' as const;

  private readonly navTitleHandler = new NavTitleHandler((props: this['props']) => {
    return Strings.gDevFunctions()(props.locale);
  });

  private static devFunctionKeyExtractor(item, index) {
    return `${index}`;
  }

  componentDidUpdate() {
    this.navTitleHandler.apply(this.props);
  }

  render() {
    const { hostData, guestSession, openOrder } = this.props;
    return (
      <Screen
        backgroundColor={Colors.white}
        usesReactNavigationHeader={true}
        hasStatusBar={true}
        statusBarBackgroundColor={Colors.white}
        statusBarBackgroundColorIsLight={false}
      >
        <StyledFlatList
          keyExtractor={ScreenDevFunctions.devFunctionKeyExtractor}
          renderItem={this.renderItem}
          ItemSeparatorComponent={Hairline}
          data={[
            {
              render: this.renderGenericText,
              renderProps: { text: `COMMIT_HASH: ${Config.COMMIT_HASH}` },
            },
            {
              render: this.renderClickableButton,
              renderProps: {
                text: 'Reset Host',
                onPress: this.props.resetSessionAndNavigate,
              },
            },
            {
              render: this.renderGenericText,
              renderProps: { text: `Table N: ${_.get(_.get(hostData?.tables, guestSession?.tableId || ''), FIELD_NAME)}` },
            },
            {
              render: this.renderGenericText,
              renderProps: { text: `Open order Id: ${_.get(openOrder, FIELD_ID)}` },
            },
            {
              render: this.renderClickableButton,
              renderProps: {
                text: `Crash with crashlytics`,
                onPress: () => ReactX.crashlytics().crash(),
              },
            },
          ]}
        />
      </Screen>
    );
  }

  private renderItem = ({ item }: ListRenderItemInfo<DevFunction>) => {
    return item.render(item.renderProps);
  };

  private renderClickableButton = (item: any) => {
    const { text, onPress } = item;
    return (
      <Touchable
        item={item}
        onPress={onPress}>
        <DevFunctionItem>
          <DevFunctionItemName>
            {text}
          </DevFunctionItemName>
        </DevFunctionItem>
      </Touchable>
    );
  };

  private renderGenericText = (item: any) => {
    const { text } = item;
    return (
      <DevFunctionItem>
        <DevFunctionItemName>
          {text}
        </DevFunctionItemName>
      </DevFunctionItem>
    );
  };
}

const StyledFlatList = styled(FlatList as new () => FlatList<DevFunction>)`
  margin-top: 12px;
`;

const DevFunctionItem = styled(View)`
  padding: 12px;
`;

const DevFunctionItemName = styled(Text)`

`;
