import { DbPaths } from '../../../db/DbRefs';
import { DbRes } from '../../../db/DbResources';
import { MObjDef } from '../../../lib/model/MObjDef';
import { Station } from './Station';
import { TStation } from './StationTypes';
import { defStation } from '../ModelDefaults';

const pathBuilderKeys = [
  'hostId',
  'stationId',
] as const;

export type TStationPathBuilderParams = {
  [key in ((typeof pathBuilderKeys)[number])]: string
};

class StationBuilderBase extends MObjDef<typeof DbRes.Station> {
  readonly resourceKey = DbRes.Station;

  protected readonly pathBuilderKeys = [...pathBuilderKeys];

  protected readonly _default = defStation;

  protected newChild(item: TStation) {
    return new Station(item);
  }

  pathBuilder(params: TStationPathBuilderParams): string {
    return DbPaths.station(params);
  }
}

export const StationBuilder = new StationBuilderBase();
