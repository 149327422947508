import { TRKHostSubscription } from '../../../db/DbResources';
import { HostSubscriptionBuilder } from './HostSubscriptionBuilder';
import { MObj } from '../../../lib/model/MObj';
import {
  ESubscriptionFeatureFlags,
  ESubscriptionType,
  subscriptionTypeToFeatureFlag,
  THostSubscription,
  THostSubscriptionUpdate,
} from './HostSubscriptionTypes';

export class HostSubscription extends MObj<TRKHostSubscription> {
  static hasAccessToFeature(
    { stripeSubscriptionType }: Pick<THostSubscription, 'stripeSubscriptionType'>,
    feature: ESubscriptionFeatureFlags,
  ): boolean {
    const subscription = stripeSubscriptionType || ESubscriptionType.Starter;
    return subscriptionTypeToFeatureFlag[subscription].includes(feature);
  }

  constructor(hostSubscription: THostSubscription) {
    super(HostSubscriptionBuilder, hostSubscription);
  }

  updateSubscription(hostSubscription: Partial<THostSubscriptionUpdate>) {
    return this.updateFields(hostSubscription);
  }
}
