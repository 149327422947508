import styled from 'styled-components/native';
import { Text } from 'react-native';

import { selectPlatform } from '../lib/Platform';
import { Theme } from '../config/Theme';

export const AlaText = styled(Text)`
  font-family: ${({ theme }) => theme.t.font.nunitoSans};
`;

export const AlaTextBold = styled(AlaText)`
  font-weight: 900;
`;

export const alaHeaderTextStyle = {
  fontFamily: Theme.font.nunitoSans,
  letterSpacing: 0,
  fontSize: selectPlatform({
    android: 20,
    ios: 17,
    web: 16, // original 18
    other: 18,
  }),
  fontWeight: selectPlatform({
    android: '900',
    ios: '600',
    web: '900', // original 500
    other: '500',
  }),
};
