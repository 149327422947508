import { Secrets } from '../../../core/src/secrets/Secrets';
import { corePackageSecrets } from '../../../core/src/config/corePackageSecrets';
import { libReactPackageSecrets } from '../../../lib-react/src/config/libReactPackageSecrets';

const packageSecretKeys = {
  ...corePackageSecrets,
  ...libReactPackageSecrets,
  FIREBASE_DB_URL: '',
  FIREBASE_PROJECT_ID: '',
  FIREBASE_STORAGE_BUCKET: '',
  FIREBASE_AUTH_DOMAIN: '',
  FIREBASE_API_KEY: '',
  FIREBASE_MESSAGING_SENDER_ID: '',
  FIREBASE_APP_ID: '',
  FIREBASE_MEASUREMENT_ID: '',
  FIREBASE_IOS_APP_INFO_PLIST: '',
  FIREBASE_ANDROID_APP_JSON: '',
};

type TSecrets = typeof corePackageSecrets
  & typeof libReactPackageSecrets
  & typeof packageSecretKeys;

export const PackageSecrets = new Secrets<TSecrets>({ packageSecretKeys });
