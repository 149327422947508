import { Colors } from '../../config/Theme';
import { CardStyleInterpolators, StackNavigationOptions } from '@react-navigation/stack';
import { alaHeaderTextStyle } from '../../components/Text';
import { ReactNativeStatusBarHeight } from '../../lib-components/theme/react-native-status-bar-height';

export const NavConst = {
  stackNavOptions: <StackNavigationOptions>{
    animationEnabled: true,
    headerBackTitle: '',
    headerBackTitleVisible: false,
    // headerBackground(props: { style: StyleProp<ViewStyle> }): React.ReactNode {
    //   return undefined;
    // },
    // headerLeft(props: StackHeaderLeftButtonProps): React.ReactNode {
    //   return undefined;
    // },
    // headerRight(props: { tintColor?: string }): React.ReactNode {
    //   return undefined;
    // },
    //
    // headerBackAllowFontScaling: false,
    // headerBackImage: undefined,
    // headerBackTitleStyle: {},
    // headerLeftContainerStyle: undefined,
    // headerPressColorAndroid: '',
    // headerRightContainerStyle: undefined,
    // headerStatusBarHeight: 0,
    // headerStyle: undefined,
    headerTintColor: Colors.accentDark,
    // headerTitle: undefined,
    // headerTitleAlign: undefined,
    // headerTitleAllowFontScaling: false,
    // headerTitleContainerStyle: undefined,
    // headerTitleStyle: undefined,
    // headerTransparent: false,
    // headerTruncatedBackTitle: '',
    headerStatusBarHeight: ReactNativeStatusBarHeight.getStatusBarHeight(),
  },
  modalStackNavOptions: <StackNavigationOptions>{
    headerShown: false,
    cardStyle: {
      shadowColor: 'transparent',
      backgroundColor: 'transparent',
    },
    animationEnabled: false,
  },
  stackNavOptionsForCenteredTitle: {
    headerTitleAlign: 'center',
    headerTitleContainerStyle: {
      width: '80%',
      alignItems: 'center',
    },
    headerTitleStyle: {
      ...alaHeaderTextStyle,
      textTransform: 'uppercase',
    },
  },
  get stackNavOptionsForCenteredUppercaseTitle() {
    return <StackNavigationOptions>{
      ...this.stackNavOptionsForCenteredTitle,
      headerTitleStyle: {
        ...this.stackNavOptionsForCenteredTitle.headerTitleStyle,
        textTransform: 'uppercase',
      },
    };
  },
  transitions: {
    forHorizontalIOS: {
      name: 'forHorizontalIOS' as const,
      f: CardStyleInterpolators.forHorizontalIOS,
    } as const,
    forRevealFromBottomAndroid: {
      name: 'forRevealFromBottomAndroid' as const,
      f: CardStyleInterpolators.forRevealFromBottomAndroid,
    } as const,
  } as const,

  cardStyleInterpolators: { simpleFade: ({ current }) => ({ cardStyle: { opacity: current.progress } }) },
};
