import * as React from 'react';
import { Colors } from '../config/Theme';
import Toolbar from './react-native-material-ui/Toolbar/Toolbar.react';
import { HeaderBackButton } from './HeaderBackButton';
import { alaHeaderTextStyle } from '../components/Text';

type THeaderSearchableProps = {
  title: string;
  searchPlaceholder: string;
  onSearchTextChanged: (search: string) => void;
  onSearchClosed: () => void;
  onBackPressed: () => void;
  backLabel?: string;
  autoSearch?: boolean;
};

export const HeaderSearchable = ({
  title,
  searchPlaceholder,
  onSearchTextChanged,
  onSearchClosed,
  onBackPressed,
  backLabel,
  autoSearch = false,
}: THeaderSearchableProps) => {
  const [searchActive, setSearchActive] = React.useState(autoSearch);

  const _onSearchPressed = React.useCallback(() => {
    setSearchActive(true);
  }, [setSearchActive]);

  const _onSearchClosed = React.useCallback(() => {
    setSearchActive(false);
  }, [setSearchActive]);

  const _onSearchClosedRequested = React.useCallback(() => {
    if (autoSearch) {
      return onBackPressed();
    }
    return onSearchClosed();
  }, [autoSearch, onBackPressed]);
  return (
    <Toolbar
      style={toolbarStyle}
      onLeftElementPress={onBackPressed}
      leftElement={(
        <HeaderBackButton
          label={backLabel}
          onPress={onBackPressed}
        />
      )}
      isSearchActive={searchActive}
      centerElement={title}
      searchable={{
        autoFocus: true,
        placeholder: searchPlaceholder,
        onChangeText: onSearchTextChanged,
        onSearchCloseRequested: _onSearchClosedRequested,
        onSearchPressed: _onSearchPressed,
        onSearchClosed: _onSearchClosed,
      }}
    />
  );
};

const leftRightElement = 50;

const toolbarStyle = {
  container: {
    backgroundColor: Colors.white,
    color: Colors.black,
    borderBottomWidth: 1,
    borderBottomColor: 'rgb(216, 216, 216)',
    boxShadow: 'rgb(216 216 216) 0px 0px 0px',
  },
  centerElementContainer: { color: Colors.black },
  titleText: {
    color: Colors.black,
    ...alaHeaderTextStyle,
  },
  leftElementContainer: {
    color: Colors.black,
    width: leftRightElement,
  },
  leftElement: { color: Colors.black },
  rightElement: { color: Colors.black },
  rightElementContainer: {
    color: Colors.black,
    width: leftRightElement,
    justifyContent: 'flex-end',
  },
};
