import * as React from 'react';
import * as _ from 'lodash';
import { FIELD_ID } from '../../../../core/src/db/DbDefs';
import Swipeable from 'react-native-swipeable';
import { ListItemDeleteButton } from '../buttons/ListItemDeleteButton';
import {
  OpenOrderListSectionIndex0,
  OpenOrderListSectionIndex1,
  OpenOrderListSectionIndexOther,
} from './OpenOrderListSectionIndex';
import { OrderItemExtrasList } from './OrderItemExtrasList';
import { OrderItemHeader } from './OrderItemHeader';
import { StyledComponentsTheme } from '../../lib-components/theme/StyledComponentsTheme';
import { TOrder } from '../../../../core/src/models/db/order/OrderTypes';
import { TOrderItem } from '../../../../core/src/models/db/orderItem/OrderItemTypes';
import { SectionList, SectionListData, SectionListRenderItemInfo, View } from 'react-native';
import { mapOrderItemsToPrecedenceSections } from '../../lib/order/OrderPrecedenceSorter';
import { useOnOrderItemDelete } from '../hooks/ModelHooks';
import styled from 'styled-components/native';
import { AlaTouchableOpacity } from '../../lib-components/Touchable';
import { LockedOrdersListConst } from './LockedOrdersListConst';
import { MarginedUnderline } from '../../lib-components/Underline';

export type TOpenOrderListProps = {
  openOrder: TOrder;
  onOrderItemPress: (orderItem: TOrderItem) => void;
  renderStaticListHeader?: React.ReactNode;
  layoutInvalidationKey?: string;
  shouldRenderData?: boolean;
};

export const OpenOrderList = ({
  openOrder,
  onOrderItemPress,
  renderStaticListHeader,
  shouldRenderData = true,
}: TOpenOrderListProps) => {
  const data = mapOrderItemsToPrecedenceSections({
    orderItemsData: _.values<TOrderItem>(openOrder.items),
    sectionCount: 3,
  });

  const onOrderItemDelete = useOnOrderItemDelete({ orderData: openOrder });
  const { theme } = React.useContext(StyledComponentsTheme.Context);
  const deleteButtonWidth = theme.scaler.verticalScale(96);

  const renderOrderItem = React.useCallback(({ item }: SectionListRenderItemInfo<TOrderItem>) => {
    if (!shouldRenderData) {
      return null;
    }

    return (
      <SectionItemRoot>
        <Swipeable
          rightButtonWidth={96}
          rightButtons={[
            <ListItemDeleteButton
              key={`${item[FIELD_ID]}-delete`}
              width={deleteButtonWidth}
              onPress={() => onOrderItemDelete(item)}
            />,
          ]}>
          <AlaTouchableOpacity
            item={item}
            onPress={() => onOrderItemPress(item)}>
            <SectionItemContent
              height={LockedOrdersListConst.calcSingleItemHeight(item)}>
              <OrderItemRoot>
                <OrderItemHeader orderItem={item}/>
                <OrderItemExtrasList orderItem={item}/>
              </OrderItemRoot>
            </SectionItemContent>
          </AlaTouchableOpacity>
        </Swipeable>
      </SectionItemRoot>
    );
  }, [shouldRenderData]);

  const renderPrecedenceSectionHeader = React.useCallback(({ section: { precedenceNumber } }: { section: SectionListData<TOrderItem> }) => {
    function render() {
      if (precedenceNumber <= 0) {
        return <OpenOrderListSectionIndex0 />;
      }

      if (precedenceNumber == 1) {
        return <OpenOrderListSectionIndex1 />;
      }

      return <OpenOrderListSectionIndexOther />;
    }

    return (
      <SectionTitleContainer
        key={`header-${precedenceNumber}`}
        height={calculateSectionHeaderHeight()}
      >
        {render()}
      </SectionTitleContainer>
    );
  }, []);

  const ListHeaderComponent = React.useMemo(() => () => <>{renderStaticListHeader}</>, [renderStaticListHeader]);

  return (
    <StyledRoot>
      <SectionList<TOrderItem>
        ListHeaderComponent={ListHeaderComponent}
        sections={data}
        ItemSeparatorComponent={MarginedUnderline}
        keyExtractor={(item) => item[FIELD_ID]}
        renderItem={renderOrderItem}
        renderSectionHeader={renderPrecedenceSectionHeader}
      />
    </StyledRoot>
  );
};

function calculateSectionHeaderHeight() {
  return (itemVerticalPadding * 2) + sectionTextLineHeight;
}

// flex 1 needed for DraggableSectionList to fill parent
const StyledRoot = styled(View)`
  flex: 1;
`;

const itemVerticalPadding = 10;
const sectionTextLineHeight = 14;
const OrderItemRoot = styled(View)`
  background-color: ${({ theme }) => theme.t.col.white};
  flex: 1;
  padding: ${({ theme }) => theme.scaler.verticalScale(itemVerticalPadding)}px 0;
`;

const SectionTitleContainer = styled(View)<{ height?: number }>`
  ${({ height }) => (height ? `height: ${height}px;` : '')};
`;

const SectionItemRoot = styled(View)`
`;

const SectionItemContent = styled(View)<{ height?: number }>`
  ${({ height }) => (height ? `height: ${height}px;` : '')};
`;
