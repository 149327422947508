import { TState } from '../redux/Store';
import {
  RCStackScreenProps,
  RouteContract,
  TOutNavParams,
  TRCMergedCheckedProps,
  TRCOwnProps,
} from '../lib/routeContract/RouteContract';
import { CardStyleInterpolators, StackNavigationOptions } from '@react-navigation/stack';
import { TNavigationParamsWithMethod } from '../lib/navigation/Navigation';
import { Host } from '../../../core/src/models/db/host/Host';
import { CheckPropsResultBuild } from '../lib/routeContract/RouteContractCheckProps';

export class RouteHostMenuContract extends RouteContract<'ScreenHostMenu'> {
  protected readonly routeName = 'ScreenHostMenu' as const;

  onBeforeRemoveShouldAllowBack(props: TRCMergedCheckedProps<'ScreenHostMenu'>) {
    return true;
  }

  navigationOptions(props: RCStackScreenProps<TOutNavParams<'ScreenHostMenu'>>, navParams: TOutNavParams<'ScreenHostMenu'>): StackNavigationOptions {
    return {
      // We're using the search bar header
      headerShown: false,
      cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
    };
  }

  shouldSkipToRoute(state: TState, ownProps: TOutNavParams<'ScreenHostMenu'>): TNavigationParamsWithMethod<any> | undefined {
    return undefined;
  }

  mapStateToProps(state: TState, navParams: TOutNavParams<'ScreenHostMenu'>) {
    const host = state.host.hostData;
    const menuId = navParams.pathParams.menuId;
    const menuData = host && Host.findMenuById(host, menuId);
    return {
      menuId,
      locale: state.app.locale,
      language: state.app.language,
      host,
      guestSession: state.guestSession.guestSessionData,
      openOrder: state.guestSession.openOrder,
      menuData,
    };
  }

  mapDispatchToProps(dispatch: any, ownProps: TRCOwnProps<'ScreenHostMenu'>) {
    return {};
  }

  checkProps(props: ReturnType<this['mapStateToProps']>) {
    const {
      host,
      menuData,
    } = props;

    if (host == null) {
      return CheckPropsResultBuild.WAIT();
    }

    if (menuData == null) {
      return CheckPropsResultBuild.WAIT();
    }

    return CheckPropsResultBuild.READY({
      ...props,
      host,
      menuData,
    });
  }
}
