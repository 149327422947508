import * as React from 'react';
import * as _ from 'lodash';
import { Colors } from '../config/Theme';
import { FIELD_ID, FIELD_NAME } from '../../../core/src/db/DbDefs';
import { Screen } from '../lib-components/screen/Screen';
import { FullScreenRequestTemplate } from '../lib-components/FullScreenRequestTemplate';
import { Host } from '../../../core/src/models/db/host/Host';
import { Strings } from '../../../core/src/locale/Strings';
import { TGuestSession } from '../../../core/src/models/db/guestSession/GuestSessionTypes';
import { parseIntOrDefault } from '../../../core/src/lib/HelperFunctions';
import { GuestSessionBuilder } from '../../../core/src/models/db/guestSession/GuestSessionBuilder';
import { EExitHostButtonTheme, ExitHostButton } from '../components/host/ExitHostButton';
import { FullScreenRedirect } from '../lib-components/FullScreenRedirect';
import { RCComp } from '../lib/routeContract/RCComp';
import { RoutesCliGuest } from '../../../core/src/lib/apis/routes/RoutableCliGuest';

export class ScreenSetupGuestSession extends RCComp<'ScreenSetupGuestSession'> {
  protected routeName = 'ScreenSetupGuestSession' as const;

  private shouldRequestTableNumber = () => {
    return !this.props.tableRequested && this.props.guestSession == null;
  };

  private shouldRequestGuestCount = () => {
    return !this.props.guestCountRequested && this.props.guestSession != null;
  };

  render() {
    const { locale } = this.props;
    return (
      <Screen
        backgroundColor={Colors.white}
        usesReactNavigationHeader={false}
        hasStatusBar={true}
        statusBarBackgroundColor={this.shouldRequestTableNumber() ? Colors.white : Colors.accentDark}
        statusBarBackgroundColorIsLight={!this.shouldRequestTableNumber()}
      >
        <ExitHostButton
          themeStyle={this.shouldRequestTableNumber()
            ? EExitHostButtonTheme.backgroundLight
            : EExitHostButtonTheme.backgroundDark}
          locale={locale}
        />
        {this.renderContent()}
      </Screen>
    );
  }

  renderContent() {
    const {
      locale,
      hostData,
      guestSession,
    } = this.props;

    if (this.shouldRequestTableNumber()) {
      return (
        <FullScreenRequestTemplate.View
          locale={locale}
          key="renderTableNumberRequest"
          keyboardType={'numeric'}
          mapValueToField={FullScreenRequestTemplate.mappers.intParser}
          onContinue={this.onTableNameSet}
          mapResultToErrorMsg={this.validateTableNumber}
          titlePrefix={Strings.gWelcome()(locale)}
          title={hostData[FIELD_NAME]}
          request={Strings.gTableNr()(locale)}
          resultPlaceholder={Strings.gTableNrPhd()(locale)}
        />
      );
    }

    if (this.shouldRequestGuestCount()) {
      return (
        <FullScreenRequestTemplate.View
          key="renderGuestCountRequest"
          darkView
          locale={locale}
          keyboardType={'numeric'}
          mapValueToField={FullScreenRequestTemplate.mappers.intParser}
          onContinue={this.onNumberOfGuestsConfirm}
          syncingActive={true}
          value={`${_.get(guestSession, 'numberOfGuests', '')}`}
          onValueChange={this.onNumberOfGuestsChange}
          mapResultToErrorMsg={this.validateGuestCount}
          titlePrefix={Strings.gWelcome()(locale)}
          title={hostData[FIELD_NAME]}
          request={Strings.gTableGuestsNr()(locale)}
          resultPlaceholder={Strings.gGuestsNrPhd()(locale)}
        />
      );
    }

    return (
      <FullScreenRedirect
        routeName={RoutesCliGuest.ScreenHostHome.name}
        params={{
          params: {},
          pathParams: {},
        }}
        method={'reset'}
      />
    );
  }

  private onNumberOfGuestsChange = async (guestCount: string) => {
    const { guestSession } = this.props;
    await GuestSessionBuilder.fromItem(guestSession as TGuestSession)
      .setGuestCount(parseIntOrDefault(guestCount, 0));
  };

  private onNumberOfGuestsConfirm = async (guestCount: string) => {
    const { guestSessionSetSetupFlags } = this.props;

    guestSessionSetSetupFlags({ guestCountRequested: true });
  };

  private onTableNameSet = async (tableName: string) => {
    const {
      hostData,
      guestSessionSetTableId,
    } = this.props;

    const table = Host.findTableByName(hostData, tableName);
    table && await guestSessionSetTableId({ tableId: table[FIELD_ID] });
  };

  private validateTableNumber = (tableName?: string) => {
    const { locale, hostData } = this.props;

    if (!tableName) {
      return Strings.gTableNrEmptyMsg()(locale);
    }

    const table = Host.findTableByName(hostData, tableName);
    if (!table) {
      return Strings.gTableNrNonExistentMsg()(locale);
    }

    return undefined;
  };

  private validateGuestCount = (guestCount?: string) => {
    const { locale } = this.props;
    if (!guestCount) {
      return Strings.gGuestCountMissing()(locale);
    }
    if (parseInt(guestCount, 10) <= 0) {
      return Strings.gGuestCountSmallerThanOne()(locale);
    }

    return undefined;
  };
}
