import * as React from 'react';
import { CheckoutList } from '../components/checkout/CheckoutList';
import { Colors } from '../config/Theme';
import { Screen } from '../lib-components/screen/Screen';
import { GuestSession } from '../../../core/src/models/db/guestSession/GuestSession';
import { GuestSessionCheckoutUnderlay } from '../components/orderUnderlays/GuestSessionCheckoutUnderlay';
import { NavTitleHandler } from '../lib/navigation/NavTitleHandler';
import { Order } from '../../../core/src/models/db/order/Order';
import { Strings } from '../../../core/src/locale/Strings';
import { TOrderItem } from '../../../core/src/models/db/orderItem/OrderItemTypes';

import { GuestSessionBuilder } from '../../../core/src/models/db/guestSession/GuestSessionBuilder';
import { HostSubscription } from '../../../core/src/models/db/hostSubscription/HostSubscription';
import { ESubscriptionFeatureFlags } from '../../../core/src/models/db/hostSubscription/HostSubscriptionTypes';
import { Navigation } from '../lib/navigation/Navigation';
import { RCComp } from '../lib/routeContract/RCComp';
import { RoutesCliGuest } from '../../../core/src/lib/apis/routes/RoutableCliGuest';
import { FIELD_ID } from '../../../core/src/db/DbDefs';

export class RouteHostOrderCheckoutComp extends RCComp<'ScreenHostOrderCheckout'> {
  protected readonly routeName = 'ScreenHostOrderCheckout' as const;

  private readonly navTitleHandler = new NavTitleHandler((props: this['props']) => {
    return Strings.gOrderSummaryPage()(props.locale);
  });

  constructor(props, context) {
    super(props, context);
    this.navTitleHandler.apply(props);
  }

  componentDidUpdate() {
    this.navTitleHandler.apply(this.props);
  }

  private onActiveOrderItemPress = (orderItem: TOrderItem) => {
    Navigation.routePush(RoutesCliGuest.ScreenHostOrderItemEdit.name, {
      params: { orderItemData: orderItem },
      pathParams: { orderItemId: orderItem[FIELD_ID] },
    });
  };

  private onOrder = async () => {
    await this.props.guestSessionCompleteOrderToBatching({ openOrder: this.props.openOrder });

    Navigation.routePush(RoutesCliGuest.ScreenHostOrderRequestComplete.name);
  };

  private onBillRequestPress = async () => {
    await this.props.guestSessionRequestBill({ guestSession: this.props.guestSessionData });

    Navigation.routePush(RoutesCliGuest.ScreenHostBillRequestComplete.name);
  };

  render() {
    const {
      locale,
      hostPublicSubscription,
      hostData,
      guestSessionData,
      openOrder,
    } = this.props;

    // Order press is enabled if the open orderUnderlays has items
    const orderIsOpenAndHasItems = Order.orderIsOpen(openOrder) && Order.orderHasItems(openOrder);

    // Bill press is enabled if there are locked orders with items
    // And orderPressEnabled is false
    const guestSession = GuestSessionBuilder.fromItem(guestSessionData);
    const billRequestEnabled = !orderIsOpenAndHasItems
      && guestSession.hasLockedOrdersWithItems()
      && !GuestSession.isBillRequested(guestSessionData);
    return (
      <Screen
        backgroundColor={Colors.white}
        usesReactNavigationHeader={true}
        hasStatusBar={true}
        statusBarBackgroundColor={Colors.white}
        statusBarBackgroundColorIsLight={true}
        underlayHeight={195}
        underlay={HostSubscription.hasAccessToFeature(hostPublicSubscription, ESubscriptionFeatureFlags.guestOrders) && (
          <GuestSessionCheckoutUnderlay
            locale={locale}
            hostData={hostData}
            guestSessionData={guestSessionData}
            openOrderData={openOrder}
            orderPressEnabled={orderIsOpenAndHasItems}
            billRequestEnabled={billRequestEnabled}
            onBillRequestPress={this.onBillRequestPress}
            onOrder={this.onOrder}
          />
        )}
      >
        <CheckoutList
          locale={locale}
          guestSessionData={guestSessionData}
          openOrder={openOrder}
          billRequestEnabled={billRequestEnabled}
          onActiveOrderItemPress={this.onActiveOrderItemPress}
        />
      </Screen>
    );
  }
}

