import * as _ from 'lodash';
import { FIELD_ID } from '../../../../core/src/db/DbDefs';
import { HostMainMenuButton } from './HostMainMenuButton';
import { HostMenuList } from '../menu/HostMenuList';
import { Menu } from '../../../../core/src/models/db/menu/Menu';
import { MenuSearchedEmptyState } from '../menu/MenuSearchedEmptyState';
import {
  ScrollView,
  View,
} from 'react-native';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import { TMenuId } from '../../../../core/src/models/db/menu/MenuTypes';
import { TMenuItem } from '../../../../core/src/models/db/menuItem/MenuItemTypes';
import { dbItemSortableItemSort } from '../../../../core/src/db/DbLib';
import React from 'react';
import styled from 'styled-components/native';
import { useCurrentLanguage } from "../../hooks/hooks";

type THostMainMenuProps = {
  hostData: THost;
  onMenuPress?: (menuId: TMenuId) => void;
  onMenuItemPress?: (menu: TMenuItem) => void;
  filterString?: string;
};

export const HostMainMenu = ({
  hostData,
  onMenuPress,
  onMenuItemPress,
  filterString,
}: THostMainMenuProps) => {
  const language = useCurrentLanguage();
  const filteredMenu = Menu.filterByString(hostData.menu, filterString, language);

  const secondaryMenu = Menu.getSecondaryMenu(filteredMenu);
  const secondarySubMenusObj = _.get(secondaryMenu, 'subMenus', {});
  const secondarySubMenusArr = _.values(secondarySubMenusObj)
    .sort(dbItemSortableItemSort);

  const firstSubMenu = _.first(secondarySubMenusArr) || filteredMenu;
  const [selectedSubMenuId, setSelectedSubMenuId] = React.useState<TMenuId>(firstSubMenu[FIELD_ID]);
  return (
    <Root>
      <MenusContScrollView
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        horizontal={true}>
        {secondarySubMenusArr
          .map((subMenu) => (
            <HostMainMenuButton
              key={subMenu[FIELD_ID]}
              menu={subMenu}
              buttonHeight={horizontalMenuHeight}
              onButtonPress={setSelectedSubMenuId}
              isSelected={subMenu[FIELD_ID] === selectedSubMenuId}/>
          ))}
      </MenusContScrollView>
      {secondarySubMenusArr.length <= 0 || !secondarySubMenusObj[selectedSubMenuId] ? (
        <MenuSearchedEmptyState/>
      ) : (
        <HostMenuList
          key={selectedSubMenuId}
          host={hostData}
          menu={secondarySubMenusObj[selectedSubMenuId]}
          items={hostData.items || {}}
          onMenuPress={onMenuPress}
          onMenuItemPress={onMenuItemPress}
        />
      )}
    </Root>
  );
};

const horizontalMenuHeight = 60;

const Root = styled(View)`
  background-color: ${({ theme }) => theme.t.col.white};
  flex: 1;
`;

const MenusContScrollView = styled(ScrollView)`
  background-color: ${({ theme }) => theme.t.col.accentDark};
  height: ${horizontalMenuHeight}px;
  max-height: ${horizontalMenuHeight}px;
  min-height: ${horizontalMenuHeight}px;
`;
