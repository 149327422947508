import { Log } from '../config/Instance';
import {
  call,
  spawn,
} from 'redux-saga/effects';

export function* sagaSpawnAndKeepAlive<T extends (
  ...args: any[]) => any>(
  name: string,
  saga: T,
  ...args: Parameters<T>
) {
  Log.v('sagaSpawnAndKeepAlive', 'sagaSpawnAndKeepAlive', `${name} starting`);
  yield spawn(function* () {
    while (true) {
      try {
        yield call(saga, ...args);
        break;
      } catch (e) {
        Log.e('sagaSpawnAndKeepAlive', 'sagaSpawnAndKeepAlive', `${name} threw, ${e.message}`);
      }
    }
  });
}
