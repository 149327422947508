import { TRKHostPublicSubscription } from '../../../db/DbResources';
import { HostPublicSubscriptionBuilder } from './HostPublicSubscriptionBuilder';
import { MObj } from '../../../lib/model/MObj';
import { THostPublicSubscription } from './HostPublicSubscriptionTypes';

export class HostPublicSubscription extends MObj<TRKHostPublicSubscription> {
  constructor(hostPublicSubscription: THostPublicSubscription) {
    super(HostPublicSubscriptionBuilder, hostPublicSubscription);
  }
}
