/**
 * // todo obsolete comment
 * All secrets needed by all apps, there should be no references to process.env outside of this file
 * This allows us to inject configs/secrets from anywhere, mainly CI for staging/prod deployment and for tests
 * ===
 * - This object should be fulfilled by 1 environment variable ALACARTE_SECRETS_JSON
 * - The hardcoded config is the one used for local development (Testing)
 */
import * as _ from 'lodash';
import { Logger } from '../lib/Logger';
import { Process } from '../config/Process';
import { SecretLoaders } from './SecretLoaders';
import { corePackageSecrets } from '../config/corePackageSecrets';
import { isProduction } from '../lib/Environment';

const Log = new Logger({
  name: '@alacarte/core',
  env: Process.env.NODE_ENV,
  isCI: Process.env.CI,
});

export enum ESecretInspectionLevel {
  /**
   * Does nothing on missing or extra secret
   */
  NONE = 0,
  /**
   * Only logs on missing or extra secret
   */
  LOG = 2,
  /**
   * Throws exception on missing or extra secret
   */
  STRICT = 3,
}

type TSecretParams<T> = {
  packageSecretKeys: T;
  secretInspectionLevel: ESecretInspectionLevel;
};

const additionalLibKeys = [
  corePackageSecrets,
];

type MergedSec<T> = T
& typeof corePackageSecrets;

const defaultParams = {
  packageSecretKeys: {},
  secretInspectionLevel: isProduction(Process.env.NODE_ENV)
    ? ESecretInspectionLevel.STRICT
    : ESecretInspectionLevel.LOG,
};

export class Secrets<T> {
  readonly params: TSecretParams<T>;

  private readonly instanceSecretKeys: MergedSec<T>;

  private readonly secretInspectionLevel: ESecretInspectionLevel;

  /**
   * Only loaded when needed
   * By not calling .val right away we can access instanceSecretKeys to inject the needed
   * env variables from the CI
   */
  private cachedSecrets: MergedSec<T> | undefined;

  get val(): MergedSec<T> {
    if (this.cachedSecrets == null) {
      this.cachedSecrets = this.getSecrets(this.instanceSecretKeys);
    }
    return this.cachedSecrets;
  }

  private static applyDefaults(params: Partial<TSecretParams<any>> | undefined, defaults: TSecretParams<any>): TSecretParams<any> {
    return {
      packageSecretKeys: _.get(params, 'packageSecretKeys', defaults.packageSecretKeys),
      secretInspectionLevel: _.get(params, 'secretInspectionLevel', defaults.secretInspectionLevel) as any,
    };
  }

  constructor(params: Partial<TSecretParams<T>>) {
    this.params = Secrets.applyDefaults(params, defaultParams as any);
    this.instanceSecretKeys = _.merge(this.params.packageSecretKeys, ...additionalLibKeys) as MergedSec<T>;
    this.secretInspectionLevel = this.params.secretInspectionLevel;
  }

  readonly invalidateCaches = () => {
    this.cachedSecrets = undefined;
    Log.wtf('Secrets', 'invalidateCaches', 'Caches invalidated');
  };

  readonly reloadWithParams = (params: Partial<TSecretParams<T>>) => {
    return new Secrets<any>(Secrets.applyDefaults(params, this.params));
  };

  private getSecrets(expectedSecrets: any): MergedSec<T> {
    const {
      decSecrets,
      missingSecrets,
    } = SecretLoaders.loadSecretsJsonFromEnv<MergedSec<T>>();

    // Ensure all needed secrets are provided
    const expectedSecretKeys = Object.keys(expectedSecrets);
    expectedSecretKeys.forEach((expectedSecretKey) => {
      if (missingSecrets.includes(expectedSecretKey)) {
        this.handleInspection('getSecrets', `Expected missing secret "${expectedSecretKey}"`);
      }
    });

    // Ensure there are no extra secrets
    Object.keys(decSecrets)
      .filter((secretKey) => !_.isEmpty(decSecrets[secretKey]))
      .forEach((providedSecretKey) => {
        if (!expectedSecretKeys.includes(providedSecretKey)) {
          // this.handleInspection('getSecrets', `Config has more secrets than are needed ${providedSecretKey}`);
          delete decSecrets[providedSecretKey];
        }
      });

    return decSecrets;
  }

  private readonly handleInspection = (funcName: string, ...msgs: string[]) => {
    if (this.secretInspectionLevel >= ESecretInspectionLevel.LOG) {
      Log.wtf('Secrets', funcName, ...msgs);
    }

    if (this.secretInspectionLevel >= ESecretInspectionLevel.STRICT) {
      throw new Error(['Secrets.ts', funcName, ...msgs].join(', '));
    }
  };
}
