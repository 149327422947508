import { DbRes } from '../../../db/DbResources';
import { FIELD_ID } from '../../../db/DbDefs';
import { GuestSessionCreator } from '../guestSession/GuestSessionCreator';
import { MObj } from '../../../lib/model/MObj';
import { Refs } from '../../../db/DbRefs';
import { TGuestSession } from '../guestSession/GuestSessionTypes';
import { TRoomId } from '../room/RoomTypes';
import { TTable } from './TableTypes';
import {
  TTablePathBuilderParams,
  TableBuilder,
} from './TableBuilder';
import { GuestSessionBuilder } from '../guestSession/GuestSessionBuilder';

export class Table extends MObj<typeof DbRes.Table> {
  static async getOrSetOpenGuestSession(pbp: TTablePathBuilderParams): Promise<TGuestSession> {
    const guestSession = await Refs.guestSession(pbp).transaction((gs) => {
      if (gs != null) {
        return undefined;
      }

      return GuestSessionCreator.buildNewForTable(pbp, { [FIELD_ID]: pbp.tableId });
    });

    if (!guestSession) {
      throw Error(['Table', 'getOrSetOpenGuestSession', `Failed to open GuestSession in path ${JSON.stringify(pbp)}`].join(', '));
    }
    return guestSession;
  }

  constructor(table: TTable) {
    super(TableBuilder, table);
  }

  async hasOpenGuestSessions() {
    const pbp = this.builder.getPathBuilderParamsFromDataPath(this.item);
    return GuestSessionBuilder.dataExistsById(pbp);
  }

  async updateInternalNote(internalNote: string) {
    return this.updateFields({ internalNote });
  }

  async updateRoomId(newRoomId: TRoomId) {
    return this.updateFields({ roomId: newRoomId });
  }
}
