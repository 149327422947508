import * as React from 'react';
import { Dimensions, ScaledSize } from 'react-native';
import { selectPlatform } from '../../lib/Platform';
import { ReactX } from '../../lib/ReactX';
import { ReactNativeStatusBarHeight } from './react-native-status-bar-height';

type TBuildDimensionsParams = {
  scaledSize: ScaledSize;
};

function buildDimensions({ scaledSize }: TBuildDimensionsParams) {
  const {
    width,
    fontScale,
    scale,
  } = scaledSize;

  const screenHeight = Dimensions.get('screen').height;
  const windowHeight = Dimensions.get('window').height;

  const statusBarHeight = ReactNativeStatusBarHeight.getStatusBarHeight();
  const actionBarHeight = selectPlatform({
    ios: 64,
    android: 54,
    web: 65,
    other: 65,
  });

  const navigationBarHeight = screenHeight - windowHeight;
  const contentHeight = screenHeight - statusBarHeight - actionBarHeight;
  const drawableHeight = statusBarHeight + actionBarHeight + contentHeight - navigationBarHeight;

  return {
    width,
    windowHeight,
    fontScale,
    scale,
    actionBarHeight,
    statusBarHeight,
    contentHeight,
    navigationBarHeight,
    drawableHeight,
  };
}

export type TDimensions = ReturnType<typeof buildDimensions>;

export function useGetDimensions(): TDimensions {
  const scaledSize = ReactX.useWindowDimensions();
  const dimensions = buildDimensions({ scaledSize });
  return React.useMemo(() => dimensions, [
    dimensions.width,
    dimensions.windowHeight,
    dimensions.fontScale,
    dimensions.scale,
    dimensions.actionBarHeight,
    dimensions.statusBarHeight,
    dimensions.contentHeight,
    dimensions.navigationBarHeight,
    dimensions.drawableHeight,
  ]);
}

export type TScaler = ReturnType<typeof buildScaler>;

export function buildScaler({ width, windowHeight }: TDimensions) {
  // Base sizes are based on alacarte's figma view
  const baseWidth = 375;
  const baseHeight = 812;

  const scale = (size: number) => (width / baseWidth) * size;
  const verticalScale = (size: number) => (windowHeight / baseHeight) * size;
  const moderateScale = (size: number, factor = 0.5) => size + (scale(size) - size) * factor;
  const textScale = (fontSize: number) => (fontSize * windowHeight) / baseHeight;

  return {
    scale,
    textScale,
    verticalScale,
    moderateScale,
  };
}

class DimBase {
  RFPercentage = (dimensions: TDimensions, percent: number) => {
    const { drawableHeight } = dimensions;
    const heightPercent = (percent * drawableHeight) / 100;
    return Math.round(heightPercent);
  };

  // guideline height for standard 5" device screen is 680
  RFPercentageScale = (dimensions: TDimensions, percent: number, standardScreenHeight = 896) => {
    const { scale } = dimensions;
    const normalizedPercentage = this.RFPercentage(dimensions, percent);
    return (100 * normalizedPercentage) / (standardScreenHeight * scale);
  };

  // guideline height for standard 5" device screen is 680
  RFValue = (dimensions: TDimensions, fontSize: number, standardScreenHeight = 896) => {
    const { drawableHeight } = dimensions;
    const heightPercent = (fontSize * drawableHeight) / standardScreenHeight;
    return Math.round(heightPercent);
  };
}

export const Dim = new DimBase();
