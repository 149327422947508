import * as React from 'react';
import * as _ from 'lodash';
import {
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { Log } from '../config/Log';
import { promisify } from '../../../core/src/lib/HelperFunctions';
import { Camera } from 'expo-camera';
import styled from 'styled-components/native';
import { Strings } from '../../../core/src/locale/Strings';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { useCurrentLocale } from "../hooks/hooks";

export type TQRScannerProps = {
  onScanData: (data: any) => Promise<any>;
  reactivateOnFail?: boolean;
};

export const QRScanner = ({
  onScanData,
  reactivateOnFail,
}: TQRScannerProps) => {
  const [hasPermission, setHasPermission] = React.useState<null | boolean>(null);

  React.useEffect(() => {
    (async () => {
      // todo Strings.mCameraPermissionsDescription
      const { status } = await Camera.requestPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
  }, []);

  const qrScannerRef = React.useRef(null);

  let timeout: NodeJS.Timeout | null = null;
  const stopTimeout = React.useCallback(() => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = null;
  }, [timeout]);

  // Clear timeout on unmount
  React.useEffect(() => stopTimeout, [stopTimeout]);
  const locale = useCurrentLocale();

  const onSuccess = React.useCallback(async ({ data }: { data: string }) => {
    try {
      await promisify(onScanData(data));
    } catch (e) {
      Log.v('QRScanner', 'QRScanner', 'Parse error', e && e.message);
      //
    }
    if (!timeout) {
      // @ts-ignore
      timeout = setTimeout(() => {
        // Reactivate and clear timeout
        _.invoke(qrScannerRef, 'current.reactivate');
        stopTimeout();
      }, 2500);
    }
  }, [reactivateOnFail, stopTimeout]);

  if (hasPermission === null) {
    return <View/>;
  }

  if (!hasPermission) {
    return (
      <Text>
        {Strings.errPermissionsMissing()(locale)}
      </Text>
    );
  }

  return (
    <Root>
      <Camera
        autoFocus={Camera.Constants.AutoFocus.on}
        style={styles.camera}
        type={Camera.Constants.Type.back}
        barCodeScannerSettings={{ barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr] }}
        onBarCodeScanned={onSuccess}
        // onCameraReady={(...params) => undefined}
        onMountError={(...params) => {
          Log.v('QRScanner', 'QRScanner', 'onMountError from Camera');
        }}
      >
        <CameraContentView>
          <CameraContentSpacerMain>
            <CameraQrSquareView/>
          </CameraContentSpacerMain>
          <CameraContentSpacerSecondary>
            <CamerContentBottomText>
              {Strings.gScanQR()(locale)}
            </CamerContentBottomText>
          </CameraContentSpacerSecondary>
        </CameraContentView>
      </Camera>
    </Root>
  );
};

QRScanner.defaultProps = { reactivateOnFail: true };

const Root = styled(View)`
  flex: 1;
`;

const contentFlex = 1;
const contentMainFlex = 0.75;

const CameraContentView = styled(View)`
  align-items: center;
  flex: ${contentFlex};
  justify-content: flex-end;
`;

const CameraContentSpacerMain = styled(View)`
  align-items: center;
  flex: ${contentMainFlex};
  justify-content: center;
`;

const CameraQrSquareView = styled(View)`
  border: 2px solid ${({ theme }) => theme.t.col.red};
  height: ${({ theme }) => _.min([theme.dim.width, theme.dim.drawableHeight]) * 0.70}px;
  width: ${({ theme }) => _.min([theme.dim.width, theme.dim.drawableHeight]) * 0.70}px;
`;

const CameraContentSpacerSecondary = styled(CameraContentSpacerMain)`
  flex: ${contentFlex - contentMainFlex};
`;

const CamerContentBottomText = styled(Text)`
  color: ${({ theme }) => theme.t.col.white};
  font-family: ${({ theme }) => theme.t.font.nunitoSans};
  font-size: 18px;
  font-weight: bold;
  line-height: 25px;
`;

const styles = StyleSheet.create({ camera: { flex: 1 } });

