export const Colors = {
  transparent: 'transparent',
  black: '#000000',
  white: '#FFFFFF',
  red: '#F15B40',
  yellow: '#EFB339',
  accentDark: '#2B2C33',
  accentLight: '#A6A7B3',
  greyB2: '#B2B2B2',
  aLaCarteTheme: '#FFDECD',
  modalBackgroundOverlay: 'rgba(0, 0, 0, 0.55)',
  lightGray1: '#D5D5D6',
  lightGray2: '#D5D5D6',
  lightGray3: '#F3F4F7',
  lightGray4: '#FAFBFC',
  lightGray5: '#D8D8D8',
  lightGray6: '#f0f0f0',
};

