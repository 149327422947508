import * as React from 'react';

// @ts-ignore
import { View } from 'react-native';

import { App as CGNApp } from '../../cli-guest-rn/src/App';

export const App = () => (
  <View style={{
    width: '100vw',
    height: '100vh',
  }}>
    <CGNApp/>
  </View>
);
