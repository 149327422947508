import * as React from 'react';
import {
  EMenuItemState,
  TMenuItem,
} from '../../../core/src/models/db/menuItem/MenuItemTypes';
import { MenuSearchedEmptyState } from './menu/MenuSearchedEmptyState';
import {
  StyleProp,
  ViewStyle,
} from 'react-native';
import { SwipeListView } from 'react-native-swipe-list-view';
import { TMenu } from '../../../core/src/models/db/menu/MenuTypes';
import { TOrder } from '../../../core/src/models/db/order/OrderTypes';
import { Underline } from '../lib-components/Underline';
import {
  dbItemIdableMap,
  dbItemNameableSort,
} from '../../../core/src/db/DbLib';
import { useRendererMenuItemItem } from './hooks/useRendererMenuItemItem';
import styled from 'styled-components/native';

type MenuItemListProps = {
  orderData?: TOrder;
  menuItems: TMenuItem[];
  onMenuItemPress?: (menuItem: TMenuItem) => void;
  style?: StyleProp<ViewStyle>;
};

export const MenuItemList = ({
  orderData,
  menuItems,
  onMenuItemPress,
  style,
}: MenuItemListProps) => {
  const activeItems = menuItems
    .filter(({ status }) => status === EMenuItemState.ACTIVE);

  const renderMenuItemListItem = useRendererMenuItemItem({
    orderData,
    onItemPress: onMenuItemPress,
  });

  if (activeItems.length <= 0) {
    return (
      <MenuSearchedEmptyState/>
    );
  }

  return (
    <StyledSectionList
      // @ts-ignore
      useSectionList
      style={style}
      sections={[
        {
          title: '',
          data: activeItems
            .sort(dbItemNameableSort),
        },
      ]}
      renderItem={renderMenuItemListItem}
      keyExtractor={dbItemIdableMap}
      removeClippedSubviews={false}
      ItemSeparatorComponent={Underline}
    />
  );
};

const StyledSectionList = styled(SwipeListView as new () => SwipeListView<TMenu | TMenuItem>)`

`;

