import * as _ from 'lodash';

export type TEnv = 'production' | 'development' | 'test';
const production = /^(prod|production)$/i;
const development = /^(dev|development)$/i;
const test = /^test$/i;

function getNodeEnv() {
  return _.get(process, 'env.NODE_ENV', 'development');
}

export function isProduction(val: string = getNodeEnv()) {
  return production.test(val);
}

export function isDevelopment(val: string = getNodeEnv()) {
  return development.test(val);
}

export function isTest(val: string = getNodeEnv()) {
  return test.test(val);
}
