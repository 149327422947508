import * as React from 'react';
import * as _ from 'lodash';
import { RouteContract, TRCMergedCheckedProps } from './RouteContract';
import { RCCompContracts } from '../navigation/routes/RCCompContracts';
import { Navigation, TNavigationParamsWithMethod } from '../navigation/Navigation';
import { EventArg } from '@react-navigation/core';
import { BackHandler } from 'react-native';
import { Log } from '../../config/Log';
import { TRoutesCliGuestNames } from '../../../../core/src/lib/apis/routes/RoutableCliGuest';

export type TBeforeRemoveEvent = EventArg<'beforeRemove', true, {
  action: {
    type: string;
    payload?: object | undefined;
    source?: string | undefined;
    target?: string | undefined;
  };
}>;

type TShouldAllowBack = {
  shouldAllowBack: boolean;
  navigateTo: TNavigationParamsWithMethod<any> | null;
};

export abstract class RCComp<RK extends TRoutesCliGuestNames, State = any> extends React.Component<TRCMergedCheckedProps<RK>, State> {
  protected abstract routeName: RK;

  constructor(props: TRCMergedCheckedProps<RK>, context: any) {
    super(props, context);
  }

  componentDidMount() {
    this.props.navigation.addListener('beforeRemove', this.handleBeforeRemove);
    BackHandler.addEventListener('hardwareBackPress', this.handleHardwareBackPress);
  }

  componentWillUnmount() {
    this.props.navigation.removeListener('beforeRemove', this.handleBeforeRemove);
    BackHandler.removeEventListener('hardwareBackPress', this.handleHardwareBackPress);
  }

  private readonly shouldAllowBack = (): TShouldAllowBack => {
    Log.v('RCComp', 'shouldAllowBack', `Checking if back allowed`);
    const currentRouteName = Navigation.getCurrentRouteName();
    const contract = RCCompContracts[currentRouteName] as RouteContract<RK>;
    if (!contract) {
      Log.v('RCComp', 'shouldAllowBack', `${currentRouteName} no contract found, allowing back`);
      return {
        shouldAllowBack: true,
        navigateTo: null,
      };
    }

    const shouldAllowBackResult = contract.onBeforeRemoveShouldAllowBack(this.props as any);
    Log.v('RCComp', 'shouldAllowBack', `${currentRouteName} onBeforeRemoveShouldAllowBack returned result ${shouldAllowBackResult}`);
    return {
      shouldAllowBack: !!shouldAllowBackResult,
      navigateTo: _.isBoolean(shouldAllowBackResult)
        ? null
        : shouldAllowBackResult,
    };
  };

  private readonly handleBeforeRemove = (event: TBeforeRemoveEvent) => {
    Log.v('RCComp', 'handleBeforeRemove', `Got beforeRemove event`);
    const res = this.shouldAllowBack();

    if (!res.shouldAllowBack) {
      Log.v('RCComp', 'handleBeforeRemove', `shouldAllowBack false, blocking`);
      event.preventDefault();
    }

    if (res.navigateTo != null) {
      Log.v('RCComp', 'handleBeforeRemove', `navigateTo ${res.navigateTo.routeName}`);
      Navigation.navigate(res.navigateTo.routeName, res.navigateTo.params);
    }

    return false;
  };

  private readonly handleHardwareBackPress = (): boolean => {
    Log.v('RCComp', 'handleHardwareBackPress', `Got hardwareBackPress event`);
    const res = this.shouldAllowBack();

    if (res.navigateTo != null) {
      Log.v('RCComp', 'handleHardwareBackPress', `navigateTo ${res.navigateTo.routeName}`);
      Navigation.navigate(res.navigateTo.routeName, res.navigateTo.params);
    }

    // Return true to block, false to allow
    return !res.shouldAllowBack;
  };
}

