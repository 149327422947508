import * as React from 'react';
import * as _ from 'lodash';
import { TObjList } from '../../../core/src/db/DbDefs';
import {
  TMenuItem,
  TMenuItemId,
} from '../../../core/src/models/db/menuItem/MenuItemTypes';
import { Host } from '../../../core/src/models/db/host/Host';
import {
  ListRenderItemInfo,
  StyleProp,
  ViewStyle,
} from 'react-native';
import { MenuItemBuilder } from '../../../core/src/models/db/menuItem/MenuItemBuilder';
import { MenuSearchedEmptyState } from './menu/MenuSearchedEmptyState';
import { SwipeListView } from 'react-native-swipe-list-view';
import { THost } from '../../../core/src/models/db/host/HostTypes';
import { TMenu } from '../../../core/src/models/db/menu/MenuTypes';
import { TOrder } from '../../../core/src/models/db/order/OrderTypes';
import { Underline } from '../lib-components/Underline';
import {
  dbItemIdableMap,
  dbItemNameableSort,
  dbItemSortableItemSort,
} from '../../../core/src/db/DbLib';
import { useRenderMenuListItem } from './hooks/useRenderMenuListItem';
import { useRendererMenuItemItem } from './hooks/useRendererMenuItemItem';
import styled from 'styled-components/native';

type SubMenusAndItemsListProps = {
  host: THost;
  orderData?: TOrder;
  menu: TMenu;
  items: TObjList<TMenuItemId, TMenuItem>;
  onMenuPress?: (menu: TMenu) => void;
  onMenuItemPress?: (menuItem: TMenuItem) => void;
  style?: StyleProp<ViewStyle>;
};

export const SubMenusAndItemsList = ({
  host,
  orderData,
  menu,
  items,
  onMenuPress,
  onMenuItemPress,
  style,
}: SubMenusAndItemsListProps) => {
  const renderMenuListItem = useRenderMenuListItem({
    onItemPress: onMenuPress,
    host,
  });

  const renderMenuItemItem = useRendererMenuItemItem({
    orderData,
    onItemPress: onMenuItemPress,
  });

  const renderMenuOrMenuItem = React.useCallback((params: ListRenderItemInfo<TMenu | TMenuItem>) => {
    return !MenuItemBuilder.isTMenuItem(params.item)
      ? renderMenuListItem(params as ListRenderItemInfo<TMenu>)
      : renderMenuItemItem(params as ListRenderItemInfo<TMenuItem>);
  }, [renderMenuListItem, renderMenuItemItem]);

  const subMenus = _.values(menu.subMenus);
  const activeItems = Host.getActiveMenuItemsForMenu({ items }, menu);

  if (subMenus.length <= 0 && activeItems.length <= 0) {
    return (
      <MenuSearchedEmptyState/>
    );
  }
  return (
    <StyledSectionList
      // @ts-ignore
      useSectionList
      style={style}
      sections={[
        {
          title: '',
          data: subMenus
            .sort(dbItemSortableItemSort),
        },
        {
          title: '',
          data: activeItems
            .sort(dbItemNameableSort),
        },
      ]}
      renderItem={renderMenuOrMenuItem}
      keyExtractor={dbItemIdableMap}
      removeClippedSubviews={false}
      ItemSeparatorComponent={Underline}
      SectionSeparatorComponent={Underline}
    />
  );
};

const StyledSectionList = styled(SwipeListView as new () => SwipeListView<TMenu | TMenuItem>)`

`;
