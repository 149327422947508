import { TKnownError } from '../ErrorTypes';

export function knownErrorPrioritySorter(ke1: TKnownError, ke2: TKnownError) {
  const prio1 = ke1.priority != null
    ? ke1.priority
    : 0;
  const prio2 = ke2.priority != null
    ? ke2.priority
    : 0;
  return prio2 - prio1;
}

export function knownErrorPriorityMapBuilder(multiplier = 1) {
  return (knownError: TKnownError) => {
    const priority = knownError.priority != null
      ? knownError.priority
      : 1;
    return {
      internalCode: knownError.internalCode,
      priority: priority * multiplier,
    };
  };
}
