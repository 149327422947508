import * as _ from 'lodash';
import moment from 'moment';

const base64ArrayBuffer = (function () {
  /* eslint-disable */
  // Converts an ArrayBuffer directly to base64, without any intermediate 'convert to string then
  // use window.btoa' step. According to my tests, this appears to be a faster approach:
  // http://jsperf.com/encoding-xhr-image-data/5

  /*
  MIT LICENSE
  Copyright 2011 Jon Leighton
  Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:
  The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
  */
  return function (arrayBuffer) {
    let base64 = '';
    const encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

    const bytes = new Uint8Array(arrayBuffer);
    const { byteLength } = bytes;
    const byteRemainder = byteLength % 3;
    const mainLength = byteLength - byteRemainder;

    let a;
    let b;
    let c;
    let
      d;
    let chunk;

    // Main loop deals with bytes in chunks of 3
    for (let i = 0; i < mainLength; i += 3) {
      // Combine the three bytes into a single integer
      chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

      // Use bitmasks to extract 6-bit segments from the triplet
      a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
      b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
      c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
      d = chunk & 63; // 63       = 2^6 - 1

      // Convert the raw binary segments to the appropriate ASCII encoding
      base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
    }

    // Deal with the remaining bytes and padding
    if (byteRemainder == 1) {
      chunk = bytes[mainLength];

      a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

      // Set the 4 least significant bits to zero
      b = (chunk & 3) << 4; // 3   = 2^2 - 1

      base64 += `${encodings[a] + encodings[b]}==`;
    } else if (byteRemainder == 2) {
      chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

      a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
      b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

      // Set the 2 least significant bits to zero
      c = (chunk & 15) << 2; // 15    = 2^4 - 1

      base64 += `${encodings[a] + encodings[b] + encodings[c]}=`;
    }

    return base64;
  };
  /* eslint-enable */
}());

function deepMerge<T, X>(t: T, x: X): T & X {
  return _.mergeWith(_.cloneDeep(t), _.cloneDeep(x), (objValue, srcValue) => {
    return _.isArray(objValue)
      ? objValue.concat(srcValue)
      : undefined;
  });
}

function nowMs() {
  return new Date().getTime();
}

function memoizeFunc<T extends (...any) => any>(func: T) {
  const cache = {};
  return function (...args: any[]) {
    const argsStr = JSON.stringify(args);

    // @ts-ignore
    cache[argsStr] = cache[argsStr] || func.apply(this, args);
    return cache[argsStr];
  } as any as T;
}

const unescape = (function () {
  const hex2 = /^[\da-f]{2}$/i;
  const hex4 = /^[\da-f]{4}$/i;

  return function utils_unescape(string: string) {
    const str = `${string}`;
    let result = '';
    const length = str.length;
    let index = 0;
    let chr;
    let slice;
    while (index < length) {
      chr = str.charAt(index++);
      if (chr === '%') {
        if (str.charAt(index) === 'u') {
          slice = str.slice(index + 1, index + 5);
          if (hex4.test(slice)) {
            result += String.fromCharCode(parseInt(slice, 16));
            index += 5;
            continue;
          }
        } else {
          slice = str.slice(index, index + 2);
          if (hex2.test(slice)) {
            result += String.fromCharCode(parseInt(slice, 16));
            index += 2;
            continue;
          }
        }
      }
      result += chr;
    }

    return result;
  };
}());

const escape = (function () {
  const raw = /[\w*+\-./@]/;

  const hex = function (code: number, length: number) {
    let result = code.toString(16);
    while (result.length < length) result = `0${result}`;
    return result;
  };

  return function utils_escape(string: string) {
    const str = `${string}`;
    let result = '';
    const length = str.length;
    let index = 0;
    let chr;
    let
      code;
    while (index < length) {
      chr = str.charAt(index++);
      if (raw.test(chr)) {
        result += chr;
      } else {
        code = chr.charCodeAt(0);
        if (code < 256) {
          result += `%${hex(code, 2)}`;
        } else {
          result += `%u${hex(code, 4).toUpperCase()}`;
        }
      }
    }
    return result;
  };
}());

function isNotEmptyString(value: any): value is string {
  return !_.isEmpty(value);
}

function isValidDate(date: any): date is string {
  return moment(date, moment.ISO_8601, true).isValid();
}

function getDateTimeOrDefault(date: any, def = 0) {
  return isValidDate(date)
    ? new Date(date).getTime()
    : def;
}

function randomString(length: number) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charactersLength = characters.length;
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function safe<T>(
  run: () => T,
  errFn: ((err: Error) => T | undefined) | undefined = undefined,
): T | undefined {
  try {
    return run();
  } catch (e) {
    return errFn && errFn(e);
  }
}

async function safeWait<T>(
  run: () => Promise<T>,
  errFn: ((err: Error) => T),
): Promise<T> {
  try {
    return await run();
  } catch (e) {
    return errFn(e);
  }
}

async function safeWaitOrUndefined<T>(
  run: () => Promise<T>,
  errFn?: ((err: Error) => T),
): Promise<T | undefined> {
  try {
    return await run();
  } catch (e) {
    return errFn != null
      ? errFn(e)
      : undefined;
  }
}

async function safeWaitOrMap<T>(
  run: () => Promise<T>,
  map: ((err: Error) => Error),
): Promise<T> {
  try {
    return await run();
  } catch (e) {
    throw map(e);
  }
}

function uuidv4() {
  /* eslint-disable */
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0;
    const
      v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
  /* eslint-enable */
}

export function addIfDefined<Key extends string, Val, TBD = any>(
  propName: Key,
  tbd: TBD | undefined,
  value?: (tbd: TBD) => Val,
): { [key in Key]?: Val } {
  if (tbd != null) {
    const res = value != null
      ? value(tbd)
      : tbd;
    return { [propName]: res } as any;
  }

  return {};
}

function addIfTrue<Key extends string, Truthy extends boolean, Val>(
  propName: Key,
  toBeTrue: Truthy,
  value: Val,
): Truthy extends true ? { [key in Key]: Val } : { [key in Key]: Val } {
  return addIfDefined<Key, Val>(propName, !toBeTrue && undefined, () => value) as any;
}

function removeEmpty(obj: any) {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] === Object(obj[key])) newObj[key] = removeEmpty(obj[key]);
    else if (obj[key] !== undefined) newObj[key] = obj[key];
  });
  return newObj;
}

export const Utils = {
  base64ArrayBuffer,
  deepMerge,
  nowMs,
  memoizeFunc,

  unescape,
  escape,
  encodeURIComponent,
  decodeURIComponent,

  randomString,
  isNotEmptyString,
  isValidDate,
  getDateTimeOrDefault,

  safe,
  safeWait,
  safeWaitOrUndefined,
  safeWaitOrMap,

  uuidv4,

  addIfDefined,
  addIfTrue,

  removeEmpty,
};
