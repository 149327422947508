// @ts-nocheck
import * as React from 'react';
import Svg,
{
  Circle,
  Ellipse,
  G,
  Mask,
  Path,
} from 'react-native-svg';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg width={227} height={120} viewBox="0 0 227 120" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113 33c4 0 18 21 18 21l24-22-42-20v21zM113 33c-4 0-18 21-18 21L71 32l42-20v21z"
        fill="#4B4D59"
      />
      <Ellipse
        cx={154}
        cy={49}
        rx={35}
        ry={18}
        transform="rotate(-45 154 49)"
        fill="#5F6170"
      />
      <Ellipse
        rx={35}
        ry={18}
        transform="scale(-1 1) rotate(-45 22.648 112.619)"
        fill="#5F6170"
      />
      <Circle cx={183} cy={76} r={44} fill="#2B2C33"/>
      <Mask
        id="prefix__a"
        maskUnits="userSpaceOnUse"
        x={139}
        y={32}
        width={88}
        height={88}
      >
        <Circle cx={183} cy={76} r={44} fill="#fff"/>
      </Mask>
      <G mask="url(#prefix__a)">
        <Circle cx={188} cy={70} r={44} fill="#3A3B45"/>
      </G>
      <Circle cx={44} cy={76} r={44} fill="#2B2C33"/>
      <Mask
        id="prefix__b"
        maskUnits="userSpaceOnUse"
        x={0}
        y={32}
        width={88}
        height={88}
      >
        <Circle cx={44} cy={76} r={44} fill="#fff"/>
      </Mask>
      <G mask="url(#prefix__b)">
        <Circle cx={49} cy={70} r={44} fill="#3A3B45"/>
      </G>
      <Circle cx={183} cy={76} r={29} fill="#FFDECD"/>
      <Circle cx={44} cy={76} r={29} fill="#FFDECD"/>
      <Path
        d="M163.322 62.268c.913-1.5 2.232-2.64 3.901-2.154 1.67.486 2.13 2.173 2.147 3.916.124 12.816 2.242 15.996 6.421 20.192 3.968 3.984 15.818 5.96 20.375 6.036 1.74.03 3.381.69 3.762 2.392.38 1.704-.788 3.095-2.383 3.777-14.981 6.403-23.853 3.599-31.326-3.649-7.474-7.247-7.92-22.254-2.897-30.51zM24.323 62.268c.912-1.5 2.23-2.64 3.9-2.154 1.67.486 2.13 2.173 2.146 3.916.125 12.816 2.243 15.996 6.422 20.192 3.968 3.984 15.818 5.96 20.375 6.036 1.74.03 3.38.69 3.762 2.392.38 1.704-.788 3.095-2.383 3.777-14.98 6.403-23.853 3.599-31.326-3.649-7.474-7.247-7.92-22.254-2.896-30.51z"
        fill="#EDCEBF"
      />
      <Circle cx={113} cy={15} r={15} fill="#3A3B45"/>
      <Circle cx={113} cy={15} r={7} fill="#4B4D59"/>
    </Svg>
  );
}

export const SvgNoResult = React.memo(SvgComponent);
