import { DbPaths } from '../../../db/DbRefs';
import {
  DbRes,
  TDbResName,
} from '../../../db/DbResources';
import { MObjDef } from '../../../lib/model/MObjDef';
import { TArchivedMObj } from './ArchivedMObjTypes';
import { defArchivedMObj } from '../ModelDefaults';

const pathBuilderKeys = [
  'archivedMObjId',
] as const;

export type TArchivedMObjPathBuilderParams = {
  [key in ((typeof pathBuilderKeys)[number])]: string
};

export class ArchivedMObjBuilder<T extends TDbResName> extends MObjDef<typeof DbRes.ArchivedMObj> {
  readonly resourceKey = DbRes.ArchivedMObj;

  protected readonly pathBuilderKeys = [...pathBuilderKeys];

  protected readonly _default = defArchivedMObj;

  protected newChild(item: TArchivedMObj<T>) {
    // Workaround for circular import between ArchivedMObjBuilder and ArchivedMObj
    return new (require('./ArchivedMObj').ArchivedMObj)(item);
  }

  pathBuilder(pbp: TArchivedMObjPathBuilderParams): string {
    return DbPaths.archivedItem(pbp);
  }
}
