import * as React from 'react';
import { TOrder } from '../../../../core/src/models/db/order/OrderTypes';
import { TOrderItem } from '../../../../core/src/models/db/orderItem/OrderItemTypes';
import { actionOrderRemoveOrderItem } from '../../redux/actions/Order';
import { useDispatch } from '../../../../lib-react/src/redux/redux';

/**
 * OrderItem
 */
type TUseOnOrderItemDelete = {
  orderData: TOrder;
};

export function useOnOrderItemDelete({ orderData }: TUseOnOrderItemDelete) {
  const dispatch = useDispatch();

  return React.useCallback(async (orderItem: TOrderItem) => {
    await dispatch(actionOrderRemoveOrderItem({ orderItem }));
  }, [orderData]);
}
