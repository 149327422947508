import { LinkingOptions } from '@react-navigation/native';
import * as _ from 'lodash';
import { RoutesCliGuest } from '../../../../core/src/lib/apis/routes/RoutableCliGuest';

export const linking: LinkingOptions = {
  prefixes: [],

  // @ts-ignore
  config: { screens: _.mapValues(RoutesCliGuest, (route) => route.path) },
};
