export type THostIdP = {
  hostId: string;
};

export type TTagIdP = {
  tagId: string;
};

export type TItemIdP = {
  itemId: string;
};

export const StPaths = {
  public() {
    return `public`;
  },
  private() {
    return `private`;
  },
  publicHosts() {
    return `${this.public()}/hosts`;
  },
  publicHost(pbp: THostIdP) {
    return `${this.publicHosts()}/${pbp.hostId}`;
  },
  publicHostItem(pbp: THostIdP & TItemIdP) {
    return `${this.publicHost(pbp)}/${pbp.itemId}`;
  },
  privateTags() {
    return `${this.private()}/tags`;
  },
  privateTag(pbp: TTagIdP) {
    return `${this.privateTags()}/${pbp.tagId}`;
  },
};
