import * as _ from 'lodash';
import {
  KnownError,
  KnownErrors,
} from './KnownError';
import { Log } from '../../config/Instance';
import {
  THandledError,
  TKnownError,
  TResolvedError,
} from './ErrorTypes';
import {
  knownErrorPriorityMapBuilder,
  knownErrorPrioritySorter,
} from './lib/knownErrorUtils';
import { ELocale } from '../../locale/Locale';

class ErrorHandlerBase {
  /**
   * Utility function to map an error to it's message
   */
  handleAndResolveToMessage = (error: any, locale?: ELocale) => {
    const handledError = this.handle(error);
    const resolvedError = this.resolve(handledError.knownError, {});
    return resolvedError.message(locale);
  };

  /**
   * Handles an Error by matching it to a known error and logging it
   */
  handle = (error: any): THandledError<TKnownError> => {
    const knownError = this.match(error);

    // Log error based on log level
    if (knownError.logLevel) {
      Log[knownError.logLevel]('ErrorHandler', 'handle', `knownError: ${knownError.internalCode}`, _.get(error, 'message'));
    }

    return Object.freeze({
      knownError,
      handledError: error,
    });
  };

  /**
   * Resolves a KnownError by mapping it to it's string message?
   */
  resolve = <KE extends TKnownError<MA>, MA = any>(knownError: KE, args: MA): TResolvedError<KE, MA> => {
    const isUnknownCode = knownError.internalCode == KnownError.unknown.internalCode;
    const errorMessage = _.get(knownError, 'handledError.message');
    return {
      knownError,
      message(locale?: ELocale) {
        if (isUnknownCode && !!errorMessage) {
          return errorMessage;
        }
        return knownError.internalMessageBuilder(args)(locale);
      },
    };
  };

  /**
   * Matches an Error to a KnownError based on the defined priority
   * Returns KnownError.unknown if nothing matches
   */
  private match = (error: any): TKnownError => {
    const code = _.get(error, 'code', undefined);
    const message = _.get(error, 'message', undefined);
    const knownError = _.get(error, 'knownError', undefined);

    if (knownError) {
      return knownError as TKnownError;
    }

    const codeMatches = code && KnownErrors
      .filter(({ codeMatcher }) => codeMatcher && codeMatcher.test(code))
      .sort(knownErrorPrioritySorter)
      .map(knownErrorPriorityMapBuilder(1.5));

    const messageMatches = message && KnownErrors
      .filter(({ messageMatcher }) => messageMatcher && messageMatcher.test(message))
      .sort(knownErrorPrioritySorter)
      .map(knownErrorPriorityMapBuilder(1));

    const codeMatch = _.head(codeMatches);
    const messageMatch = _.head(messageMatches);

    // @ts-expect-error
    if (codeMatch && codeMatch.priority > _.get(messageMatch, 'priority', -1)) {
      // @ts-ignore
      return KnownError[codeMatch.internalCode];
    }

    // @ts-expect-error
    if (messageMatch && messageMatch.priority > _.get(codeMatch, 'priority', -1)) {
      // @ts-ignore
      return KnownError[messageMatch.internalCode];
    }

    return KnownError.unknown;
  };
}

export const ErrorHandler = new ErrorHandlerBase();
