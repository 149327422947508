import { ErrorHandler } from '../../../core/src/lib/error/ErrorHandler';
import { TDispatch } from '../../../lib-react/src/redux/redux';
import {
  TGetState,
  TState,
} from './Store';
import { actionSnackbarsAddNegativeSnackbar } from './reducers/Snackbars';
import {
  put,
  select,
} from 'redux-saga/effects';
import { ELocale } from '../../../core/src/locale/Locale';

export function getSnackbarAction(locale: ELocale, error: any) {
  const handledError = ErrorHandler.handle(error);
  const resolvedError = ErrorHandler.resolve(handledError.knownError, {});

  return actionSnackbarsAddNegativeSnackbar({ text: resolvedError.message(locale) });
}

/**
 * redux-saga
 */
export function sagaWithErrorHandler<T extends (...args: any[]) => any>(func: T) {
  return function* (...args: Parameters<T>) {
    try {
      return yield func(...args);
    } catch (error) {
      return yield sagaHandleError(error);
    }
  };
}

export function* sagaHandleError(error: any) {
  const locale: ELocale = yield select((state: TState) => state.app.locale);
  // @ts-ignore
  yield put(getSnackbarAction(locale, error));
}

/**
 * redux-thunk
 */
export function dispatchWithErrorHandler<T extends (...args: any[]) => any>(func: T) {
  return function (...args: Parameters<T>) {
    return async (dispatch) => {
      try {
        return await dispatch(await func(...args));
      } catch (error) {
        return dispatch(actionErrorHandler(error));
      }
    };
  };
}

export function actionErrorHandler(error: any) {
  return (dispatch: TDispatch, getState: TGetState) => {
    const { locale } = getState().app;
    dispatch(getSnackbarAction(locale, error));
  };
}
