import { TGuestSessionPathBuilderParams } from '../GuestSessionBuilderBase';
import {
  TOrder,
  TOrderId,
} from '../../order/OrderTypes';
import { THost } from '../../host/HostTypes';
import * as _ from 'lodash';
import { Log } from '../../../../config/Instance';
import { PrintBuilderForStationOrder } from '../../../../printer/prints/PrintBuilderForStationOrder';
import { HostPrintQueueItemCreator } from '../../hostPrintQueueItem/HostPrintQueueItemCreator';
import { TOrderItem } from '../../orderItem/OrderItemTypes';
import { TGuestSession } from '../GuestSessionTypes';
import { TStationId } from '../../station/StationTypes';
import { TObjList } from '../../../../db/DbDefs';

type TOrders = TObjList<TOrderId, TOrder>;

export class GuestSessionPrintBatch {
  private readonly pbp: TGuestSessionPathBuilderParams;

  private readonly host: THost;

  private readonly guestSession: TGuestSession;

  private readonly orderItemsToPrint: TOrderItem[];

  private readonly tableId;

  constructor(
    pbp: TGuestSessionPathBuilderParams,
    host: THost,
    guestSession: TGuestSession,
    batchedOrders: TOrders,
  ) {
    this.pbp = pbp;
    this.host = host;
    this.guestSession = guestSession;
    this.tableId = this.pbp.tableId;
    this.orderItemsToPrint = _.values(batchedOrders).flatMap((order) => _.values(order.items));
    this.run = this.run.bind(this);
  }

  async run() {
    if (this.orderItemsToPrint.length <= 0) {
      Log.v(
        'GuestSessionPrintBatch',
        'mergePrintOrdersInPrintingStatus',
        `tableId=${this.tableId} No orderItems to print`,
      );
      return;
    }

    const promises = _.chain(this.orderItemsToPrint)
      .groupBy((orderItem) => orderItem.stationId)
      .map((orderItemsToPrint, stationId) => this.printStationOrder(orderItemsToPrint, stationId))
      .value();

    await Promise.all(promises);
  }

  private printStationOrder(orderItems: TOrderItem[], stationId: TStationId) {
    const printQueueItem = new PrintBuilderForStationOrder({
      host: this.host,
      guestSession: this.guestSession,
      orderItemsToPrint: orderItems,
      destinationStationId: stationId,
    }).build();

    return HostPrintQueueItemCreator.remoteSaveNewToPath(
      { hostId: this.pbp.hostId },
      printQueueItem,
    );
  }
}

