import * as _ from 'lodash';
import { AbsSafeRef } from './SafeRef';
import { FIELD_ID, FIELD_PATH } from '../../db/DbDefs';
import { FirebaseApi } from '../../FirebaseApi';
import { Log } from '../../config/Instance';
import { ModelValidator } from '../ModelValidator';
import { PathParser } from '../PathParser';
import { TDbResData, TDbResModel, TDbResPbp } from '../../db/DbResources';
import { TLocalObj, TMObjDataNoPath } from './ModelTypes';

export abstract class MObjDef<RK extends keyof TDbResData> extends AbsSafeRef<TDbResData[RK], TDbResPbp[RK]> {
  get default() {
    return _.cloneDeep(this._default);
  }

  readonly abstract resourceKey: RK;

  protected readonly abstract pathBuilderKeys: string[];

  protected readonly abstract _default: TDbResData[RK];

  protected abstract newChild(item: TDbResData[RK]): TDbResModel[RK];

  abstract pathBuilder(pbp: TDbResPbp[RK]): string;

  fromItemAsLocal(item: TDbResData[RK]): TLocalObj<RK> {
    return _.omit(this.fromItem(item).item, [
      FIELD_ID,
      FIELD_PATH,
    ]) as TLocalObj<RK>;
  }

  fromItem(item: TDbResData[RK], pbp?: TDbResPbp[RK]): TDbResModel[RK] {
    return this.newChild({
      ...item,
      [FIELD_PATH]: pbp
        ? this.pathBuilder(pbp)
        : item[FIELD_PATH],
    });
  }

  getPathBuilderParamsFromDataPath(data: Pick<TDbResData[RK], typeof FIELD_PATH>): TDbResPbp[RK] {
    return PathParser.parse(
      this.pathBuilder(_.keyBy(this.pathBuilderKeys) as any as TDbResPbp[RK]),
      data[FIELD_PATH],
    ) as TDbResPbp[RK];
  }

  async remoteSaveToPath(pbp: TDbResPbp[RK], data: TMObjDataNoPath<RK>): Promise<TDbResData[RK]> {
    const consistentData = this.makeConsistentTModelObj(pbp, {
      ...data,
      [FIELD_PATH]: '',
    } as TDbResData[RK]);
    Log.v('MObjDef', 'remoteSaveToPath', `Saving`);
    return this.remoteSave(consistentData);
  }

  async remoteSave(data: TDbResData[RK]): Promise<TDbResData[RK]> {
    Log.v('MObjDef', 'remoteSave', `Saving rk=${this.resourceKey}, id=${data[FIELD_ID]}`);
    const ref = FirebaseApi.instance.ref(data[FIELD_PATH]);
    await ref.set(data);
    return data;
  }

  async remoteDataFromPath(pathBuilderParams: TDbResPbp[RK]): Promise<TDbResData[RK] | undefined> {
    const path = this.pathBuilder(pathBuilderParams);
    try {
      return await this.remoteFromPathString(path);
    } catch (e) {
      Log.v('ModelWrapper', 'remoteDataFromPath', 'Data doesn\'t exist pbp=', pathBuilderParams);
    }
    return undefined;
  }

  async remoteFromPath(pathBuilderParams: TDbResPbp[RK]): Promise<TDbResData[RK]> {
    const path = this.pathBuilder(pathBuilderParams);
    return this.remoteFromPathString(path);
  }

  private async remoteFromPathString(path: string): Promise<TDbResData[RK]> {
    const ref = FirebaseApi.instance.ref(path);
    const data = await FirebaseApi.once<TDbResData[RK]>(ref);
    if (!data) {
      throw new Error(`TFbObjBuilder, remoteFromPath, ref=${path} is undefined`);
    }
    return this.fromItem(data).item as TDbResData[RK];
  }

  async dataExistsById(pathBuilderParams: TDbResPbp[RK]): Promise<boolean> {
    const path = this.pathBuilder(pathBuilderParams);
    const dataIdFieldRef = FirebaseApi.instance.ref(`${path}/${FIELD_ID}`);
    const dataIdField = await FirebaseApi.once(dataIdFieldRef);
    return dataIdField != null;
  }

  makeConsistentTModelObj(pbp: TDbResPbp[RK], data: TDbResData[RK]): TDbResData[RK] {
    return ModelValidator.makeConsistentTModelObj({
      obj: data as any,
      traversedPath: this.pathBuilder(pbp),
    });
  }

  /**
   * New API below
   * New API below
   * New API below
   * New API below
   */
  async remoteFromPathOrUndefined(pathBuilderParams: TDbResPbp[RK]): Promise<TDbResData[RK] | undefined> {
    const path = this.pathBuilder(pathBuilderParams);
    return this.remoteFromPathStringOrUndefined(path);
  }

  async remoteFromPathStringOrUndefined(path: string): Promise<TDbResData[RK] | undefined> {
    const ref = FirebaseApi.instance.ref(path);
    const data = await FirebaseApi.once<TDbResData[RK]>(ref);
    return data != null
      ? this.fromItem(data).item as TDbResData[RK]
      : undefined;
  }
}
