import * as React from 'react';
import { FIELD_ID } from '../../../../core/src/db/DbDefs';
import {
  TMenu,
  TMenuId,
} from '../../../../core/src/models/db/menu/MenuTypes';
import { View } from 'react-native';
import { localize } from '../../../../core/src/lib/formatters/LocaleLib';
import styled from 'styled-components/native';
import { AlaTextBold } from '../Text';
import { Touchable } from '../../lib-components/Touchable';
import { ELocale } from '../../../../core/src/locale/Locale';
import { useCurrentLanguage } from "../../hooks/hooks";

export type THostMainMenuButtonProps = {
  menu: TMenu;
  buttonHeight: number;
  onButtonPress: (menuId: TMenuId) => void;
  isSelected?: boolean;
};

export const HostMainMenuButton = ({
  menu,
  buttonHeight,
  onButtonPress,
  isSelected = false,
}: THostMainMenuButtonProps) => {
  const menuId = menu[FIELD_ID];

  const onPress = React.useCallback(() => {
    return onButtonPress(menuId);
  }, [onButtonPress, menuId]);

  const language = useCurrentLanguage();
  const name = localize(menu, 'name', language);
  return (
    <StyledRoot>
      <Touchable
        key={menuId}
        onPress={onPress}>
        <ButtonContainer
          height={buttonHeight}>
          <ButtonText>
            {name}
          </ButtonText>
        </ButtonContainer>
      </Touchable>
      {isSelected && (
        <SelectedUnderlineCont>
          <SelectedUnderline/>
        </SelectedUnderlineCont>
      )}
    </StyledRoot>
  );
};

const StyledRoot = styled(View)`
`;

const SelectedUnderlineCont = styled(View)`
  align-items: center;
  bottom: 0;
  justify-content: center;
  left: 0;
  position: absolute;
  width: 100%;
`;

const SelectedUnderline = styled(View)`
  background-color: ${({ theme }) => theme.t.col.white};
  height: 2px;
  width: ${({ theme }) => theme.scaler.scale(30)}px;
`;

const ButtonContainer = styled(View)<{ buttonHeight: number }>`
  align-items: center;
  display: flex;
  height: ${({ height }) => height}px;
  justify-content: center;
  padding-horizontal: 15px;
`;

const ButtonText = styled(AlaTextBold)`
  color: ${({ theme }) => theme.t.col.white};
  font-size: 14px;
  text-transform: uppercase;
`;
