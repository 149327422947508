import { Colors } from '../../config/Theme';
import { OrderBuilder } from '../../../../core/src/models/db/order/OrderBuilder';
import { OrderItemBuilder } from '../../../../core/src/models/db/orderItem/OrderItemBuilder';
import { Strings } from '../../../../core/src/locale/Strings';
import { TDispatch, TThunkAction } from '../../../../lib-react/src/redux/redux';
import { TGetState } from '../Store';
import { TOrderItem } from '../../../../core/src/models/db/orderItem/OrderItemTypes';
import { actionSnackbarsAddPositiveSnackbar } from '../reducers/Snackbars';
import { dispatchWithErrorHandler } from '../ReduxErrorHandlers';
import { GuestSession } from '../../../../core/src/models/db/guestSession/GuestSession';
import { FIELD_ID } from '../../../../core/src/db/DbDefs';
import { Log } from '../../config/Log';

type TActionOrderRemoveOrderItem = {
  orderItem: TOrderItem;
};

export const actionOrderRemoveOrderItem = dispatchWithErrorHandler(({ orderItem }: TActionOrderRemoveOrderItem): TThunkAction<Promise<void>> => {
  return async (dispatch: TDispatch, getState: TGetState) => {
    const { locale } = getState().app;
    const guestSession = getState().guestSession.guestSessionData;
    if (!guestSession) {
      Log.wtf('actionOrderRemoveOrderItem', 'actionOrderRemoveOrderItem', `No GuestSession in store`);
      return;
    }

    const orderItemId = orderItem[FIELD_ID];
    const order = GuestSession.findOrderWithOrderItemByOrderItemId(guestSession, orderItemId);
    if (!order) {
      Log.e('actionOrderRemoveOrderItem', 'actionOrderRemoveOrderItem', `Order for OrderItem ${orderItemId} not found`);
      return;
    }

    await OrderBuilder.fromItem(order).deleteOrderItem(orderItem);
    dispatch(actionSnackbarsAddPositiveSnackbar({
      text: Strings.gOrderItemDeleted()(locale),
      action: {
        text: Strings.gCancelOp()(locale),
        textColor: Colors.red,
        onPress: async () => {
          await OrderItemBuilder.remoteSave(orderItem);
        },
      },
    }));
  };
});
