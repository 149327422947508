import * as React from 'react';
import * as _ from 'lodash';
import { FIELD_NAME } from '../../../../core/src/db/DbDefs';
import { GenericListItem1 } from '../../lib-components/GenericListItem1';
import { Host } from '../../../../core/src/models/db/host/Host';
import { ListRenderItemInfo } from 'react-native';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import { TMenu } from '../../../../core/src/models/db/menu/MenuTypes';
import { isNotEmpty } from '../../../../core/src/lib/HelperFunctions';
import { localize } from '../../../../core/src/lib/formatters/LocaleLib';
import { useCurrentLanguage } from "../../hooks/hooks";

export type TUseRendererMenuItemProps = {
  host: THost;
  onItemPress?: (item: TMenu) => void;
};

export function useRenderMenuListItem({ host, onItemPress }: TUseRendererMenuItemProps) {
  const language = useCurrentLanguage();
  return React.useCallback(({ item }: ListRenderItemInfo<TMenu>) => {
    const name = localize(item, FIELD_NAME, language);

    const thisMenuItems = Host.getActiveMenuItemsForMenu(host, item);
    const menuItemsDescription = _.values(thisMenuItems)
      .map((mappedItem) => localize(mappedItem, FIELD_NAME, language))
      .filter(isNotEmpty);

    const subMenuDescriptions = _.values(item.subMenus)
      .map((mappedItem) => localize(mappedItem, 'description', language))
      .filter(isNotEmpty);

    const description = [...menuItemsDescription, subMenuDescriptions]
      .filter(isNotEmpty)
      .join(', ');
    return (
      <GenericListItem1
        item={{
          item,
          headerTextLeft: name,
          headerTextRight: undefined,
          description,
          badgeValue: undefined,
        }}
        onItemPress={onItemPress}
        onReadMorePress={undefined}
      />
    );
  }, [host, language, onItemPress]);
}
