import { Config } from './Config';
import { Logger } from '../../../core/src/lib/Logger';
import { ReactX } from '../lib/ReactX';

export const Log = new Logger({
  name: Config.appName,
  env: Config.env,
  isCI: Config.isCI,
  onLog: (logString: string) => {
    ReactX.firebase.apps.length > 0 && ReactX.crashlytics().log(logString);
  },
});
