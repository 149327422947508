type TColor = {
  r: number;
  g: number;
  b: number;
};

const defaultColor: TColor = {
  r: 0,
  g: 0,
  b: 0,
};

export function hexToRgbaString(hex: string, a: number): string {
  const { r, g, b } = hexToRgb(hex) || defaultColor;
  return `rgba(${r}, ${g}, ${b}, ${a})`;
}

export function hexToRgb(hex: string): TColor | undefined {
  const match = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!match) {
    return undefined;
  }

  return {
    r: parseInt(match[1], 16),
    g: parseInt(match[2], 16),
    b: parseInt(match[3], 16),
  };
}

export function colorToHex(c: TColor): string {
  return `#${componentToHex(c.r)}${componentToHex(c.g)}${componentToHex(c.b)}`;
}

function componentToHex(c: number): string {
  const hex = c.toString(16);
  return hex.length == 1 ? `0${hex}` : hex;
}

export function colorsOverlayHex(hex1: string, hex2: string, weight = 50) {
  const c1 = hex1.replace('#', '');
  const c2 = hex2.replace('#', '');

  // convert a decimal value to hex
  function d2h(d) {
    return d.toString(16);
  }

  // convert a hex value to decimal
  function h2d(h) {
    return parseInt(h, 16);
  }

  let color = '#';

  // loop through each of the 3 hex pairs—red, green, and blue
  for (let i = 0; i <= 5; i += 2) {
    // extract the current pairs
    const v1 = h2d(c1.substr(i, 2));
    const v2 = h2d(c2.substr(i, 2));

    // combine the current pairs from each source color, according to the specified weight
    let val = d2h(Math.floor(v2 + (v1 - v2) * (weight / 100.0)));

    // prepend a '0' if val results in a single digit
    while (val.length < 2) {
      val = `0${val}`;
    }

    // concatenate val to our new color string
    color += val;
  }

  return color;
}
