import { ELocale } from './Locale';

const defaultLocaleDef = {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal(number) {
    return number === 1 ? 'er' : 'ème';
  },
  currency: { symbol: '€' },
};

export const LocaleDefinitions: {
  [K in ELocale]: typeof defaultLocaleDef
} = {
  [ELocale['it-IT']]: defaultLocaleDef,
  [ELocale['es-ES']]: defaultLocaleDef,
  [ELocale['en-US']]: defaultLocaleDef,
};
