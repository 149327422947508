import { TState } from '../Store';
import { TTableId } from '../../../../core/src/models/db/table/TableTypes';
import { call, put, select } from 'redux-saga/effects';
import { actions } from '../Actions';
import { sagaWaitForVal } from '../../../../lib-react/src/sagas/sagaWaitForVal';
import { THostPublicSubscription } from '../../../../core/src/models/db/HostPublicSubscription/HostPublicSubscriptionTypes';
import { HostSubscription } from '../../../../core/src/models/db/hostSubscription/HostSubscription';
import { ESubscriptionFeatureFlags } from '../../../../core/src/models/db/hostSubscription/HostSubscriptionTypes';
import { HostPublicSubscriptionBuilder } from '../../../../core/src/models/db/HostPublicSubscription/HostPublicSubscriptionBuilder';
import { Log } from '../../config/Log';
import { ensureGuestSessionExists } from '../functions/ensureGuestSessionExists';
import * as _ from 'lodash';
import { actionResetSessionAndNavigate } from '../actions/NavigationActions';
import { startReactiveAction } from '../../../../lib-react/src/redux/startReactiveAction';

export const { trigger: actionGuestSessionSetTableId } = startReactiveAction<TState, { tableId: TTableId | undefined }>({
  runnerName: 'actionGuestSessionSetTableId',

  react: [
    {
      select: (store: TState) => {
        return { tableId: store.guestSession.tableId };
      },
      mapOutput: _.identity,
    },
    {
      select: (store: TState) => {
        return { stripeSubscriptionType: store.host.hostPublicSubscription && store.host.hostPublicSubscription.stripeSubscriptionType };
      },
      * mapOutput(params) {
        return { tableId: yield select((store: TState) => store.guestSession.tableId) };
      },
    },
  ],

  * reaction({ tableId }) {
    if (!tableId) {
      return;
    }
    const hostPublicSubscription = yield sagaWaitForVal<THostPublicSubscription | undefined>(function* () {
      return yield select((store: TState) => store.host.hostPublicSubscription);
    });

    if (!hostPublicSubscription) {
      return;
    }

    const { hostId } = HostPublicSubscriptionBuilder.getPathBuilderParamsFromDataPath(hostPublicSubscription);

    if (!HostSubscription.hasAccessToFeature(hostPublicSubscription, ESubscriptionFeatureFlags.guestOrders)) {
      Log.e('actionGuestSessionSetTableId', '_', `Host ${hostId} doesnt have access to guestOrders features`);
      yield put(actionResetSessionAndNavigate() as any);
      return;
    }

    // Check if guest session is still valid
    yield call(ensureGuestSessionExists, 'actionGuestSessionSetTableId', {
      hostId,
      tableId,
    });

    // All good, open guest session
    yield put(actions.pureActionTypeGuestSessionSetTableId({ tableId }));
  },
});
