import * as _ from 'lodash';
import {
  DbRes,
  TRKHost,
} from '../../../db/DbResources';
import {
  EMenuItemState,
  TMenuItem,
} from '../menuItem/MenuItemTypes';
import {
  EStationType,
  TStation,
  TStationId,
} from '../station/StationTypes';
import { FIELD_ID } from '../../../db/DbDefs';
import { HostBuilder } from './HostBuilder';
import { MObj } from '../../../lib/model/MObj';
import { Menu } from '../menu/Menu';
import { MenuBuilder } from '../menu/MenuBuilder';
import { MenuItemCreator } from '../menuItem/MenuItemCreator';
import { OrderItem } from '../orderItem/OrderItem';
import { Refs } from '../../../db/DbRefs';
import { StationCreator } from '../station/StationCreator';
import { TGuestSession } from '../guestSession/GuestSessionTypes';
import {
  FIELD_HOST_EMAIL,
  THost,
  TRoomToTableCount,
} from './HostTypes';
import { TMObjDataCreator } from '../../../lib/model/ModelTypes';
import {
  TMenu,
  TMenuId,
} from '../menu/MenuTypes';
import { TOrderItem } from '../orderItem/OrderItemTypes';
import {
  TRoom,
  TRoomId,
} from '../room/RoomTypes';
import {
  TTable,
  TTableId,
} from '../table/TableTypes';
import { TableCreator } from '../table/TableCreator';
import { buildRawUpdate } from '../../dbUpdaters/lib/buildRawUpdate';
import {
  dbItemNameableNumberSort,
  dbItemNameableUncasedIncludeComparer,
} from '../../../db/DbLib';
import { hostUpdateRooms } from '../../dbUpdaters/hostUpdateRooms';
import { hostUpdateStations } from '../../dbUpdaters/hostUpdateStations';
import { push } from '../../../lib/HelperFunctions';
import { hostCustomizationUpdate } from '../../dbUpdaters/hostCustomizationUpdate';
import { FirebaseApi } from '../../../FirebaseApi';
import {LanguageCodes, Languages, TLanguage} from '../../../locale/Languages';

export class Host extends MObj<TRKHost> {
  static async findHostByEmail(email: string): Promise<THost | undefined> {
    const query = Refs.hosts().ref()
      .orderByChild(FIELD_HOST_EMAIL)
      .equalTo(email);

    const hosts = await FirebaseApi.once(query);
    return _.first(_.values(hosts));
  }

  static findStationByIdOrThrow({ stations }: Pick<THost, 'stations'>, stationId: TStationId): TStation {
    const station = _.get(stations, stationId);
    if (!station) {
      throw new Error(['Host', 'findStationByIdOrThrow', `Station with id=${stationId} not found`].join(', '));
    }
    return station;
  }

  static findTableByIdOrThrow({ tables }: Pick<THost, 'tables'>, tableId: TTableId): TTable {
    const table = _.get(tables, tableId);
    if (!table) {
      throw new Error(['Host', 'findTableByIdOrThrow', `Table with id=${tableId} not found`].join(', '));
    }
    return table;
  }

  static findRoomWithTableOrThrow({ rooms }: Pick<THost, 'rooms'>, tableId: TTableId): TRoom {
    const room = _.find(rooms, (searchRoom) => searchRoom[FIELD_ID] === tableId);
    if (!room) {
      throw new Error(['Host', 'findRoomWithTableOrThrow', `Room with tableId=${tableId} not found`].join(', '));
    }
    return room;
  }

  static findMainStationOrThrow(host: Pick<THost, 'stations'>): TStation {
    const mainStation = Host.findMainStation(host);

    if (!mainStation) {
      throw new Error(['Host', 'findMainStationOrThrow', `Station with type=${EStationType.MAIN} not found`].join(', '));
    }

    return mainStation;
  }

  static findMainStation({ stations }: Pick<THost, 'stations'>): TStation | undefined {
    return _.values(stations).find((station: TStation) => {
      return station.type === EStationType.MAIN;
    });
  }

  static findMenuById({ menu }: Pick<THost, 'menu'>, menuId: TMenuId): TMenu | undefined {
    return MenuBuilder.fromItem(menu).findMenuById(menuId);
  }

  static findTableByName({ tables }: Pick<THost, 'tables'>, tableName: string): TTable | undefined {
    return _.find(_.values(tables), dbItemNameableUncasedIncludeComparer(tableName));
  }

  static findFreeTable({ tables }: Pick<THost, 'tables'>, guestSessions: TGuestSession[]): TTable | undefined {
    const occupiedTableIds = guestSessions
      .map((guestSession) => guestSession.tableId);
    return _.values(tables)
      .find((table) => !occupiedTableIds.includes(table[FIELD_ID]));
  }

  static getCoverCharge({ menu }: Pick<THost, 'menu'>) {
    const activatedMenu = Menu.getSecondaryMenu(menu);
    return _.get(activatedMenu, 'coverCharge', 0);
  }

  static getPriceInfoForOrderItems(
    host: THost,
    orderItems: TOrderItem[],
    numberOfGuests: number,
    orderDiscountNum = 0,
  ) {
    const elementsTotal = _.sumBy(orderItems, (orderItem) => OrderItem.getTotalPrice(orderItem));
    const coverTotal = Host.getCoverCharge(host) * numberOfGuests;
    const totalWithoutDiscount = elementsTotal + coverTotal;
    const totalWithDiscount = totalWithoutDiscount - orderDiscountNum;
    const orderDiscountPercentage = 1 / totalWithoutDiscount * orderDiscountNum;
    return {
      elementsTotal,
      coverTotal,
      totalWithoutDiscount,
      totalWithDiscount,
      orderDiscountPercentage,
    };
  }

  static getActiveMenuItems({ items, menu }: Pick<THost, 'items' | 'menu'>) {
    const allMenuIds = _.keyBy(Menu.allMenuIds(menu));
    return _.values(items)
      .filter((menuItem) => menuItem.status == EMenuItemState.ACTIVE)
      .filter((menuItem) => {
        // Get the menuIds this menuItem is in
        const menuItemIsInMenus = _.keys(menuItem.menuIds);

        // For each menuId, check if that menu is active
        return menuItemIsInMenus
          .some((menuId) => allMenuIds[menuId] != null);
      });
  }

  static getActiveMenuItemsForMenu({ items }: Pick<THost, 'items'>, menu: Pick<TMenu, typeof FIELD_ID>): TMenuItem[] {
    return _.values(items)
      .filter((menuItem) => menuItem.status == EMenuItemState.ACTIVE)
      .filter((menuItem) => {
        const menuIds = menuItem.menuIds || {};
        return menuIds[menu[FIELD_ID]] != null;
      });
  }

  static getTablesWithoutRoom({ tables }: Pick<THost, 'tables'>): TTable[] {
    return _.values(tables)
      .filter((table) => table.roomId == null)
      .sort(dbItemNameableNumberSort);
  }

  static getTablesInRoom({ rooms, tables }: Pick<THost, 'tables' | 'rooms'>, roomId: TRoomId): TTable[] {
    return _.values(tables)
      .filter((table) => table.roomId === roomId)
      .sort(dbItemNameableNumberSort);
  }

  static getRoomToTableCount({ rooms, tables }: Pick<THost, 'tables' | 'rooms'>): TRoomToTableCount[] {
    const roomsByTableCount = {
      // Default all rooms to 0 tables
      ..._.mapValues(_.groupBy(rooms, FIELD_ID), () => 0),

      // Overwrite rooms with table count
      ..._.mapValues(_.groupBy(tables, 'roomId'), (tablesArr) => tablesArr.length),
    };

    return _.reduce(roomsByTableCount, (acc, tableCount, roomId) => {
      const room = _.get(rooms, roomId);
      if (!room) {
        return acc;
      }

      return push(acc, {
        room,
        tableCount,
      });
    }, [] as TRoomToTableCount[]);
  }

  readonly updateStations = buildRawUpdate(this, 'Host/stations', hostUpdateStations, (update) => {
    return Refs.hostStations(this.pbp()).ref().update(update);
  });

  readonly updateRooms = buildRawUpdate(this, 'Host/rooms', hostUpdateRooms, (update) => {
    return Refs.host(this.pbp()).ref().update(update);
  });

  readonly updateHostCustomization = buildRawUpdate(this, 'HostCustomization/*', hostCustomizationUpdate, (update) => {
    return this.updateFields(update);
  });

  constructor(host: THost) {
    super(HostBuilder, host);
  }

  addMenuItem(menuItem: TMObjDataCreator<typeof DbRes.MenuItem>) {
    return MenuItemCreator.remoteSaveNewToPath(this.pbp(), menuItem);
  }

  addTable(table: TMObjDataCreator<typeof DbRes.Table>) {
    return TableCreator.remoteSaveNewToPath(this.pbp(), table);
  }

  addStation(station: TMObjDataCreator<typeof DbRes.Station>) {
    return StationCreator.remoteSaveNewToPath(this.pbp(), station);
  }

  async updateEmail(newEmail: string) {
    await this.updateFields({ email: newEmail });
  }
}
