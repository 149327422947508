import * as React from 'react';
import { Colors } from '../config/Theme';
import { Config } from '../config/Config';
import { ConfirmationScreenView } from '../components/ConfirmationScreenView';
import { Screen } from '../lib-components/screen/Screen';
import { Strings } from '../../../core/src/locale/Strings';
import { Navigation } from '../lib/navigation/Navigation';
import { RCComp } from '../lib/routeContract/RCComp';
import { RoutesCliGuest } from '../../../core/src/lib/apis/routes/RoutableCliGuest';

export class RouteHostOrderRequestCompleteComp extends RCComp<'ScreenHostOrderRequestComplete'> {
  protected routeName = 'ScreenHostOrderRequestComplete' as const;

  render() {
    const { locale } = this.props;
    return (
      <Screen
        backgroundColor={Colors.red}
        usesReactNavigationHeader={false}
        hasStatusBar={true}
        statusBarBackgroundColor={Colors.red}
        statusBarBackgroundColorIsLight={true}
      >
        <ConfirmationScreenView
          onTimeoutComplete={this.onTimeoutComplete}
          backgroundColor={Colors.red}
          timeoutMs={Config.confirmationScreenTimeoutMs}
          completeText={Strings.gOrderSentMsg()(locale)}
        />
      </Screen>
    );
  }

  private onTimeoutComplete = () => {
    Navigation.routePush(RoutesCliGuest.ScreenHostHome.name);
  };
}
