import * as _ from 'lodash';
import { EStationType, TStation, TStationCreate, TStationId } from '../db/station/StationTypes';
import { FIELD_ID, TObjList } from '../../db/DbDefs';
import { Host } from '../db/host/Host';
import { StationCreator } from '../db/station/StationCreator';
import { TBuilderParamsBase } from './lib/buildRawUpdate';
import { THostId } from '../db/host/HostTypes';
import { TRKHost } from '../../db/DbResources';
import { dbItemIdableMap } from '../../db/DbLib';
import { stripUnchangedValues } from './lib/localizedObjectUpdater';

/**
 * Either a full Station or a station creator
 */

type THostUpdateStationsParams = {
  stations: (TStationCreate | TStation)[];
};

export function getUpdatedStations(stations: (TStationCreate | TStation)[]) {
  return stations.filter((station) => station[FIELD_ID] != null) as TStation[];
}

export function getCreatedStations(stations: (TStationCreate | TStation)[]) {
  return stations.filter((station) => station[FIELD_ID] == null) as TStationCreate[];
}

export function getDeleteStationsUpdate(
  existingStations: TStation[],
  stations: (TStationCreate | TStation)[],
) {
  const updatedStationIds = getUpdatedStations(stations)
    .map(dbItemIdableMap);

  return _.chain(existingStations)
    .filter((station) => !updatedStationIds.includes(station[FIELD_ID]))
    .keyBy(FIELD_ID)
    .mapValues(() => null)
    .value();
}

export function getCreatedStationsUpdate(
  hostId: THostId,
  stations: (TStationCreate | TStation)[],
) {
  return _.chain(getCreatedStations(stations))
    .map((station) => StationCreator.buildNew({ hostId }, station))
    .keyBy(FIELD_ID)
    .value();
}

export function ensureMainStationExists(stations: TObjList<TStationId, TStation | null>) {
  const nonNullStations = _.keyBy(_.filter(stations, (station) => station != null), FIELD_ID) as TObjList<TStationId, TStation>;
  const mainStation = Host.findMainStation({ stations: nonNullStations });
  if (mainStation != null) {
    return stations;
  }

  const firstStationId = _.get(_.first(_.values(stations)), FIELD_ID);
  // if (firstStationId == null) {
  //   throw new LocalError('hostUpdateStations', 'ensureMainStationExists', KnownError.errCantRemoveMainStation);
  // }

  return {
    ...stations,
    ...(firstStationId ? {
      [firstStationId]: {
        ...stations[firstStationId],
        type: EStationType.MAIN,
      },
    } : {}),
  };
}

export async function hostUpdateStations({
  item,
  stations,
}: TBuilderParamsBase<TRKHost> & THostUpdateStationsParams) {
  const updatedStations: TObjList<TStationId, TStation | null> = {
    // Add all new Stations to the update object
    ..._.keyBy(getUpdatedStations(stations), FIELD_ID),

    // Delete all missing stations from the update object
    ...getDeleteStationsUpdate(
      _.values(item.stations),
      stations,
    ),

    // Add new stations (the ones that don't have an id)
    ...getCreatedStationsUpdate(item[FIELD_ID], stations),
  } as any;

  const updatedStationsWithMain = ensureMainStationExists(updatedStations);
  return stripUnchangedValues({
    initialItem: item.stations,
    updatedItem: updatedStationsWithMain as any,
  });
}
