import * as React from 'react';
import * as _ from 'lodash';
import { Strings } from '../../../../core/src/locale/Strings';
import { TagParser } from '../../../../core/src/lib/TagParser';
import { View } from 'react-native';
import { localize } from '../../../../core/src/lib/formatters/LocaleLib';
import styled from 'styled-components/native';
import { MenuItemTag } from '../MenuItemTag';
import { OrderItemEditorContext } from './OrderItemEditorContext';
import { AlaText } from '../Text';
import { ReactNativeHTMLRenderer } from "../../lib-components/ReactNativeHTMLRenderer";
import { Theme } from "../../config/Theme";
import { useCurrentLanguage, useCurrentLocale } from "../../hooks/hooks";

type TOrderItemEditorSectionDescriptionProps = {
  //
};

export const OrderItemEditorSectionDescription = (props: TOrderItemEditorSectionDescriptionProps) => {
  const { orderItem } = React.useContext(OrderItemEditorContext);

  const language = useCurrentLanguage();
  const locale = useCurrentLocale();
  const description = localize(orderItem, 'description', language);
  const hasDescription = !_.isEmpty(description);
  const hasAllergens = !_.isEmpty(orderItem.allergens);
  const hasTags = _.size(orderItem.tags) > 0;
  const hasContent = [hasDescription, hasAllergens, hasTags].some(_.identity);
  if (!hasContent) {
    return null;
  }

  return (
    <Root>
      {hasDescription && <ReactNativeHTMLRenderer html={description} tagsStyles={descriptionTagStyles}/>}
      {hasAllergens && (
        <AllergensText>
          {Strings.gAllergensContain()(locale)}: {TagParser.toString(orderItem.allergens)}
        </AllergensText>
      )}
      {hasTags && (
        <TagsView>
          {TagParser.parseFormatAndSortTags(orderItem.tags)
            .map((tag) => (
              <StyledMenuItemTag
                key={tag}
                tag={tag}
              />
            ))}
        </TagsView>
      )}
    </Root>
  );
};

const Root = styled(View)`
  padding: 20px;
`;

const descriptionTagStyles = {
  body: {
    color: Theme.col.accentDark,
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center'
  },
  a: {
    color: Theme.col.red
  },
  p: {
    margin: 0
  }
};

const AllergensText = styled(AlaText)`
  color: ${({ theme }) => theme.t.col.accentLight};
  font-size: 14px;
  line-height: 20px;
  margin-top: ${({ theme }) => theme.scaler.verticalScale(10)}px;
  text-align: center;
`;

const TagsView = styled(View)`
  flex-direction: row;
  justify-content: center;
  margin-top: ${({ theme }) => theme.scaler.verticalScale(15)}px;
  flex-wrap: wrap;
`;

const StyledMenuItemTag = styled(MenuItemTag)`
  margin-left: 2.5px;
  margin-right: 2.5px;
  padding-vertical: 2px;
`;
