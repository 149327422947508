import * as React from 'react';
import { Colors } from '../../config/Theme';
import { Loader } from '../../lib-components/Loader';
import { SvgDelete } from '../../img/SvgDelete';
import { Touchable } from '../../lib-components/Touchable';
import { View } from 'react-native';
import { useLoadingCallback } from '../../../../lib-react/src/hooks/useLoadingCallback';
import styled from 'styled-components/native';

export type TListItemDeleteButtonProps = {
  width: number;
  onPress: () => Promise<void>;
};

export const ListItemDeleteButton = ({
  width,
  onPress,
}: TListItemDeleteButtonProps) => {
  const [onItemPress, loading] = useLoadingCallback(onPress);
  return (
    <Touchable
      onPress={onItemPress}>
      <ContentCont
        width={width}>
        {loading && (
          <Loader
            color={Colors.white}
          />
        )}
        {!loading && (
          <IconContainer
            width={width}>
            <SvgDelete/>
          </IconContainer>
        )}
      </ContentCont>
    </Touchable>
  );
};

const ContentCont = styled(View)<{ width: number }>`
  align-items: center;
  height: 100%;
  justify-content: center;
  width: ${({ width }) => width}px;
`;

const IconContainer = styled(View)<{ width: number }>`
  align-items: center;
  background-color: ${({ theme }) => theme.t.col.red};
  height: 100%;
  justify-content: center;
  width: ${({ width }) => width}px;
`;
