import { DbRes } from '../../../db/DbResources';
import { MObj } from '../../../lib/model/MObj';
import { StationBuilder } from './StationBuilder';
import { TStation } from './StationTypes';

export class Station extends MObj<typeof DbRes.Station> {
  constructor(station: TStation) {
    super(StationBuilder, station);
  }
}
