import {
  TMObj,
  TMObjMaybeDataCreator,
} from '../../../lib/model/ModelTypes';
import { TMenu } from '../menu/MenuTypes';
import {
  TMenuItem,
  TMenuItemId,
} from '../menuItem/MenuItemTypes';
import {
  FIELD_NAME,
  TObjList,
} from '../../../db/DbDefs';
import { TRKHost } from '../../../db/DbResources';
import {
  TRoom,
  TRoomId,
  TRoomUpdate,
} from '../room/RoomTypes';
import {
  TStation,
  TStationId,
} from '../station/StationTypes';
import {
  TTable,
  TTableId,
} from '../table/TableTypes';
import { ELocale } from '../../../locale/Locale';
import {CurrencyCodes, TCurrencyCode} from '../../../locale/Currencies';

export type THostId = string;

export const FIELD_HOST_EMAIL = 'email' as const;

export type THost = TMObj<TRKHost> & {
  [FIELD_HOST_EMAIL]: string;
  menu: TMenu;
  items?: TObjList<TMenuItemId, TMenuItem>;
  tables?: TObjList<TTableId, TTable>;
  rooms?: TObjList<TRoomId, TRoom>;
  stations?: TObjList<TStationId, TStation>;

  /**
   * Default batching start-ms for orders coming from guests
   * If not set, Batching.defaultGuestOrderBatchingTTLMs is used
   */
  guestOrderBatchingTTLMs?: number;

  /**
   * Default batching start-ms for orders coming from hosts
   * If not set, Batching.defaultHostOrderBatchingTTLMs is used
   */
  hostOrderBatchingTTLMs?: number;

  [FIELD_NAME]: string;
  description: string;
  logoImg?: string;
  currency?: TCurrencyCode;
  coverImg?: string;
  primaryColor?: string;
  secondaryColor?: string;
  mainLocale?: ELocale;
  denomination?: string;
  address?: string;
  phone?: string;
  vatId?: string;
  placeId?: string;
  coordinates?: string;
};

export type TRoomToTableCount = {
  room: TRoomUpdate;
  tableCount: number;
};

export type THostDataChange = {
  rooms: TRoomToTableCount[];
  stations: TStation[];
};

export type THostUpdate = TMObjMaybeDataCreator<TRKHost>;
