import * as React from 'react';
import { Colors } from '../config/Theme';
import { TIngredient, TIngredientId } from '../../../core/src/models/db/ingredient/IngredientTypes';
import { TObjList } from '../../../core/src/db/DbDefs';
import { Screen } from '../lib-components/screen/Screen';
import { NavTitleHandler } from '../lib/navigation/NavTitleHandler';
import { Strings } from '../../../core/src/locale/Strings';
import { TLocalOrderItem } from '../../../core/src/models/db/orderItem/OrderItemBuilder';

import { OrderItemIngredientModifier } from '../components/ingredientEditor/OrderItemIngredientModifier';
import { RCComp } from '../lib/routeContract/RCComp';
import { Navigation } from '../lib/navigation/Navigation';
import { OrderItemIngredientModifierBottomButton } from '../components/ingredientEditor/OrderItemIngredientModifierBottomButton';
import { OrderItemIngredientContextProvider } from '../components/ingredientEditor/OrderItemIngredientContext';

type HostOrderItemModifyOptionsCompState = {
  ingredients: TLocalOrderItem['ingredients'];
};

export class HostOrderItemModifyOptionsComp extends RCComp<'ScreenHostOrderItemModifyOptions', HostOrderItemModifyOptionsCompState> {
  protected readonly routeName = 'ScreenHostOrderItemModifyOptions' as const;

  private readonly navTitleHandler = new NavTitleHandler((props: this['props']) => {
    return Strings.gItemPreferencesXtraOptions()(props.locale);
  });

  constructor(props, context) {
    super(props, context);
    this.navTitleHandler.apply(props);
  }

  componentDidUpdate() {
    this.navTitleHandler.apply(this.props);
  }

  private onConfirmPress = async (pickedIngredients: TObjList<TIngredientId, TIngredient>) => {
    this.props.orderItemEditorSet({ ingredients: pickedIngredients });
    Navigation.goBack();
  };

  render() {
    const {
      locale,
      menuItem,
      orderItem,
    } = this.props;
    return (
      <OrderItemIngredientContextProvider
        availableIngredients={menuItem.ingredients || {}}
        initialSelectedIngredients={orderItem.ingredients || {}}>
        <Screen
          backgroundColor={Colors.white}
          usesReactNavigationHeader={true}
          hasStatusBar={true}
          statusBarBackgroundColor={Colors.white}
          statusBarBackgroundColorIsLight={true}
          underlayHeight={100}
          underlay={(
            <OrderItemIngredientModifierBottomButton
              locale={locale}
              availableIngredients={menuItem.ingredients || {}}
              onConfirmPress={this.onConfirmPress}
            />
          )}
        >
          <OrderItemIngredientModifier
            locale={locale}
          />
        </Screen>
      </OrderItemIngredientContextProvider>
    );
  }
}
