import { DbPaths } from '../../../db/DbRefs';
import {
  DbRes,
  TRKHostPrintQueueItem,
} from '../../../db/DbResources';
import {
  FIELD_ID,
  FIELD_PATH,
} from '../../../db/DbDefs';
import { HostPrintQueueItem } from './HostPrintQueueItem';
import { MObjDef } from '../../../lib/model/MObjDef';
import { THostPrintQueueItem } from './HostPrintQueueItemTypes';
import { TStation } from '../station/StationTypes';
import { defHostPrintQueueItem } from '../ModelDefaults';

export type TRawHostPrintQueueItem = {
  [FIELD_ID]: string;
  [FIELD_PATH]: string;
  ops: string;
  station: TStation;
};

const pathBuilderKeys = [
  'hostId',
  'hostPrintQueueItemId',
] as const;

export type THostPrintQueueItemPathBuilderParams = {
  [key in ((typeof pathBuilderKeys)[number])]: string
};

class HostPrintQueueItemBuilderBase extends MObjDef<TRKHostPrintQueueItem> {
  readonly resourceKey = DbRes.HostPrintQueueItem;

  protected readonly pathBuilderKeys = [...pathBuilderKeys];

  protected readonly _default = defHostPrintQueueItem;

  protected newChild(item: THostPrintQueueItem) {
    return new HostPrintQueueItem(item);
  }

  pathBuilder(params: THostPrintQueueItemPathBuilderParams): string {
    return DbPaths.printQueueHost(params);
  }
}

export const HostPrintQueueItemBuilder = new HostPrintQueueItemBuilderBase();
