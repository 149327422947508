import { defineReducer } from '../../../../lib-react/src/redux/redux';
import { actions } from '../Actions';
import { TOrderItemCreate } from '../../../../core/src/models/db/orderItem/OrderItemTypes';
import { OrderItem } from '../../../../core/src/models/db/orderItem/OrderItem';
import { defOrderItem } from '../../../../core/src/models/db/ModelDefaults';

export type TStateOrderItemEditor = {
  scopeId: string;
  orderItem: TOrderItemCreate;
  itemCount: number;
};

const initialState: TStateOrderItemEditor = {
  scopeId: '',
  orderItem: OrderItem.asCreator(defOrderItem),
  itemCount: 1,
};

export const { reducer: reducerOrderItemEditor } = defineReducer<TStateOrderItemEditor, typeof actions>({
  actions,
  initialState,
  reducer: (state, action, getType) => {
    switch (action.type) {
      case getType(actions.actionTypeOrderItemEditorBind):
        // The component remounted, keep ths current state
        if (action.payload.scopeId === state.scopeId) {
          return state;
        }

        return {
          ...initialState,
          scopeId: action.payload.scopeId,
          orderItem: action.payload.initialOrderItem,
        };
      case getType(actions.actionTypeOrderItemEditorSet):
        return {
          ...state,
          orderItem: {
            ...state.orderItem,
            ...action.payload,
          },
        };
      case getType(actions.actionTypeOrderItemEditorItemCountSet):
        return {
          ...state,
          itemCount: action.payload,
        };
    }
    return state;
  },
});
