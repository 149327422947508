import { ArchivedMObj } from '../models/db/archivedMObj/ArchivedMObj';
import { GuestSession } from '../models/db/guestSession/GuestSession';
import { Host } from '../models/db/host/Host';
import { HostMessage } from '../models/db/hostMessage/HostMessage';
import { HostPrintQueueItem } from '../models/db/hostPrintQueueItem/HostPrintQueueItem';
import { HostSubscription } from '../models/db/hostSubscription/HostSubscription';
import { Ingredient } from '../models/db/ingredient/Ingredient';
import { MObj } from '../lib/model/MObj';
import { Menu } from '../models/db/menu/Menu';
import { MenuItem } from '../models/db/menuItem/MenuItem';
import { ObjOfType } from '../lib/Types';
import { Order } from '../models/db/order/Order';
import { OrderItem } from '../models/db/orderItem/OrderItem';
import { Room } from '../models/db/room/Room';
import { Station } from '../models/db/station/Station';
import {
  TArchivedMObj,
  TArchivedMObjId,
} from '../models/db/archivedMObj/ArchivedMObjTypes';
import { TArchivedMObjPathBuilderParams } from '../models/db/archivedMObj/ArchivedMObjBuilder';
import {
  TGuestSession,
  TGuestSessionId,
} from '../models/db/guestSession/GuestSessionTypes';
import { TGuestSessionPathBuilderParams } from '../models/db/guestSession/GuestSessionBuilderBase';
import {
  THost,
  THostId,
} from '../models/db/host/HostTypes';
import {
  THostMessage,
  THostMessageId,
} from '../models/db/hostMessage/HostMessageTypes';
import { THostMessagePathBuilderParams } from '../models/db/hostMessage/HostMessageBuilder';
import { THostPathBuilderParams } from '../models/db/host/HostBuilder';
import {
  THostPrintQueueItem,
  THostPrintQueueItemId,
} from '../models/db/hostPrintQueueItem/HostPrintQueueItemTypes';
import { THostPrintQueueItemPathBuilderParams } from '../models/db/hostPrintQueueItem/HostPrintQueueItemBuilder';
import {
  THostSubscription,
  THostSubscriptionId,
} from '../models/db/hostSubscription/HostSubscriptionTypes';
import { THostSubscriptionPathBuilderParams } from '../models/db/hostSubscription/HostSubscriptionBuilder';
import {
  TIngredient,
  TIngredientId,
  TIngredientLocalizedFields,
} from '../models/db/ingredient/IngredientTypes';
import { TIngredientPathBuilderParams } from '../models/db/ingredient/IngredientBuilder';
import { TMObj } from '../lib/model/ModelTypes';
import {
  TMenu,
  TMenuId,
  TMenuLocalizedFields,
  menuLocalizedFields,
} from '../models/db/menu/MenuTypes';
import {
  TMenuItem,
  TMenuItemId,
  TMenuItemLocalizedFields,
  menuItemLocalizedFields,
} from '../models/db/menuItem/MenuItemTypes';
import { TMenuItemPathBuilderParams } from '../models/db/menuItem/MenuItemBuilder';
import { TMenuPathBuilderParams } from '../models/db/menu/MenuBuilder';
import {
  TOrder,
  TOrderId,
} from '../models/db/order/OrderTypes';
import {
  TOrderItem,
  TOrderItemId,
  TOrderItemLocalizedFields,
  orderItemLocalizedFields,
} from '../models/db/orderItem/OrderItemTypes';
import { TOrderItemPathBuilderParams } from '../models/db/orderItem/OrderItemBuilder';
import { TOrderPathBuilderParams } from '../models/db/order/OrderBuilder';
import {
  TRoom,
  TRoomId,
} from '../models/db/room/RoomTypes';
import { TRoomPathBuilderParams } from '../models/db/room/RoomBuilder';
import {
  TStation,
  TStationId,
} from '../models/db/station/StationTypes';
import { TStationPathBuilderParams } from '../models/db/station/StationBuilder';
import {
  TTable,
  TTableId,
} from '../models/db/table/TableTypes';
import { TTablePathBuilderParams } from '../models/db/table/TableBuilder';
import { Table } from '../models/db/table/Table';
import {
  THostPublicSubscription,
  THostPublicSubscriptionId,
} from '../models/db/HostPublicSubscription/HostPublicSubscriptionTypes';
import { HostPublicSubscription } from '../models/db/HostPublicSubscription/HostPublicSubscription';
import { THostPublicSubscriptionPathBuilderParams } from '../models/db/HostPublicSubscription/HostPublicSubscriptionBuilder';

export type TDbResName = keyof typeof DbRes;

export const DbRes = {
  Room: 'Room' as const,
  GuestSession: 'GuestSession' as const,
  Host: 'Host' as const,
  HostMessage: 'HostMessage' as const,
  HostPrintQueueItem: 'HostPrintQueueItem' as const,
  HostSubscription: 'HostSubscription' as const,
  HostPublicSubscription: 'HostPublicSubscription' as const,
  Ingredient: 'Ingredient' as const,
  Menu: 'Menu' as const,
  MenuItem: 'MenuItem' as const,
  Order: 'Order' as const,
  OrderItem: 'OrderItem' as const,
  Station: 'Station' as const,
  Table: 'Table' as const,
  ArchivedMObj: 'ArchivedMObj' as const,
} as const;

export type TRKGuestSession = typeof DbRes.GuestSession;
export type TRKHost = typeof DbRes.Host;
export type TRKHostMessage = typeof DbRes.HostMessage;
export type TRKHostPrintQueueItem = typeof DbRes.HostPrintQueueItem;
export type TRKHostSubscription = typeof DbRes.HostSubscription;
export type TRKHostPublicSubscription = typeof DbRes.HostPublicSubscription;
export type TRKIngredient = typeof DbRes.Ingredient;
export type TRKMenu = typeof DbRes.Menu;
export type TRKMenuItem = typeof DbRes.MenuItem;
export type TRKOrder = typeof DbRes.Order;
export type TRKOrderItem = typeof DbRes.OrderItem;
export type TRKStation = typeof DbRes.Station;
export type TRKTable = typeof DbRes.Table;
export type TRKRoom = typeof DbRes.Room;
export type TRKArchivedMObj = typeof DbRes.ArchivedMObj;

export const DbResLocalizeableFields = {
  GuestSession: [] as const,
  Host: [] as const,
  HostMessage: [] as const,
  HostPrintQueueItem: [] as const,
  HostSubscription: [] as const,
  HostPublicSubscription: [] as const,
  Ingredient: [] as const,
  Menu: menuLocalizedFields,
  MenuItem: menuItemLocalizedFields,
  Order: [] as const,
  OrderItem: orderItemLocalizedFields,
  Station: [] as const,
  Table: [] as const,
  Room: [] as const,
  ArchivedMObj: [],
} as const;

export type TDbResLocalizeableFields = ObjOfType<TDbResName, Record<string, string>, {
  GuestSession: Record<string, string>;
  Host: Record<string, string>;
  HostMessage: Record<string, string>;
  HostPrintQueueItem: Record<string, string>;
  HostSubscription: Record<string, string>;
  HostPublicSubscription: Record<string, string>;
  Ingredient: TIngredientLocalizedFields;
  Menu: TMenuLocalizedFields;
  MenuItem: TMenuItemLocalizedFields;
  Order: Record<string, string>;
  OrderItem: TOrderItemLocalizedFields;
  Station: Record<string, string>;
  Table: Record<string, string>;
  Room: Record<string, string>;
  ArchivedMObj: Record<string, string>;
}>;

export type TDbResData = ObjOfType<TDbResName, TMObj<any>, {
  GuestSession: TGuestSession;
  Host: THost;
  HostMessage: THostMessage;
  HostPrintQueueItem: THostPrintQueueItem;
  HostSubscription: THostSubscription;
  HostPublicSubscription: THostPublicSubscription;
  Ingredient: TIngredient;
  Menu: TMenu;
  MenuItem: TMenuItem;
  Order: TOrder;
  OrderItem: TOrderItem;
  Station: TStation;
  Table: TTable;
  Room: TRoom;
  ArchivedMObj: TArchivedMObj<any>;
}>;

export type TDbResDataId = ObjOfType<TDbResName, string, {
  GuestSession: TGuestSessionId;
  Host: THostId;
  HostMessage: THostMessageId;
  HostPrintQueueItem: THostPrintQueueItemId;
  HostSubscription: THostSubscriptionId;
  HostPublicSubscription: THostPublicSubscriptionId;
  Ingredient: TIngredientId;
  Menu: TMenuId;
  MenuItem: TMenuItemId;
  Order: TOrderId;
  OrderItem: TOrderItemId;
  Station: TStationId;
  Table: TTableId;
  Room: TRoomId;
  ArchivedMObj: TArchivedMObjId;
}>;

export type TDbResModel = ObjOfType<TDbResName, MObj<any>, {
  GuestSession: GuestSession;
  Host: Host;
  HostMessage: HostMessage;
  HostPrintQueueItem: HostPrintQueueItem;
  HostSubscription: HostSubscription;
  HostPublicSubscription: HostPublicSubscription;
  Ingredient: Ingredient;
  Menu: Menu;
  MenuItem: MenuItem;
  Order: Order;
  OrderItem: OrderItem;
  Station: Station;
  Table: Table;
  Room: Room;
  ArchivedMObj: ArchivedMObj<any>;
}>;

export type TDbResPbp = ObjOfType<TDbResName, any, {
  GuestSession: TGuestSessionPathBuilderParams;
  Host: THostPathBuilderParams;
  HostMessage: THostMessagePathBuilderParams;
  HostPrintQueueItem: THostPrintQueueItemPathBuilderParams;
  HostSubscription: THostSubscriptionPathBuilderParams;
  HostPublicSubscription: THostPublicSubscriptionPathBuilderParams;
  Ingredient: TIngredientPathBuilderParams;
  Menu: TMenuPathBuilderParams;
  MenuItem: TMenuItemPathBuilderParams;
  Order: TOrderPathBuilderParams;
  OrderItem: TOrderItemPathBuilderParams;
  Station: TStationPathBuilderParams;
  Table: TTablePathBuilderParams;
  Room: TRoomPathBuilderParams;
  ArchivedMObj: TArchivedMObjPathBuilderParams;
}>;
