import * as React from 'react';
import { Button } from './buttons/Button';
import { Colors } from '../config/Theme';
import {
  Text,
  View,
} from 'react-native';
import styled from 'styled-components/native';

export type TGenericInfoViewProps = {
  text?: string;
  title?: string;
  icon?: React.ReactNode;
  actionText?: string;
  onActionPress?: () => void;
};

export const GenericInfoView = ({
  text,
  title,
  icon,
  actionText,
  onActionPress,
}: TGenericInfoViewProps) => {
  return (
    <StyledView>
      {icon && (
        <>
          <EmptySpacer/>
          <IconCont>
            {icon}
          </IconCont>
        </>
      )}
      {title && (
        <StyledTitle>
          {title}
        </StyledTitle>
      )}
      {text && (
        <StyledText>
          {text}
        </StyledText>
      )}
      {(actionText && onActionPress) && (
        <ErrorButton
          title={actionText}
          onPress={onActionPress}
          block={true}
          height={45}
          color={Colors.white}
          backgroundColor={Colors.red}
        />
      )}
    </StyledView>
  );
};

const StyledView = styled(View)`
  flex: 1;
  height: 100%;
  padding: ${({ theme }) => theme.scaler.verticalScale(58)}px;
  width: 100%;
`;

const StyledTitle = styled(Text)`
  color: ${({ theme }) => theme.t.col.accentDark};
  font-family: ${({ theme }) => theme.t.font.nunitoSans};
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
`;

const StyledText = styled(StyledTitle)`
  font-size: 18px;
  line-height: 25px;
  margin-top: 20px;
`;

const EmptySpacer = styled(View)`
  height: 36px;
  width: 100%;
`;

const IconCont = styled(View)`
  align-items: center;
  justify-content: center;
`;

const ErrorButton = styled(Button)`
  margin-bottom: 20px;
  margin-top: 40px;
`;
