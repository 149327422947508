import {
  FIELD_NAME,
  TLocalizableObj,
  TObjList,
} from '../../../db/DbDefs';
import {
  TIngredient,
  TIngredientId,
  TIngredientUpdate,
} from '../ingredient/IngredientTypes';
import {
  TMObj,
  TMObjDataCreator,
  TMObjMaybeDataCreator,
} from '../../../lib/model/ModelTypes';
import { TMenuId } from '../menu/MenuTypes';
import { TRKMenuItem } from '../../../db/DbResources';
import { TStationId } from '../station/StationTypes';
import { TTagString } from '../../Tag';

export enum EMenuItemState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type TMenuItemDataChange = {
  name: string;
  price: number;
  description: string;
  stationId: TStationId;
  itemImg: string;
  shareable: boolean;
  allergens: TTagString;
  tags: TTagString;
  ingredients: TIngredientUpdate[];
};

export const menuItemLocalizedFields = [
  FIELD_NAME,
  'description',
] as const;

export type TMenuItemLocalizedFields = {
  [key in ((typeof menuItemLocalizedFields)[number])]: string
};

export type TMenuItemId = string;
export type TMenuItem = TMObj<TRKMenuItem> & TLocalizableObj<TMenuItemLocalizedFields> & {
  price: number;
  stationId: TStationId;
  itemImg: string;
  status: EMenuItemState;
  shareable?: boolean;
  tags?: TTagString;
  allergens?: TTagString;
  menuIds?: TObjList<TMenuId, TMenuId>;
  ingredients?: TObjList<TIngredientId, TIngredient>;
};

export type TMenuItemUpdate = TMObjMaybeDataCreator<TRKMenuItem>;
export type TMenuItemCreate = TMObjDataCreator<TRKMenuItem>;
