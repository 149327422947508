import {
  all,
  call,
  delay,
  put,
  spawn,
} from 'redux-saga/effects';
import { sagaDeepLinkListener } from './sagaDeepLinkListener';
import { sagaLockScreenListener } from './sagaLockScreenListener';
import { sagaOfflineListenerRoot } from './sagaOfflineListenerRoot';
import { sagaOnTableIdChange } from './sagaOnTableIdChange';
import { sagaSetLocaleInStrings } from './sagaSetLocaleInStrings';
import { sagaSpawnAndKeepAlive } from '../../../../lib-react/src/sagas/sagaSpawnAndKeepAlive';
import { sagaSubscribeToGuestSession } from './sagaSubscribeToGuestSession';
import { sagaSubscribeToHost } from './sagaSubscribeToHost';
import { sagaVerifyGuestSessionStillValid } from './sagaVerifyGuestSessionStillValid';
import { actions } from '../Actions';
import { Config } from '../../config/Config';
import { sagaEnsureStoreRehydrated } from './sagaEnsureStoreRehydrated';
import { sagaSubscribeToHostPublicSubscription } from './sagaSubscribeToHostPublicSubscription';
import { sagaValidateNavigationState } from './navigation/sagaValidateNavigationState';
import { sagaNavigate } from './navigation/sagaNavigate';
import { Navigation } from '../../lib/navigation/Navigation';

export function* rootSaga() {
  yield call(sagaEnsureStoreRehydrated);

  // Verify navigation path is aligned with store
  yield call(sagaValidateNavigationState);

  // Check if we should reset because of old guest session
  yield call(sagaVerifyGuestSessionStillValid);

  yield all([
    yield spawn(function* () {
      while (Navigation.navigation == null) {
        yield delay(0);
      }
      yield call(sagaValidateNavigationState);
    }),
    yield sagaSpawnAndKeepAlive('sagaNavigationRoot', sagaNavigate),
    yield sagaSpawnAndKeepAlive('sagaOfflineListenerRoot', sagaOfflineListenerRoot),
    yield sagaSpawnAndKeepAlive('sagaDeepLinkListener', sagaDeepLinkListener),
    yield sagaSpawnAndKeepAlive('sagaLockScreenListener', sagaLockScreenListener),
    yield sagaSpawnAndKeepAlive('sagaSetLocaleInStrings', sagaSetLocaleInStrings),
    yield sagaSpawnAndKeepAlive('sagaSubscribeToHost', sagaSubscribeToHost),
    yield sagaSpawnAndKeepAlive('sagaSubscribeToHostPublicSubscription', sagaSubscribeToHostPublicSubscription),
    yield sagaSpawnAndKeepAlive('sagaOnTableIdChange', sagaOnTableIdChange),
    yield sagaSpawnAndKeepAlive('sagaSubscribeToGuestSession', sagaSubscribeToGuestSession),
  ]);

  // Safe delay to ensure sagas running and show splash
  yield delay(Config.splashScreenMinTimeMs);

  // Continue and trigger sagas
  yield put(actions.actionTypeOnSagasLoaded());
}

