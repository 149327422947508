type TUnsplash = {
  photographerName: string;
  photographerUsername: string;
}

export function makeUnsplashReferralComponents({
                                                 photographerName,
                                                 photographerUsername,
                                               }: TUnsplash) {
  const unsplashAppName = 'alacarte';
  return {
    segment1Text: `By `,
    segment2LinkText: photographerName,
    segment2LinkHref: `https://unsplash.com/${photographerUsername}?utm_source=${unsplashAppName}&utm_medium=referral`,
    segment3Text: ' on ',
    segment4LinkText: `Unsplash`,
    segment4LinkHref: `https://unsplash.com/?utm_source=${unsplashAppName}&utm_medium=referral`,
  };
}