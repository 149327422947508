import { DbPaths } from '../../../db/DbRefs';
import { DbRes } from '../../../db/DbResources';
import { FIELD_PATH } from '../../../db/DbDefs';
import { MObjDef } from '../../../lib/model/MObjDef';
import { MenuItem } from './MenuItem';
import { TMenuItem } from './MenuItemTypes';
import { defMenuItem } from '../ModelDefaults';

const pathBuilderKeys = [
  'hostId',
  'menuItemId',
] as const;

export type TMenuItemPathBuilderParams = {
  [key in ((typeof pathBuilderKeys)[number])]: string
};

class MenuItemBuilderBase extends MObjDef<typeof DbRes.MenuItem> {
  readonly resourceKey = DbRes.MenuItem;

  protected readonly pathBuilderKeys = [...pathBuilderKeys];

  protected readonly _default = defMenuItem;

  protected newChild(item: TMenuItem) {
    return new MenuItem(item);
  }

  pathBuilder(params: TMenuItemPathBuilderParams): string {
    return DbPaths.menuItem(params);
  }

  public isTMenuItem(menuItem: any): boolean {
    const path = menuItem[FIELD_PATH];
    const expectedPathParams = this.getPathBuilderParamsFromDataPath(menuItem);
    const expectedPath = this.pathBuilder(expectedPathParams);
    return path === expectedPath;
  }
}

export const MenuItemBuilder = new MenuItemBuilderBase();

