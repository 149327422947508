import {LocaleDefinitions} from '../../locale/LocaleDefinitions';
import numeral from 'numeral';
import {ELocale} from '../../locale/Locale';
import {Currencies, DEFAULT_CURRENCY, TCurrencyCode} from '../../locale/Currencies';

Object.keys(LocaleDefinitions).forEach((locale) => {
  numeral.register('locale', locale, LocaleDefinitions[locale]);
});

numeral.locale(ELocale['en-US']);

export class Num {
  static formatPrice(_price: number | string, _def?: number): string {
    const def = _def ? `${_def}` : '';
    const parsed = _price && parseFloat(`${_price}`);
    return !Number.isNaN(parsed)
      ? numeral(parsed).format('0,0.00 $')
      : def;
  }

  static formatPrice2(
    _price: number | string,
    currency?: TCurrencyCode,
    _def?: number,
  ): string {
    const def = _def ? `${_def}` : '';
    const parsed = _price && parseFloat(`${_price}`);
    const currencySymbol = Currencies[currency ?? DEFAULT_CURRENCY]?.symbol ?? currency;
    return !Number.isNaN(parsed)
      ? `${numeral(parsed).format('0,0.00')} ${currencySymbol}`
      : `${def} ${currencySymbol}`;
  }

  static format(val: number | string, _def?: number): string {
    const def = _def ? `${_def}` : '';
    const parsed = val && parseFloat(`${val}`);
    return !Number.isNaN(parsed)
      ? numeral(parsed).format('0,0.00')
      : def;
  }

  static formatPercentage(val: number): string {
    return numeral(val).format('0.00%');
  }

  static parseFloat(_float: any): number {
    return numeral(_float).value();
  }
}
