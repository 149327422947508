import { Colors as CoreColors } from '../../../core/src/config/Colors';
import { TStyledComponentsTheme } from '../lib-components/theme/StyledComponentsTheme';
import { selectPlatform } from '../lib/Platform';

export const Colors = CoreColors;

export const Theme = {
  col: Colors,
  font: {
    nunitoSans: selectPlatform({
      ios: 'NunitoSans-Regular',
      android: 'NunitoSans-Regular',
      web: 'Nunito Sans',
      other: 'Nunito Sans',
    }),
  },
};

// @ts-ignore
declare module 'styled-components/native' {
  type Theme = TStyledComponentsTheme<typeof Theme>;

  export type DefaultTheme = TStyledComponentsTheme<typeof Theme>;
}
