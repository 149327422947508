import * as React from 'react';
import {
  RadioButtonContext,
  RadioButtonContextType,
} from './RadioButtonGroup';
import { StyledComponentsTheme } from '../../theme/StyledComponentsTheme';
import { TouchableOpacity } from 'react-native';
import {
  handlePress,
  isChecked,
} from './utils';
import MaterialCommunityIcon from 'react-native-paper/src/components/MaterialCommunityIcon';

type Props = {
  /**
   * Value of the radio button
   */
  value: string;
  /**
   * Status of radio button.
   */
  status?: 'checked' | 'unchecked';
  /**
   * Whether radio is disabled.
   */
  disabled?: boolean;
  /**
   * Function to execute on press.
   */
  onPress?: () => void;
  /**
   * Custom color for radio.
   */
  checkedColor: string;
  /**
   * Custom color for unchecked radio.
   */
  uncheckedColor: string;
  /**
   * testID to be used on tests.
   */
  testID?: string;
};

/**
 * Radio buttons allow the selection a single option from a set.
 * This component follows platform guidelines for iOS, but can be used
 * on any platform.
 *
 * <div class="screenshots">
 *   <figure>
 *     <img src="screenshots/radio-enabled.ios.png" />
 *     <figcaption>Enabled</figcaption>
 *   </figure>
 *   <figure>
 *     <img src="screenshots/radio-disabled.ios.png" />
 *     <figcaption>Disabled</figcaption>
 *   </figure>
 * </div>
 */
const RadioButtonIOS = ({
  disabled,
  onPress,
  status,
  value,
  testID,
  uncheckedColor,
  ...rest
}: Props) => {
  const { theme } = React.useContext(StyledComponentsTheme.Context);
  return (
    <RadioButtonContext.Consumer>
      {(context?: RadioButtonContextType) => {
        const checked = isChecked({
          contextValue: context?.value,
          status,
          value,
        }) === 'checked';

        return (
          <TouchableOpacity
            {...rest}
            onPress={
              disabled
                ? undefined
                : () => {
                  handlePress({
                    onPress,
                    value,
                    onValueChange: context?.onValueChange,
                  });
                }
            }
            accessibilityTraits={disabled ? ['button', 'disabled'] : 'button'}
            accessibilityComponentType={
              checked ? 'radiobutton_checked' : 'radiobutton_unchecked'
            }
            accessibilityRole="radio"
            accessibilityState={{
              disabled,
              checked,
            }}
            accessibilityLiveRegion="polite"
            style={{ borderRadius: 18 }}
            testID={testID}
          >
            <MaterialCommunityIcon
              allowFontScaling={false}
              name={checked ? 'checkbox-blank-circle' : 'checkbox-blank-circle-outline'}
              size={theme.scaler.verticalScale(24)}
              color={(checked ? rest.checkedColor : uncheckedColor) || '#FFFFFF'}
              direction="ltr"
            />
          </TouchableOpacity>
        );
      }}
    </RadioButtonContext.Consumer>
  );
};

RadioButtonIOS.displayName = 'RadioButton.IOS';

// @component-docs ignore-next-line
export { RadioButtonIOS };
