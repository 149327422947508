import { b64FromClearText, b64ToClearText } from '../../../HelperFunctions';

export const CommonRoutes = {
  DeferredDeeplink: {
    name: 'DeferredDeeplink' as const,
    path: '/fbDefer',
    secondaryPaths: {} as const,
    hashes: {} as const,
    pathParams: { in: undefined },
    params: {
      in: { data: '' as string },
      out: {
        // todo rename param
        b64Link: '' as string,
      },
      map(params) {
        return { b64Link: b64FromClearText(params.data) };
      },
      invert(params) {
        return { data: b64ToClearText(params.b64Link) };
      },
    },
  },
};
