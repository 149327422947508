import * as React from 'react';
import { InlineInputWithButton } from '../../lib-components/InlineInputWithButton';
import { Log } from '../../config/Log';
import { PromiseButton } from '../../lib-components/buttons/PromiseButton';
import { TextInput } from '../../lib-components/TextInput';
import { Colors } from '../../config/Theme';
import { RoutableCliGuestWeb } from '../../../../core/src/lib/apis/routes/RoutableCliGuest';

type TQRScanSimulator = {
  onScanData: (data: any) => Promise<void>;
};

export const QRScanSimulator = ({ onScanData }: TQRScanSimulator) => {
  const url = RoutableCliGuestWeb.ScreenSetupGuestSession.url({
    params: {},
    pathParams: { storeHostId: 'ZvoJVASKaqaQHXju1vwWDV43bFi2' },
  });
  const [scanData, setScanData] = React.useState(url);
  const onScanDataPress = React.useCallback(async () => {
    try {
      return await onScanData(scanData);
    } catch (e) {
      Log.v('QRScanSimulator', 'QRScanSimulator', 'Error', e);
    }
    return undefined;
  }, [scanData, onScanData]);
  return (
    <InlineInputWithButton
      input={(
        <TextInput
          value={scanData}
          onChangeText={setScanData}
          autoCapitalize={'none'}
          autoCorrect={false}
        />
      )}
      action={(
        <PromiseButton
          title={'SIMULATE'}
          onPress={onScanDataPress}
          loadingColor={Colors.accentDark}
        />
      )}
    />
  );
};

