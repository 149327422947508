import * as React from 'react';
import { Colors } from '../../config/Theme';
import { StyledComponentsTheme } from '../../lib-components/theme/StyledComponentsTheme';
import { SvgOrderMinus } from '../../img/SvgOrderMinus';
import { SvgOrderPlus } from '../../img/SvgOrderPlus';
import {
  Text,
  View,
} from 'react-native';
import { AlaTouchableWithoutFeedback } from '../../lib-components/Touchable';
import styled from 'styled-components/native';
import { OrderItemEditorContext } from './OrderItemEditorContext';

type TOrderItemEditorSectionItemCountProps = {
  //
};

export const OrderItemEditorSectionItemCount = (props: TOrderItemEditorSectionItemCountProps) => {
  const {
    itemCount,
    itemCountIncrement,
    itemCountDecrement,
  } = React.useContext(OrderItemEditorContext);

  const { theme } = React.useContext(StyledComponentsTheme.Context);
  return (
    <Root>
      <RowCont>
        <Row>
          <AlaTouchableWithoutFeedback
            disabled={itemCount <= 1}
            onPress={itemCountDecrement}>
            <PlusMinusIconCont>
              <SvgOrderMinus
                height={theme.scaler.verticalScale(plusMinusIconSize)}
                width={theme.scaler.verticalScale(plusMinusIconSize)}
                fill={itemCount <= 1 ? Colors.accentLight : Colors.black}
              />
            </PlusMinusIconCont>
          </AlaTouchableWithoutFeedback>
          <AddToOrderCountText>
            {itemCount}
          </AddToOrderCountText>
          <AlaTouchableWithoutFeedback
            onPress={itemCountIncrement}>
            <PlusMinusIconCont>
              <SvgOrderPlus
                height={theme.scaler.verticalScale(plusMinusIconSize)}
                width={theme.scaler.verticalScale(plusMinusIconSize)}
                fill={Colors.accentLight}
              />
            </PlusMinusIconCont>
          </AlaTouchableWithoutFeedback>
        </Row>
      </RowCont>
    </Root>
  );
};

const plusMinusIconSize = 20;

const Root = styled(View)`
  align-items: center;
  min-height: 80px;
`;

const RowCont = styled(View)`
  width: ${({ theme }) => theme.scaler.scale(200)}px;
`;

const Row = styled(View)`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

const PlusMinusIconCont = styled(View)`
  margin-vertical: ${({ theme }) => theme.scaler.verticalScale(6)}px;
  padding: ${({ theme }) => theme.scaler.verticalScale(24)}px;
`;

const AddToOrderCountText = styled(Text)`
  align-items: center;
  color: ${({ theme }) => theme.t.col.accentDark};
  font-family: ${({ theme }) => theme.t.font.nunitoSans};
  font-size: 36px;
  font-weight: 200;
  line-height: 49px;
  text-align: center;
`;
