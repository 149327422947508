import * as React from 'react';
import { Colors } from '../../config/Theme';
import {
  FIELD_ID,
  FIELD_NAME,
  TObjList,
} from '../../../../core/src/db/DbDefs';
import { Num } from '../../../../core/src/lib/formatters/Num';
import {
  TIngredient,
  TIngredientId,
} from '../../../../core/src/models/db/ingredient/IngredientTypes';
import {
  Text,
  View,
} from 'react-native';
import { Touchable } from '../../lib-components/Touchable';
import { localize } from '../../../../core/src/lib/formatters/LocaleLib';
import styled from 'styled-components/native';
import { AlaTextBold } from '../Text';
import { DEFAULT_CURRENCY } from '../../../../core/src/locale/Currencies';
import { useCurrentHost, useCurrentLanguage } from "../../hooks/hooks";

type TOrderItemModifyOptionProps = {
  title: string;
  ingredients: TIngredient[];
  selectedIngredients: TObjList<TIngredientId, TIngredient>;
  onItemPress: (ingredient: TIngredient) => void;
};

export const OrderItemModifyOption = ({
  title,
  ingredients,
  selectedIngredients,
  onItemPress,
}: TOrderItemModifyOptionProps) => {
  const language = useCurrentLanguage();
  const host = useCurrentHost();
  return (
    <Root>
      <TitleCont>
        <Title>
          {title}
        </Title>
      </TitleCont>
      <IngredientsCont>
        {ingredients.map((ingredient) => {
          const selected = !!selectedIngredients[ingredient[FIELD_ID]];
          return (
            <Touchable
              key={ingredient[FIELD_ID]}
              item={ingredient}
              onPress={onItemPress}>
              <IngredientCont
                selected={selected}>
                <IngredientText
                  selected={selected}>
                  {localize(ingredient, FIELD_NAME, language)}
                </IngredientText>
                <IngredientRightCont
                  selected={selected}>
                  {ingredient.price !== 0 && (
                    <IngredientText
                      selected={selected}>
                      {Num.formatPrice2(ingredient.price, host?.currency ?? DEFAULT_CURRENCY)}
                    </IngredientText>
                  )}
                </IngredientRightCont>
              </IngredientCont>
            </Touchable>
          );
        })}
      </IngredientsCont>
    </Root>
  );
};

const Root = styled(View)`

`;

const TitleCont = styled(View)`

`;

const Title = styled(AlaTextBold)`
  color: ${({ theme }) => theme.t.col.accentDark};
  font-size: 15px;
  text-transform: uppercase;
`;

const IngredientsCont = styled(View)`
`;

const A6A7B3Opacity01 = 'rgba(166, 167, 179, 0.1)';
const IngredientCont = styled(View)<{ selected: boolean }>`
  align-items: center;
  background-color: ${({ selected }) => (selected ? Colors.accentDark : A6A7B3Opacity01)};
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: space-between;
  margin-top: 10px;
  padding: 0 20px;
`;

const IngredientText = styled(Text)<{ selected: boolean }>`
  color: ${({ selected }) => (selected ? Colors.white : Colors.accentDark)};
  font-family: ${({ theme }) => theme.t.font.nunitoSans};
  font-size: 14px;
  line-height: 22px;
`;

const IngredientRightCont = styled(View)<{ selected: boolean }>`
  color: ${({ selected }) => (selected ? Colors.white : Colors.accentDark)};
  justify-content: center;
`;
