import * as _ from 'lodash';
import {
  FIELD_DEFAULT_LOCALE,
  FIELD_NAME,
} from '../../db/DbDefs';
import { TBuilderParamsBase } from './lib/buildRawUpdate';
import {
  menuLocalizedFields,
  TMenuUpdate,
} from '../db/menu/MenuTypes';
import { TRKMenu } from '../../db/DbResources';
import { addIfDefined } from '../../lib/HelperFunctions';
import {
  buildLocalizedFieldsUpdate,
  rebaseLocalizedItemToLocales,
  stripUnchangedValues,
  syncLocalizedItemToDefaultLocale,
} from './lib/localizedObjectUpdater';
import { ELocale, ELOCALE_GLOBAL_DEFAULT } from '../../locale/Locale';

type TMenuUpdateParams = {
  menuDataChange: Partial<TMenuUpdate>;
  localizedUpdateLocale: ELocale;
};

export async function menuUpdate({
  item: _item,
  menuDataChange,
  localizedUpdateLocale,
}: TBuilderParamsBase<TRKMenu> & TMenuUpdateParams) {
  const item = _.omit(_item, 'subMenus');
  const update = {
    // FIELD_NAME
    ...buildLocalizedFieldsUpdate(
      {
        localizedUpdateLocale,
        item,
      },
      [
        {
          fieldName: FIELD_NAME,
          updateValue: menuDataChange.name,
        },
      ],
    ),

    // coverCharge
    ...addIfDefined('coverCharge', menuDataChange.coverCharge),

    // isHidden
    ...addIfDefined('isHidden', menuDataChange.isHidden),

    // locales
    ...menuDataChange.locales && rebaseLocalizedItemToLocales({
      item,
      locales: menuDataChange.locales,
      localizedFields: menuLocalizedFields as any,
      updateValue: menuDataChange,
    }),
  };

  const localizedItem = syncLocalizedItemToDefaultLocale({
    item: _.merge({}, item, update),
    localizedFields: menuLocalizedFields as any,
    newDefaultLocale: _.get(menuDataChange.locales, '[0]')
      || item[FIELD_DEFAULT_LOCALE]
      || ELOCALE_GLOBAL_DEFAULT,
  });

  return stripUnchangedValues({
    initialItem: item,
    updatedItem: localizedItem,
  });
}
