import * as React from 'react';
import { FIELD_ID } from '../../../../core/src/db/DbDefs';
import { Strings } from '../../../../core/src/locale/Strings';
import { OrderItemEditorProvider } from './OrderItemEditorContext';
import { OrderItemEditorView } from './OrderItemEditorView';
import { TOrderItemCreate } from '../../../../core/src/models/db/orderItem/OrderItemTypes';
import {
  TMenuItem,
  TMenuItemId,
} from '../../../../core/src/models/db/menuItem/MenuItemTypes';
import { MenuItem } from '../../../../core/src/models/db/menuItem/MenuItem';
import { useCurrentLocale } from "../../hooks/hooks";

type TOrderItemCreatorProps = {
  menuItem: TMenuItem;
  onModifyOptions: (orderItem: TOrderItemCreate, menuItemId: TMenuItemId) => void;
  orderItemUpdate?: (orderItem: TOrderItemCreate, count: number) => Promise<void>;
  openOrderDelete?: () => Promise<void>;
};

export const OrderItemCreator = ({
  menuItem,
  onModifyOptions,
  orderItemUpdate,
  openOrderDelete,
}: TOrderItemCreatorProps) => {
  const locale = useCurrentLocale()
  return (
    <OrderItemEditorProvider
      scopeId={menuItem[FIELD_ID]}
      menuItem={menuItem}
      initialOrderItem={MenuItem.toLocalOrderItemData(menuItem)}
      allowMultiple={true}
      orderItemUpdate={orderItemUpdate}
      openOrderDelete={openOrderDelete}
    >
      <OrderItemEditorView
        buttonText={Strings.gAddToOrderCmd()(locale)}
        onModifyOptions={onModifyOptions}
      />
    </OrderItemEditorProvider>
  );
};
