import * as React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

export type TInlineInputWithButtonProps = {
  input: React.ReactNode;
  action: React.ReactNode;
  header?: React.ReactNode;
};

export const InlineInputWithButton = ({
  input,
  action,
  header,
}: TInlineInputWithButtonProps) => {
  return (
    <Root>
      {header && (
        <HeaderCont>
          {header}
        </HeaderCont>
      )}
      <RowContent>
        <InputCont>
          {input}
        </InputCont>
        <ActionCont>
          {action}
        </ActionCont>
      </RowContent>
    </Root>
  );
};

const Root = styled(View)`
  justify-content: center;
`;

const HeaderCont = styled(View)`
  align-items: center;
  margin-bottom: 24px;
`;

const RowContent = styled(View)`
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const InputCont = styled(View)`
  display: flex;
  flex: 1;
`;

const ActionCont = styled(View)`
  justify-content: center;
  margin-left: 6px;
  width: 96px;
`;
