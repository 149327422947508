import * as React from 'react';
import * as _ from 'lodash';
import { Colors } from '../config/Theme';
import {
  KeyboardTypeOptions,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { PromiseButton } from './buttons/PromiseButton';
import { Strings } from '../../../core/src/locale/Strings';
import {
  TUseStateWithExternalSyncProps,
  useStateWithExternalSync,
} from '../../../lib-react/src/hooks/useStateWithExternalSync';
import { TextInput } from './TextInput';
import { parseIntOrDefault } from '../../../core/src/lib/HelperFunctions';
import { useChangedAtLeastOnce } from '../../../lib-react/src/hooks/useChangedAtLeastOnce';
import styled from 'styled-components/native';
import { AlaTextBold } from '../components/Text';
import { ELocale } from '../../../core/src/locale/Locale';

type TFullScreenRequestTemplateProps = Omit<TUseStateWithExternalSyncProps<string>, 'value'> & {
  locale: ELocale;
  titlePrefix: string;
  title: string;
  request: string;
  resultPlaceholder: string;
  onContinue: (result: string) => Promise<any>;
  keyboardType?: KeyboardTypeOptions;
  mapResultToErrorMsg?: (result: string) => string | undefined;
  mapValueToField?: (value: string) => string;
  value?: string;
  darkView?: boolean;
};

function FullScreenRequestTemplateBase<Result>({
  locale,
  titlePrefix,
  title,
  request,
  resultPlaceholder,
  onContinue,
  keyboardType,
  mapResultToErrorMsg = (_.noop as any),
  mapValueToField = _.identity,
  value = '',
  syncingActive = false,
  onValueChange = (_.noop as any),
  darkView = false,
}: TFullScreenRequestTemplateProps) {
  const [syncedValue, setSyncedValue] = useStateWithExternalSync({
    value,
    syncingActive,
    onValueChange,
  });

  const valueChangedAtLeastOnce = useChangedAtLeastOnce(syncedValue);
  const errorMsg = valueChangedAtLeastOnce && mapResultToErrorMsg(syncedValue);

  return (
    <Root darkView={darkView}>
      <HeaderContainer>
        <TitlePrefix darkView={darkView} numberOfLines={2}>{titlePrefix}</TitlePrefix>
        <Title darkView={darkView} snumberOfLines={2}>{title}</Title>
        <Hairline darkView={darkView}/>
      </HeaderContainer>
      <ContentContainer>
        <RequestContainer>
          <Request darkView={darkView}>{request}</Request>
          <StyledTextInput
            darkView={darkView}
            autoFocus
            keyboardType={keyboardType}
            autoCapitalize={'characters'}
            value={mapValueToField(syncedValue)}
            placeholder={`${resultPlaceholder}`.toUpperCase()}
            placeholderTextColor={Colors.accentLight}
            onChangeText={setSyncedValue}
          />
        </RequestContainer>
        <FooterContainer>
          <PromiseButton
            item={syncedValue}
            onPress={onContinue}
            disabled={!!errorMsg}
            height={defaultInputHeight}
            color={Colors.white}
            backgroundColor={Colors.red}
            title={Strings.gActionContinue()(locale).toUpperCase()}
          />
          <ErrorMsgContainer>
            {errorMsg && (
              <ErrorMsg>
                {errorMsg}
              </ErrorMsg>
            )}
          </ErrorMsgContainer>
        </FooterContainer>
      </ContentContainer>
    </Root>
  );
}

const defaultInputHeight = 60;

type TDarkViewProps = {
  darkView: boolean;
};

function applyDarkView(darkView: boolean, light: string, dark: string) {
  return darkView
    ? dark
    : light;
}

export const FullScreenRequestTemplate = {
  View: FullScreenRequestTemplateBase,
  mappers: { intParser: (val: string) => `${parseIntOrDefault(val, '')}` },
};

const Root = styled(View)<TDarkViewProps>`
  background-color: ${({ darkView, theme }) => applyDarkView(darkView, theme.t.col.white, theme.t.col.accentDark)};
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 18px;
`;

const Hairline = styled(View)<TDarkViewProps>`
  background-color: ${({ darkView, theme }) => applyDarkView(darkView, theme.t.col.accentDark, theme.t.col.white)};
  height: ${StyleSheet.hairlineWidth}px;
  margin-top: ${({ theme }) => theme.scaler.verticalScale(20)}px;
  opacity: 0.2;
`;

const HeaderContainer = styled(View)`
  flex: 0.15;
  min-height: 98px;
`;

const ContentContainer = styled(View)`
  flex: 0.85;
  margin-top: ${({ theme }) => theme.scaler.verticalScale(18)}px;
`;

const TitlePrefix = styled(AlaTextBold)<TDarkViewProps>`
  color: ${({
    darkView,
    theme,
  }) => applyDarkView(darkView, theme.t.col.accentDark, theme.t.col.white)};
  font-size: 12px;
  letter-spacing: 1px;
  margin-top: ${({ theme }) => theme.scaler.verticalScale(24)}px;
  text-align: center;
  text-transform: uppercase;
`;

const Title = styled(AlaTextBold)<TDarkViewProps>`
  color: ${({ darkView, theme }) => applyDarkView(darkView, theme.t.col.accentDark, theme.t.col.white)};
  font-size: 30px;
  line-height: 33px;
  margin-top: 5px;
  text-align: center;
`;

const RequestContainer = styled(View)`
  margin-top: ${({ theme }) => theme.scaler.verticalScale(72)}px;
`;

const Request = styled(AlaTextBold)<TDarkViewProps>`
  color: ${({ darkView, theme }) => applyDarkView(darkView, theme.t.col.accentDark, theme.t.col.white)};
  font-family: ${({ theme }) => theme.t.font.nunitoSans};
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
`;

const StyledTextInput = styled(TextInput)<TDarkViewProps>`
  background-color: ${({ darkView, theme }) => applyDarkView(darkView, theme.t.col.white, theme.t.col.accentDark)};
  border-radius: 2px;
  color: ${({ darkView, theme }) => applyDarkView(darkView, theme.t.col.accentDark, theme.t.col.white)};
  font-size: 18px;
  font-weight: bold;
  height: ${defaultInputHeight};
  margin-top: ${({ theme }) => theme.scaler.verticalScale(14)}px;
  text-align: center;
`;

const FooterContainer = styled(View)`
  margin-top: ${({ theme }) => theme.scaler.verticalScale(20)}px;
  width: 100%;
`;

const ErrorMsgContainer = styled(View)`
  margin-top: ${({ theme }) => theme.scaler.verticalScale(16)}px;
`;

const ErrorMsg = styled(Text)`
  color: ${({ theme }) => theme.t.col.red};
  font-family: ${({ theme }) => theme.t.font.nunitoSans};
  font-size: ${({ theme }) => theme.scaler.verticalScale(12)}px;
  font-weight: bold;
  line-height: ${({ theme }) => theme.scaler.verticalScale(16)}px;
  text-align: center;
`;
