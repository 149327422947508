import { TState } from '../redux/Store';
import { TDispatch } from '../../../lib-react/src/redux/redux';
import {
  RCStackScreenProps,
  RouteContract,
  TOutNavParams,
  TRCMergedCheckedProps,
  TRCOwnProps,
} from '../lib/routeContract/RouteContract';
import { StackNavigationOptions } from '@react-navigation/stack';
import { TNavigationParamsWithMethod } from '../lib/navigation/Navigation';
import { NavConst } from '../lib/navigation/NavConst';
import { CheckPropsResultBuild } from '../lib/routeContract/RouteContractCheckProps';

export class OfflineOverlayRouteContract extends RouteContract<'ModalOfflineOverlay'> {
  protected readonly routeName = 'ModalOfflineOverlay' as const;

  onBeforeRemoveShouldAllowBack(props: TRCMergedCheckedProps<'ModalOfflineOverlay'>) {
    return true;
  }

  navigationOptions(props: RCStackScreenProps<TOutNavParams<'ModalOfflineOverlay'>>, navParams: TOutNavParams<'ModalOfflineOverlay'>): StackNavigationOptions {
    return { ...NavConst.modalStackNavOptions };
  }

  shouldSkipToRoute(state: TState, ownProps: TOutNavParams<'ModalOfflineOverlay'>): TNavigationParamsWithMethod<any> | undefined {
    return undefined;
  }

  mapStateToProps(state: TState, navParams: TOutNavParams<'ModalOfflineOverlay'>) {
    return { locale: state.app.locale };
  }

  mapDispatchToProps(dispatch: TDispatch, ownProps: TRCOwnProps<'ModalOfflineOverlay'>) {
    return {};
  }

  checkProps(props: ReturnType<this['mapStateToProps']>) {
    return CheckPropsResultBuild.READY(props);
  }
}
