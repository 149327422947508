import * as _ from 'lodash';
import {enumToArray} from '../lib/HelperFunctions';

export enum ELang {
  en = 'en',
  it = 'it',
  es = 'es',
}

export enum ELocale {
  'en-US' = 'en-US',
  'it-IT' = 'it-IT',
  'es-ES' = 'es-ES',


  // Unsupported locales below
  // 'af-NA' = 'af-NA',
  // 'ak-GH' = 'ak-GH',
  // 'sq-AL' = 'sq-AL',
  // 'am-ET' = 'am-ET',
  // 'ar-DZ' = 'ar-DZ',
  // 'hy-AM' = 'hy-AM',
  // 'as-IN' = 'as-IN',
  // 'az-AZ' = 'az-AZ',
  // 'bm-Latn_ML' = 'bm-Latn_ML',
  // 'eu-ES' = 'eu-ES',
  // 'be-BY' = 'be-BY',
  // 'bn-BD' = 'bn-BD',
  // 'bs-BA' = 'bs-BA',
  // 'br-FR' = 'br-FR',
  // 'bg-BG' = 'bg-BG',
  // 'my-MM' = 'my-MM',
  // 'ca-AD' = 'ca-AD',
  // 'zh-CN' = 'zh-CN',
  // 'kw-GB' = 'kw-GB',
  // 'hr-BA' = 'hr-BA',
  // 'cs-CZ' = 'cs-CZ',
  // 'da-DK' = 'da-DK',
  // 'nl-AW' = 'nl-AW',
  // 'dz-BT' = 'dz-BT',
  // 'en-CC' = 'en-CC',
  // 'et-EE' = 'et-EE',
  // 'ee-GH' = 'ee-GH',
  // 'fo-FO' = 'fo-FO',
  // 'fi-FI' = 'fi-FI',
  // 'fr-DZ' = 'fr-DZ',
  // 'ff-CM' = 'ff-CM',
  // 'gl-ES' = 'gl-ES',
  // 'lg-UG' = 'lg-UG',
  // 'ka-GE' = 'ka-GE',
  // 'de-AT' = 'de-AT',
  // 'el-CY' = 'el-CY',
  // 'gu-IN' = 'gu-IN',
  // 'ha-GH' = 'ha-GH',
  // 'he-IL' = 'he-IL',
  // 'hi-IN' = 'hi-IN',
  // 'hu-HU' = 'hu-HU',
  // 'is-IS' = 'is-IS',
  // 'ig-NG' = 'ig-NG',
  // 'id-ID' = 'id-ID',
  // 'ga-IE' = 'ga-IE',
  // 'ja-JP' = 'ja-JP',
  // 'kl-GL' = 'kl-GL',
  // 'kn-IN' = 'kn-IN',
  // 'ks-Arab_IN' = 'ks-Arab_IN',
  // 'kk-Cyrl_KZ' = 'kk-Cyrl_KZ',
  // 'km-KH' = 'km-KH',
  // 'ki-KE' = 'ki-KE',
  // 'rw-RW' = 'rw-RW',
  // 'ko-KP' = 'ko-KP',
  // 'ky-Cyrl_KG' = 'ky-Cyrl_KG',
  // 'lo-LA' = 'lo-LA',
  // 'lv-LV' = 'lv-LV',
  // 'ln-AO' = 'ln-AO',
  // 'lt-LT' = 'lt-LT',
  // 'lu-CD' = 'lu-CD',
  // 'lb-LU' = 'lb-LU',
  // 'mk-MK' = 'mk-MK',
  // 'mg-MG' = 'mg-MG',
  // 'ms-BN' = 'ms-BN',
  // 'ml-IN' = 'ml-IN',
  // 'mt-MT' = 'mt-MT',
  // 'gv-IM' = 'gv-IM',
  // 'mr-IN' = 'mr-IN',
  // 'mn-Cyrl_MN' = 'mn-Cyrl_MN',
  // 'ne-IN' = 'ne-IN',
  // 'nd-ZW' = 'nd-ZW',
  // 'se-FI' = 'se-FI',
  // 'no-NO' = 'no-NO',
  // 'nb-NO' = 'nb-NO',
  // 'nn-NO' = 'nn-NO',
  // 'or-IN' = 'or-IN',
  // 'om-ET' = 'om-ET',
  // 'os-GE' = 'os-GE',
  // 'ps-AF' = 'ps-AF',
  // 'fa-AF' = 'fa-AF',
  // 'pl-PL' = 'pl-PL',
  // 'pt-AO' = 'pt-AO',
  // 'pa-Arab_PK' = 'pa-Arab_PK',
  // 'qu-BO' = 'qu-BO',
  // 'ro-MD' = 'ro-MD',
  // 'rm-CH' = 'rm-CH',
  // 'rn-BI' = 'rn-BI',
  // 'ru-BY' = 'ru-BY',
  // 'sg-CF' = 'sg-CF',
  // 'gd-GB' = 'gd-GB',
  // 'sr-BA' = 'sr-BA',
  // 'sh-BA' = 'sh-BA',
  // 'sn-ZW' = 'sn-ZW',
  // 'ii-CN' = 'ii-CN',
  // 'si-LK' = 'si-LK',
  // 'sk-SK' = 'sk-SK',
  // 'sl-SI' = 'sl-SI',
  // 'so-DJ' = 'so-DJ',
  // 'sw-KE' = 'sw-KE',
  // 'sv-AX' = 'sv-AX',
  // 'tl-PH' = 'tl-PH',
  // 'ta-IN' = 'ta-IN',
  // 'te-IN' = 'te-IN',
  // 'th-TH' = 'th-TH',
  // 'bo-CN' = 'bo-CN',
  // 'ti-ER' = 'ti-ER',
  // 'to-TO' = 'to-TO',
  // 'tr-CY' = 'tr-CY',
  // 'uk-UA' = 'uk-UA',
  // 'ur-IN' = 'ur-IN',
  // 'ug-Arab_CN' = 'ug-Arab_CN',
  // 'uz-AF' = 'uz-AF',
  // 'vi-VN' = 'vi-VN',
  // 'cy-GB' = 'cy-GB',
  // 'fy-NL' = 'fy-NL',
  // 'yo-BJ' = 'yo-BJ',
  // 'zu-ZA' = 'zu-ZA',
}

const mapLocaleToLang: {
  [K in ELocale]: ELang;
} = {
  [ELocale['en-US']]: ELang.en,
  [ELocale['it-IT']]: ELang.it,
  [ELocale['es-ES']]: ELang.es,
};

export const ELOCALE_GLOBAL_DEFAULT = ELocale['en-US'];

const mapLangToLocale = _.invert(mapLocaleToLang) as {
  [K in ELang]: ELocale;
};

export const SupportedLocales: ELocale[] = enumToArray<ELocale>(ELocale);

export function langToELocale(lang: ELang): ELocale {
  return mapLangToLocale[lang] || ELOCALE_GLOBAL_DEFAULT;
}

export function localeToLang(locale: ELocale): ELang {
  return mapLocaleToLang[locale] || mapLocaleToLang[ELOCALE_GLOBAL_DEFAULT];
}
