import * as React from 'react';
import * as _ from 'lodash';
import { Colors } from '../../config/Theme';
import { EIngredientType, TIngredient, } from '../../../../core/src/models/db/ingredient/IngredientTypes';
import { FIELD_NAME } from '../../../../core/src/db/DbDefs';
import { Ingredient } from '../../../../core/src/models/db/ingredient/Ingredient';
import { TOrderItem } from '../../../../core/src/models/db/orderItem/OrderItemTypes';
import { Text, View, } from 'react-native';
import { localize } from '../../../../core/src/lib/formatters/LocaleLib';
import styled from 'styled-components/native';
import { LockedOrdersListConst } from './LockedOrdersListConst';
import { useCurrentLanguage } from "../../hooks/hooks";

type TOrderItemExtrasListProps = {
  orderItem: TOrderItem;
};

export const OrderItemExtrasList = ({ orderItem }: TOrderItemExtrasListProps) => {
  const language = useCurrentLanguage();
  if (_.size(orderItem.ingredients) <= 0) {
    return null;
  }

  return (
    <Root>
      {_.values(orderItem.ingredients)
        .filter(Ingredient.filterType(EIngredientType.OPTION))
        .map((ingredient: TIngredient, index) => (
          <ExtraText
            key={`ing-option-${index}`}
            color={Colors.accentLight}>
            {`+ ${localize(ingredient, FIELD_NAME, language)}`}
          </ExtraText>
        ))}
      {_.values(orderItem.ingredients)
        .filter(Ingredient.filterType(EIngredientType.ADDABLE))
        .map((ingredient: TIngredient, index) => (
          <ExtraText
            key={`ing-addable-${index}`}
            color={Colors.accentLight}>
            {`+ ${localize(ingredient, FIELD_NAME, language)}`}
          </ExtraText>
        ))}
      {_.values(orderItem.ingredients)
        .filter(Ingredient.filterType(EIngredientType.REMOVABLE))
        .map((ingredient: TIngredient, index) => (
          <ExtraText
            key={`ing-removable-${index}`}
            color={Colors.red}>
            {`- ${localize(ingredient, FIELD_NAME, language)}`}
          </ExtraText>
        ))}
    </Root>
  );
};

const Root = styled(View)`
  flex: 1;
  padding: 0 30px;
`;

const ExtraText = styled(Text)<{ color: string }>`
  color: ${({ color }) => color};
  font-family: ${({ theme }) => theme.t.font.nunitoSans};
  font-size: 14px;
  height: ${LockedOrdersListConst.ingredientLineHeight}px;
  line-height: ${LockedOrdersListConst.ingredientLineHeight}px;

  margin-bottom: ${LockedOrdersListConst.ingredientPadding}px;
  margin-left: 4px;
  max-height: ${LockedOrdersListConst.ingredientLineHeight}px;
  min-height: ${LockedOrdersListConst.ingredientLineHeight}px;
`;
