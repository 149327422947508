import { DbPaths } from '../../../db/DbRefs';
import {
  DbRes,
  TRKHost,
} from '../../../db/DbResources';
import { Host } from './Host';
import { MObjDef } from '../../../lib/model/MObjDef';
import { THost } from './HostTypes';
import { defHost } from '../ModelDefaults';

const pathBuilderKeys = [
  'hostId',
] as const;

export type THostPathBuilderParams = {
  [key in ((typeof pathBuilderKeys)[number])]: string
};

class HostBuilderBase extends MObjDef<TRKHost> {
  readonly resourceKey = DbRes.Host;

  protected readonly pathBuilderKeys = [...pathBuilderKeys];

  protected readonly _default = defHost;

  protected newChild(item: THost) {
    return new Host(item);
  }

  pathBuilder(params: THostPathBuilderParams): string {
    return DbPaths.host(params);
  }
}

export const HostBuilder = new HostBuilderBase();

