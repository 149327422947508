import { DbPaths } from '../../../db/DbRefs';
import {
  DbRes,
  TRKHostSubscription,
} from '../../../db/DbResources';
import { HostSubscription } from './HostSubscription';
import { MObjDef } from '../../../lib/model/MObjDef';
import { THostSubscription } from './HostSubscriptionTypes';
import { defHostSubscription } from '../ModelDefaults';

const pathBuilderKeys = [
  'hostId',
] as const;

export type THostSubscriptionPathBuilderParams = {
  [key in ((typeof pathBuilderKeys)[number])]: string
};

class HostSubscriptionBuilderBase extends MObjDef<TRKHostSubscription> {
  readonly resourceKey = DbRes.HostSubscription;

  protected readonly pathBuilderKeys = [...pathBuilderKeys];

  protected readonly _default = defHostSubscription;

  protected newChild(item: THostSubscription) {
    return new HostSubscription(item);
  }

  pathBuilder(params: THostSubscriptionPathBuilderParams): string {
    return DbPaths.hostSubscription(params);
  }
}

export const HostSubscriptionBuilder = new HostSubscriptionBuilderBase();
