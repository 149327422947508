import { DbRes } from '../../../db/DbResources';
import { HostPrintQueueItemBuilder } from './HostPrintQueueItemBuilder';
import { MObj } from '../../../lib/model/MObj';
import { THostPrintQueueItem } from './HostPrintQueueItemTypes';

export class HostPrintQueueItem extends MObj<typeof DbRes.HostPrintQueueItem> {
  constructor(hostPrintQueueItem: THostPrintQueueItem) {
    super(HostPrintQueueItemBuilder, hostPrintQueueItem);
  }

  remove = async () => {
    await this.ref().remove();
  };
}
