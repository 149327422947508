import * as _ from 'lodash';
import {
  deepMerge,
  isNotEmptyString,
  memoizeFunc,
} from '../../HelperFunctions';
import { TURLParams, URLParams } from './URLParams';
import { RecursivePartial, UndefinedOptional } from '../../Types';
import stringify from 'fast-json-stable-stringify';
import { Utils } from '../../Utils';
import { Base64 } from '../../Base64';

const ALLOWED_PROTOCOLS = [
  'exp',
  'http',
  'https',
];

const ALLOWED_TOP_LEVEL_DOMAINS = [
  'com',
  'it',
  'de',
  'co.uk',
  'es',
  'de',
  'net',
];

const REGEX_MATCH_PROTOCOL = new RegExp(`^(${ALLOWED_PROTOCOLS.join('|')})://`);
const REGEX_MATCH_HOST = new RegExp(`(?:${REGEX_MATCH_PROTOCOL.source})?(www\\.)?(.+)\.(${ALLOWED_TOP_LEVEL_DOMAINS.join('|')}).*`);

type TURLToolboxParams<PathParams extends TURLParams<any, any, any, any>, SearchParams extends TURLParams<any, any, any, any>> = {
  pathTemplate: string;
  pathParams: PathParams;
  searchParams: SearchParams;
};

export type TURLToolboxDeconstructedURL<PathParams extends TURLParams<any, any, any, any>, SearchParams extends TURLParams<any, any, any, any>> = {
  baseURL?: string;
  hash?: string;
  pathParams: PathParams['in'];
  searchParams: SearchParams['in'];
};

type TURLToolboxURLMatcherParams = {
  protocols: string[];
  baseUrls: string[];
  postfix?: string;
};

export class URLToolbox<PathParams extends TURLParams<any, any, any, any>, SearchParams extends TURLParams<any, any, any, any>> {
  static mergeParams<Def1 extends TURLParams<any, any>, Def2 extends TURLParams<any, any>>(def1: Def1, def2: Def2) {
    return {
      in: deepMerge(def1.in, def2.in),
      out: deepMerge(def1.out || def1.in, def2.out || def1.in),
      map(p: any) {
        return {
          ...(def1.map && def1.map(_.pick(p, _.keys(def1.in)))),
          ...(def2.map && def2.map(_.pick(p, _.keys(def2.in)))),
        };
      },
      invert(p: any) {
        return {
          ...(def1.invert && def1.invert(_.pick(p, _.keys(def1.out)))),
          ...(def2.invert && def2.invert(_.pick(p, _.keys(def2.out)))),
        };
      },
    } as TURLParams<Def1['in'] & Def2['in'], Def1['out'] & Def2['out']>;
  }

  static deconstructSearchParams<Def extends TURLParams<any, any>>(def: Def, constructed: string) {
    const { url } = URLToolbox.makeSafeURL(constructed);
    const params = new URLParams<Def>(def);
    return params.invertFromURLSearchParams(url.searchParams);
  }

  static safeEncode(obj: any): string {
    return Utils.safe(
      () => Utils.encodeURIComponent(Base64.b64FromClearText(stringify(obj))),
      () => '',
    ) as string;
  }

  static safeDecode(value: string): any {
    return Utils.safe(
      () => JSON.parse(Base64.b64ToClearText(Utils.decodeURIComponent(value))),
      () => ({}),
    );
  }

  static hasProtocol(url: string | undefined) {
    return REGEX_MATCH_PROTOCOL.test(url || '');
  }

  static hasHost(url: string | undefined) {
    return REGEX_MATCH_HOST.test(url || '');
  }

  static makeSafeURL(url: string | undefined) {
    let prefix = '';

    if (!this.hasHost(url)) {
      prefix = `fake-host.com`;
    }

    if (!this.hasProtocol(url)) {
      prefix = `https://${prefix}`;
    }

    return {
      url: new URL(`${prefix}${url}`),
      prefix,
    };
  }

  static buildDomainMatcherRegexp(params: TURLToolboxURLMatcherParams) {
    const protocolsStr = params.protocols
      .join('|');

    const baseUrlsStr = params.baseUrls
      .map((domain) => domain.replace('.', '\\.'))
      .join('|');

    return new RegExp(`(?:(?:${protocolsStr}):\\/\\/)?(?:${baseUrlsStr})${params.postfix || ''}`);
  }

  private readonly def: TURLToolboxParams<PathParams, SearchParams>;
  private readonly pathParams: URLParams<PathParams>;
  private readonly searchParams: URLParams<SearchParams>;

  constructor(def: TURLToolboxParams<PathParams, SearchParams>) {
    this.def = def;
    this.pathParams = new URLParams<PathParams>(this.def.pathParams);
    this.searchParams = new URLParams<SearchParams>(this.def.searchParams);
  }

  readonly constructURL = (deconstructed: UndefinedOptional<TURLToolboxDeconstructedURL<PathParams, SearchParams>>) => {
    // @ts-ignore // todo fix
    const path = _.keys(deconstructed.pathParams).reduce((_path, key) => {
      // @ts-ignore // todo fix
      return _path.replace(`:${key}`, (deconstructed.pathParams || {})[key]);
    }, this.def.pathTemplate);

    const baseURL = deconstructed.baseURL
      ? deconstructed.baseURL
      : '';

    const {
      url,
      prefix,
    } = URLToolbox.makeSafeURL(`${baseURL}${path}`);

    const searchParamsMapped = this.searchParams
    // @ts-ignore // todo fix
      .map(deconstructed.searchParams);

    _.keys(searchParamsMapped).forEach((key) => {
      const value = searchParamsMapped[key];
      value != null && url.searchParams.set(key, value);
    });

    if (isNotEmptyString(deconstructed.hash)) {
      url.hash = deconstructed.hash;
    }

    return url
      .toString()
      .replace(prefix, '');
  };

  readonly deconstructURL = (constructed: string): TURLToolboxDeconstructedURL<PathParams, SearchParams> => {
    const {
      url,
      prefix,
    } = URLToolbox.makeSafeURL(constructed);
    const match = this.pathMatcher().exec(url.pathname);

    // Hermes (used by cgr) doesn't support regex named groups (<>) so we have to do this manually for now
    const pathParams = this.def.pathTemplate
      .split('/')
      .filter((pathSegment) => pathSegment.startsWith(':'))
      .reduce((acc, pathSegment, idx) => {
        acc[pathSegment.replace(':', '')] = _.get(match, idx + 1);
        return acc;
      }, {} as any);

    const searchParams = this.searchParams.invertFromURLSearchParams(url.searchParams);

    const newURL = new URL(url.toString());
    const pathMatcher = this.pathMatcher();
    newURL.pathname = newURL.pathname.replace(pathMatcher, '');

    const baseURL = newURL.toString()
      .replace(prefix, '')
      .replace(`?${url.searchParams.toString()}`, '')
      .replace(url.hash, '');

    return {
      baseURL: _.trimEnd(baseURL, '/'),
      hash: url.hash,
      searchParams,
      pathParams,
    };
  };

  readonly deconstructURLIfMatch = (constructed: string): TURLToolboxDeconstructedURL<PathParams, SearchParams> | undefined => {
    const { url } = URLToolbox.makeSafeURL(constructed);

    if (this.pathMatcher().test(url.pathname)) {
      return this.deconstructURL(constructed);
    }
  };

  readonly updateURL = (constructed: string, update: RecursivePartial<TURLToolboxDeconstructedURL<PathParams, SearchParams>> = {}) => {
    const deconstructed: TURLToolboxDeconstructedURL<PathParams, SearchParams> = this.deconstructURL(constructed);
    return this.constructURL(deepMerge(deconstructed, update));
  };

  readonly pathMatcher = memoizeFunc(() => {
    const pathParams = new URLParams(this.def.pathParams);

    const pathTemplateRegexString = pathParams.keysOut.reduce((acc, key) => {
      return acc.replace(`/:${key}`, `(?:/([^/]*))?`);
    }, this.def.pathTemplate);

    return new RegExp(pathTemplateRegexString);
  });

  readonly matcher = (params: Omit<TURLToolboxURLMatcherParams, 'postfix'>) => {
    const domainMatch = URLToolbox.buildDomainMatcherRegexp(params);
    return new RegExp(`${domainMatch.source}${this.pathMatcher().source}.*`);
  };
}
