import * as React from 'react';
import { Strings } from '../../../../core/src/locale/Strings';
import { StyledComponentsTheme } from '../../lib-components/theme/StyledComponentsTheme';
import { SvgOrderPrecedenceArrowDown } from '../../img/SvgOrderPrecedenceArrowDown';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { AlaTextBold } from '../Text';
import { useCurrentLocale } from "../../hooks/hooks";

type TOpenOrderListSectionIndexGeneralProps = {
  //
};

export const OpenOrderListSectionIndex0 = (props: TOpenOrderListSectionIndexGeneralProps) => {
  const locale = useCurrentLocale();
  const { theme } = React.useContext(StyledComponentsTheme.Context);
  return (
    <OpenOrderListSectionIndex
      title={Strings.gOrderSummary1stCourse()(locale)}
      backgroundColor={theme.t.col.transparent}
      textColor={theme.t.col.accentDark}
      arrowIconVisible={false}
    />
  );
};
export const OpenOrderListSectionIndex1 = (props: TOpenOrderListSectionIndexGeneralProps) => {
  const locale = useCurrentLocale();
  const { theme } = React.useContext(StyledComponentsTheme.Context);
  return (
    <OpenOrderListSectionIndex
      title={Strings.gOrderSummaryDragToCourse2()(locale)}
      backgroundColor={theme.t.col.accentDark}
      textColor={theme.t.col.white}
      arrowIconVisible={true}
    />
  );
};

export const OpenOrderListSectionIndexOther = (props: TOpenOrderListSectionIndexGeneralProps) => {
  const locale = useCurrentLocale();
  const { theme } = React.useContext(StyledComponentsTheme.Context);
  return (
    <OpenOrderListSectionIndex
      title={Strings.gOrderSummaryDragToCourse3()(locale)}
      backgroundColor={theme.t.col.accentLight}
      textColor={theme.t.col.white}
      arrowIconVisible={true}
    />
  );
};

type TOpenOrderListSectionIndexProps = {
  title: string;
  backgroundColor: string;
  textColor: string;
  arrowIconVisible: boolean;
};

export const OpenOrderListSectionIndex = ({
                                            title,
                                            backgroundColor,
                                            textColor,
                                            arrowIconVisible,
                                          }: TOpenOrderListSectionIndexProps) => {
  return (
    <SectionContainer backgroundColor={backgroundColor}>
      {arrowIconVisible && (
        <ArrowContainer>
          <SvgOrderPrecedenceArrowDown/>
        </ArrowContainer>
      )}
      <SectionText textColor={textColor}>
        {title.toUpperCase()}
      </SectionText>
    </SectionContainer>
  );
};

const itemVerticalPadding = 10;
const sectionTextLineHeight = 14;

const SectionContainer = styled(View)<{ backgroundColor: string }>`
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: ${itemVerticalPadding}px 20px;
`;

const SectionText = styled(AlaTextBold)<{ textColor: string }>`
  color: ${({ textColor }) => textColor};
  font-size: 10px;
  letter-spacing: 0.5px;
  line-height: ${({ theme }) => theme.scaler.verticalScale(sectionTextLineHeight)}px;
`;

const ArrowContainer = styled(View)`
  margin-right: 10px;
`;

