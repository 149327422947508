import {
  FIELD_ID,
  TObjList,
} from '../../../../core/src/db/DbDefs';
import { SubMenusAndItemsList } from '../SubMenusAndItemsList';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import {
  TMenu,
  TMenuId,
} from '../../../../core/src/models/db/menu/MenuTypes';
import {
  TMenuItem,
  TMenuItemId,
} from '../../../../core/src/models/db/menuItem/MenuItemTypes';
import { TOrder } from '../../../../core/src/models/db/order/OrderTypes';
import React from 'react';
import styled from 'styled-components/native';

type THostMenuListProps = {
  host: THost;
  menu: TMenu;
  items: TObjList<TMenuItemId, TMenuItem>;
  openOrderData?: TOrder;
  onMenuPress?: (menuId: TMenuId) => void;
  onMenuItemPress?: (menuItem: TMenuItem) => void;
};

export const HostMenuList = ({
  host,
  openOrderData,
  menu,
  items,
  onMenuPress,
  onMenuItemPress,
}: THostMenuListProps) => {
  const onPress = React.useCallback((pressedMenu: TMenu) => {
    return onMenuPress && onMenuPress(pressedMenu[FIELD_ID]);
  }, [onMenuPress]);

  return (
    <StyledMenuList
      host={host}
      orderData={openOrderData}
      menu={menu}
      items={items}
      onMenuPress={onPress}
      onMenuItemPress={onMenuItemPress}
    />
  );
};

const StyledMenuList = styled(SubMenusAndItemsList)`
  
`;
