import { TState } from '../redux/Store';
import { TDispatch } from '../../../lib-react/src/redux/redux';
import {
  RCStackScreenProps,
  RouteContract,
  TOutNavParams,
  TRCMergedCheckedProps,
  TRCOwnProps,
} from '../lib/routeContract/RouteContract';
import { StackNavigationOptions } from '@react-navigation/stack';
import { TNavigationParamsWithMethod } from '../lib/navigation/Navigation';
import { NavConst } from '../lib/navigation/NavConst';
import { CheckPropsResultBuild } from '../lib/routeContract/RouteContractCheckProps';

export class RouteHostOrderRequestCompleteContract extends RouteContract<'ScreenHostOrderRequestComplete'> {
  protected readonly routeName = 'ScreenHostOrderRequestComplete' as const;

  onBeforeRemoveShouldAllowBack(props: TRCMergedCheckedProps<'ScreenHostOrderRequestComplete'>) {
    return true;
  }

  navigationOptions(props: RCStackScreenProps<TOutNavParams<'ScreenHostOrderRequestComplete'>>, navParams: TOutNavParams<'ScreenHostOrderRequestComplete'>): StackNavigationOptions {
    return {
      headerShown: false,
      cardStyleInterpolator: NavConst.cardStyleInterpolators.simpleFade,
      ...NavConst.stackNavOptions,
    };
  }

  shouldSkipToRoute(state: TState, ownProps: TOutNavParams<'ScreenHostOrderRequestComplete'>): TNavigationParamsWithMethod<any> | undefined {
    return undefined;
  }

  mapStateToProps(state: TState, navParams: TOutNavParams<'ScreenHostOrderRequestComplete'>) {
    const hostId = navParams.pathParams.storeHostId;
    const tableId = navParams.pathParams.storeTableId;
    return {
      locale: state.app.locale,
      hostId,
      tableId,
    };
  }

  mapDispatchToProps(dispatch: TDispatch, ownProps: TRCOwnProps<'ScreenHostOrderRequestComplete'>) {
    return {};
  }

  checkProps(props: ReturnType<this['mapStateToProps']>) {
    return CheckPropsResultBuild.READY(props);
  }
}
