import { Strings as Lang } from './Lang';
import { FirstParameter } from '../lib/Types';
import * as _ from 'lodash';
import { ELocale, ELOCALE_GLOBAL_DEFAULT, localeToLang, SupportedLocales } from './Locale';

const defaultLang = 'it' as const;
type TDefaultLang = typeof defaultLang;

type TStrings = typeof Lang;

type TStringKeys = keyof TStrings[TDefaultLang];

type TStringParameters<K extends TStringKeys> = FirstParameter<TStrings[TDefaultLang][K]>;

type TStringsByKey = {
  [K in TStringKeys]: (params?: TStringParameters<K>) => (locale: ELocale) => string;
};

type TStringsByLocale = {
  [L in ELocale]: {
    [K in TStringKeys]: (params?: TStringParameters<K>) => string
  }
};

type TStrings2 = TStringsByKey & TStringsByLocale & {
  setLocale: (locale: ELocale) => TStrings2;
  keys: {
    [K in TStringKeys]: K
  };
};

// @ts-ignore
class Strings2Base {
  private locale: ELocale = ELOCALE_GLOBAL_DEFAULT;

  // Reflect keys
  readonly keys = _.keys(Lang[defaultLang]).reduce((acc, key) => {
    acc[key] = key;
    return acc;
  }, {});

  constructor() {
    // Reflect locale accessors
    SupportedLocales.forEach((locale) => {
      const lang = localeToLang(locale);
      this[locale] = new Proxy({}, {
        get(target, prop) {
          return Lang[lang][prop];
        },
      });
    });

    // Reflect key accessors
    _.keys(Lang[defaultLang]).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      this[key] = function (params: any) {
        return function (locale: ELocale = that.locale) {
          const lang = localeToLang(locale);
          return Lang[lang][key](params);
        };
      };
    });
  }

  readonly setLocale = (locale: ELocale) => {
    this.locale = locale;
    return this;
  };
}

export const Strings = new Strings2Base() as any as TStrings2;
