import * as React from 'react';
import { OrderItemModifyOption } from './OrderItemModifyOption';
import { Strings } from '../../../../core/src/locale/Strings';
import { ScrollView, View } from 'react-native';
import styled from 'styled-components/native';
import { Ingredient } from '../../../../core/src/models/db/ingredient/Ingredient';
import * as _ from 'lodash';
import { withScreenContext } from '../../lib-components/screen/withScreenContext';
import { OrderItemIngredientContext } from './OrderItemIngredientContext';
import { useCurrentLocale } from "../../hooks/hooks";

type TOrderItemIngredientModifier = {
  //
};

export const OrderItemIngredientModifier = (props: TOrderItemIngredientModifier) => {
  const locale = useCurrentLocale();
  const {
    availableIngredients,
    selectedIngredients,
    onIngredientToggle,
  } = React.useContext(OrderItemIngredientContext);

  const available = Ingredient.groupByIngredientType(_.values(availableIngredients));
  return (
    <Root>
      <ScrollableContent
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}>
        {available.extraOptions.length > 0 && (
          <IngredientTypeSection>
            <OrderItemModifyOption
              ingredients={available.extraOptions}
              selectedIngredients={selectedIngredients}
              onItemPress={onIngredientToggle}
              title={Strings.gItemPreferencesXtraOptions()(locale)}
            />
          </IngredientTypeSection>
        )}
        {available.extraRemovables.length > 0 && (
          <IngredientTypeSection>
            <OrderItemModifyOption
              ingredients={available.extraRemovables}
              selectedIngredients={selectedIngredients}
              onItemPress={onIngredientToggle}
              title={Strings.gItemPreferencesRemovable()(locale)}
            />
          </IngredientTypeSection>
        )}
        {available.extraAddables.length > 0 && (
          <IngredientTypeSection>
            <OrderItemModifyOption
              ingredients={available.extraAddables}
              selectedIngredients={selectedIngredients}
              onItemPress={onIngredientToggle}
              title={Strings.gItemPreferencesAddable()(locale)}
            />
          </IngredientTypeSection>
        )}
      </ScrollableContent>
    </Root>
  );
};

const Root = styled(View)`
  padding: 20px 20px 0 20px;
`;

const ScrollableContent = withScreenContext(styled(ScrollView)`
  height: ${({ screen }) => screen.contentHeight}px;
  max-height: ${({ screen }) => screen.contentHeight}px;
  min-height: ${({ screen }) => screen.contentHeight}px;
`);

const IngredientTypeSection = styled(View)`
  margin-top: 30px;
`;
