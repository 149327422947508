import { EGuestSessionStatus } from '../../../../core/src/models/db/guestSession/GuestSessionTypes';
import { Log } from '../../config/Log';
import { Refs } from '../../../../core/src/db/DbRefs';
import { actions } from '../Actions';
import { ensureOpenOrderExists } from '../functions/ensureOpenOrderExists';
import { put } from 'redux-saga/effects';
import { sagaBindRefByKey } from '../../../../lib-react/src/sagas/sagaBindRefByKey';
import {
  sagaMonitorSelector,
  selectUndefinedAsClearTask,
} from '../../../../lib-react/src/sagas/sagaMonitorSelector';
import { selectGuestSessionPbp } from '../selectors';
import { actionResetSessionAndNavigate } from '../actions/NavigationActions';

export function* sagaSubscribeToGuestSession() {
  Log.v('sagaSubscribeToGuestSession', 'sagaSubscribeToGuestSession', `starting`);
  yield sagaMonitorSelector(['*'], selectUndefinedAsClearTask(selectGuestSessionPbp), function* (params) {
    const {
      hostId,
      tableId,
    } = params;
    Log.v(
      'sagaSubscribeToGuestSession',
      'sagaSubscribeToGuestSession',
      `hostId=${hostId} tableId=${tableId} Subscribing`,
    );

    const ref = Refs.guestSession({
      hostId,
      tableId,
    });

    const guestSession = yield ref.val();
    yield put(actions.actionTypeGuestSessionSet({ guestSessionData: guestSession }));

    yield sagaBindRefByKey('sagaSubscribeToGuestSession', ref, function* (updatedGuestSession) {
      if (updatedGuestSession == null || updatedGuestSession.status === EGuestSessionStatus.CLOSED) {
        yield put(actionResetSessionAndNavigate() as any);
        return;
      }

      yield ensureOpenOrderExists(updatedGuestSession);
      yield put(actions.actionTypeGuestSessionSet({ guestSessionData: updatedGuestSession }));
    });
  });
}

