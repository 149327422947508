import { DbRes } from '../../../db/DbResources';
import { MObjCreatorById } from '../../../lib/model/MObjCreator';
import { RoomBuilder } from './RoomBuilder';

class RoomCreatorBase extends MObjCreatorById<typeof DbRes.Room, 'roomId'> {
  constructor() {
    super(RoomBuilder);
  }

  protected readonly idKey = 'roomId';
}

export const RoomCreator = new RoomCreatorBase();
