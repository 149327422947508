import * as _ from 'lodash';
import { Dt } from '../../lib/formatters/Dt';
import {
  EIngredientType,
  TIngredient,
} from '../../models/db/ingredient/IngredientTypes';
import { FIELD_NAME } from '../../db/DbDefs';
import { Host } from '../../models/db/host/Host';
import { PrintFormat } from './PrintFormat';
import {
  PrintOps,
  TPrintOp,
} from '../PrintOps';
import { Strings } from '../../locale/Strings';
import { THost } from '../../models/db/host/HostTypes';
import { TMObjDataCreator } from '../../lib/model/ModelTypes';
import { TRKHostPrintQueueItem } from '../../db/DbResources';
import {
  TStation,
  TStationId,
} from '../../models/db/station/StationTypes';
import { localize } from '../../lib/formatters/LocaleLib';
import {
  addIfDefinedArr,
  nowMs,
} from '../../lib/HelperFunctions';
import { ELocale, ELOCALE_GLOBAL_DEFAULT } from '../../locale/Locale';

type TPrintBuilderParams = {
  host: THost;
  destinationStationId: TStationId;
};

export abstract class PrintBuilder {
  protected readonly host: THost;

  protected readonly locale: ELocale;

  protected readonly destinationStation: TStation;

  constructor(params: TPrintBuilderParams) {
    this.host = params.host;
    this.locale = this.host.mainLocale || ELOCALE_GLOBAL_DEFAULT;
    this.destinationStation = Host.findStationByIdOrThrow(this.host, params.destinationStationId);
    this.build = this.build.bind(this);
    this.printIngredient = this.printIngredient.bind(this);
  }

  build(): TMObjDataCreator<TRKHostPrintQueueItem> {
    return {
      station: this.destinationStation,
      ops: JSON.stringify([
        // Keep default size at 0
        PrintOps.printOpSize(0, 0),

        ...this.buildPrintOps(),
        PrintOps.printOpNewLine(1),
        PrintOps.printOpNewLine(1),
        PrintOps.printOpNewLine(1),
        PrintOps.printOpEmitSound(1, 3),
      ]),
    };
  }

  abstract buildPrintOps(): TPrintOp[];

  printIngredient(ingredient: TIngredient): TPrintOp[] {
    const name = localize(ingredient, FIELD_NAME, this.locale);
    const type = PrintBuilder.ingredientTypeToSymbol(ingredient.type);
    return [
      PrintOps.printOpText(`${PrintFormat.strIndent(1)}${type} ${name}`),
    ];
  }

  buildTableIndicatorHeaderFull = (params: { destinationStationName: string; tableName: string; roomName: string; note?: string }): TPrintOp[] => {
    return _.flattenDeep([
      this.buildTableIndicatorHeader(params),
      PrintOps.printOpAlign('CT'),
      PrintOps.printOpSize(1, 1),
      PrintFormat.bold(this.strHostTableInfo(params.tableName, params.roomName)),
      ...addIfDefinedArr(params.note, PrintOps.printOpText(params.note || '')),
      PrintOps.printOpSize(0, 0),
      PrintOps.printOpAlign('LT'),
    ]);
  };

  buildTableIndicatorHeader = (params: { destinationStationName: string }): TPrintOp[] => {
    return _.flattenDeep([
      PrintOps.printOpAlign('CT'),
      PrintOps.printOpText(this.strTimeNow()),
      PrintOps.printOpSize(1, 1),
      PrintFormat.bold(`${params.destinationStationName}`.toUpperCase()),
      PrintOps.printOpSize(0, 0),
      PrintOps.printOpAlign('LT'),
    ]);
  };

  strHostTableInfo(tableName: string, roomName: string): string {
    return `${roomName}: ${Strings.printerTable()(this.locale)} ${tableName}`.toUpperCase();
  }

  strTimeNow() {
    return Dt.dateTimeFormat(nowMs(), this.locale);
  }

  buildKeyValueTable = ({ key, value, left = 0.5 }: { key: string; value: string; left?: number }) => {
    return [
      PrintOps.printOpTableCustom([
        {
          width: left,
          text: key,
          align: 'LEFT',
        },
        {
          width: 1 - left,
          text: value,
          align: 'RIGHT',
        },
      ]),
    ];
  };

  buildKeyValueTableBold = ({ key, value }: { key: string; value: string }) => {
    return [
      PrintOps.printOpTableCustom([
        {
          width: 0.5,
          text: key,
          align: 'LEFT',
          style: 'B',
        },
        {
          width: 0.5,
          text: value,
          align: 'RIGHT',
          style: 'B',
        },
      ]),
    ];
  };

  static ingredientTypeToSymbol(type: EIngredientType) {
    switch (type) {
      case EIngredientType.ADDABLE:
        return '+';
      case EIngredientType.REMOVABLE:
        return '-';
      case EIngredientType.OPTION:
        return 'x';
    }
  }
}
