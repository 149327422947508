import {
  FIELD_NAME,
  TLocalizableObj,
} from '../../../db/DbDefs';
import {
  TMObj,
  TMObjDataCreator,
  TMObjMaybeDataCreator,
} from '../../../lib/model/ModelTypes';
import { TRKIngredient } from '../../../db/DbResources';

export enum EIngredientType {
  REMOVABLE = 'REMOVABLE',
  ADDABLE = 'ADDABLE',
  OPTION = 'OPTION',
}

export const ingredientLocalizedFields = [
  FIELD_NAME,
] as const;

export type TIngredientLocalizedFields = {
  [key in ((typeof ingredientLocalizedFields)[number])]: string
};

export type TIngredientUpdate = TMObjMaybeDataCreator<TRKIngredient>;
export type TIngredientCreate = TMObjDataCreator<TRKIngredient>;

export type TIngredientId = string;
export type TIngredient = TMObj<TRKIngredient> & TLocalizableObj<TIngredientLocalizedFields> & {
  price: number;
  type: EIngredientType;
};
