import { Process } from '../../../core/src/config/Process';

export const Config = {
  appName: '@alacarte/lib-react',

  // Process and secrets
  services: {},
  env: Process.env.NODE_ENV,
  isCI: Process.env.CI,
};

