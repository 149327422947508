import { TState } from '../redux/Store';
import { bindActionCreators } from '../../../lib-react/src/redux/redux';
import {
  RCStackScreenProps,
  RouteContract,
  TOutNavParams,
  TRCMergedCheckedProps,
  TRCOwnProps,
} from '../lib/routeContract/RouteContract';
import { StackNavigationOptions } from '@react-navigation/stack';
import { Navigation, TNavigationParamsWithMethod } from '../lib/navigation/Navigation';
import { NavConst } from '../lib/navigation/NavConst';
import { CheckPropsResultBuild } from '../lib/routeContract/RouteContractCheckProps';
import { Strings } from '../../../core/src/locale/Strings';
import { actionResetSessionAndNavigate } from '../redux/actions/NavigationActions';
import { RoutesCliGuest } from '../../../core/src/lib/apis/routes/RoutableCliGuest';

export class RouteHostHomeContract extends RouteContract<'ScreenHostHome'> {
  protected readonly routeName = 'ScreenHostHome' as const;

  onBeforeRemoveShouldAllowBack(props: TRCMergedCheckedProps<'ScreenHostHome'>) {
    const { locale } = props;

    // Navigation.routePush(RoutesCliGuest.ModalConfirmationDialog.name, {
    //   params: {
    //     title: Strings.gConfirmExitTitle()(locale),
    //     description: Strings.gConfirmExitDescription()(locale),
    //     positiveButtonText: Strings.gConfirmExitPositiveButtonText()(locale),
    //     negativeButtonText: Strings.gConfirmExitNegativeButtonText()(locale),
    //     positiveButtonOnClick: () => {
    //       Navigation.hideRouteIfCurrent(RoutesCliGuest.ModalConfirmationDialog.name);
    //     },
    //     negativeButtonOnClick: async () => {
    //       Navigation.hideRouteIfCurrent(RoutesCliGuest.ModalConfirmationDialog.name);
    //       await props.resetSessionAndNavigate();
    //     },
    //   },
    //   pathParams: {},
    // });

    return true;
  }

  navigationOptions(props: RCStackScreenProps<TOutNavParams<'ScreenHostHome'>>, navParams: TOutNavParams<'ScreenHostHome'>): StackNavigationOptions {
    return {
      ...NavConst.stackNavOptions,
      headerShown: false,
      animationEnabled: false,
    };
  }

  shouldSkipToRoute(state: TState, ownProps: TOutNavParams<'ScreenHostHome'>): TNavigationParamsWithMethod<any> | undefined {
    if (state.host.hostId == null) {
      return {
        routeName: RoutesCliGuest.ScreenSetupScanHostQR.name,
        params: {
          params: {},
          pathParams: {},
        },
        method: 'reset',
      };
    }

    return undefined;
  }

  mapStateToProps(state: TState, navParams: TOutNavParams<'ScreenHostHome'>) {
    return {
      locale: state.app.locale,
      language: state.app.language,
      hostData: state.host.hostData,
      guestSession: state.guestSession.guestSessionData,
      openOrder: state.guestSession.openOrder,
    };
  }

  mapDispatchToProps(dispatch: any, ownProps: TRCOwnProps<'ScreenHostHome'>) {
    return {
      resetSessionAndNavigate: bindActionCreators(actionResetSessionAndNavigate, dispatch),
    };
  }

  checkProps(props: ReturnType<this['mapStateToProps']>) {
    const { hostData } = props;

    if (hostData == null) {
      return CheckPropsResultBuild.WAIT();
    }

    return CheckPropsResultBuild.READY({
      ...props,
      hostData,
    });
  }
}
