import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { TState } from "../redux/Store";
import { actions } from "../redux/Actions";
import {TLanguage} from '../../../../packages/core/src/locale/Languages'

export function useCurrentHost() {
  return useSelector((store: TState) => store.host.hostData);
}

export function useCurrentLanguage() {
  return useSelector((store: TState) => store.app.language);
}

export function useCurrentLocale() {
  return useSelector((store: TState) => store.app.locale);
}

export function useChangeCurrentLocale() {
  const dispatch = useDispatch();
  return React.useCallback((locale: TLanguage) => {
    dispatch(actions.actionTypeAppSetLocale({locale}))
  }, [dispatch]);
}