import * as React from 'react';
import { StyleProp, View, ViewStyle, } from 'react-native';
import { SvgFrozenTag } from '../img/tags/SvgFrozenTag';
import { SvgSpicyTag } from '../img/tags/SvgSpicyTag';
import { SvgVeganTag } from '../img/tags/SvgVeganTag';
import { SvgVegetarianTag } from '../img/tags/SvgVegetarianTag';
import { SvgGlutenFreeSelected } from '../img/tags/SvgGlutenFreeSelected';
import { SvgCaffeineFreeFullIcon } from "../img/tags/SvgCaffeineFreeFullIcon";
import { SvgDairyFreeFullIcon } from "../img/tags/SvgDairyFreeFullIcon";
import { SvgNewFullIcon } from "../img/tags/SvgNewFullIcon";
import { SvgNutsFullIcon } from "../img/tags/SvgNutsFullIcon";
import { SvgRecommendedFullIcon } from "../img/tags/SvgRecommendedFullIcon";
import { SvgSesameFullIcon } from "../img/tags/SvgSesameFullIcon";

type TMenuItemTag = {
  tag: string;
  style?: StyleProp<ViewStyle>;
};

const TagToSvgMap = {
  '#frozen': SvgFrozenTag,
  '#spicy': SvgSpicyTag,
  '#vegan': SvgVeganTag,
  '#vegetarian': SvgVegetarianTag,
  '#caffeinefree': SvgCaffeineFreeFullIcon,
  '#dairyfree': SvgDairyFreeFullIcon,
  '#glutenfree': SvgGlutenFreeSelected,
  '#new': SvgNewFullIcon,
  '#nutsfree': SvgNutsFullIcon,
  '#recommended': SvgRecommendedFullIcon,
  '#sesamefree': SvgSesameFullIcon,
};

export const MenuItemTag = ({
                              tag,
                              style,
                            }: TMenuItemTag) => {
  const LocalTag = TagToSvgMap[`${tag}`.toLowerCase()];
  if (!LocalTag) {
    return null;
  }
  return (
    <View style={style}>
      <LocalTag
        width={tagHeight}
        height={tagHeight}
      />
    </View>
  );
};

const tagHeight = 50;
