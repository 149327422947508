import * as React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import {
  EExitHostButtonTheme,
  ExitHostButton,
  TExitHostButtonProps,
} from './ExitHostButton';

type TAbsoluteExitHostButtonOverlayProps = TExitHostButtonProps & {
  children: React.ReactNode;
};

export const AbsoluteExitHostButtonOverlay = ({
  locale,
  children,
}: TAbsoluteExitHostButtonOverlayProps) => {
  return (
    <Root>
      <AbsoluteView>
        <ExitHostButton
          locale={locale}
          themeStyle={EExitHostButtonTheme.backgroundTransparent}
        />
      </AbsoluteView>
      <ChildrenWrapper>
        {children}
      </ChildrenWrapper>
    </Root>
  );
};

const Root = styled(View)`
  flex: 1;
`;

const ChildrenWrapper = styled(View)`
  flex: 1;
`;

const AbsoluteView = styled(View)`
  elevation: 3;
  margin-top: ${({ theme }) => theme.dim.statusBarHeight}px;
  position: absolute;
  z-index: 3;
`;
