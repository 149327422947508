import * as React from 'react';
import { Colors } from '../../config/Theme';
import { GuestSession } from '../../../../core/src/models/db/guestSession/GuestSession';
import { Host } from '../../../../core/src/models/db/host/Host';
import { Num } from '../../../../core/src/lib/formatters/Num';
import { Order } from '../../../../core/src/models/db/order/Order';
import { PromiseButton } from '../../lib-components/buttons/PromiseButton';
import { Strings } from '../../../../core/src/locale/Strings';
import { TGuestSession } from '../../../../core/src/models/db/guestSession/GuestSessionTypes';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import { TOrder } from '../../../../core/src/models/db/order/OrderTypes';
import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import { useSyncedDataRef } from '../../../../lib-react/src/hooks/useSyncedDataRef';
import { useOnOrderPress } from '../confirmationDialogs/UseOnOrderPress';
import { ShadowView2 } from '../../lib-components/ShadowView';
import { ELocale } from '../../../../core/src/locale/Locale';

export type TGuestSessionCheckoutUnderlayProps = {
  locale: ELocale;
  hostData: THost;
  guestSessionData: TGuestSession;
  openOrderData: TOrder;
  orderPressEnabled: boolean;
  onOrder: () => Promise<void>;
  billRequestEnabled: boolean;
  onBillRequestPress: () => Promise<void>;
};

export const GuestSessionCheckoutUnderlay = ({
  locale,
  guestSessionData,
  hostData,
  openOrderData,

  orderPressEnabled,
  onOrder,

  billRequestEnabled,
  onBillRequestPress,
}: TGuestSessionCheckoutUnderlayProps) => {
  const onOrderRef = useSyncedDataRef(onOrder);
  const onOrderPress = useOnOrderPress({
    onOrderRef,
  });

  const coverCharge = GuestSession.getTotalCoverPrice(guestSessionData, Host.getCoverCharge(hostData));
  const totalOrderPrice = Order.getTotalPrice(openOrderData);
  const totalTablePrice = GuestSession.getTotalPrice(guestSessionData);
  return (
    <Root>
      <Content>
        <CheckoutRow>
          <CheckoutCoverName>
            {Strings.gOrderSummaryCoverCharge()(locale)}
          </CheckoutCoverName>
          <CheckoutCoverNameValue>
            {Num.formatPrice2(coverCharge, hostData?.currency)}
          </CheckoutCoverNameValue>
        </CheckoutRow>
        {totalOrderPrice > 0 && (<CheckoutRowTotal>
          <CheckoutCoverName>
            {Strings.gOrderSummaryTotalBill()(locale)}
          </CheckoutCoverName>
          <CheckoutCoverNameValue>
            {Num.formatPrice2(totalOrderPrice, hostData?.currency)}
          </CheckoutCoverNameValue>
        </CheckoutRowTotal>)}
        <CheckoutRowTotal>
          <CheckoutCoverName>
            {Strings.gOrderSummaryTotalTableBill()(locale)}
          </CheckoutCoverName>
          <CheckoutCoverNameValue>
            {Num.formatPrice2(coverCharge + totalTablePrice, hostData?.currency)}
          </CheckoutCoverNameValue>
        </CheckoutRowTotal>
        <CheckoutRowButtons>
          <PromiseButton
            block={false}
            width={checkoutButtonWidth}
            onPress={onOrderPress}
            disabled={!orderPressEnabled}
            color={Colors.white}
            backgroundColor={Colors.red}
            title={Strings.gOrderConfirmCmd()(locale).toUpperCase()}
          />
          <PromiseButton
            block={false}
            width={checkoutButtonWidth}
            disabled={!billRequestEnabled}
            onPress={onBillRequestPress}
            color={Colors.white}
            backgroundColor={Colors.accentDark}
            title={Strings.gOrderBillRequestCmd()(locale).toUpperCase()}
          />
        </CheckoutRowButtons>
      </Content>
    </Root>
  );
};

const checkoutButtonWidth = 158;

const Root = styled(ShadowView2)`
  background-color: ${({ theme }) => theme.t.col.white};
  flex: 1;
`;

const Content = styled(View)`
  display: flex;
  flex: 1;
  padding: 20px 20px 80px 20px;
`;

const CheckoutRow = styled(View)`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`;

const CheckoutRowTotal = styled(View)`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`;

const CheckoutRowButtons = styled(View)`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const CheckoutCoverName = styled(Text)`
  color: ${({ theme }) => theme.t.col.accentLight};
  font-family: ${({ theme }) => theme.t.font.nunitoSans};
  font-size: 16px;
  line-height: 22px;
`;

const CheckoutCoverNameValue = styled(Text)`
  color: ${({ theme }) => theme.t.col.accentLight};
  font-family: ${({ theme }) => theme.t.font.nunitoSans};
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  text-align: right;
`;
