import { DbRes } from '../../../db/DbResources';
import { MObjCreatorById } from '../../../lib/model/MObjCreator';
import { MenuItemBuilder } from './MenuItemBuilder';

export class MenuItemCreatorBase extends MObjCreatorById<typeof DbRes.MenuItem, 'menuItemId'> {
  constructor() {
    super(MenuItemBuilder);
  }

  protected readonly idKey = 'menuItemId';
}

export const MenuItemCreator = new MenuItemCreatorBase();
