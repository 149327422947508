import * as React from 'react';
import { NativeBaseProvider } from 'native-base';
import {
  LoadPersistanceAndRunSagas,
  StoreProvider,
  TState,
} from './redux/Store';
import { StyledComponentsTheme } from './lib-components/theme/StyledComponentsTheme';
import { Theme } from './config/Theme';
import { actionAppSignInAnonymously } from './redux/reducers/App';
import { useDispatch } from '../../lib-react/src/redux/redux';
import { usePromiseLoader } from '../../lib-react/src/hooks/usePromiseLoader';
import { useSelector } from 'react-redux';
import { ReactX } from './lib/ReactX';
import { linking } from './lib/navigation/Linking';
import { NavigationContainer } from '@react-navigation/native';
import { AppMain } from './app/AppMain';
import { ActionSheetProvider } from './components/ActionSheet';
import { LogBox } from 'react-native';

// eslint-disable-next-line
LogBox.ignoreAllLogs(true);

type TChildrenProps = {
  children: React.ReactNode;
};

const SnackbarListener = ({ children }: TChildrenProps) => {
  const dispatch = useDispatch();
  const snackbar = useSelector((state: TState) => state.snackbars.snackbar);
  React.useEffect(() => {
    const action = snackbar && snackbar.action;
    snackbar && ReactX.Snackbar.show({
      backgroundColor: snackbar.backgroundColor,
      duration: snackbar.duration,
      fontFamily: Theme.font.nunitoSans,
      textColor: snackbar.textColor,
      text: snackbar.text,
      ...(action ? {
        text: action.text,
        textColor: action.textColor,
        onPress: () => {
          action.onPress(dispatch);
        },
      } : {}),
    });
  }, [snackbar, dispatch]);
  return (
    <>
      {children}
    </>
  );
};

const Authenticator = ({ children }: TChildrenProps) => {
  const dispatch = useDispatch();
  usePromiseLoader(() => dispatch(actionAppSignInAnonymously()));
  return (
    <>
      {children}
    </>
  );
};

export const App = () => (
  <StyledComponentsTheme.Provider
    initialTheme={Theme}>
    <NativeBaseProvider>
      <StoreProvider>
        <SnackbarListener>
          <ActionSheetProvider>
            <LoadPersistanceAndRunSagas>
              <Authenticator>
                <NavigationContainer linking={linking}>
                  <AppMain/>
                </NavigationContainer>
              </Authenticator>
            </LoadPersistanceAndRunSagas>
          </ActionSheetProvider>
        </SnackbarListener>
      </StoreProvider>
    </NativeBaseProvider>
  </StyledComponentsTheme.Provider>
);
