import * as _ from 'lodash';
import { EGuestSessionStatus, TGuestSession } from './GuestSessionTypes';
import { FIELD_ID } from '../../../db/DbDefs';
import { TGuestSessionPathBuilderParams } from './GuestSessionBuilderBase';
import { KnownError } from '../../../lib/error/KnownError';
import { LocalError } from '../../../lib/error/LocalError';
import { Log } from '../../../config/Instance';
import { MObj } from '../../../lib/model/MObj';
import { Order } from '../order/Order';
import { OrderCreator } from '../order/OrderCreator';
import { Refs } from '../../../db/DbRefs';
import { TArchivedMObj } from '../archivedMObj/ArchivedMObjTypes';
import { TOrder, TOrderId } from '../order/OrderTypes';
import { TOrderItem, TOrderItemId } from '../orderItem/OrderItemTypes';
import { TRKGuestSession } from '../../../db/DbResources';
import { buildRawUpdate } from '../../dbUpdaters/lib/buildRawUpdate';
import { dbItemCreatedOnSort } from '../../../db/DbLib';
import { guestSessionStripEmptyOrders } from '../../dbUpdaters/guestSessionStripEmptyOrders';
import { nowMs } from '../../../lib/HelperFunctions';
import { GuestSessionBuilder } from './GuestSessionBuilder';
import { THost } from '../host/HostTypes';
import { GuestSessionPrintBatchPrepareAtomically } from './lib/GuestSessionPrintBatchPrepareAtomically';
import { GuestSessionPrintBatch } from './lib/GuestSessionPrintBatch';
import { OrderItemCreator } from '../orderItem/OrderItemCreator';
import { MenuItem } from '../menuItem/MenuItem';
import { TMenuItem } from '../menuItem/MenuItemTypes';
import { OrderBuilder } from '../order/OrderBuilder';

export class GuestSession extends MObj<TRKGuestSession> {
  static getAllConfirmedOrderItems({ orders }: Pick<TGuestSession, 'orders'>) {
    return _.chain(orders)
      .values()
      .filter((order) => !Order.orderIsOpen(order))
      .map(({ items }) => _.values(items))
      .flattenDeep()
      .sort(dbItemCreatedOnSort)
      .value();
  }

  static getTotalPrice(GuestSessionData: Pick<TGuestSession, 'orders'>) {
    return _.sumBy(_.values(GuestSessionData.orders), Order.getTotalPrice);
  }

  static getOrderItemCount(guestSessionData: Pick<TGuestSession, 'orders'>) {
    return _.sumBy(_.values(guestSessionData.orders), Order.getOrderItemCount);
  }

  static getTotalCoverPrice(guestSessionData: Pick<TGuestSession, 'numberOfGuests'>, coverCharge: number) {
    return coverCharge * guestSessionData.numberOfGuests;
  }

  static getOpenOrder({ orders }: Pick<TGuestSession, 'orders'>): TOrder | undefined {
    return _.find(orders, (order) => Order.orderIsOpen(order));
  }

  static isBillRequested({ lastBillRequestTs }: Pick<TGuestSession, 'lastBillRequestTs'>) {
    return !!lastBillRequestTs && lastBillRequestTs > 0;
  }

  static areAllOrdersFullyPaid({ orders }: Pick<TGuestSession, 'orders'>) {
    const ordersArr = _.values(orders);
    for (let i = 0; i < ordersArr.length; i++) {
      const orderItemsArr = _.values(ordersArr[i].items);
      for (let x = 0; x < orderItemsArr.length; x++) {
        const orderItem = orderItemsArr[x];
        if (!orderItem.paid) {
          return false;
        }
      }
    }
    return true;
  }

  static async getOrSetGuestOpenOrder(pbp: TGuestSessionPathBuilderParams): Promise<TOrder> {
    const updatedOrders = await Refs.orders(pbp).transaction((_orders) => {
      const orders = _orders || {};
      const openOrder = GuestSession.getOpenOrder({ orders });
      if (openOrder != null) {
        return undefined;
      }

      const newOpenOrder = OrderCreator.buildGuestOpenOrder(pbp);
      return {
        ...orders,
        [newOpenOrder[FIELD_ID]]: newOpenOrder,
      };
    });

    const openOrder = GuestSession.getOpenOrder({ orders: updatedOrders || {} });
    if (!openOrder) {
      throw Error(['GuestSession', 'getOrSetOpenOrder', `Failed to open Order in path ${JSON.stringify(pbp)}`].join(', '));
    }
    return openOrder;
  }

  static findOrderItemById({ orders }: Pick<TGuestSession, 'orders'>, orderItemId: TOrderItemId): TOrderItem | undefined {
    return _.chain(orders)
      .flatMap((order) => _.values(order.items))
      .find((item) => item[FIELD_ID] === orderItemId)
      .value();
  }

  static findOrderWithOrderItemByOrderItemId({ orders }: Pick<TGuestSession, 'orders'>, orderItemId: TOrderItemId): TOrder | undefined {
    return _.chain(orders)
      .find((order) => order.items[orderItemId] != null)
      .value();
  }

  readonly stripEmptyOrders = buildRawUpdate(this, 'GuestSession/emptyOrders', guestSessionStripEmptyOrders, async (update) => {
    try {
      return Refs.orders(this.pbp()).ref().update(update);
    } catch (e) {
      // Not fatal
      Log.e('GuestSession', 'stripEmptyOrders', `Update failed with ${e.message}`);
    }
  });

  constructor(guestSession: TGuestSession) {
    super(GuestSessionBuilder, guestSession);
  }

  async setGuestCount(guestCount: number): Promise<TGuestSession> {
    return this.updateFields({ numberOfGuests: guestCount });
  }

  getLockedOrdersWithItems(): TOrder[] {
    return _.filter(_.values(this.item.orders), (orderData: TOrder) => {
      return !Order.orderIsOpen(orderData) && Order.orderHasItems(orderData);
    });
  }

  hasLockedOrdersWithItems() {
    return this.getLockedOrdersWithItems().length > 0;
  }

  async requestBill(): Promise<void> {
    const hasPendingOrderItems = _.values(this.item.orders).some((order: TOrder) => {
      return Order.orderIsOpen(order) && Order.orderHasItems(order);
    });

    if (hasPendingOrderItems) {
      throw new LocalError('GuestSession', 'requestBill', KnownError.errCantRequestBillWithActiveOrders);
    }
    await this.updateFields({ lastBillRequestTs: nowMs() });
  }

  getTotalCoverPrice(coverCharge: number) {
    return coverCharge * this.item.numberOfGuests;
  }

  async setAllOrderItemsToUnPaid() {
    Log.v('GuestSession', 'setAllOrderItemsToUnPaid', `Start`);
    const rootPath = this.path();

    const {
      hostId,
      tableId,
    } = this.pbp();

    const updateObj = _.values(this.item.orders).reduce((obj, order) => {
      _.values(order.items).forEach((orderItem) => {
        const orderId = order[FIELD_ID];
        const ref = Refs.orderItemPaid({
          hostId,
          tableId,
          orderId,
          orderItemId: orderItem[FIELD_ID],
        });
        obj[ref.path().replace(rootPath, '')] = false;
      });
      return obj;
    }, {} as any);

    await this.updateFields(updateObj);
    Log.v('GuestSession', 'setAllOrderItemsToUnPaid', `Complete`);
    const guestSession = await GuestSessionBuilder.remoteFromPath(this.pbp());
    await GuestSessionBuilder.fromItem(guestSession).openIfUnPaid();
  }

  async tryPrintBatchAtomically(host: THost, batchedOrderIds: TOrderId[]) {
    const pbp = this.pbp();
    const atomicUpdater = new GuestSessionPrintBatchPrepareAtomically(pbp, batchedOrderIds);
    await atomicUpdater.prepare(async (orders) => {
      await new GuestSessionPrintBatch(pbp, host, this.item, orders).run();
    });
  }

  async setOrderItemsToPaidById(orderItemIds: TOrderItemId[]) {
    Log.v('GuestSession', 'setOrderItemsToPaidById', `Start ${JSON.stringify(orderItemIds)}`);
    const rootPath = this.path();

    const {
      hostId,
      tableId,
    } = this.pbp();

    const updateObj = orderItemIds.reduce((obj, orderItemId) => {
      const order = _.find(this.item.orders, (searchOrder) => {
        return Boolean(_.get(searchOrder.items, orderItemId));
      });

      if (!order) {
        Log.wtf(
          'GuestSession',
          'setOrderItemsToPaidById',
          `Got OrderItem id (${orderItemId}) that is not in guest session`,
        );
        return obj;
      }

      const orderId = order[FIELD_ID];
      const ref = Refs.orderItemPaid({
        hostId,
        tableId,
        orderId,
        orderItemId,
      });

      obj[ref.path().replace(rootPath, '')] = true;
      return obj;
    }, {} as any);

    await this.updateFields(updateObj);
    Log.v('GuestSession', 'setOrderItemsToPaidById', `Complete`);
    const guestSession = await GuestSessionBuilder.remoteFromPath(this.pbp());

    // todo a guest doesn't know what to do when tableId exists but session is closed
    await GuestSessionBuilder.fromItem(guestSession).closeIfPaid();
  }

  async closeIfPaid() {
    // todo a guest doesn't know what to do when tableId exists but session is closed
    // if (GuestSession.areAllOrdersFullyPaid(this.item)) {
    //   await this.updateFields({
    //     status: EGuestSessionStatus.CLOSED,
    //   });
    // }
  }

  async closeAndArchive(): Promise<TArchivedMObj<TRKGuestSession>> {
    await this.stripEmptyOrders({});
    await this.updateFields({ status: EGuestSessionStatus.CLOSED });
    return this.moveToDbArchive();
  }

  async open() {
    await this.updateFields({ status: EGuestSessionStatus.OPEN });
  }

  async openIfUnPaid() {
    if (!GuestSession.areAllOrdersFullyPaid(this.item)) {
      await this.open();
    }
  }

  async addMenuItemToGuestSessionAsHost(menuItem: TMenuItem) {
    const pbp = this.pbp();

    const newOrder = OrderCreator.buildHostOpenOrder(pbp);
    const newOrderPbp = OrderBuilder.getPathBuilderParamsFromDataPath(newOrder);

    const orderItemCreate = MenuItem.toLocalOrderItemData(menuItem);
    const orderItem = OrderItemCreator.buildNew(newOrderPbp, orderItemCreate);

    // Ensure everything is in a single update so we don't jitter any UI's
    await OrderCreator.remoteSaveNewToPath(newOrderPbp, {
      ...newOrder,
      ...Order.orderToBatchUpdate(),
      items: { [orderItem[FIELD_ID]]: orderItem },
    });
  }
}
