import { FirebaseApi } from '../../FirebaseApi';
import {
  DataSnapshot,
  IRef,
} from '../../FirebaseTypes';
import {
  KeySubscriber,
  Log,
} from '../../config/Instance';

type TBindByKeyParamsBase<D, P> = {
  key: string;
  onChange: (value: D) => void;
};

type TBindByKeyParams<D, P> = TBindByKeyParamsBase<D, P> & {
  pbp: P;
};

export abstract class AbsSafeRef<D, P> {
  static globalUnBindByKey(key: string) {
    KeySubscriber.unSubscribeByKey(key);
  }

  /**
   * Do not use arrow functions or overriding
   * these methods from child classes will fail
   */
  constructor() {
    this.ref = this.ref.bind(this);
    this.transaction = this.transaction.bind(this);
    this.val = this.val.bind(this);
    this.bindByKey = this.bindByKey.bind(this);
    this.bindChildAddedByKey = this.bindChildAddedByKey.bind(this);
  }

  abstract pathBuilder(pbp: P): string;

  ref(pathBuilderParams: P): IRef<D> {
    return FirebaseApi.instance.ref(this.pathBuilder(pathBuilderParams));
  }

  async transaction(
    pathBuilderParams: P,
    func: (data: D | null) => D | undefined,
    onComplete?: (
      a: Error | null,
      b: boolean,
      c: DataSnapshot<D> | null,
    ) => any,
  ): Promise<D> {
    const response = await this.ref(pathBuilderParams).transaction(
      (data: D | null) => {
        return func(data);
      },
      onComplete,
    );
    return response && response.snapshot && response.snapshot.val();
  }

  val(pathBuilderParams: P): Promise<D | undefined> {
    return FirebaseApi.once(this.ref(pathBuilderParams));
  }

  bindByKey({
    key,
    pbp,
    onChange,
  }: TBindByKeyParams<D, P>) {
    KeySubscriber.subscribeByKey({
      key,
      subscriber: () => {
        const path = this.pathBuilder(pbp);
        Log.v('SafeRef', 'bindByKey', `Subscribing to ${path}`);

        const { unsubscribe } = FirebaseApi.subscribeValue<D>(this.ref(pbp), onChange);

        return {
          unsubscribe: () => {
            Log.v('SafeRef', 'bindByKey', `Unsubscribing to ${path}`);
            return unsubscribe();
          },
        };
      },
    });
  }

  bindChildAddedByKey({
    key,
    pbp,
    onChange,
  }: TBindByKeyParams<D[any], P>) {
    KeySubscriber.subscribeByKey({
      key,
      subscriber: () => {
        const path = this.pathBuilder(pbp);
        Log.v('SafeRef', 'bindChildAddedByKey', `Subscribing to ${path}`);

        const { unsubscribe } = FirebaseApi.subscribeChildAdded<D>(this.ref(pbp), onChange);

        return {
          unsubscribe: () => {
            Log.v('SafeRef', 'bindChildAddedByKey', `Unsubscribing to ${path}`);
            return unsubscribe();
          },
        };
      },
    });
  }
}

export class SafeRef<D, P> extends AbsSafeRef<D, P> {
  private readonly pbp: P;

  readonly pathBuilder: (p: P) => string;

  constructor(pbp: P, pathBuilder: (p: P) => string) {
    super();
    this.pbp = pbp;
    this.pathBuilder = pathBuilder;
  }

  readonly path = () => {
    return this.pathBuilder(this.pbp);
  };

  readonly ref = () => {
    return super.ref(this.pbp);
  };

  // @ts-ignore
  readonly transaction = (
    func: (data: D | null) => D | undefined,
    onComplete?: (
      a: Error | null,
      b: boolean,
      c: DataSnapshot<D> | null,
    ) => any,
  ): Promise<D | undefined> => {
    return super.transaction(this.pbp, func, onComplete);
  };

  readonly val = () => {
    return super.val(this.pbp);
  };

  readonly bindByKey = (baseParams: TBindByKeyParamsBase<D, P>) => {
    return super.bindByKey({
      ...baseParams,
      pbp: this.pbp,
    });
  };

  readonly bindChildAddedByKey = (baseParams: TBindByKeyParamsBase<D[any], P>) => {
    return super.bindChildAddedByKey({
      ...baseParams,
      pbp: this.pbp,
    });
  };
}
