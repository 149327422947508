import { FirebaseApi } from '../../../core/src/FirebaseApi';
import { Log } from '../config/Log';
import { safeWaitLogExpectDefined } from '../../../core/src/lib/HelperFunctions';
import { ReactX } from './ReactX';

const {
  firebase,
  database,
  crashlytics,
  auth,
  storage,
  dynamicLinks,
} = ReactX;

export class FirebaseBase extends FirebaseApi {
  private app: any;

  // @ts-ignore
  constructor() {
    super({
      database: () => database() as any,
      storage: () => storage() as any,
      auth: () => auth() as any,
    });
    this.app = firebase.app();
  }

  authSignInAnonymously() {
    return safeWaitLogExpectDefined('Firebase', 'authSignInAnonymously', async () => {
      const userCredential = await auth().signInAnonymously();
      await this.crashlyticsOnSignIn(userCredential);
      return userCredential.user;
    });
  }

  async authLogout(): Promise<void> {
    await this.app.delete();
    Log.v('Firebase', 'logout', 'Sign off complete');
  }

  subscribeForLinks(cb: (link: string) => void) {
    dynamicLinks().getInitialLink()
      .then((initialLink) => {
        // @ts-ignore
        const url = initialLink && initialLink.url;
        url && cb(url);
      });
    return { unsubscribe: dynamicLinks().onLink(cb) };
  }

  readonly crashlyticsOnSignIn = async (user: any) => {
    await crashlytics().setUserId(user.user.uid);
  };
}

export const Firebase = new FirebaseBase();

