import * as _ from 'lodash';
import { Strings } from '../../locale/Strings';
import { TKnownError } from './ErrorTypes';

function regexpWords(...words: string[]) {
  return new RegExp(['', ...words, ''].join('(.*)'), 'i');
}

const regExpMatchNothing = new RegExp('a^');

export const KnownErrorType = {
  unknown: {
    internalCode: 'unknown',
    internalMessageBuilder: Strings.errUnknown,
    messageMatcher: /(.*)/,
    priority: -1,
    logLevel: 'wtf',
  } as const,
  authInvalidEmail: {
    internalCode: 'authInvalidEmail',
    internalMessageBuilder: Strings.errAuthInvalidEmail,
    messageMatcher: regexpWords('email', 'badly', 'formatted'),
  } as const,
  authWrongPassword: {
    internalCode: 'authWrongPassword',
    internalMessageBuilder: Strings.errAuthWrongPassword,
    messageMatcher: regexpWords('password', 'invalid'),
  } as const,
  authWrongEmail: {
    internalCode: 'authWrongEmail',
    internalMessageBuilder: Strings.errAuthWrongEmail,
    messageMatcher: regexpWords('no', 'user', 'record'),
  } as const,
  hostNotFound: {
    internalCode: 'hostNotFound',
    internalMessageBuilder: Strings.errUnknown,
    messageMatcher: regExpMatchNothing,
  } as const,
  hostSubscriptionNotFound: {
    internalCode: 'hostSubscriptionNotFound',
    internalMessageBuilder: Strings.errUnknown,
    messageMatcher: regExpMatchNothing,
  } as const,
  hostPublicSubscriptionNotFound: {
    internalCode: 'hostPublicSubscriptionNotFound',
    internalMessageBuilder: Strings.errUnknown,
    messageMatcher: regExpMatchNothing,
  } as const,
  inputEmailsDontMatch: {
    internalCode: 'inputEmailsDontMatch',
    internalMessageBuilder: Strings.errInputEmailsDontMatch,
    messageMatcher: regExpMatchNothing,
  } as const,
  inputPasswordsDontMatch: {
    internalCode: 'inputPasswordsDontMatch',
    internalMessageBuilder: Strings.errInputPasswordsDontMatch,
    messageMatcher: regExpMatchNothing,
  } as const,
  validationFieldNotSet: {
    internalCode: 'validationFieldNotSet',
    internalMessageBuilder: Strings.validationFieldNotSet,
    messageMatcher: regExpMatchNothing,
  } as const,
  validationInvalidUUID: {
    internalCode: 'validationInvalidUUID',
    internalMessageBuilder: Strings.validationInvalidUUID,
    messageMatcher: regExpMatchNothing,
  } as const,
  validationInvalidUrl: {
    internalCode: 'validationInvalidUrl',
    internalMessageBuilder: Strings.validationInvalidUrl,
    messageMatcher: regExpMatchNothing,
  } as const,
  validationInvalidLength: {
    internalCode: 'validationInvalidLength',
    internalMessageBuilder: Strings.validationInvalidLength,
    messageMatcher: regExpMatchNothing,
  } as const,
  validationInvalidNumRange: {
    internalCode: 'validationInvalidNumRange',
    internalMessageBuilder: Strings.validationInvalidNumRange,
    messageMatcher: regExpMatchNothing,
  } as const,
  validationInvalidType: {
    internalCode: 'validationInvalidType',
    internalMessageBuilder: Strings.validationInvalidType,
    messageMatcher: regExpMatchNothing,
  } as const,
  validationInvalidEmail: {
    internalCode: 'validationInvalidEmail',
    internalMessageBuilder: Strings.validationInvalidEmail,
    messageMatcher: regExpMatchNothing,
  } as const,
  permissionsMissing: {
    internalCode: 'permissionsMissing',
    internalMessageBuilder: Strings.errPermissionsMissing,
    logLevel: 'e',
    messageMatcher: regexpWords('permission_denied'),
  } as const,
  noOtherMenuToActivate: {
    internalCode: 'noOtherMenuToActivate',
    internalMessageBuilder: Strings.errNoOtherMenuToPublish,
    logLevel: 'v',
    messageMatcher: regExpMatchNothing,
  } as const,
  cantDeletePublishedMenu: {
    internalCode: 'cantDeletePublishedMenu',
    internalMessageBuilder: Strings.errCantDeletePublishedMenu,
    logLevel: 'e',
    messageMatcher: regExpMatchNothing,
  } as const,
  cantDeleteLastLocale: {
    internalCode: 'cantDeleteLastLocale',
    internalMessageBuilder: Strings.errCantDeleteLastLocale,
    logLevel: 'v',
    messageMatcher: regExpMatchNothing,
  } as const,
  errCantDeleteDefaultLocale: {
    internalCode: 'errCantDeleteDefaultLocale',
    internalMessageBuilder: Strings.errCantDeleteDefaultLocale,
    logLevel: 'v',
    messageMatcher: regExpMatchNothing,
  } as const,
  errCantRemoveTablesWithGuestSessions: {
    internalCode: 'errCantRemoveTablesWithGuestSessions',
    internalMessageBuilder: Strings.errCantRemoveTablesWithGuestSessions,
    logLevel: 'v',
    messageMatcher: regExpMatchNothing,
  } as const,
  errCantRequestBillWithActiveOrders: {
    internalCode: 'errCantRequestBillWithActiveOrders',
    internalMessageBuilder: Strings.errCantRequestBillWithActiveOrders,
    logLevel: 'v',
    messageMatcher: regExpMatchNothing,
  } as const,
  errCantRemoveMainStation: {
    internalCode: 'errCantRemoveMainStation',
    internalMessageBuilder: Strings.errCantRemoveMainStation,
    logLevel: 'v',
    messageMatcher: regExpMatchNothing,
  } as const,
} as const;

export const KnownError = KnownErrorType as {
  [key in keyof typeof KnownErrorType]: TKnownError
};

export const KnownErrors = Object.freeze(_.values(KnownError));
