import * as React from 'react';
import * as _ from 'lodash';
import { useSyncedDataRef } from './useSyncedDataRef';

export function useThrottle<T extends (...args: any) => any>(wait: number, cb: T): T {
  const onChangeRef = useSyncedDataRef(cb);
  return React.useMemo(() => {
    return _.throttle(
      (args: any) => onChangeRef.current(args),
      wait,
    ) as any as T;
  }, []);
}
