import { AlacarteUrls } from '../../AlacarteUrls';
import { Routable } from '../../define/routable/Routable';
import {
  TRoutableRouteInParams,
  TRoutableRouteInPathParams,
  TRoutableRouteNames,
  TRoutableRouteOutParams,
  TRoutableRouteOutPathParams,
} from '../../define/routable/RoutableTypes';
import { ELocale, ELOCALE_GLOBAL_DEFAULT } from '../../../locale/Locale';
import { TOrderItem, TOrderItemCreate } from '../../../models/db/orderItem/OrderItemTypes';
import { CommonRoutes } from './common/CommonRoutes';
import { parseBoolOrUndefined, parseIntOrUndefined } from '../../HelperFunctions';

export type TRoutesCliGuestNames = TRoutableRouteNames<typeof RoutesCliGuest>;
export type TRoutesCliGuestInParams = TRoutableRouteInParams<typeof RoutesCliGuest>;
export type TRoutesCliGuestOutParams = TRoutableRouteOutParams<typeof RoutesCliGuest>;
export type TRoutesCliGuestInPathParams = TRoutableRouteInPathParams<typeof RoutesCliGuest>;
export type TRoutesCliGuestOutPathParams = TRoutableRouteOutPathParams<typeof RoutesCliGuest>;

export const RoutesCliGuest = {
  ScreenDevFunctions: {
    name: 'ScreenDevFunctions' as const,
    path: '/dev-functions',
    secondaryPaths: {} as const,
    hashes: {} as const,
    pathParams: { in: undefined },
    params: {
      in: {
        storeLocale: ELOCALE_GLOBAL_DEFAULT as (ELocale | undefined),
        storeHostId: undefined as any as string,
        storeTableId: undefined as (string | undefined),
        storeTableNumber: undefined as (string | undefined),
        storeSkipGuestsCheck: undefined as (boolean | undefined),
      },
    },
  },
  ScreenSetupScanHostQR: {
    name: 'ScreenSetupScanHostQR' as const,
    path: '/',
    secondaryPaths: {} as const,
    hashes: {} as const,
    pathParams: { in: undefined },
    params: {
      in: {
        storeLocale: ELOCALE_GLOBAL_DEFAULT as (ELocale | undefined),
        storeHostId: undefined as any as string,
        storeTableId: undefined as (string | undefined),
        storeTableNumber: undefined as (string | undefined),
        storeSkipGuestsCheck: undefined as (boolean | undefined),
      },
    },
  },
  ScreenSetupGuestSession: {
    name: 'ScreenSetupGuestSession' as const,
    path: '/host/:storeHostId/setup',
    secondaryPaths: {} as const,
    hashes: {} as const,
    pathParams: { in: { storeHostId: undefined as any as string } },
    params: {
      in: {
        storeLocale: ELOCALE_GLOBAL_DEFAULT as (ELocale | undefined),
        storeTableId: undefined as (string | undefined),
        storeTableNumber: undefined as (number | undefined),
        storeSkipGuestsCheck: undefined as (boolean | undefined),
      },
      out: {
        storeLocale: ELOCALE_GLOBAL_DEFAULT as (ELocale | undefined),
        storeTableId: undefined as (string | undefined),
        storeTableNumber: undefined as (string | undefined),
        storeSkipGuestsCheck: undefined as (string | undefined),
      },
      map(params) {
        return {
          ...params,
          storeTableNumber: params.storeTableNumber != null ? `${params.storeTableNumber}` : undefined,
          storeSkipGuestsCheck: params.storeSkipGuestsCheck != null ? `${params.storeSkipGuestsCheck}` : undefined,
        };
      },
      invert(params) {
        return {
          ...params,
          storeTableNumber: parseIntOrUndefined(params.storeTableNumber),
          storeSkipGuestsCheck: parseBoolOrUndefined(params.storeSkipGuestsCheck),
        };
      },
    },
  },
  ScreenHostHome: {
    name: 'ScreenHostHome' as const,
    path: '/host/:storeHostId',
    secondaryPaths: {} as const,
    hashes: {} as const,
    pathParams: { in: { storeHostId: undefined as any as string } },
    params: {
      in: {
        storeLocale: ELOCALE_GLOBAL_DEFAULT as (ELocale | undefined),
        storeTableId: undefined as (string | undefined),
        storeTableNumber: undefined as (string | undefined),
      },
    },
  },
  ScreenHostMenuItemSearch: {
    name: 'ScreenHostMenuItemSearch' as const,
    path: '/host/:storeHostId/search',
    secondaryPaths: {} as const,
    hashes: {} as const,
    pathParams: { in: { storeHostId: undefined as any as string } },
    params: {
      in: {
        storeLocale: ELOCALE_GLOBAL_DEFAULT as (ELocale | undefined),
        storeHostId: undefined as any as string,
        storeTableId: undefined as (string | undefined),
        storeTableNumber: undefined as (string | undefined),
        filterByNameString: undefined as (string | undefined),
      },
    },
  },
  ScreenHostMenu: {
    name: 'ScreenHostMenu' as const,
    path: '/host/:storeHostId/menu/:menuId',
    secondaryPaths: {} as const,
    hashes: {} as const,
    pathParams: {
      in: {
        storeHostId: undefined as any as string,
        menuId: undefined as any as string,
      },
    },
    params: {
      in: {
        storeLocale: ELOCALE_GLOBAL_DEFAULT as (ELocale | undefined),
        storeHostId: undefined as any as string,
        storeTableId: undefined as (string | undefined),
        storeTableNumber: undefined as (string | undefined),
      },
    },
  },
  ScreenHostMenuItemView: {
    name: 'ScreenHostMenuItemView' as const,
    path: '/host/:storeHostId/menuItem/:menuItemId',
    secondaryPaths: {} as const,
    hashes: {} as const,
    pathParams: {
      in: {
        storeHostId: undefined as any as string,
        menuItemId: undefined as any as string,
      },
    },
    params: {
      in: {
        storeLocale: ELOCALE_GLOBAL_DEFAULT as (ELocale | undefined),
        storeHostId: undefined as any as string,
        storeTableId: undefined as (string | undefined),
        storeTableNumber: undefined as (string | undefined),
      },
    },
  },
  ScreenHostOrderItemEdit: {
    name: 'ScreenHostOrderItemEdit' as const,
    path: '/host/:storeHostId/table/:storeTableId/open-order/orderItem/:orderItemId',
    secondaryPaths: {} as const,
    hashes: {} as const,
    pathParams: {
      in: {
        storeHostId: undefined as any as string,
        storeTableId: undefined as (string | undefined),
        orderItemId: undefined as (string | undefined),
      },
    },
    params: {
      in: {
        storeLocale: ELOCALE_GLOBAL_DEFAULT as (ELocale | undefined),
        storeHostId: undefined as any as string,
        storeTableId: undefined as (string | undefined),
        storeTableNumber: undefined as (string | undefined),
        orderItemData: undefined as (TOrderItem | undefined),
      },
    },
  },
  ScreenHostOrderCheckout: {
    name: 'ScreenHostOrderCheckout' as const,
    path: '/host/:storeHostId/table/:storeTableId/checkout',
    secondaryPaths: {} as const,
    hashes: {} as const,
    pathParams: {
      in: {
        storeHostId: undefined as any as string,
        storeTableId: undefined as any as string,
      },
    },
    params: {
      in: {
        storeLocale: ELOCALE_GLOBAL_DEFAULT as (ELocale | undefined),
        storeHostId: undefined as any as string,
        storeTableId: undefined as (string | undefined),
        storeTableNumber: undefined as (string | undefined),
      },
    },
  },
  ScreenHostOrderRequestComplete: {
    name: 'ScreenHostOrderRequestComplete' as const,
    path: '/host/:storeHostId/table/:storeTableId/checkout/ordered',
    secondaryPaths: {} as const,
    hashes: {} as const,
    pathParams: {
      in: {
        storeHostId: undefined as any as string,
        storeTableId: undefined as any as string,
      },
    },
    params: {
      in: {
        storeLocale: ELOCALE_GLOBAL_DEFAULT as (ELocale | undefined),
        storeHostId: undefined as any as string,
        storeTableId: undefined as (string | undefined),
        storeTableNumber: undefined as (string | undefined),
      },
    },
  },
  ScreenHostBillRequestComplete: {
    name: 'ScreenHostBillRequestComplete' as const,
    path: '/host/:storeHostId/table/:storeTableId/checkout/bill-requested',
    secondaryPaths: {} as const,
    hashes: {} as const,
    pathParams: {
      in: {
        storeHostId: undefined as any as string,
        storeTableId: undefined as any as string,
      },
    },
    params: {
      in: {
        storeLocale: ELOCALE_GLOBAL_DEFAULT as (ELocale | undefined),
        storeHostId: undefined as any as string,
        storeTableId: undefined as (string | undefined),
        storeTableNumber: undefined as (string | undefined),
      },
    },
  },
  ScreenHostOrderItemModifyOptions: {
    name: 'ScreenHostOrderItemModifyOptions' as const,
    path: '/__ScreenHostOrderItemModifyOptions',
    secondaryPaths: {} as const,
    hashes: {} as const,
    pathParams: { in: undefined },
    params: {
      in: {
        storeLocale: ELOCALE_GLOBAL_DEFAULT as (ELocale | undefined),
        storeHostId: undefined as any as string,
        storeTableId: undefined as (string | undefined),
        storeTableNumber: undefined as (string | undefined),
        menuItemId: undefined as any as string,
        orderItem: undefined as any as TOrderItemCreate,
      },
    },
  },
  ModalOfflineOverlay: {
    name: 'ModalOfflineOverlay' as const,
    path: '/__ModalOfflineOverlay',
    secondaryPaths: {} as const,
    hashes: {} as const,
    pathParams: { in: undefined },
    params: { in: undefined },
  },
  ModalLockScreenOverlay: {
    name: 'ModalLockScreenOverlay' as const,
    path: '/__ModalLockScreenOverlay',
    secondaryPaths: {} as const,
    hashes: {} as const,
    pathParams: { in: undefined },
    params: { in: undefined },
  },
  ModalConfirmationDialog: {
    name: 'ModalConfirmationDialog' as const,
    path: '/__ModalConfirmationDialog',
    secondaryPaths: {} as const,
    hashes: {} as const,
    pathParams: { in: undefined },
    params: {
      in: {
        title: '' as string,
        description: '' as string,
        positiveButtonText: '' as string,
        positiveButtonOnClick: (() => undefined) as () => any,
        negativeButtonText: '' as string,
        negativeButtonOnClick: (() => undefined) as () => any,
      },
    },
  },
  DeferredDeeplink: CommonRoutes.DeferredDeeplink,
};

export const RoutableCliGuestWeb = Routable.wrapApi({
  baseUrl: AlacarteUrls.BASE_URL_CLI_GUEST_WEB.https,
  routes: RoutesCliGuest,
});

export const RoutableCliGuestRn = Routable.wrapApi({
  baseUrl: AlacarteUrls.BASE_URL_CLI_GUEST_RN.https,
  routes: RoutesCliGuest,
});
