import {
  TDbResData,
  TDbResPbp,
  TRKHostPrintQueueItem,
} from '../../../db/DbResources';
import { HostPrintQueueItemBuilder } from './HostPrintQueueItemBuilder';
import { MObjCreatorById } from '../../../lib/model/MObjCreator';
import { TMObjDataCreator } from '../../../lib/model/ModelTypes';
import { cleanPrintEncoding } from '../../../printer/cleanPrintEncoding';

class HostPrintQueueItemCreatorBase extends MObjCreatorById<TRKHostPrintQueueItem, 'hostPrintQueueItemId'> {
  protected readonly idKey = 'hostPrintQueueItemId';

  constructor() {
    super(HostPrintQueueItemBuilder);
  }

  buildNew(
    pbp: Omit<TDbResPbp[TRKHostPrintQueueItem], 'hostPrintQueueItemId'>,
    data: TMObjDataCreator<TRKHostPrintQueueItem>,
    id?: string,
  ): TDbResData[TRKHostPrintQueueItem] {
    const newData = {
      ...data,
      ops: cleanPrintEncoding(data.ops),
    };

    return super.buildNew(pbp, newData, id);
  }
}

export const HostPrintQueueItemCreator = new HostPrintQueueItemCreatorBase();
