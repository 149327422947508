import { ArchivedMObjBuilder } from './ArchivedMObjBuilder';
import {
  DbRes,
  TDbResName,
} from '../../../db/DbResources';
import { MObjCreatorById } from '../../../lib/model/MObjCreator';

export class ArchivedMObjCreator<T extends TDbResName> extends MObjCreatorById<typeof DbRes.ArchivedMObj, 'archivedMObjId'> {
  constructor() {
    super(new ArchivedMObjBuilder<T>());
  }

  protected readonly idKey = 'archivedMObjId';
}
