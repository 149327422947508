import { GuestSession } from '../../../../core/src/models/db/guestSession/GuestSession';
import { TGuestSession } from '../../../../core/src/models/db/guestSession/GuestSessionTypes';
import { TOrder } from '../../../../core/src/models/db/order/OrderTypes';
import { TTableId } from '../../../../core/src/models/db/table/TableTypes';
import { actions } from '../Actions';
import * as _ from 'lodash';
import {
  defineReducer,
  TDispatch,
  TThunkAction,
} from '../../../../lib-react/src/redux/redux';
import { TGetState } from '../Store';
import { dispatchWithErrorHandler } from '../ReduxErrorHandlers';
import { Log } from '../../config/Log';
import {
  FIELD_ID,
  FIELD_NAME,
} from '../../../../core/src/db/DbDefs';
import { actionGuestSessionSetTableId } from '../procedures/actionGuestSessionSetTableId';

export type TStateGuestSession = {
  tableId: TTableId | undefined;
  guestSessionData: TGuestSession | undefined;
  openOrder: TOrder | undefined;
  setup: {
    tableRequested: boolean;
    guestCountRequested: boolean;
  };
};

const initialState: TStateGuestSession = {
  tableId: undefined,
  guestSessionData: undefined,
  openOrder: undefined,
  setup: {
    tableRequested: false,
    guestCountRequested: false,
  },
};

export const {
  actionTypeGuestSessionSetSetupFlags,
  reducer: reducerGuestSession,
} = defineReducer<TStateGuestSession, typeof actions>({
  actions,
  initialState,
  reducer: (state, action, getType) => {
    switch (action.type) {
      case getType(actions.pureActionTypeResetSession):
        return initialState;
      case getType(actions.pureActionTypeGuestSessionSetTableId):
        return {
          ...state,
          tableId: action.payload.tableId,
        };
      case getType(actions.actionTypeGuestSessionSet):
        const guestSession = action.payload.guestSessionData;
        return {
          ...state,
          tableId: guestSession.tableId,
          guestSessionData: guestSession,
          openOrder: guestSession
            ? GuestSession.getOpenOrder(guestSession)
            : undefined,
        };
      case getType(actions.actionTypeGuestSessionSetSetupFlags):
        return {
          ...state,
          setup: {
            guestCountRequested: _.get(action.payload, 'guestCountRequested', state.setup.guestCountRequested),
            tableRequested: _.get(action.payload, 'tableRequested', state.setup.tableRequested),
          },
        };
    }
    return state;
  },
});

export const actionGuestSessionSetTableIdByTableNumber = dispatchWithErrorHandler((tableNumber: number): TThunkAction<Promise<void>> => {
  return async (dispatch: TDispatch, getState: TGetState) => {
    const { hostData } = getState().host;
    if (!hostData) {
      const err = ['GuestSession', 'actionGuestSessionSetTableNumber', 'hostData undefined'];
      Log.e(err[0], err[1], err[2]);
      throw new Error(err.join(', '));
    }

    const tableId = _.find(hostData.tables, (table) => table[FIELD_NAME] == `${tableNumber}`);
    if (!tableId) {
      const err = ['GuestSession', 'actionGuestSessionSetTableNumber', `tableNumber=${tableNumber} coudln't match to tableId`];
      Log.e(err[0], err[1], err[2]);
      throw new Error(err.join(', '));
    }

    dispatch(actionGuestSessionSetTableId({ tableId: tableId[FIELD_ID] }));
  };
});

