import * as React from 'react';
import * as _ from 'lodash';
import { useSyncedDataRef } from './useSyncedDataRef';
import { useThrottle } from './useThrottle';

export type TUseStateWithExternalSyncProps<Value> = {
  value: Value;
  syncingActive?: boolean;
  throttleSyncMs?: number;

  /**
   * Triggered when the value changes both internally and externally
   */
  onValueChange?: (value: Value) => void | Promise<void>;
};

export function useStateWithExternalSync<Value>({
  value,
  syncingActive = true,
  onValueChange = _.noop,
  throttleSyncMs = 0,
}: TUseStateWithExternalSyncProps<Value>): [Value, (v: Value) => void] {
  const syncingActiveRef = useSyncedDataRef(syncingActive);

  const [stateValue, setStateValue] = React.useState<Value>(value);

  const throttledOnValueChange = useThrottle(throttleSyncMs, onValueChange);
  const throttledOnValueChangeRef = useSyncedDataRef(throttledOnValueChange);

  const setSyncedValue = React.useCallback(async (val: Value) => {
    if (syncingActiveRef.current) {
      await throttledOnValueChangeRef.current(val);
    }
    setStateValue(val);
  }, [setStateValue]);

  const stateValueRef = useSyncedDataRef(stateValue);
  const setStateValueRef = useSyncedDataRef(setStateValue);
  React.useEffect(() => {
    if (!syncingActiveRef.current) {
      return;
    }
    if (!_.isEqual(stateValueRef.current, value)) {
      setStateValueRef.current(value);
    }
  }, [value]);

  return [stateValue, setSyncedValue];
}
