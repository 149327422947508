import * as _ from 'lodash';
import { Logger } from '../lib/Logger';
import { Process } from '../config/Process';
import { SecretFunctions } from './SecretFunctions';

const Log = new Logger({
  name: '@alacarte/core',
  env: Process.env.NODE_ENV,
  isCI: Process.env.CI,
});

export type TLoadSecretsJsonFromEnv<T> = {
  decSecrets: T;
  missingSecrets: string[];
};

export class SecretLoaders {
  static loadSecretsJsonFromEnv<T>(): TLoadSecretsJsonFromEnv<T> {
    Log.v('SecretLoaders', 'loadSecretsJsonFromEnv', 'Loading secrets from env', Process.env);
    const envSecrets = Process.env.ALACARTE_SECRETS_JSON;
    if (_.isEmpty(envSecrets)) {
      return {
        decSecrets: {} as T,
        missingSecrets: [] as any,
      };
    }

    const decSecret = SecretFunctions.secretDec(envSecrets) || '';
    const loadedEnvSecrets = JSON.parse(decSecret);
    const decSecrets = SecretFunctions.mapObjVals(loadedEnvSecrets, SecretFunctions.secretDec) as T;
    return {
      decSecrets,
      missingSecrets: [],
    };
  }
}
