import * as React from 'react';

type TUseToggleRet = [
  boolean,
  () => void,
  () => void,
  () => void,
];

export function useToggle(initialVal = false): TUseToggleRet {
  const [val, setVal] = React.useState(initialVal);
  const toggle = React.useCallback(() => setVal(!val), [setVal, val]);
  const setTrue = React.useCallback(() => setVal(true), [setVal]);
  const setFalse = React.useCallback(() => setVal(false), [setVal]);
  return [
    val,
    toggle,
    setTrue,
    setFalse,
  ];
}
