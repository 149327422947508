import * as React from 'react';
import { Colors } from '../config/Theme';
import { FIELD_NAME } from '../../../core/src/db/DbDefs';
import { Screen } from '../lib-components/screen/Screen';
import { NavTitleHandler } from '../lib/navigation/NavTitleHandler';
import { localize } from '../../../core/src/lib/formatters/LocaleLib';

import { TOrderItemCreate } from '../../../core/src/models/db/orderItem/OrderItemTypes';
import * as _ from 'lodash';
import { OrderBuilder } from '../../../core/src/models/db/order/OrderBuilder';
import { Navigation } from '../lib/navigation/Navigation';
import { RCComp } from '../lib/routeContract/RCComp';
import { OrderItemCreator } from '../components/orderItemEditor/OrderItemCreator';
import { TMenuItemId } from '../../../core/src/models/db/menuItem/MenuItemTypes';
import { RoutesCliGuest } from '../../../core/src/lib/apis/routes/RoutableCliGuest';

export class ScreenHostMenuItemView extends RCComp<'ScreenHostMenuItemView'> {
  protected readonly routeName = 'ScreenHostMenuItemView' as const;

  private readonly navTitleHandler = new NavTitleHandler((props: this['props']) => {
    return localize(props.menuItem, FIELD_NAME, props.language);
  });

  constructor(props, context) {
    super(props, context);
    this.navTitleHandler.apply(props);
  }

  componentDidUpdate() {
    this.navTitleHandler.apply(this.props);
  }

  readonly orderItemUpdate = async (orderItem: TOrderItemCreate, count: number) => {
    const { openOrder } = this.props;

    if (!openOrder) {
      // This shouldn't happen because
      // the callback shouldn't be passed into OrderItemEditor
      return;
    }

    const items = _.times(count, () => orderItem);
    await OrderBuilder.fromItem(openOrder).addOrderItems(items);

    Navigation.goBack();
  };

  render() {
    const {
      openOrder,
      menuItem,
    } = this.props;
    return (
      <Screen
        backgroundColor={Colors.white}
        usesReactNavigationHeader={true}
        hasStatusBar={true}
        statusBarBackgroundColor={Colors.white}
        statusBarBackgroundColorIsLight={true}
      >
        <OrderItemCreator
          menuItem={menuItem}
          orderItemUpdate={openOrder ? this.orderItemUpdate : undefined}
          onModifyOptions={this.onModifyOptions}
        />
      </Screen>
    );
  }

  private onModifyOptions = async (orderItem: TOrderItemCreate, menuItemId: TMenuItemId) => {
    await Navigation.routePush(RoutesCliGuest.ScreenHostOrderItemModifyOptions.name, {
      params: {
        orderItem,
        menuItemId,
      },
      pathParams: {},
    });
  };
}
