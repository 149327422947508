import { Log } from '../../../config/Instance';
import { MObj } from '../../../lib/model/MObj';
import {
  TDbResData,
  TDbResName,
} from '../../../db/DbResources';
import { itemToPathItem } from '../../../lib/PathParser';

export type TBuilderParamsBase<RK extends TDbResName> = {
  item: TDbResData[RK];
};

export function buildRawUpdate<RK extends TDbResName, BuilderParams, BuilderResult>(
  parent: MObj<RK>,
  key: string,
  builder: (builderParams: BuilderParams & TBuilderParamsBase<RK>) => Promise<BuilderResult>,
  updater: (update: Record<string, any>) => Promise<any>,
) {
  return async (inputParams: Omit<BuilderParams, keyof TBuilderParamsBase<RK>>) => {
    Log.v('RawUpdater', 'buildRawUpdate', `${key} Building update`, inputParams);
    const result = await builder({
      ...inputParams,
      item: parent.item,
    } as any);

    const built = itemToPathItem(result);
    Log.v('RawUpdater', 'buildRawUpdate', `${key} Building update complete`, built);
    try {
      await updater(built);
      Log.v('RawUpdater', 'buildRawUpdate', `${key} Sending update complete`);
    } catch (e) {
      Log.v('RawUpdater', 'buildRawUpdate', `${key} Sending update failed with msg=${e.message}`);
      throw e;
    }
  };
}
