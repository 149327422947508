// @ts-nocheck
import * as React from 'react';
import Svg,
{
  Circle,
  Path,
} from 'react-native-svg';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
      <Circle cx={10} cy={10} r={10} fill="#F15B40"/>
      <Path
        d="M9.174 14.202c-.339-.612-.6-1.062-.782-1.35l-.29-.454-.218-.334c-.478-.744-.976-1.358-1.493-1.843.295-.244.57-.366.825-.366.314 0 .593.115.837.344.244.23.552.667.924 1.313.423-1.375.961-2.678 1.614-3.91.36-.671.681-1.122.962-1.351.284-.234.66-.35 1.127-.35.248 0 .554.038.918.115a8.796 8.796 0 00-2.247 2.69c-.562 1.04-1.287 2.871-2.177 5.496z"
        fill="#fff"
      />
    </Svg>
  );
}

export const SvgCheckmark2 = React.memo(SvgComponent);
