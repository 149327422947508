import * as React from 'react';
import { Navigation, TNavigationParamsWithMethod } from '../lib/navigation/Navigation';
import { FullScreenLoader } from './FullScreenLoader';
import { TRoutesCliGuestNames } from '../../../core/src/lib/apis/routes/RoutableCliGuest';

export const FullScreenRedirect = <RK extends TRoutesCliGuestNames>({
  routeName,
  params,
  method,
}: TNavigationParamsWithMethod<RK>) => {
  const triggeredRef = React.useRef(false);

  // Don't useEffect, we don't want to delay navigation to the next tick
  if (!triggeredRef.current) {
    triggeredRef.current = true;
    switch (method) {
      case 'push':
        Navigation.routePush(routeName, params);
        break;
      case 'replace':
        Navigation.routeReplace(routeName, params);
        break;
      case 'reset':
        Navigation.routeReset(routeName, params);
        break;
    }
  }

  return (
    <FullScreenLoader/>
  );
};
