import * as React from 'react';
import { GenericInfoView } from '../../lib-components/GenericInfoView';
import { Strings } from '../../../../core/src/locale/Strings';
import { SvgAlacarteErrorState } from '../../img/SvgAlacarteErrorState';
import { useCurrentLocale } from "../../hooks/hooks";

type TMenuSearchedEmptyStateProps = {
  //
};

export const MenuSearchedEmptyState = (props: TMenuSearchedEmptyStateProps) => {
  const locale = useCurrentLocale();
  return (
    <GenericInfoView
      title={Strings.gSearchItemNotFoundMsg()(locale)}
      icon={<SvgAlacarteErrorState/>}/>
  )
};
