import * as React from 'react';
import { Log } from '../config/Instance';
import { useCancelableAsyncCallback } from './useCancelableAsyncCallback';
import { useSyncedDataRef } from './useSyncedDataRef';

export function useLoadingCallback<F extends (...args: any) => Promise<any>>(originalCallback: F): [F, boolean] {
  const [loading, setLoading] = React.useState(false);
  const callbackRef = useSyncedDataRef(originalCallback);

  const callback = useCancelableAsyncCallback(async (...args: any) => {
    if (loading) {
      return;
    }

    setLoading(true);
    try {
      const cb = callbackRef.current;
      if (cb != null) {
        return await cb(...args);
      }

      return undefined;
    } catch (e) {
      Log.e('useLoadingCallback', 'useLoadingCallback', 'Error', e);
    } finally {
      setLoading(false);
    }
  }, [loading, setLoading]);

  return [callback as any as F, loading];
}
