import { AppErrorHandler } from './AppErrorHandler';
import { isDevelopment } from '../../../core/src/lib/Environment';
import { Config } from '../config/Config';
import { DevFunctionsButton } from '../components/DevFunctionsButton';
import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { enableScreens } from 'react-native-screens';
import { RouteComponents } from '../lib/navigation/routes/RCCompComponents';
import { withNavigationMiddleware } from '../components/hocs/withNavigationMiddleware';
import { NavConst } from '../lib/navigation/NavConst';
import { RCCompContracts } from '../lib/navigation/routes/RCCompContracts';
import { RoutesCliGuest } from '../../../core/src/lib/apis/routes/RoutableCliGuest';

enableScreens(true);

const AppMainNavigator = createStackNavigator();
export const AppMain = () => {
  return (
    <AppErrorHandler>
      {isDevelopment(Config.env) && (
        <DevFunctionsButton/>
      )}
      <AppMainNavigator.Navigator
        initialRouteName={RoutesCliGuest.ScreenSetupScanHostQR.name}

        // 'float' renders a single floating header across all screens
        // 'screen' renders separate headers for each screen.
        // headerMode={'float'}

        // @ts-ignore
        detachInactiveScreens={true}

        // With react-native-screen we need this so previous screens aren't detached
        // and modals can show the previous screen underneath
        mode={'modal'}

        screenOptions={{ ...NavConst.stackNavOptions }}
      >
        {isDevelopment(Config.env) && (
          <AppMainNavigator.Screen
            name={RoutesCliGuest.ScreenDevFunctions.name}
            component={withNavigationMiddleware(RouteComponents.ScreenDevFunctions)}
            options={RCCompContracts.ScreenDevFunctions.navOptions}
          />
        )}
        <AppMainNavigator.Screen
          name={RoutesCliGuest.ScreenSetupScanHostQR.name}
          component={withNavigationMiddleware(RouteComponents.ScreenSetupScanHostQR)}
          options={RCCompContracts.ScreenSetupScanHostQR.navOptions}
        />
        <AppMainNavigator.Screen
          name={RoutesCliGuest.ScreenSetupGuestSession.name}
          component={withNavigationMiddleware(RouteComponents.ScreenSetupGuestSession)}
          options={RCCompContracts.ScreenSetupGuestSession.navOptions}
        />
        <AppMainNavigator.Screen
          name={RoutesCliGuest.ScreenHostHome.name}
          component={withNavigationMiddleware(RouteComponents.ScreenHostHome)}
          options={RCCompContracts.ScreenHostHome.navOptions}
        />
        <AppMainNavigator.Screen
          name={RoutesCliGuest.ScreenHostMenu.name}
          component={withNavigationMiddleware(RouteComponents.ScreenHostMenu)}
          options={RCCompContracts.ScreenHostMenu.navOptions}
        />
        <AppMainNavigator.Screen
          name={RoutesCliGuest.ScreenHostMenuItemSearch.name}
          component={withNavigationMiddleware(RouteComponents.ScreenHostMenuItemSearch)}
          options={RCCompContracts.ScreenHostMenuItemSearch.navOptions}
        />
        <AppMainNavigator.Screen
          name={RoutesCliGuest.ScreenHostMenuItemView.name}
          component={withNavigationMiddleware(RouteComponents.ScreenHostMenuItemView)}
          options={RCCompContracts.ScreenHostMenuItemView.navOptions}
        />
        <AppMainNavigator.Screen
          name={RoutesCliGuest.ScreenHostOrderItemEdit.name}
          component={withNavigationMiddleware(RouteComponents.ScreenHostOrderItemEdit)}
          options={RCCompContracts.ScreenHostOrderItemEdit.navOptions}
        />
        <AppMainNavigator.Screen
          name={RoutesCliGuest.ScreenHostOrderCheckout.name}
          component={withNavigationMiddleware(RouteComponents.ScreenHostOrderCheckout)}
          options={RCCompContracts.ScreenHostOrderCheckout.navOptions}
        />
        <AppMainNavigator.Screen
          name={RoutesCliGuest.ScreenHostOrderRequestComplete.name}
          component={withNavigationMiddleware(RouteComponents.ScreenHostOrderRequestComplete)}
          options={RCCompContracts.ScreenHostOrderRequestComplete.navOptions}
        />
        <AppMainNavigator.Screen
          name={RoutesCliGuest.ScreenHostBillRequestComplete.name}
          component={withNavigationMiddleware(RouteComponents.ScreenHostBillRequestComplete)}
          options={RCCompContracts.ScreenHostBillRequestComplete.navOptions}
        />
        <AppMainNavigator.Screen
          name={RoutesCliGuest.ScreenHostOrderItemModifyOptions.name}
          component={withNavigationMiddleware(RouteComponents.ScreenHostOrderItemModifyOptions)}
          options={RCCompContracts.ScreenHostOrderItemModifyOptions.navOptions}
        />
        <AppMainNavigator.Screen
          name={RoutesCliGuest.ModalOfflineOverlay.name}
          component={withNavigationMiddleware(RouteComponents.ModalOfflineOverlay)}
          options={RCCompContracts.ModalOfflineOverlay.navOptions}
        />
        <AppMainNavigator.Screen
          name={RoutesCliGuest.ModalLockScreenOverlay.name}
          component={withNavigationMiddleware(RouteComponents.ModalLockScreenOverlay)}
          options={RCCompContracts.ModalLockScreenOverlay.navOptions}
        />
        <AppMainNavigator.Screen
          name={RoutesCliGuest.ModalConfirmationDialog.name}
          component={withNavigationMiddleware(RouteComponents.ModalConfirmationDialog)}
          options={RCCompContracts.ModalConfirmationDialog.navOptions}
        />
      </AppMainNavigator.Navigator>
    </AppErrorHandler>
  );
};
