import { TState } from '../redux/Store';
import { TDispatch } from '../../../lib-react/src/redux/redux';
import {
  RCStackScreenProps,
  RouteContract,
  TOutNavParams,
  TRCMergedCheckedProps,
  TRCOwnProps,
} from '../lib/routeContract/RouteContract';
import { StackNavigationOptions } from '@react-navigation/stack';
import { TNavigationParamsWithMethod } from '../lib/navigation/Navigation';
import { NavConst } from '../lib/navigation/NavConst';
import { CheckPropsResultBuild } from '../lib/routeContract/RouteContractCheckProps';

export class LockOverlayRouteContract extends RouteContract<'ModalLockScreenOverlay'> {
  protected readonly routeName = 'ModalLockScreenOverlay' as const;

  onBeforeRemoveShouldAllowBack(props: TRCMergedCheckedProps<'ModalLockScreenOverlay'>) {
    return true;
  }

  navigationOptions(props: RCStackScreenProps<TOutNavParams<'ModalLockScreenOverlay'>>, navParams: TOutNavParams<'ModalLockScreenOverlay'>): StackNavigationOptions {
    return { ...NavConst.modalStackNavOptions };
  }

  shouldSkipToRoute(state: TState, ownProps: TOutNavParams<'ModalLockScreenOverlay'>): TNavigationParamsWithMethod<any> | undefined {
    return undefined;
  }

  mapStateToProps(state: TState, navParams: TOutNavParams<'ModalLockScreenOverlay'>) {
    return {
      locale: state.app.locale,
      lockScreen: state.app.lockScreen,
    };
  }

  mapDispatchToProps(dispatch: TDispatch, ownProps: TRCOwnProps<'ModalLockScreenOverlay'>) {
    return {};
  }

  checkProps(props: ReturnType<this['mapStateToProps']>) {
    return CheckPropsResultBuild.READY(props);
  }
}
