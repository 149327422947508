import * as React from 'react';
import Svg,
{
  G,
  Path,
  SvgProps,
} from 'react-native-svg';

function SvgComponent(props: SvgProps) {
  return (
    <Svg
      width={9}
      height={10}
      viewBox="0 0 9 10"
      {...props}
    >
      <G fill="#FFF" fillRule="evenodd">
        <Path d="M4 8h1V0H4z"/>
        <Path d="M8.036 5.5l-.708-.707-3.535 3.535.707.708z"/>
        <Path d="M1.672 4.793L.964 5.5 4.5 9.036l.707-.708z"/>
      </G>
    </Svg>
  );
}

export const SvgOrderPrecedenceArrowDown = React.memo(SvgComponent);
