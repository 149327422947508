import { Base64 as JSBase64 } from 'js-base64';

export function b64FromClearText(clearText: string) {
  return JSBase64.btoa(clearText);
}

export function b64ToClearText(b64: string) {
  return JSBase64.atob(b64);
}

export const Base64 = {
  b64FromClearText,
  b64ToClearText,
};
