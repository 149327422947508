// @ts-nocheck
import * as React from 'react';
import Svg,
{ Path } from 'react-native-svg';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg width={163} height={163} viewBox="0 0 163 163" fill="none" {...props}>
      <Path
        fill="#F15B40"
        d="M.987 25.124L137.875.987l24.137 136.888-136.888 24.137z"
      />
      <Path stroke="#A6A7B3" strokeWidth={2} d="M13 13h137v137H13z"/>
    </Svg>
  );
}

export const SvgALaCarteLogo = React.memo(SvgComponent);
