import { TState } from '../redux/Store';
import { bindActionCreators } from '../../../lib-react/src/redux/redux';
import {
  RCStackScreenProps,
  RouteContract,
  TOutNavParams,
  TRCMergedCheckedProps,
  TRCOwnProps,
} from '../lib/routeContract/RouteContract';
import { StackNavigationOptions } from '@react-navigation/stack';
import { TNavigationParamsWithMethod } from '../lib/navigation/Navigation';
import {
  actionGuestSessionCompleteOrderToBatching,
  actionGuestSessionRequestBill,
} from '../redux/actions/GuestSession';
import { NavConst } from '../lib/navigation/NavConst';
import { CheckPropsResultBuild } from '../lib/routeContract/RouteContractCheckProps';
import { RoutesCliGuest } from '../../../core/src/lib/apis/routes/RoutableCliGuest';

export class RouteHostOrderCheckoutContract extends RouteContract<'ScreenHostOrderCheckout'> {
  protected readonly routeName = 'ScreenHostOrderCheckout' as const;

  onBeforeRemoveShouldAllowBack(props: TRCMergedCheckedProps<'ScreenHostOrderCheckout'>) {
    return true;
  }

  navigationOptions(props: RCStackScreenProps<TOutNavParams<'ScreenHostOrderCheckout'>>, navParams: TOutNavParams<'ScreenHostOrderCheckout'>): StackNavigationOptions {
    return {
      headerShown: true,
      ...NavConst.stackNavOptionsForCenteredUppercaseTitle,
      ...NavConst.stackNavOptions,
    };
  }

  shouldSkipToRoute(state: TState, ownProps: TOutNavParams<'ScreenHostOrderCheckout'>): TNavigationParamsWithMethod<any> | undefined {
    return undefined;
  }

  mapStateToProps(state: TState, navParams: TOutNavParams<'ScreenHostOrderCheckout'>) {
    return {
      locale: state.app.locale,
      hostData: state.host.hostData,
      hostPublicSubscription: state.host.hostPublicSubscription,
      guestSessionData: state.guestSession.guestSessionData,
      openOrder: state.guestSession.openOrder,
    };
  }

  mapDispatchToProps(dispatch: any, ownProps: TRCOwnProps<'ScreenHostOrderCheckout'>) {
    return {
      guestSessionRequestBill: bindActionCreators(actionGuestSessionRequestBill, dispatch),
      guestSessionCompleteOrderToBatching: bindActionCreators(actionGuestSessionCompleteOrderToBatching, dispatch),
    };
  }

  checkProps(props: ReturnType<this['mapStateToProps']>) {
    const {
      hostData,
      hostPublicSubscription,
      guestSessionData,
      openOrder,
    } = props;

    if (hostData == null) {
      return CheckPropsResultBuild.WAIT();
    }

    if (hostPublicSubscription == null) {
      return CheckPropsResultBuild.WAIT();
    }

    if (guestSessionData == null) {
      return CheckPropsResultBuild.WAIT();
    }

    if (openOrder == null) {
      return CheckPropsResultBuild.REDIRECT({
        routeName: RoutesCliGuest.ScreenHostHome.name,
        params: {
          params: {},
          pathParams: {},
        },
        method: 'reset',
      });
    }

    return CheckPropsResultBuild.READY({
      ...props,
      hostData,
      hostPublicSubscription,
      guestSessionData,
      openOrder,
    });
  }
}
