import {
  EOrderStatus,
  TOrder,
  TOrderId,
} from '../../order/OrderTypes';
import { Refs } from '../../../../db/DbRefs';
import { TObjList } from '../../../../db/DbDefs';
import { Log } from '../../../../config/Instance';
import * as _ from 'lodash';
import { TGuestSessionPathBuilderParams } from '../GuestSessionBuilderBase';
import { DataSnapshot } from '../../../../FirebaseTypes';

type TOrders = TObjList<TOrderId, TOrder>;
type TMaybeOrders = TOrders | undefined | null;

export class GuestSessionPrintBatchPrepareAtomically {
  private readonly pbp: TGuestSessionPathBuilderParams;

  private readonly batchedOrderIds: readonly TOrderId[];

  private readonly tableId;

  constructor(
    pbp: TGuestSessionPathBuilderParams,
    batchedOrderIds: TOrderId[],
  ) {
    this.pbp = pbp;
    this.batchedOrderIds = batchedOrderIds;
    this.tableId = this.pbp.tableId;
    this.prepare = this.prepare.bind(this);
    this.transaction = this.transaction.bind(this);
    this.getUpdatedOrdersIfShouldPrint = this.getUpdatedOrdersIfShouldPrint.bind(this);
  }

  async prepare(onShouldPrint: (updatedOrders: TOrders) => Promise<any>) {
    await Refs.orders(this.pbp).transaction(
      this.transaction,
      this.buildOnComplete(onShouldPrint),
    );
  }

  private transaction(remoteOrders: TMaybeOrders) {
    if (!remoteOrders) {
      // Nothing to do
      Log.e(
        'GuestSessionPrintBatchPrepareAtomically',
        'transaction',
        `tableId=${this.tableId} remoteOrders orders empty`,
      );
      return;
    }

    // Ensure all updatedOrders are printable
    const batchIsPrintable = this.batchedOrderIds.every((batchedOrderId) => {
      return this.ensureOrderIsPrintable(remoteOrders, batchedOrderId);
    });

    if (!batchIsPrintable) {
      Log.e(
        'GuestSessionPrintBatchPrepareAtomically',
        'transaction',
        `tableId=${this.tableId} Batch is not printable`,
      );
      return;
    }

    return _.mapValues(remoteOrders, (order) => ({
      ...order,
      status: EOrderStatus.PENDING,
    }));
  }

  private buildOnComplete(onShouldPrint: (updatedOrders: TOrders) => Promise<void>) {
    return async (error: Error | null, committed: boolean, snapshot: DataSnapshot<TOrders> | null) => {
      const updatedOrders = await this.getUpdatedOrdersIfShouldPrint(error, committed, snapshot);
      if (updatedOrders) {
        await onShouldPrint(updatedOrders);
      }
    };
  }

  private async getUpdatedOrdersIfShouldPrint(
    error: Error | null,
    committed: boolean,
    snapshot: DataSnapshot<TOrders> | null,
  ): Promise<TMaybeOrders> {
    if (error) {
      Log.e(
        'GuestSessionPrintBatchPrepareAtomically',
        'onComplete',
        `tableId=${this.tableId} Transaction failed with error, ${error.message}`,
      );
      return undefined;
    }

    if (!committed) {
      Log.v(
        'GuestSessionPrintBatchPrepareAtomically',
        'onComplete',
        `tableId=${this.tableId} Transaction was not committed`,
      );
      return undefined;
    }

    if (!snapshot) {
      Log.wtf(
        'GuestSessionPrintBatchPrepareAtomically',
        'onComplete',
        `tableId=${this.tableId} snapshot was null, should not happen`,
      );
      return undefined;
    }

    const allOrders: TMaybeOrders = await snapshot.val();
    if (!allOrders) {
      Log.wtf(
        'GuestSessionPrintBatchPrepareAtomically',
        'onComplete',
        `tableId=${this.tableId} allOrders was null, should not happen`,
      );
      return undefined;
    }

    Log.v(
      'GuestSessionPrintBatchPrepareAtomically',
      'onComplete',
      `tableId=${this.tableId} Transaction succeeded, returning shouldPrint=true`,
    );

    return _.pick(allOrders, this.batchedOrderIds);
  }

  private ensureOrderIsPrintable(remoteOrders: TOrders, batchedOrderId: TOrderId) {
    const remoteOrder = remoteOrders[batchedOrderId];

    if (!remoteOrder) {
      Log.e(
        'GuestSessionPrintBatchPrepareAtomically',
        'ensureOrderIsPrintable',
        `tableId=${this.tableId} batchedOrderId=${batchedOrderId}, not present in remoteOrders`,
      );
      return false;
    }

    if (remoteOrder.status !== EOrderStatus.BATCHING) {
      Log.e(
        'GuestSessionPrintBatchPrepareAtomically',
        'ensureOrderIsPrintable',
        `tableId=${this.tableId} batchedOrderId=${batchedOrderId}, not in BATCHING status in remoteOrders`,
      );
      return false;
    }

    return true;
  }
}
