import registerServiceWorker from './registerServiceWorker';
import './initialize';
import './injectIcons';

// eslint-disable-next-line
import * as React from 'react';
// eslint-disable-next-line
import { AppRegistry } from 'react-native';

import { App } from './App';

const name = require('../package.json').name;

AppRegistry.registerComponent(name, () => App);
AppRegistry.runApplication(name, {
  initialProps: {},
  rootTag: document.getElementById('root'),
});

if (process.env.NODE_ENV === 'production') {
  registerServiceWorker();
}
