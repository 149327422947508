// @ts-nocheck
import * as React from 'react';
import Svg, { Path } from "react-native-svg"


function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg
      width={50}
      height={50}
      fill="none"
      viewBox={"0 0 60 60"}
      {...props}
    >
      <Path fill="#F15B40" d="M0 0h60v60H0z"/>
      <Path
        d="M24.048 51v-2.82L25.92 51h.96v-4.44h-.96v2.826l-1.872-2.826h-.96V51h.96Zm6.577 0v-.9h-1.848v-.918h1.638v-.852h-1.638v-.87h1.848v-.9h-2.82V51h2.82Zm1.755 0h.954l.834-2.916.81 2.916h.948l1.356-4.44h-.99l-.594 1.938a9.246 9.246 0 0 0-.234.954c-.078-.426-.15-.696-.222-.966l-.522-1.926h-1.086l-.546 1.926c-.078.276-.15.528-.222.966a7.197 7.197 0 0 0-.222-.966l-.582-1.926h-1.014L32.38 51ZM30 10l3.028 4.68 5.201-2.006-.3 5.566 5.386 1.434L39.8 24l3.515 4.326-5.387 1.434.301 5.566-5.2-2.006L30 38l-3.028-4.68-5.201 2.006.3-5.566-5.386-1.434L20.2 24l-3.515-4.326 5.387-1.434-.301-5.566 5.2 2.006L30 10Z"
        fill="#fff"
      />
    </Svg>
  );
}

export const SvgNewFullIcon = React.memo(SvgComponent);
