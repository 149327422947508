import * as React from 'react';
import { Strings } from '../../../../core/src/locale/Strings';
import { TObjList } from '../../../../core/src/db/DbDefs';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { TIngredient, TIngredientId } from '../../../../core/src/models/db/ingredient/IngredientTypes';
import { Underline } from '../../lib-components/Underline';
import { OrderItemEditorSectionUpdateButton } from '../orderItemEditor/OrderItemEditorSectionUpdateButton';
import { Num } from '../../../../core/src/lib/formatters/Num';
import { useItemCallback } from '../../../../lib-react/src/hooks/useItemCallback';
import { OrderItem } from '../../../../core/src/models/db/orderItem/OrderItem';
import { OrderItemIngredientContext } from './OrderItemIngredientContext';
import { DEFAULT_CURRENCY } from '../../../../core/src/locale/Currencies';
import { useCurrentHost, useCurrentLocale } from "../../hooks/hooks";

type TOrderItemIngredientModifierBottomButtonProps = {
  availableIngredients: TObjList<TIngredientId, TIngredient>;
  onConfirmPress: (selectedIngredients: TObjList<TIngredientId, TIngredient>) => Promise<any>;
};

export const OrderItemIngredientModifierBottomButton = ({
                                                          onConfirmPress,
                                                        }: TOrderItemIngredientModifierBottomButtonProps) => {
  const locale = useCurrentLocale();
  const host = useCurrentHost();

  const { selectedIngredients } = React.useContext(OrderItemIngredientContext);

  const onConfirmButtonPress = useItemCallback(selectedIngredients, onConfirmPress);
  const extrasPrice = OrderItem.getExtrasPrice({ ingredients: selectedIngredients });
  return (
    <Root>
      <ActionButtonCont>
        <UnderlineCont>
          <Underline/>
        </UnderlineCont>
        <StyledOrderItemEditorSectionUpdateButton
          leftText={Strings.gOrderExtrasConfirmCmd()(locale)}
          onPress={onConfirmButtonPress}
          rightText={
            extrasPrice !== 0
              ? Num.formatPrice2(extrasPrice, host?.currency ?? DEFAULT_CURRENCY)
              : ''
          }
        />
      </ActionButtonCont>
    </Root>
  );
};

const actionButtonHeight = 84;

const Root = styled(View)`
  padding: 0 20px 80px 20px;
`;

const UnderlineCont = styled(View)`
`;

const StyledOrderItemEditorSectionUpdateButton = styled(OrderItemEditorSectionUpdateButton)`
  margin-top: 30px;
`;

const ActionButtonCont = styled(View)`
  height: ${actionButtonHeight}px;
  justify-content: flex-end;
  margin-top: 10px;
`;
