import { Log } from '../config/Instance';
import { SafeRef } from '../../../core/src/lib/model/SafeRef';
import {
  buffers,
  eventChannel,
} from 'redux-saga';
import {
  call,
  cancelled,
  fork,
  take,
} from 'redux-saga/effects';

export function* sagaBindRefByKey<D, P>(key: string, ref: SafeRef<D, P>, saga: (data: D | undefined) => Generator) {
  return yield sagaForkedBindRefByKeyBase(key, ref, saga, (onChange) => {
    return ref.bindByKey({
      key,
      onChange,
    });
  });
}

export function* sagaBindRefChildAddedByKey<D, P>(key: string, ref: SafeRef<D, P>, saga: (data: D[any] | undefined) => Generator) {
  return yield sagaForkedBindRefByKeyBase(key, ref, saga, (onChange) => {
    return ref.bindChildAddedByKey({
      key,
      onChange,
    });
  });
}

function* sagaForkedBindRefByKeyBase<D, P, C = D>(
  key: string,
  ref: SafeRef<D, P>,
  saga: (data: C | undefined) => Generator,
  emitter: (input: any) => void,
) {
  return yield fork(function* () {
    return yield sagaBindRefByKeyBase(key, ref, saga, emitter);
  });
}

function* sagaBindRefByKeyBase<D, P, C = D>(
  key: string,
  ref: SafeRef<D, P>,
  saga: (data: C | undefined) => Generator,
  emitter: (input: any) => void,
) {
  const nullValue = '__NULL';

  const channel = eventChannel((emit) => {
    emitter((val: any) => {
      const nonNullableValue = val != null ? val : '__NULL';
      emit(nonNullableValue);
    });
    return () => undefined;
  }, buffers.expanding());

  try {
    while (true) {
      const data = yield take(channel);
      const nullableValue = data !== nullValue ? data : undefined;
      Log.v('sagaBindRefByKey', 'sagaBindRefByKeyBase', `${key} updated`);
      yield call(saga, nullableValue);
    }
  } finally {
    if (yield cancelled()) {
      Log.v('sagaBindRefByKey', 'sagaBindRefByKeyBase', `${key} Saga cancelled, clearing up`);
      SafeRef.globalUnBindByKey(key);
      channel.close();
    }
  }
}
