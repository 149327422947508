import { ArchivedMObjBuilder } from './ArchivedMObjBuilder';
import { FirebaseApi } from '../../../FirebaseApi';
import { Log } from '../../../config/Instance';
import { MObj } from '../../../lib/model/MObj';
import { ModelValidator } from '../../../lib/ModelValidator';
import { TArchivedMObj } from './ArchivedMObjTypes';
import {
  TDbResName,
  TRKArchivedMObj,
} from '../../../db/DbResources';

export class ArchivedMObj<T extends TDbResName> extends MObj<TRKArchivedMObj> {
  constructor(message: TArchivedMObj<T>) {
    super(new ArchivedMObjBuilder<T>(), message);
  }

  async moveToDbArchive() {
    throw new Error('ArchivedMObj, archiveItem, cannot archive an archived item');
    return this.item;
  }

  unArchive = async () => {
    const {
      archivedItem,
      archivedItemOriginalPath,
    } = this.item;

    const unArchivedItem = ModelValidator.makeConsistentTModelObj({
      traversedPath: archivedItemOriginalPath,
      obj: archivedItem,
    });
    Log.v('ArchivedMObj', 'unArchive', `UnArchiving ${archivedItemOriginalPath}`, unArchivedItem);

    await FirebaseApi.instance.ref(archivedItemOriginalPath).set(unArchivedItem);
    return unArchivedItem;
  };
}
