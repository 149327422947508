import {
  TMObj,
  TMObjDataCreator,
  TMObjMaybeDataCreator,
} from '../../../lib/model/ModelTypes';
import { TObjList } from '../../../db/DbDefs';
import {
  TOrder,
  TOrderId,
} from '../order/OrderTypes';
import { TRKGuestSession } from '../../../db/DbResources';
import { TTableId } from '../table/TableTypes';

export enum EGuestSessionStatus {
  OPEN = 'OPEN', // Guest scanned QR code and opened app
  CLOSED = 'CLOSED', // All orderUnderlays have been paid
}

export type TGuestSessionId = string;
export type TGuestSession = TMObj<TRKGuestSession> & {
  tableId: TTableId;
  numberOfGuests: number;
  status: EGuestSessionStatus;
  orders?: TObjList<TOrderId, TOrder>;
  lastBillRequestTs?: number;
};

export type TGuestSessionUpdate = TMObjMaybeDataCreator<TRKGuestSession>;
export type TGuestSessionCreate = TMObjDataCreator<TRKGuestSession>;
