import * as React from 'react';
import { Config } from '../config/Config';
import { DevFunctionsButton } from '../components/DevFunctionsButton';
import { GenericErrorViewWithReset } from '../lib-components/error/GenericErrorViewWithReset';
import { Log } from '../config/Log';
import { TState } from '../redux/Store';
import { actionAppSetError } from '../redux/reducers/Session';
import { bindActionCreators } from '../../../lib-react/src/redux/redux';
import { connect } from 'react-redux';
import { isDevelopment } from '../../../core/src/lib/Environment';

type TBaseNavigationViewProps = {
  children: React.ReactNode;
};

type TAppErrorHandlerBaseProps = TBaseNavigationViewProps
& ReturnType<typeof mapStateToProps>
& ReturnType<typeof mapDispatchToProps>;

type TAppErrorHandlerBaseState = {
  //
};

export class AppErrorHandlerBase extends React.Component<TAppErrorHandlerBaseProps, TAppErrorHandlerBaseState> {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { appSetError } = this.props;
    Log.e('AppErrorHandler', 'componentDidCatch', error.message);
    appSetError(error, errorInfo);
  }

  render() {
    const {
      error,
      children,
    } = this.props;
    return (
      <>
        {isDevelopment(Config.env) && (
          <DevFunctionsButton/>
        )}
        {error == null ? children : (
          <GenericErrorViewWithReset />
        )}
      </>
    );
  }
}

function mapStateToProps(state: TState) {
  return {
    locale: state.app.locale,
    error: state.session.error,
  };
}

function mapDispatchToProps(dispatch) {
  return { appSetError: bindActionCreators(actionAppSetError, dispatch) };
}

export const AppErrorHandler = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppErrorHandlerBase);
