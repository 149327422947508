export const corePackageSecrets = {
  BASE_URL_CLI_GUEST_RN: '',
  BASE_URL_ALACARTE_LINKING: '',
  CGR_APPLE_ID: '',
  CGR_ANDROID_ID: '',

  CHM_RELEASES_MAC_DOWNLOAD: '',
  CHM_RELEASES_WIN_DOWNLOAD: '',
  CHM_RELEASES_LINUX_DOWNLOAD: '',

  BASE_URL_ARCHIVE_SYNC: '',
  BASE_URL_CLI_GUEST_WEB: '',
  BASE_URL_CLI_HOST_WEB: '',
  BASE_URL_LANDING_PAGE: '',
  BASE_URL_PUBLIC_API: '',
  BASE_URL_QR_MANAGER: '',
  BASE_URL_STRIPE: '',
};
