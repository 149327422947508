import { Log } from '../config/Instance';

export function cleanPrintEncoding(ops: string): string {
  try {
    return strictCleanPrintEncoding(ops);
  } catch (e) {
    Log.e('cleanPrintEncoding', 'cleanPrintEncoding', 'Something went wrong, using original');
    return ops;
  }
}

function strictCleanPrintEncoding(ops: string): string {
  const mappedOps = charMap.reduce((str, [find, replace]) => {
    return str.replace(find, replace);
  }, `${ops}`);

  // Ensure still a valid JSON (Might throw)
  return JSON.stringify(JSON.parse(mappedOps));
}

const charMap: [RegExp, string][] = [
  [/à/g, 'a'],
  [/è/g, 'e'],
  [/ì/g, 'i'],
  [/ò/g, 'o'],
  [/ù/g, 'u'],
  [/À/g, 'A'],
  [/È/g, 'E'],
  [/Ì/g, 'I'],
  [/Ò/g, 'O'],
  [/Ù/g, 'U'],
  [/á/g, 'a'],
  [/é/g, 'e'],
  [/í/g, 'i'],
  [/ó/g, 'o'],
  [/ú/g, 'u'],
  [/ý/g, 'y'],
  [/Á/g, 'A'],
  [/É/g, 'E'],
  [/Í/g, 'I'],
  [/Ó/g, 'O'],
  [/Ú/g, 'U'],
  [/Ý/g, 'Y'],
  [/â/g, 'a'],
  [/ê/g, 'e'],
  [/î/g, 'i'],
  [/ô/g, 'o'],
  [/û/g, 'U'],
  [/Â/g, 'A'],
  [/Ê/g, 'E'],
  [/Î/g, 'I'],
  [/Ô/g, 'O'],
  [/Û/g, 'U'],
  [/°/g, ' '],
  [/’/g, '\''],
  [/”/g, '"'],
  [/“/g, '"'],
];
