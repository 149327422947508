import { ArchivedMObjCreator } from '../../models/db/archivedMObj/ArchivedMObjCreator';
import { FIELD_PATH } from '../../db/DbDefs';
import { FirebaseApi } from '../../FirebaseApi';
import { IRef } from '../../FirebaseTypes';
import { Log } from '../../config/Instance';
import { MObjDef } from './MObjDef';
import { RecursivePartial } from '../Types';
import { SafeRef } from './SafeRef';
import {
  TDbResData,
  TDbResName,
  TDbResPbp,
} from '../../db/DbResources';

export abstract class MObj<RK extends TDbResName> {
  readonly item: TDbResData[RK];

  readonly builder: MObjDef<RK>;

  protected constructor(builder: MObjDef<RK>, item: TDbResData[RK]) {
    this.item = Object.freeze(item) as TDbResData[RK];
    this.builder = builder;
  }

  async moveToDbArchive() {
    const user = FirebaseApi.instance.auth.currentUser;
    if (!user) {
      throw new Error('MObj, moveToDbArchive, User not authenticated');
    }
    return this.moveToDbArchiveAsUid(user.uid);
  }

  async moveToDbArchiveAsUid(ownerUid: string) {
    const obj = {
      resourceType: this.builder.resourceKey,
      ownerUid,
      archivedItemOriginalPath: this.item[FIELD_PATH],
      archivedItem: this.item,
    };
    Log.v('MObj', 'moveToDbArchive', `Archiving`, obj);
    const archivedItem = await new ArchivedMObjCreator<RK>().remoteSaveNewToPath({}, obj);
    await this.ref().remove();
    return archivedItem;
  }

  protected ref(): IRef<TDbResData[RK]> {
    return FirebaseApi.instance.ref(this.item[FIELD_PATH]);
  }

  pbp(): TDbResPbp[RK] {
    return this.builder.getPathBuilderParamsFromDataPath(this.item);
  }

  path(): string {
    return this.builder.pathBuilder(this.pbp());
  }

  subPath(rootRef: SafeRef<any, any>): string {
    return rootRef.path().replace(`${this.path()}`, '');
  }

  protected async updateOrInsertFields(partial: RecursivePartial<TDbResData[RK]>): Promise<TDbResData[RK]> {
    await this.ref().update({
      ...this.item,
      ...partial,
    });
    return {
      ...this.item,
      ...partial,
    };
  }

  protected async updateFields(partial: RecursivePartial<TDbResData[RK]>): Promise<TDbResData[RK]> {
    await this.ref().update(partial);
    return {
      ...this.item,
      ...partial,
    };
  }
}
