import * as _ from 'lodash';
import {
  FIELD_DEFAULT_LOCALE,
  TLocalizableObj,
} from '../../../db/DbDefs';
import {
  addIfTrue,
  selectNotEmpty,
} from '../../../lib/HelperFunctions';
import { dbItemLocalizableSupportedLocalesGet } from '../../../db/DbLib';
import {
  dotItemToItem,
  itemToDotItem,
} from '../../../lib/PathParser';
import { ELocale } from '../../../locale/Locale';
import {TLanguage} from '../../../locale/Languages';

function mergeLocalesAndLocalizedPaths(locales: TLanguage[], localizedFields: string[]) {
  const result = [] as {
    locale: TLanguage;
    fieldName: string;
  }[];

  for (let i = 0; i < locales.length; i++) {
    for (let x = 0; x < localizedFields.length; x++) {
      result.push({
        locale: locales[i],
        fieldName: localizedFields[x],
      });
    }
  }

  return result;
}

/**
 * buildLocalizedFieldUpdate
 */

type TBuildLocalizedFieldUpdateParams<I extends TLocalizableObj<any>, U, K extends keyof I> = {
  /**
   * The locale of this update
   */
  localizedUpdateLocale: TLanguage;

  /**
   * The original item
   */
  item: I;

  /**
   * The name of the localizable field to update
   */
  fieldName: K;

  /**
   * The update value, has to be exactly undefined
   * null will delete the item
   */
  updateValue: U | undefined;

  /**
   * Maps the update value to the update item type
   */
  updateMap?: (updateValue: U) => I[K];
};

export function buildLocalizedFieldUpdate<I extends TLocalizableObj<any>, U, K extends keyof I>(params: TBuildLocalizedFieldUpdateParams<I, U, K>) {
  const {
    localizedUpdateLocale,
    item,
    fieldName,
    updateValue,
    updateMap = _.identity,
  } = params;

  // If value undefined do no update for this field
  if (updateValue === undefined) {
    return {};
  }

  if (item[FIELD_DEFAULT_LOCALE] === localizedUpdateLocale) {
    return { [fieldName]: updateMap(updateValue) };
  }

  return { [localizedUpdateLocale]: { [fieldName]: updateMap(updateValue) } };
}

export function buildLocalizedFieldsUpdate<I extends TLocalizableObj<any>>(
  params: Pick<TBuildLocalizedFieldUpdateParams<I, any, any>, 'localizedUpdateLocale' | 'item'>,
  updates: Pick<TBuildLocalizedFieldUpdateParams<I, any, any>, 'fieldName' | 'updateValue' | 'updateMap'>[],
) {
  return updates.reduce((acc, update) => {
    return _.merge({}, acc, buildLocalizedFieldUpdate({
      ...params,
      ...update,
    }));
  }, {} as any);
}

/**
 * buildLocalizedFieldUpdate
 */

type TSyncLocalizedItemToDefaultLocaleParams = {
  /**
   * The original item
   */
  item: any;

  /**
   * All the fields in this item that have localizations
   */
  localizedFields: string[];

  /**
   * The new default locale of the item
   */
  newDefaultLocale: ELocale;
};

/**
 * syncLocalizedItemToDefaultLocale
 */
export function syncLocalizedItemToDefaultLocale(params: TSyncLocalizedItemToDefaultLocaleParams) {
  const {
    item,
    localizedFields,
    newDefaultLocale,
  } = params;

  const oldDefaultLocale = item[FIELD_DEFAULT_LOCALE];
  return {
    ...item,

    // Update default locale
    [FIELD_DEFAULT_LOCALE]: newDefaultLocale,

    // Move all default fields in root to localized obj (in oldDefaultLocale)
    ...localizedFields.reduce((acc, fieldName) => {
      if (oldDefaultLocale == newDefaultLocale) {
        return acc;
      }

      return _.set(acc, `${oldDefaultLocale}.${fieldName}`, selectNotEmpty([
        // If we want to update this item while switching locale
        // updateItem has precedence over previous values
        _.get(item, fieldName),
        _.get(item, `${oldDefaultLocale}.${fieldName}`),
      ], ''));
    }, {} as any),

    // Move all new-default fields (in newDefaultLocale) to root
    ...localizedFields.reduce((acc, fieldName) => {
      return _.set(acc, fieldName, selectNotEmpty([
        // If we want to update this item while switching locale
        // updateItem has precedence over previous values
        _.get(item, `${newDefaultLocale}.${fieldName}`),
        _.get(item, fieldName),
      ], ''));
    }, {} as any),

    // Delete newDefaultLocale localization obj
    // Keep at end so previous updates get overwritten
    ...addIfTrue(newDefaultLocale, item[newDefaultLocale] != null, null),
  };
}

/**
 * buildLocalizedFieldUpdate
 */

type TRebaseLocalizedItemToLocalesParams = {
  /**
   * The original item
   */
  item: any;

  /**
   * All the fields in this item that have localizations
   */
  locales: TLanguage[];

  /**
   * All the fields in this item that have localizations
   */
  localizedFields: string[];

  /**
   * The update value, has to be exactly undefined
   * null will delete the item
   */
  updateValue: any;
};

/**
 * syncLocalizedItemToDefaultLocale
 */
export function rebaseLocalizedItemToLocales(params: TRebaseLocalizedItemToLocalesParams) {
  const {
    item,
    locales,
    localizedFields,
    updateValue,
  } = params;

  return {
    ...item,

    ...mergeLocalesAndLocalizedPaths(locales, localizedFields)
      .reduce((acc, { locale, fieldName }) => {
        return _.merge(acc, buildLocalizedFieldUpdate({
          localizedUpdateLocale: locale,
          fieldName,
          item,
          updateValue: selectNotEmpty([
            _.get(updateValue[locale], fieldName),
            _.get(updateValue, fieldName),
            _.get(item, fieldName),
          ], ''),
        }));
      }, {} as any),

    // Remove locales that are not in the locales object
    ...dbItemLocalizableSupportedLocalesGet(item)
      .filter((eLocale) => !locales.includes(eLocale))
      .reduce((acc, eLocale) => {
        return _.set(acc, eLocale, null);
      }, {} as any),
  };
}

/**
 * stripUnchangedValues
 */
type TStripUnchangedValues<Obj> = {
  initialItem: Obj;
  updatedItem: Partial<Obj>;
};

export function stripUnchangedValues<Obj>({
  initialItem,
  updatedItem,
}: TStripUnchangedValues<Obj>) {
  const updatedDottedItem = itemToDotItem(updatedItem);
  const strippedUpdatedDottedItem = _.pickBy(updatedDottedItem, (value, key) => {
    return _.get(initialItem, key) != updatedDottedItem[key];
  });
  return dotItemToItem(strippedUpdatedDottedItem);
}
