import {
  IQuery,
  IRef,
  IStorageRef,
  TFirebase,
} from './FirebaseTypes';

type TUnSubscriber = {
  unsubscribe: () => void;
};

export type TFirebaseUser = {
  uid: string;
  isAnonymous: boolean;
  displayName: string | null;
  email: string | null;
};

export class FirebaseApi {
  static get instance(): FirebaseApi {
    // @ts-ignore
    return global.firebaseApi;
  }

  private readonly firebase: TFirebase;

  private get database() {
    return this.firebase.database();
  }

  private get storage() {
    return this.firebase.storage();
  }

  get auth() {
    return this.firebase.auth();
  }

  static async once<T>(ref: IRef<T> | IQuery<T>): Promise<T | undefined> {
    const snapshot = await ref.once('value');
    return snapshot && (snapshot.val() as T);
  }

  static subscribeValue<T>(ref: IRef<T> | IQuery<T>, on: (value: T) => void): TUnSubscriber {
    const callback = (snapshot) => on(snapshot.val());
    ref.on('value', callback);
    return { unsubscribe: () => ref.off('value', callback) };
  }

  static subscribeChildAdded<T>(ref: IRef<T> | IQuery<T>, on: (value: T[any]) => void): TUnSubscriber {
    const callback = (snapshot) => on(snapshot.val());
    ref.on('child_added', callback);
    return { unsubscribe: () => ref.off('value', callback) };
  }

  constructor(instance: TFirebase) {
    // @ts-ignore
    global.firebaseApi = this;
    this.firebase = instance;
  }

  ref<T = any>(path: string): IRef<T> {
    return this.database.ref(path);
  }

  storageRef<T = any>(path: string): IStorageRef<T> {
    return this.storage.ref(path);
  }
}
