import {FIELD_NAME, TIdable, TLocalizableObj, TObjList, TSortable,} from '../../../db/DbDefs';
import {TMObj, TMObjDataCreator,} from '../../../lib/model/ModelTypes';
import {TRKMenu} from '../../../db/DbResources';
import {TLanguage} from '../../../locale/Languages';

export type TMenuId = string;

export const menuLocalizedFields = [
  FIELD_NAME,
  'description',
] as const;

export type TMenuLocalizedFields = {
  [key in ((typeof menuLocalizedFields)[number])]: string
};

export type TMenuUpdate = {
  name: string;
  coverCharge: number;
  locales: TLanguage[];
  isHidden: boolean;
};

export type TMenuCreate = TMObjDataCreator<TRKMenu>;

export type TMenu = TMObj<TRKMenu> & TLocalizableObj<TMenuLocalizedFields> & TIdable & TSortable & {
  isHidden: boolean;
  coverCharge: number;
  subMenus?: TObjList<TMenuId, TMenu>;
};
