// @ts-ignore
import FeatherIcons from 'react-native-vector-icons/Fonts/Feather.ttf';
// @ts-ignore
import MaterialCommunityIcons from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';
// @ts-ignore
import MaterialIcons from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';
// @ts-ignore
import FA4Icon from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
// @ts-ignore
import FA5IconSolid from 'react-native-vector-icons/Fonts/FontAwesome5_Solid.ttf';
// @ts-ignore
import FA5IconRegular from 'react-native-vector-icons/Fonts/FontAwesome5_Regular.ttf';

const iconFontStyles = `
  @font-face {
    src: url(${FeatherIcons});
    font-family: Feather;
  }
  @font-face {
    src: url(${MaterialCommunityIcons});
    font-family: MaterialCommunityIcons;
  }
  @font-face {
    src: url(${MaterialIcons});
    font-family: MaterialIcons;
  }
  @font-face {
      src: url(${FA4Icon});
      font-family: FontAwesome;
  }
  @font-face {
      src: url(${FA5IconSolid});
      font-family: 'FontAwesome5_Solid';
  }
  @font-face {
      src: url(${FA5IconRegular});
      font-family: 'FontAwesome5_Regular';
  }
`;

const style = document.createElement('style');
style.type = 'text/css';

// @ts-ignore
if (style.styleSheet) {
  // @ts-ignore
  style.styleSheet.cssText = iconFontStyles;
} else {
  style.appendChild(document.createTextNode(iconFontStyles));
}

// Inject stylesheet
document.head.appendChild(style);
