import * as _ from 'lodash';
import { Task } from '@redux-saga/types';
import {
  call,
  cancel,
  select,
  takeEvery,
} from 'redux-saga/effects';

const CLEAR_TASK = 'DONT_RUN';
type TClearTask = typeof CLEAR_TASK;

export function selectUndefinedAsClearTask<Store, T>(selector: (store: Store) => T | undefined) {
  return function (store: Store) {
    const result = selector(store);
    if (result == null) {
      return CLEAR_TASK;
    }

    return result;
  };
}

export function* sagaMonitorSelector<Store, T>(
  takePatterns: any[],
  selector: (store: Store) => T | TClearTask,
  worker: (params: T) => any,
) {
  let previousValue: T | undefined;
  let previousWorker: Task;

  yield takeEvery(takePatterns, function* () {
    const nextValue = yield select(selector);
    if (_.isEqual(nextValue, previousValue)) {
      return;
    }

    // nextValue and previousValue are different
    previousValue = nextValue;

    if (nextValue == CLEAR_TASK) {
      yield cancel(previousWorker);
      return;
    }

    const newWorker = yield call(worker, nextValue);
    yield cancel(previousWorker);
    previousWorker = newWorker;
  });
}
