import {TFirebaseUser} from '../../../core/src/FirebaseApi';
import {TGuestSession} from '../../../core/src/models/db/guestSession/GuestSessionTypes';
import {THost, THostId,} from '../../../core/src/models/db/host/HostTypes';
import {TSnackbar} from './reducers/Snackbars';
import {TTableId} from '../../../core/src/models/db/table/TableTypes';
import {createAction} from '../../../lib-react/src/redux/redux';
import {REHYDRATE} from 'redux-persist/es/constants';
import {THostPublicSubscription} from '../../../core/src/models/db/HostPublicSubscription/HostPublicSubscriptionTypes';
import {TOrderItemCreate} from '../../../core/src/models/db/orderItem/OrderItemTypes';
import {TNavigationParams} from '../lib/navigation/Navigation';
import {TLanguage} from '../../../core/src/locale/Languages';

export const actions = {
  actionTypeOnStoreRehydrated: createAction(REHYDRATE)(),

  actionTypeOnSagasLoaded: createAction('global/start')(),
  pureActionTypeHostSetHostId: createAction('pure/host/setHostId')<{
    hostId: THostId;
  }>(),
  pureActionTypeGuestSessionSetTableId: createAction('pure/guestSession/setTableId')<{
    tableId: TTableId | undefined;
  }>(),
  pureActionTypeResetSession: createAction('pure/global/resetSession')(),
  actionTypeAppSetUser: createAction('app/setUser')<{
    user: TFirebaseUser;
  }>(),
  actionTypeSessionSetConnectionState: createAction('session/setConnectionState')<{
    isConnected: boolean;
  }>(),
  actionTypeAppSetError: createAction('app/setError')<{
    error: Error;
    errorInfo: any;
  }>(),
  actionTypeAppSetLocale: createAction('app/setLocale')<{
    locale: TLanguage;
  }>(),
  actionTypeAppSetLockScreen: createAction('app/setLockScreen')<{
    lockScreen: boolean;
  }>(),
  actionTypeGuestSessionSet: createAction('guestSession/setGuestSession')<{
    guestSessionData: TGuestSession;
  }>(),
  actionTypeGuestSessionSetSetupFlags: createAction('guestSession/setup')<{
    tableRequested?: boolean;
    guestCountRequested?: boolean;
  }>(),
  actionTypeHostSetHost: createAction('host/setHost')<{
    hostData: THost;
  }>(),
  actionTypeHostSetHostPublicSubscription: createAction('host/setHostPublicSubscription')<{
    hostPublicSubscription?: THostPublicSubscription;
  }>(),
  actionTypeShowSnackbar: createAction('snackbar/setSnackbar')<{
    snackbar?: TSnackbar;
  }>(),

  actionTypeOrderItemEditorBind: createAction('orderItemEditor/orderItem/bind')<{
    scopeId: string;
    initialOrderItem: TOrderItemCreate;
  }>(),
  actionTypeOrderItemEditorSet: createAction('orderItemEditor/orderItem/set')<Partial<TOrderItemCreate>>(),
  actionTypeOrderItemEditorItemCountSet: createAction('orderItemEditor/itemCount/increment')<number>(),

  actionTypeNavigationPush: createAction('navigation/push')<TNavigationParams<any>>(),
  actionTypeNavigationReplace: createAction('navigation/replace')<TNavigationParams<any>>(),
  actionTypeNavigationReset: createAction('navigation/reset')<TNavigationParams<any>>(),
  actionTypeNavigationNavigate: createAction('navigation/navigate')<TNavigationParams<any>>(),
  actionTypeNavigationBack: createAction('navigation/back')(),
};
