import { Log } from '../../config/Log';
import { actions } from '../Actions';
import { getType } from 'typesafe-actions';
import { takeLatest } from 'redux-saga/effects';
import { Navigation } from '../../lib/navigation/Navigation';
import { RoutesCliGuest } from '../../../../core/src/lib/apis/routes/RoutableCliGuest';

export function* sagaLockScreenListener() {
  Log.v('sagaLockScreenListener', 'sagaLockScreenListener', 'starting');
  yield takeLatest(
    [
      getType(actions.actionTypeAppSetLockScreen),
    ],
    (params: ReturnType<typeof actions.actionTypeAppSetLockScreen>) => {
      const { lockScreen } = params.payload;
      return lockScreen
        ? Navigation.navigate(RoutesCliGuest.ModalLockScreenOverlay.name)
        : Navigation.hideRouteIfCurrent(RoutesCliGuest.ModalLockScreenOverlay.name);
    },
  );
}
