import { FirstParameter } from '../lib/Types';
import { MStObj } from '../lib/model/StObj';
import { StPaths } from './StPaths';

type TStPaths = typeof StPaths;
type TStRefs = {
  [key in keyof TStPaths]: (param?: FirstParameter<TStPaths[key]>) => MStObj<key>
};

export const StRefs: TStRefs = Object.keys(StPaths).reduce((acc, key) => {
  acc[key] = function (pbp: any = {}) {
    return new MStObj(key as keyof TStPaths, pbp);
  };
  return acc;
}, {}) as any;
