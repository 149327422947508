import * as React from 'react';
import { OrderItem } from '../../../../core/src/models/db/orderItem/OrderItem';
import {
  defMenuItem,
  defOrderItem,
} from '../../../../core/src/models/db/ModelDefaults';
import { TOrderItemCreate } from '../../../../core/src/models/db/orderItem/OrderItemTypes';
import * as _ from 'lodash';
import { TMenuItem } from '../../../../core/src/models/db/menuItem/MenuItemTypes';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { TState } from '../../redux/Store';
import { actions } from '../../redux/Actions';

const defOrderItemEditorValue = {
  orderItem: OrderItem.asCreator(defOrderItem),
  itemCount: 1,

  menuItem: defMenuItem as TMenuItem,
  orderItemSet: _.noop as undefined | ((partial: Partial<TOrderItemCreate>) => any),

  itemCountIncrement: _.noop as undefined | (() => any),
  itemCountDecrement: _.noop as undefined | (() => any),

  hasChanges: false,
  allowMultiple: false,

  orderItemUpdate: _.noop as undefined | (() => Promise<void>),
  openOrderDelete: _.noop as undefined | (() => Promise<void>),
};

export const OrderItemEditorContext = React.createContext(defOrderItemEditorValue);

type TUseOrderItemEditorStoreParams = {
  scopeId: string;
  initialOrderItem: TOrderItemCreate;
};

function useOrderItemEditorStore({
  scopeId,
  initialOrderItem,
}: TUseOrderItemEditorStoreParams) {
  const dispatch = useDispatch();

  // Bind to store on mount
  React.useEffect(() => {
    dispatch(actions.actionTypeOrderItemEditorBind({
      scopeId,
      initialOrderItem,
    }));
  }, [dispatch, scopeId]);

  const itemCount = useSelector((state: TState) => state.orderItemEditor.itemCount);
  const orderItem = useSelector((state: TState) => state.orderItemEditor.orderItem);
  const orderItemSet = React.useCallback((update: Partial<TOrderItemCreate>) => {
    dispatch(actions.actionTypeOrderItemEditorSet(update));
  }, [dispatch]);

  const itemCountIncrement = React.useCallback(() => {
    dispatch(actions.actionTypeOrderItemEditorItemCountSet(itemCount + 1));
  }, [dispatch, itemCount]);
  const itemCountDecrement = React.useCallback(() => {
    dispatch(actions.actionTypeOrderItemEditorItemCountSet(itemCount - 1));
  }, [dispatch, itemCount]);

  return {
    orderItem,
    itemCount,
    orderItemSet,
    itemCountIncrement,
    itemCountDecrement,
  };
}

type TOrderItemEditorProviderProps = {
  scopeId;
  children: React.ReactNode;
  initialOrderItem: TOrderItemCreate;
  menuItem: TMenuItem;
  allowMultiple: boolean;
  orderItemUpdate?: (orderItem: TOrderItemCreate, count: number) => Promise<any>;
  openOrderDelete?: () => Promise<any>;
};

export const OrderItemEditorProvider = (props: TOrderItemEditorProviderProps) => {
  const {
    children,
    initialOrderItem,
    menuItem,
    allowMultiple,
    orderItemUpdate,
    openOrderDelete,
  } = props;

  const {
    orderItem,
    orderItemSet,
    itemCount,
    itemCountIncrement: _itemCountIncrement,
    itemCountDecrement: _itemCountDecrement,
  } = useOrderItemEditorStore(props);

  const _orderItemUpdate = React.useCallback(async () => {
    return orderItemUpdate && orderItemUpdate(orderItem, itemCount);
  }, [orderItem, orderItemUpdate, itemCount]);

  const itemCountIncrement = allowMultiple
    ? _itemCountIncrement
    : undefined;

  const itemCountDecrement = allowMultiple
    ? _itemCountDecrement
    : undefined;

  const hasChanges = !_.isEqual(orderItem, initialOrderItem);
  return (
    <OrderItemEditorContext.Provider
      value={{
        orderItem,
        menuItem,
        orderItemSet: orderItemUpdate
          ? orderItemSet as typeof defOrderItemEditorValue['orderItemSet']
          : undefined,

        itemCount,
        itemCountIncrement,
        itemCountDecrement,

        hasChanges,
        allowMultiple,

        orderItemUpdate: orderItemUpdate
          ? _orderItemUpdate
          : undefined,
        openOrderDelete,
      }}>
      {children}
    </OrderItemEditorContext.Provider>
  );
};
