import * as React from 'react';
import * as _ from 'lodash';
import { RatioedImage } from '../RatioedImage';
import { Linking, ScrollView, Text, TouchableWithoutFeedback, View } from 'react-native';
import { StyledComponentsTheme } from '../../lib-components/theme/StyledComponentsTheme';
import styled from 'styled-components/native';
import { OrderItemEditorSectionDescription } from './OrderItemEditorSectionDescription';
import { Utils } from '../../../../core/src/lib/Utils';
import { makeUnsplashReferralComponents } from '../../../../core/src/lib/imageReferrals';
import { withScreenContext } from '../../lib-components/screen/withScreenContext';
import { AlaText } from "../Text";

export type TOrderItemEditorFrameProps = {
  imageUrl: string;
  sections: React.ReactNode[];
  bottomActionButton?: React.ReactNode;
};

export const OrderItemEditorFrame = ({
                                       imageUrl,
                                       sections,
                                       bottomActionButton,
                                     }: TOrderItemEditorFrameProps) => {
  const { theme } = React.useContext(StyledComponentsTheme.Context);

  const data = React.useMemo(() => {
    try {
      const url = new URL(imageUrl);
      const referralPhn = url.searchParams.get('referralPhn');

      const _referralPhu = url.searchParams.get('referralPhu');
      const referralPhu = _referralPhu && Utils.decodeURIComponent(_referralPhu);

      if (!_.isEmpty(referralPhn) && !_.isEmpty(referralPhu)) {
        return makeUnsplashReferralComponents({
          photographerName: referralPhn ?? '',
          photographerUsername: referralPhu ?? '',
        });
      }
    } catch (e) {
      // Not fatal
    }
  }, [imageUrl]);
  return (
    <Root>
      <ScrollableContent
        limitHeight={sections.length > 0}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
      >
        <View>
          <RatioedImage
            scale={{
              width: theme.dim.width,
              ratio: 7 / 4,
              maxHeight: theme.dim.drawableHeight / 2.5,
            }}
            source={{ uri: imageUrl }}
            resizeMode={'cover'}
          />
          {data && (
            <View style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              display: 'flex',
              flexDirection: 'row',
              paddingHorizontal: 2,
              borderTopLeftRadius: 2,
            }}>
              <AlaText style={{fontSize: 9}}>{data.segment1Text}</AlaText>
              <TouchableWithoutFeedback
                onPress={async () => {
                  if (await Linking.canOpenURL(data.segment2LinkHref)) {
                    Linking.openURL(data.segment2LinkHref);
                  }
                }}>
                <AlaText style={{fontSize: 9}}>{data.segment2LinkText}</AlaText>
              </TouchableWithoutFeedback>
              <AlaText style={{fontSize: 9}}>{data.segment3Text}</AlaText>
              <TouchableWithoutFeedback
                onPress={async () => {
                  if (await Linking.canOpenURL(data.segment4LinkHref)) {
                    Linking.openURL(data.segment4LinkHref);
                  }
                }}>
                <AlaText style={{fontSize: 9}}>{data.segment4LinkText}</AlaText>
              </TouchableWithoutFeedback>
            </View>
          )}
        </View>
        <OrderItemEditorSectionDescription/>
      </ScrollableContent>
      <Content
        limitHeight={sections.length > 0}>
        {sections.map((child, index) => (
          <ChildContainer key={index}>
            {child}
          </ChildContainer>
        ))}
        {bottomActionButton}
      </Content>
    </Root>
  );
};

const contentMinHeight = 325;

const Root = withScreenContext(styled(View)`
  background-color: ${({ theme }) => theme.t.col.white};
  height: ${({ screen }) => screen.contentHeight}px;
  max-height: ${({ screen }) => screen.contentHeight}px;
  min-height: ${({ screen }) => screen.contentHeight}px;
`);

const ScrollableContent = withScreenContext(styled(ScrollView)<{ limitHeight: boolean }>`
  max-height: ${({
                   limitHeight,
                   screen
                 }) => (limitHeight ? screen.contentHeight - contentMinHeight : screen.contentHeight)}px;
`);

const Content = styled(View)<{ limitHeight: boolean }>`
  display: flex;
  flex: 1;
  flex-flow: column;
  justify-content: space-between;
  min-height: ${({ limitHeight }) => (limitHeight ? contentMinHeight : 0)}px;
  padding: 0 20px;
`;

const ChildContainer = styled(View)`
  flex: 1 1 auto;
`;
