import * as _ from 'lodash';
import {
  FIELD_ID,
  FIELD_PATH,
} from '../db/DbDefs';

type TModelObj = {
  [FIELD_ID]?: string;
  [FIELD_PATH]?: string;
  [keys: string]: any;
};

type TModelParam<T extends TModelObj> = {
  obj: T;
  traversedPath?: string;
};

type TReduceChildren<T extends TModelObj, R> = TModelParam<T> & {
  init: R;
  reducer: (acc: R, childPath: string, childKey: string, childValue: any) => R;
};

class ModelValidatorBase {
  isTModelObj(obj: any) {
    return _.isObject(obj) && !_.isArray(obj)
      && FIELD_ID in obj
      && FIELD_PATH in obj;
  }

  validateTModelObj<T extends TModelObj>(params: TModelParam<T>) {
    const {
      obj,
      traversedPath = '',
    } = params;

    if (!this.validateNode(params)) {
      return false;
    }

    return this.reduceChildren({
      obj,
      traversedPath,
      init: true,
      reducer: (isValid, childPath, childKey, childValue) => {
        if (!isValid) {
          return false;
        }

        if (!_.isObject(childValue)) {
          return true;
        }

        return this.validateTModelObj({
          obj: childValue,
          traversedPath: childPath,
        });
      },
    });
  }

  makeConsistentTModelObj<T extends TModelObj>({
    obj,
    traversedPath = '',
  }: TModelParam<T>): T {
    const newObj = this.makeConsistentNode({
      obj,
      traversedPath,
    });

    return this.reduceChildren({
      obj: newObj,
      init: newObj,
      traversedPath,
      reducer: (acc, childPath, childKey, childValue) => {
        if (!_.isObject(childValue)) {
          return acc;
        }

        return {
          ...acc,
          [childKey]: this.makeConsistentTModelObj({
            obj: childValue,
            traversedPath: childPath,
          }),
        };
      },
    });
  }

  reduceChildren<T extends TModelObj, R>({
    obj,
    init,
    reducer,
    traversedPath = '',
  }: TReduceChildren<T, R>): R {
    return Object.keys(obj)
      .reduce((acc, key) => reducer(acc, `${traversedPath}/${key}`, key, obj[key]), init);
  }

  private makeConsistentNode<T extends TModelObj>({
    obj,
    traversedPath = '',
  }: TModelParam<T>): T {
    return this.isTModelObj(obj)
      ? {
        ...obj,
        [FIELD_PATH]: traversedPath,
      }
      : obj;
  }

  private validateNode<T extends TModelObj>({
    obj,
    traversedPath = '',
  }: TModelParam<T>): boolean {
    if (this.isTModelObj(obj)) {
      if (obj[FIELD_PATH] !== traversedPath) {
        return false;
      }
    }
    return true;
  }
}

export const ModelValidator = new ModelValidatorBase();
