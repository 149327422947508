import * as _ from 'lodash';
import {
  FIELD_ID,
  FIELD_NAME,
  FIELD_PATH,
  FIELD_SORTABLE_INDEX,
  TObjList,
} from '../../db/DbDefs';
import { HostBuilder } from '../db/host/HostBuilder';
import { RoomCreator } from '../db/room/RoomCreator';
import { TBuilderParamsBase } from './lib/buildRawUpdate';
import {
  THost,
  TRoomToTableCount,
} from '../db/host/HostTypes';
import { TRKHost } from '../../db/DbResources';
import {
  TRoom,
  TRoomId,
} from '../db/room/RoomTypes';
import {
  TTable,
  TTableId,
} from '../db/table/TableTypes';
import { TableCreator } from '../db/table/TableCreator';
import {
  filterNull,
  push,
} from '../../lib/HelperFunctions';
import { GuestSessionBuilder } from '../db/guestSession/GuestSessionBuilder';

type TIndexedTable = TTable | null | undefined;

type TContext = {
  host: THost;
  isTableOccupied: ReturnType<typeof buildIsTableOccupied>;
};

function getTablesInRoom({ tables }: Pick<THost, 'tables'>, roomId: string): TTable[] {
  return _.filter(tables, (table) => table.roomId == roomId);
}

function countIndexedTables(tables: TIndexedTable[]) {
  return filterNull(tables).length;
}

function pushAllIndexedTables(tables: TIndexedTable[], tablesToPush: TIndexedTable[]) {
  tablesToPush.forEach((table) => pushIndexedTable(tables, table));
  return tables;
}

function pushIndexedTable(tables: TIndexedTable[], table: TIndexedTable) {
  tables[parseInt(_.get(table, FIELD_NAME, ''), 10)] = table;
  return tables;
}

function buildIndexedTables(host: Pick<THost, 'tables' | typeof FIELD_PATH>, count: number) {
  const indexedTables: TIndexedTable[] = [];

  // Index new table status by name
  for (let i = 0; i < count; i++) {
    const tableIndex = i + 1;
    indexedTables[tableIndex] = null;
  }

  // Overwrite new tables with old tables
  _.forEach(host.tables, (table) => {
    pushIndexedTable(indexedTables, table);
  });

  return indexedTables;
}

function buildIsTableOccupied(host: Pick<THost, typeof FIELD_ID>) {
  const tableIsOccupiedById: Record<TTableId, boolean> = {};

  return async function isTableOccupied(table: Pick<TTable, typeof FIELD_ID>): Promise<boolean> {
    const res = _.get(tableIsOccupiedById, table[FIELD_ID]);
    if (res !== undefined) {
      return res;
    }

    _.set(tableIsOccupiedById, table[FIELD_ID], await GuestSessionBuilder.dataExistsById({
      hostId: host[FIELD_ID],
      tableId: table[FIELD_ID],
    }));

    return isTableOccupied(table);
  };
}

function buildTableIterator(context: TContext, indexedTables: any) {
  let nextTableName = 1;

  const pbp = HostBuilder.getPathBuilderParamsFromDataPath(context.host);

  return async function tableIterator(roomId: TRoomId): Promise<TTable> {
    const thisTableName = nextTableName;
    nextTableName++;

    const table: TIndexedTable = _.get(indexedTables, thisTableName);

    if (table == null) {
      return TableCreator.buildNew(pbp, {
        [FIELD_NAME]: `${thisTableName}`,
        maxGuests: 10000,
        roomId,
      });
    }

    const occupied = await context.isTableOccupied(table);
    if (occupied) {
      // Table previously existed and was occupied
      // Try use the next tableName
      return tableIterator(roomId);
    }

    // Table previously either didn't exist,
    // or, existed and was not occupied

    if (table.roomId === roomId) {
      // Table previously existed and was in the same room
      // as it needs to be now
      return table;
    }

    // Table previously either didn't exist,
    // or, existed and was not in the same room as
    // it needs to be now, change table
    return table;
  };
}

async function getOccupiedTablesInRoom(context: TContext, roomId: string): Promise<TTable[]> {
  const previousTablesInRoom = getTablesInRoom(context.host, roomId);

  const tablesInRoom: TTable[] = [];

  for (let i = 0; i < previousTablesInRoom.length; i++) {
    const table = previousTablesInRoom[i];
    if (await context.isTableOccupied(table)) {
      pushIndexedTable(tablesInRoom, table);
    }
  }

  return tablesInRoom;
}

export async function buildRoomsUpdate(context: TContext, rooms: TRoomToTableCount[]) {
  const originalRooms = _.values(context.host.rooms);
  const roomsToUpdate = _.chain(rooms || [])
    .map(({ room }, index) => {
      const preExistingRoom = _.find(context.host.rooms, (hostRoom) => {
        if (!room[FIELD_ID]) {
          return false;
        }

        return hostRoom[FIELD_ID] == room[FIELD_ID];
      });
      return {
        ...RoomCreator.buildNew(HostBuilder.getPathBuilderParamsFromDataPath(context.host), room, room[FIELD_ID]),
        ...(preExistingRoom || {}),
        [FIELD_SORTABLE_INDEX]: index,
      };
    })
    .keyBy(FIELD_ID)
    .value() as TObjList<TRoomId, TRoom>;

  const newRoomIds = _.keys(roomsToUpdate);
  const roomsToDelete = await originalRooms
    .filter((room) => !newRoomIds.includes(room[FIELD_ID]))
    .reduce(async (acc, roomToRemove) => {
      const res = await acc;

      const roomOccupiedTables = await getOccupiedTablesInRoom(context, roomToRemove[FIELD_ID]);
      if (roomOccupiedTables.length > 0) {
        // Room has occupied tables, don't delete
        return acc;
      }

      return _.set(res, roomToRemove[FIELD_ID], null);
    }, Promise.resolve({}) as Promise<TObjList<TRoomId, null>>);

  return <TObjList<TRoomId, TRoom | null>>{
    ...roomsToUpdate,

    // Keep after updates so it can override them
    // Rooms to remove are not in the newRoomIds array
    ...roomsToDelete,
  };
}

type TTableCountUpdate = Omit<TRoomToTableCount, 'room'> & {
  room: TRoom;
};

export async function buildTablesUpdate(context: TContext, rooms: TTableCountUpdate[]) {
  const totalTableCount = _.sumBy(rooms, 'tableCount');
  const indexedTables = buildIndexedTables(context.host, totalTableCount);
  const tableIterator = buildTableIterator(context, indexedTables);

  // Group tables into rooms
  const tablesGroupedByRooms = await rooms.reduce(async (acc, { room, tableCount }) => {
    const tablesByRooms = await acc;
    const roomId = room[FIELD_ID];

    const tablesInRoom: TIndexedTable[] = [];
    tablesByRooms[roomId] = tablesInRoom;

    // Fill tablesInRoom with the tables that have GuestSessions
    // These cannot be removed
    const previouslyOccupiedTables = await getOccupiedTablesInRoom(context, roomId);

    pushAllIndexedTables(tablesInRoom, previouslyOccupiedTables);

    // Fill tablesInRoom with remaining tables
    while (countIndexedTables(tablesInRoom) < tableCount) {
      const nextNewTable = await tableIterator(roomId);
      nextNewTable.roomId = roomId;
      pushIndexedTable(tablesInRoom, nextNewTable);
    }

    return tablesByRooms;
  }, Promise.resolve({}) as Promise<Record<TRoomId, TIndexedTable[]>>);

  return {
    // Remove all tables
    ..._.mapValues(context.host.tables, () => null),

    // Add all tables
    ..._.chain(tablesGroupedByRooms)
      .flatMapDeep((tablesInRoom) => filterNull(tablesInRoom))
      .keyBy(FIELD_ID)
      .value() as any as Record<TTableId, TTable>,
  };
}

type TBuildRoomsUpdate = {
  rooms: TRoomToTableCount[];
};

export async function hostUpdateRooms({
  item,
  rooms,
}: TBuilderParamsBase<TRKHost> & TBuildRoomsUpdate) {
  const context: TContext = {
    host: item,
    isTableOccupied: buildIsTableOccupied(item),
  };

  const roomsUpdateDefinition = await buildRoomsUpdate(context, rooms);

  const roomsUpdate = {
    ...(item.rooms || {}),
    ...roomsUpdateDefinition,
  };

  const roomToTableCount = _.values(roomsUpdate)
    .filter((room): room is TRoom => room != null)
    .reduce((acc, updatedRoom) => {
      const roomTableCount = _.find(rooms, ({ room }) => {
        return _.get(room, FIELD_NAME) == updatedRoom[FIELD_NAME];
      });

      return push(acc, {
        room: updatedRoom,
        tableCount: _.get(roomTableCount, 'tableCount', 0),
      });
    }, [] as TTableCountUpdate[]);

  const tablesUpdate = await buildTablesUpdate(context, roomToTableCount);

  return <Partial<THost>>{
    tables: tablesUpdate,
    rooms: roomsUpdate,
  };
}
