import * as _ from 'lodash';
import { RoutableRoute } from './RoutableRoute';
import { TGenericRoutableRoutes } from './RoutableTypes';

export type TRoutableParams<Routes extends TGenericRoutableRoutes> = {
  baseUrl: string;
  routes: {
    [k in keyof Routes]: Routes[k]
  };
};

export type TWrapApiReturnType<Routes extends TGenericRoutableRoutes> = Routable<Routes> & {
  [K in keyof Routes]: RoutableRoute<Routes[K]>;
};

export class Routable<Routes extends TGenericRoutableRoutes> {
  static wrapApi<Routes extends TGenericRoutableRoutes>(params: TRoutableParams<Routes>) {
    return new Routable(params) as TWrapApiReturnType<Routes>;
  }

  private readonly baseUrl: string;

  private readonly routes: Routes;

  private constructor(params: TRoutableParams<Routes>) {
    this.baseUrl = params.baseUrl;
    this.routes = params.routes;

    const keys = _.keys(this.routes);
    for (let i = 0; i < keys.length; i++) {
      this[keys[i]] = new RoutableRoute(
        this.baseUrl,
        this.routes[keys[i]],
      );
    }
  }
}
