import { TRKGuestSession } from '../../../db/DbResources';
import {
  EGuestSessionStatus,
  TGuestSession,
  TGuestSessionCreate,
} from './GuestSessionTypes';
import { FIELD_ID } from '../../../db/DbDefs';
import { TGuestSessionPathBuilderParams } from './GuestSessionBuilderBase';
import { MObjCreator } from '../../../lib/model/MObjCreator';
import { TMObjDataCreator } from '../../../lib/model/ModelTypes';
import { TTable } from '../table/TableTypes';
import { uuid } from '../../../lib/UUID';
import { GuestSessionBuilder } from './GuestSessionBuilder';

export class GuestSessionCreatorBase extends MObjCreator<TRKGuestSession> {
  constructor() {
    super(GuestSessionBuilder);
  }

  buildNewForTableBase(table: Pick<TTable, typeof FIELD_ID>): TGuestSessionCreate {
    return {
      numberOfGuests: 1,
      status: EGuestSessionStatus.OPEN,
      tableId: table[FIELD_ID],
    };
  }

  buildNewForTable(pbp: Omit<TGuestSessionPathBuilderParams, 'tableId'>, table: Pick<TTable, typeof FIELD_ID>): TGuestSession {
    const tablePbp = {
      ...pbp,
      tableId: table[FIELD_ID],
    };
    return this.buildNew(tablePbp, this.buildNewForTableBase(table));
  }

  buildNew(pbp: TGuestSessionPathBuilderParams, data: Omit<TMObjDataCreator<TRKGuestSession>, 'tableId'>, id: string = uuid()): TGuestSession {
    const newGuestSession = {
      ...data,
      tableId: pbp.tableId,
    };
    return super.buildNew(pbp, newGuestSession, id);
  }
}

export const GuestSessionCreator = new GuestSessionCreatorBase();
