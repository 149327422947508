import * as React from 'react';
import {
  ActivityIndicator,
  ActivityIndicatorProps,
} from 'react-native';

type TLoader = ActivityIndicatorProps & {
  //
};

export const Loader = (props: TLoader) => (
  <ActivityIndicator
    {...props}
  />
);
