import * as React from 'react';
import { TNavigationParamsWithMethod } from '../navigation/Navigation';
import * as _ from 'lodash';

export enum ECheckPropsResult {
  READY = 'READY',
  WAIT = 'WAIT',
  REDIRECT = 'REDIRECT',
  RENDER = 'RENDER',
}

export type TCheckPropsResult = ReturnType<typeof CheckPropsResultBuild[keyof typeof CheckPropsResultBuild]>;

export class CheckPropsResult<Props = any> {
  static checkIs<K extends keyof CheckPropsResult>(e: K, data: any): data is ReturnType<CheckPropsResult[K]> {
    return _.get(data, 'action') === e;
  }

  readonly READY = <T>(props: T) => ({
    action: ECheckPropsResult.READY,
    props,
  });

  // todo fix recursive wait (App freezes)
  readonly WAIT = () => ({
    action: ECheckPropsResult.WAIT,
    data: {} as Record<never, never>,
  });

  readonly REDIRECT = (params: TNavigationParamsWithMethod<any>) => ({
    action: ECheckPropsResult.REDIRECT,
    to: params,
  });

  readonly RENDER = (node: React.ReactNode) => ({
    action: ECheckPropsResult.RENDER,
    data: node,
  });
}

export const CheckPropsResultBuild = new CheckPropsResult();
