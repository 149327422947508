import { DbPaths } from '../../../db/DbRefs';
import {
  DbRes,
  TRKHostPublicSubscription,
} from '../../../db/DbResources';
import { HostPublicSubscription } from './HostPublicSubscription';
import { MObjDef } from '../../../lib/model/MObjDef';
import { THostPublicSubscription } from './HostPublicSubscriptionTypes';
import { defHostPublicSubscription } from '../ModelDefaults';

const pathBuilderKeys = [
  'hostId',
] as const;

export type THostPublicSubscriptionPathBuilderParams = {
  [key in ((typeof pathBuilderKeys)[number])]: string
};

class HostPublicSubscriptionBuilderBase extends MObjDef<TRKHostPublicSubscription> {
  readonly resourceKey = DbRes.HostPublicSubscription;

  protected readonly pathBuilderKeys = [...pathBuilderKeys];

  protected readonly _default = defHostPublicSubscription;

  protected newChild(item: THostPublicSubscription) {
    return new HostPublicSubscription(item);
  }

  pathBuilder(params: THostPublicSubscriptionPathBuilderParams): string {
    return DbPaths.hostPublicSubscription(params);
  }
}

export const HostPublicSubscriptionBuilder = new HostPublicSubscriptionBuilderBase();
