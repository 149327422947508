import * as React from 'react';
import { Screen } from '../lib-components/screen/Screen';

import styled from 'styled-components/native';
import { shadowViewStyle1 } from '../lib-components/ShadowView';
import { Text, View } from 'react-native';
import { AlaTouchableWithoutFeedback } from '../lib-components/Touchable';
import { RCComp } from '../lib/routeContract/RCComp';
import { Theme } from '../config/Theme';

export class ConfirmationOverlayRCComp extends RCComp<'ModalConfirmationDialog'> {
  protected routeName = 'ModalConfirmationDialog' as const;

  render() {
    const {
      title,
      description,
      positiveButtonText,
      negativeButtonText,
      positiveButtonOnClick,
      negativeButtonOnClick,
    } = this.props;
    return (
      <Screen
        backgroundColor={Theme.col.modalBackgroundOverlay}
        usesReactNavigationHeader={false}
        hasStatusBar={true}
        statusBarBackgroundColor={Theme.col.modalBackgroundOverlay}
        statusBarBackgroundColorIsLight={false}
      >
        <ConfirmationDialogCont style={shadowViewStyle1}>
          <TitleText>{title}</TitleText>
          <DescriptionText>{description}</DescriptionText>
          <AlaTouchableWithoutFeedback onPress={positiveButtonOnClick}>
            <PositiveButton>
              <ButtonText>{positiveButtonText}</ButtonText>
            </PositiveButton>
          </AlaTouchableWithoutFeedback>
          <AlaTouchableWithoutFeedback onPress={negativeButtonOnClick}>
            <NegativeButton>
              <ButtonText>{negativeButtonText}</ButtonText>
            </NegativeButton>
          </AlaTouchableWithoutFeedback>
        </ConfirmationDialogCont>
      </Screen>
    );
  }
}

const ConfirmationDialogCont = styled(View)`
  background-color: ${({ theme }) => theme.t.col.white};
  border-radius: 2px;
  margin: ${({ theme }) => theme.scaler.verticalScale(230)}px 10px;
  padding: 20px;
`;

const TitleText = styled(Text)`
  color: ${({ theme }) => theme.t.col.accentDark};
  font-family: ${({ theme }) => theme.t.font.nunitoSans};
  font-size: 24px;
  font-weight: 800;
  line-height: 33px;
  text-align: center;
  text-transform: uppercase;
`;

const DescriptionText = styled(Text)`
  color: ${({ theme }) => theme.t.col.accentDark};
  font-family: ${({ theme }) => theme.t.font.nunitoSans};
  font-size: 18px;
  line-height: 25px;
  margin-top: 8px;
  text-align: center;
`;

const PositiveButton = styled(View)`
  align-items: center;
  background-color: ${({ theme }) => theme.t.col.accentDark};
  display: flex;
  height: 60px;
  justify-content: center;
  margin-top: 28px;
`;

const NegativeButton = styled(View)`
  align-items: center;
  background-color: ${({ theme }) => theme.t.col.red};
  display: flex;
  height: 60px;
  justify-content: center;
  margin-top: 10px;
`;

const ButtonText = styled(Text)`
  color: ${({ theme }) => theme.t.col.white};
  font-family: ${({ theme }) => theme.t.font.nunitoSans};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
`;
