import * as React from 'react';
import {
  Image,
  ImageProps,
  ImageSourcePropType,
  View,
} from 'react-native';
import styled from 'styled-components/native';
import {
  calculateRatio,
  TCalculateRatioParams,
} from '../lib/calculateRatio';

export type TRatioedImageProps = ImageProps & {
  scale: TCalculateRatioParams;
  source: ImageSourcePropType;
  defaultSource?: ImageSourcePropType;
  overlayOpacity?: number;
  overlayColor?: string;
};

export const RatioedImage = (props: TRatioedImageProps) => {
  const {
    source,
    defaultSource,
    scale,
    overlayOpacity,
    overlayColor,
    ...otherProps
  } = props;
  return (
    <Root scale={scale}>
      {overlayOpacity && (
        <Overlay
          scale={scale}
          overlayOpacity={overlayOpacity}
          overlayColor={overlayColor}
        />
      )}
      <StyledImage
        scale={scale}
        source={source}
        defaultSource={defaultSource}
        {...otherProps}
        style={{
          width: calculateRatio(scale).width,
          height: calculateRatio(scale).height,
        }}
      />
    </Root>
  );
};

type TRootProps = {
  scale: TCalculateRatioParams;
};

const Root = styled(View)<TRootProps>`
  height: ${(props) => calculateRatio(props.scale).height}px;
  max-height: ${(props) => calculateRatio(props.scale).height}px;
  max-width: ${(props) => calculateRatio(props.scale).width}px;
  width: ${(props) => calculateRatio(props.scale).width}px;
`;

type TOverlayProps = TRootProps & {
  overlayOpacity: number;
  overlayColor?: string;
};

const Overlay = styled(View)<TOverlayProps>`
  background-color: ${({ theme, overlayColor }) => (overlayColor || theme.t.col.black)};
  elevation: 1;
  height: ${(props) => calculateRatio(props.scale).height}px;
  max-height: ${(props) => calculateRatio(props.scale).height}px;
  max-width: ${(props) => calculateRatio(props.scale).width}px;
  opacity: ${({ overlayOpacity }) => overlayOpacity};
  position: absolute;
  width: ${(props) => calculateRatio(props.scale).width}px;
  z-index: 1;
`;

const StyledImage = styled(Image)<TRootProps>`
  height: ${(props) => calculateRatio(props.scale).height}px;
  max-height: ${(props) => calculateRatio(props.scale).height}px;
  max-width: ${(props) => calculateRatio(props.scale).width}px;
  width: ${(props) => calculateRatio(props.scale).width}px;
`;
