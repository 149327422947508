import { DbPaths } from '../../../db/DbRefs';
import {
  DbRes,
  TRKGuestSession,
} from '../../../db/DbResources';
import { GuestSession } from './GuestSession';
import { MObjDef } from '../../../lib/model/MObjDef';
import { TGuestSession } from './GuestSessionTypes';
import { defGuestSession } from '../ModelDefaults';

const pathBuilderKeys = [
  'hostId',
  'tableId',
] as const;

export type TGuestSessionPathBuilderParams = {
  [key in ((typeof pathBuilderKeys)[number])]: string
};

export class GuestSessionBuilderBase extends MObjDef<TRKGuestSession> {
  readonly resourceKey = DbRes.GuestSession;

  protected readonly pathBuilderKeys = [...pathBuilderKeys];

  protected readonly _default = defGuestSession;

  protected newChild(item: TGuestSession) {
    return new GuestSession(item);
  }

  pathBuilder(params: TGuestSessionPathBuilderParams): string {
    return DbPaths.guestSession(params);
  }
}

