// @ts-nocheck
import * as React from 'react';
import Svg,
{ Path, Circle } from 'react-native-svg';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <Circle cx={8} cy={8} r={8} fill="#2B2C33" />
      <Path
        d="m8 2 1.347 4.146h4.36L10.18 8.708l1.347 4.146L8 10.292l-3.527 2.562L5.82 8.708 2.294 6.146h4.359L8 2Z"
        fill="#fff"
      />
    </Svg>
  );
}

export const SvgRecommendedRound = React.memo(SvgComponent);
