import * as React from 'react';
import { Provider } from 'react-redux';
import { RootSiblingParent, setSiblingWrapper } from 'react-native-root-siblings/lib/RootSiblingsManager';
import { reducerApp, TStateApp } from './reducers/App';
import { reducerGuestSession, TStateGuestSession } from './reducers/GuestSession';
import { reducerHost, TStateHost } from './reducers/Host';
import { reducerSession, TStateSession } from './reducers/Session';
import { reducerSnackbars, TStateSnackbars } from './reducers/Snackbars';
import { reducerOrderItemEditor, TStateOrderItemEditor } from './reducers/OrderItemEditor';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { reduxLogger } from './middlewares/reduxLogger';
import { rootSaga } from './sagas/rootSaga';
import AsyncStorage from '@react-native-community/async-storage';
import thunk from 'redux-thunk';
import { SplashScreenMiddlewareComponent } from '../components/SplashScreenMiddlewareComponent';
import createSagaMiddleware from 'redux-saga';
import { startReactiveAction } from '../../../lib-react/src/redux/startReactiveAction';

export type TGetState = () => TState;
export type TState = {
  app: TStateApp;
  host: TStateHost;
  guestSession: TStateGuestSession;
  session: TStateSession;
  snackbars: TStateSnackbars;
  orderItemEditor: TStateOrderItemEditor;
};

const sagaProceduresMiddleware = createSagaMiddleware();
const sagaAppMiddleware = createSagaMiddleware();

export const store = createStore(
  persistReducer(
    {
      key: 'root',
      storage: AsyncStorage,
      blacklist: [
        // 'app',
        'session',
        'host',
        'guestSession',
        'snackbars',
        'orderItemEditor',
      ],
    },
    combineReducers({
      app: reducerApp,
      session: reducerSession,
      host: reducerHost,
      guestSession: reducerGuestSession,
      snackbars: reducerSnackbars,
      orderItemEditor: reducerOrderItemEditor,
    }),
  ),
  applyMiddleware(sagaAppMiddleware, sagaProceduresMiddleware, thunk, reduxLogger),
);

export const persistor = persistStore(store);

setSiblingWrapper((sibling) => (
  <StoreProvider>
    {sibling}
  </StoreProvider>
));

type TChildrenProps = {
  children: React.ReactNode;
};

export const StoreProvider = ({ children }: TChildrenProps) => (
  <Provider store={store}>
    <RootSiblingParent>
      {children}
    </RootSiblingParent>
  </Provider>
);

/**
 * The loading flow for the app is
 * - LoadPersistanceAndRunSagas => Triggers the redux-persist rehydrate and runs sagas
 *    - When sagas start (rootSaga.ts) they right away wait for REHYDRATED
 *      So it's safe to say that sagas never run on an un-hydrated store
 */
export const LoadPersistanceAndRunSagas = ({ children }: TChildrenProps) => {
  React.useEffect(() => {
    const allTasks = [
      sagaAppMiddleware.run(rootSaga),
      ...startReactiveAction.start(sagaProceduresMiddleware),
    ];
    return () => {
      allTasks.forEach((task) => task.cancel());
    };
  }, []);

  const child = (
    <SplashScreenMiddlewareComponent>
      {children}
    </SplashScreenMiddlewareComponent>
  );

  return (
    <PersistGate
      persistor={persistor}
      loading={child}>
      {child}
    </PersistGate>
  );
};

