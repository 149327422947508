// @ts-nocheck
import * as React from 'react';
import Svg,
{ Path } from 'react-native-svg';

export type TSvgCheckmark = React.SVGProps<SVGSVGElement> & {
  size: string;
};

function SvgComponent(props: TSvgCheckmark) {
  const { size } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 83 83" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 41.33C0 18.448 18.47 0 41.33 0s41.33 18.448 41.33 41.33c0 22.882-18.448 41.33-41.33 41.33C18.448 82.66 0 64.212 0 41.33zm5.526 0c0 19.835 15.969 35.803 35.804 35.803S77.134 61.165 77.134 41.33c0-19.835-15.97-35.804-35.804-35.804-19.835 0-35.804 15.969-35.804 35.804z"
        fill="#fff"
      />
      <Path
        d="M61.165 29.206c-1.093-1.093-2.753-1.093-3.867 0l-21.2 21.201L25.36 39.67c-1.093-1.093-2.753-1.093-3.866 0-1.093 1.093-1.093 2.752 0 3.866l12.67 12.67c.546.546 1.092.82 1.933.82.82 0 1.386-.274 1.933-.82l23.134-23.134c1.092-1.092 1.092-2.752 0-3.866z"
        fill="#fff"
      />
    </Svg>
  );
}

export const SvgCheckmark = React.memo(SvgComponent);
