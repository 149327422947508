import {
  call,
  put,
  select,
} from 'redux-saga/effects';
import { TState } from '../../Store';
import { actions } from '../../Actions';
import { Log } from '../../../config/Log';
import { THostId } from '../../../../../core/src/models/db/host/HostTypes';
import { actionInitializeHostSession } from '../../actions/actionInitializeHostSession';
import {
  parseBoolOrUndefined,
  parseIntOrUndefined,
} from '../../../../../core/src/lib/HelperFunctions';
import { Navigation } from '../../../lib/navigation/Navigation';
import { ELocale } from '../../../../../core/src/locale/Locale';

export function* sagaValidateNavigationState() {
  yield call(validateHostSession);
  yield call(validateAppLocale);
}

export function* validateHostSession() {
  const routeParams = Navigation.getRouteParams();
  Log.v('validateHostSession', 'validateHostSession', `Route params`, routeParams);

  const storeHostId: THostId | undefined = yield select((store: TState) => store.host.hostId);
  const hostIdToSet = routeParams.storeHostId || storeHostId;

  if (hostIdToSet == null) {
    return;
  }

  yield put(actionInitializeHostSession({
    hostId: hostIdToSet,
    tableId: routeParams.storeTableId,
    tableNumber: parseIntOrUndefined(routeParams.storeTableNumber),
    skipGuestsCheck: parseBoolOrUndefined(routeParams.storeSkipGuestsCheck),
  }) as any);
}

export function* validateAppLocale() {
  const routeParams = Navigation.getRouteParams();
  const storeLocale: ELocale | undefined = yield select((store: TState) => store.app.locale);

  if (routeParams.storeLocale != null && routeParams.storeLocale != storeLocale) {
    yield put(actions.actionTypeAppSetLocale({ locale: routeParams.storeLocale }));
  }
}
