import { TState } from '../Store';
import { call, cancelled, delay, put, race, select } from 'redux-saga/effects';
import { Log } from '../../config/Log';
import { TNavigationParamsWithMethod } from '../../lib/navigation/Navigation';
import { RCCompContracts } from '../../lib/navigation/routes/RCCompContracts';
import { RouteContract, TOutNavParams } from '../../lib/routeContract/RouteContract';
import { CheckPropsResult, ECheckPropsResult } from '../../lib/routeContract/RouteContractCheckProps';
import { actions } from '../Actions';
import { RoutesCliGuest } from '../../../../core/src/lib/apis/routes/RoutableCliGuest';
import { defaultNavParams } from '../sagas/navigation/sagaDefaultNavigationParams';
import { startReactiveAction } from '../../../../lib-react/src/redux/startReactiveAction';

export const { trigger: actionNavigate } = startReactiveAction<TState, TNavigationParamsWithMethod<any>>({
  runnerName: 'actionNavigate',

  react: [],

  * reaction(params) {
    Log.v('actionNavigate', '_', `${params.routeName} starting procedure`);

    const state: TState = yield select((s: TState) => s);
    const contract: RouteContract<any> = RCCompContracts[params.routeName];

    const route = RoutesCliGuest[params.routeName];

    const defaultParams = yield call(defaultNavParams);
    const navParams: TOutNavParams<any> = {
      params: {
        ...defaultParams,
        ...route.params.map != null
          ? route.params.map(params.params.params || {})
          : params.params.params,
      },
      pathParams: {
        ...defaultParams,
        ...route.pathParams.map != null
          ? route.pathParams.map(params.params.pathParams || {})
          : params.params.pathParams,
      },
    };

    const shouldSkipToRoute = contract.shouldSkipToRoute(state, navParams);

    if (shouldSkipToRoute != null) {
      Log.v('actionNavigate', '_', `${params.routeName} shouldSkipToRoute ${shouldSkipToRoute.routeName}`);
      yield put(actionNavigate(shouldSkipToRoute));
      return;
    }

    // The selected route should be navigated to, wait for ready
    const { timeout } = yield race({
      timeout: delay(5000),
      ok: call(function* () {
        while (true) {
          Log.v('actionNavigate', '_', `${params.routeName} while(true) starting`);
          if (yield cancelled()) {
            Log.v('actionNavigate', '_', `${params.routeName} checkProps check, cancelled`);
            break;
          }

          const refreshedState: TState = yield select((s: TState) => s);
          const mappedState = contract.mapStateToProps(refreshedState, navParams);

          const checkProps = contract.checkProps(mappedState);
          if (CheckPropsResult.checkIs(ECheckPropsResult.WAIT, checkProps)) {
            Log.v('actionNavigate', '_', `${params.routeName} checkProps is ECheckPropsResult.Wait`);
            yield delay(50);
            continue;
          }

          if (CheckPropsResult.checkIs(ECheckPropsResult.REDIRECT, checkProps)) {
            Log.v('actionNavigate', '_', `${params.routeName} checkProps is ECheckPropsResult.Redirect`);
            yield put(actionNavigate(checkProps.to));
            return;
          }

          // checkProps is ECheckPropsResult.Ready or ECheckPropsResult.Render
          // In any case, we can navigate to the screen
          Log.v('actionNavigate', '_', `${params.routeName} checkProps is ECheckPropsResult.Ready or ECheckPropsResult.Render`);
          break;
        }
      }),
    });

    if (timeout) {
      Log.v('actionNavigate', '_', `${params.routeName} checkProps loop timed out, aborting navigation`);
      return;
    }

    // All good, navigate
    switch (params.method) {
      case 'push':
        return yield put(actions.actionTypeNavigationPush(params));
      case 'replace':
        return yield put(actions.actionTypeNavigationReplace(params));
      case 'reset':
        return yield put(actions.actionTypeNavigationReset(params));
      case 'navigate':
        return yield put(actions.actionTypeNavigationNavigate(params));
    }
  },
});
