import { DbPaths } from '../../../db/DbRefs';
import { DbRes } from '../../../db/DbResources';
import { MObjDef } from '../../../lib/model/MObjDef';
import { Order } from './Order';
import { TOrder } from './OrderTypes';
import { defOrder } from '../ModelDefaults';

const pathBuilderKeys = [
  'hostId',
  'tableId',
  'orderId',
] as const;

export type TOrderPathBuilderParams = {
  [key in ((typeof pathBuilderKeys)[number])]: string
};

class OrderBuilderBase extends MObjDef<typeof DbRes.Order> {
  readonly resourceKey = DbRes.Order;

  protected readonly pathBuilderKeys = [...pathBuilderKeys];

  protected readonly _default = defOrder;

  protected newChild(item: TOrder) {
    return new Order(item);
  }

  pathBuilder(params: TOrderPathBuilderParams): string {
    return DbPaths.order(params);
  }
}

export const OrderBuilder = new OrderBuilderBase();
