import {
  TMObj,
  TMObjDataCreator,
  TMObjMaybeDataCreator,
} from '../../../lib/model/ModelTypes';
import { TRKHostSubscription } from '../../../db/DbResources';

export type THostSubscriptionId = string;
export type TStripeCustomerId = string;

export const FIELD_STRIPE_CUSTOMER_ID = 'stripeCustomerId' as const;

export enum ESubscriptionFeatureFlags {
  guestOrders = 'guestOrders'
}

export enum ESubscriptionType {
  Starter = 'Starter',
  Premium = 'Premium',
  Gold = 'Gold',
}

export const subscriptionTypeToFeatureFlag: {[K in ESubscriptionType]: ESubscriptionFeatureFlags[]} = {
  Starter: [],
  Premium: [
    ESubscriptionFeatureFlags.guestOrders,
  ],
  Gold: [
    ESubscriptionFeatureFlags.guestOrders,
  ],
};

export type THostSubscription = TMObj<TRKHostSubscription> & {
  startedOn: number;
  endsOn: number;
  [FIELD_STRIPE_CUSTOMER_ID]: TStripeCustomerId;
  stripeClientSecret?: string;
  stripeSubscriptionId?: string;
  stripeSubscriptionType?: ESubscriptionType;
  stripeCoupon?: string;
};

export type THostSubscriptionUpdate = TMObjMaybeDataCreator<TRKHostSubscription>;
export type THostSubscriptionCreate = TMObjDataCreator<TRKHostSubscription>;
