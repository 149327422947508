import * as _ from 'lodash';
import { TRKOrderItem } from '../../../db/DbResources';
import {
  FIELD_ID,
  FIELD_NAME,
} from '../../../db/DbDefs';
import { Ingredient } from '../ingredient/Ingredient';
import { MObj } from '../../../lib/model/MObj';
import { OrderItemBuilder } from './OrderItemBuilder';
import { TIngredient } from '../ingredient/IngredientTypes';
import { TMenuItem } from '../menuItem/MenuItemTypes';
import {
  TOrderItem,
  TOrderItemCreate,
  TOrderItemUpdate,
} from './OrderItemTypes';
import {
  parseFloatOrDefault,
  partitionByComparison,
} from '../../../lib/HelperFunctions';
import { mObjToMObjDataCreator } from '../../../lib/model/MObjCreator';

export class OrderItem extends MObj<TRKOrderItem> {
  static asCreator(orderItem: TOrderItem): TOrderItemCreate {
    return mObjToMObjDataCreator<TRKOrderItem>(orderItem);
  }

  static getTotalPrice(orderItemData: Pick<TOrderItem, 'price' | 'ingredients'>) {
    return orderItemData.price + OrderItem.getExtrasPrice(orderItemData);
  }

  static getExtrasPrice(orderItemData: Pick<TOrderItem, 'ingredients'>) {
    return _.sumBy<TIngredient>(_.values(orderItemData.ingredients), ({ price }: TIngredient) => parseFloatOrDefault(`${price}`, 0));
  }

  static isOrderItemSameAsMenuItem(menuItem: TMenuItem, orderItem: TOrderItem): boolean {
    return orderItem.menuItemId === menuItem[FIELD_ID];
  }

  static groupByEquality(orderItems: TOrderItem[]): TOrderItem[][] {
    return partitionByComparison(orderItems, (oi1, oi2) => OrderItem.areOrderItemsTheSame(oi1, oi2));
  }

  static areOrderItemsTheSame(orderItem1: TOrderItem, orderItem2: TOrderItem): boolean {
    if (orderItem1.menuItemId.toLowerCase() != orderItem2.menuItemId.toLowerCase()) {
      return false;
    }

    if (!OrderItem.areOrderItemIngredientsTheSame(orderItem1, orderItem2)) {
      return false;
    }

    if (orderItem1.name.toLowerCase() != orderItem2.name.toLowerCase()) {
      return false;
    }

    if (orderItem1.stationId.toLowerCase() != orderItem2.stationId.toLowerCase()) {
      return false;
    }

    if (OrderItem.getTotalPrice(orderItem1) != OrderItem.getTotalPrice(orderItem2)) {
      return false;
    }

    return true;
  }

  static areOrderItemIngredientsTheSame(orderItem1: TOrderItem, orderItem2: TOrderItem): boolean {
    if (_.size(orderItem1.ingredients) != _.size(orderItem2.ingredients)) {
      return false;
    }

    const sortedIngredients1 = _.sortBy(orderItem1.ingredients, FIELD_NAME);
    const sortedIngredients2 = _.sortBy(orderItem2.ingredients, FIELD_NAME);
    for (let i = 0; i < sortedIngredients1.length; i++) {
      if (!Ingredient.areIngredientsTheSame(sortedIngredients1[i], sortedIngredients2[i])) {
        return false;
      }
    }

    return true;
  }

  constructor(orderItem: TOrderItem) {
    super(OrderItemBuilder, orderItem);
  }

  deleteOrderItem() {
    return this.ref().remove();
  }

  async update(newOrderItem: TOrderItemUpdate): Promise<TOrderItem> {
    return OrderItemBuilder.remoteSaveToPath(
      OrderItemBuilder.getPathBuilderParamsFromDataPath(this.item),
      {
        ...this.item,
        ...newOrderItem,
        price: (this.item.price && parseFloat(`${this.item.price}`) || parseFloat(`${newOrderItem.price}`)),
      },
    );
  }
}
