// @ts-nocheck
import * as React from 'react';
import { Colors } from '../config/Theme';
import Svg,
{ Path } from 'react-native-svg';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg width={15} height={15} viewBox="0 0 15 15" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.883 9.487a6 6 0 10-1.396 1.396L13.604 15 15 13.604l-4.117-4.117zM6 10a4 4 0 110-8 4 4 0 010 8z"
        fill={props.fill || Colors.white}
      />
    </Svg>
  );
}

export const SvgSearch = React.memo(SvgComponent);
