/* eslint-disable */
import { delay, race } from 'redux-saga/effects';

export function* sagaWaitForVal<T>(getter: () => Generator<any, T, any>, maxTimeMs?: number) {
  const { result } = yield race(function() {
    const rangeObj: any = {
      result: sagaGetVal(getter),
    };

    if (!!maxTimeMs) {
      rangeObj.timeout =  delay(maxTimeMs)
    }

    return rangeObj
  }());

  return result as T | undefined;
}

function* sagaGetVal<T>(getter: () => Iterator<T>) {
  let value: T | undefined = undefined;
  while (value==null) {
    value = yield getter();
    yield delay(0);
  }
  return value;
}
