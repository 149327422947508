import * as _ from 'lodash';
import {
  FIELD_CREATED_ON,
  FIELD_DEFAULT_LOCALE,
  FIELD_ID,
  FIELD_NAME,
  FIELD_SORTABLE_INDEX,
  TCreatedOn,
  TIdable,
  TLocalizableObj,
  TNameable,
  TSortable,
} from './DbDefs';
import {localize} from '../lib/formatters/LocaleLib';
import {ELocale} from '../locale/Locale';
import {Languages, TLanguage} from '../locale/Languages';

export function dbItemIdableMap(item: TIdable) {
  return item[FIELD_ID];
}

export function dbItemNameableMap(item: TNameable) {
  return item[FIELD_NAME];
}

export function dbItemIdableNameableKeyMap(item: TIdable & TNameable) {
  return `${item[FIELD_ID]}-${item[FIELD_NAME]}`;
}

export const dbItemLocalizeableNameableUncasedIncludeCompare = (item?: TNameable, incStr = '', language?: TLanguage) => {
  const name = localize(item as any, FIELD_NAME, language);
  return name
    ? name.toLowerCase().includes(incStr.toLowerCase())
    : false;
};

export const dbItemNameableUncasedEqCompare = (item?: TNameable, eqStr = '') => {
  const name = _.get(item, FIELD_NAME);
  return `${name}`.toLowerCase() === eqStr.toLowerCase();
};

export function dbItemNameableUncasedIncludeComparer<T extends TNameable>(eqStr = '') {
  return (item: T) => {
    return dbItemNameableUncasedEqCompare(item, eqStr);
  };
}

export function dbItemNameableSort(a: TNameable, b: TNameable) {
  const aa = _.get(a, FIELD_NAME, '');
  const bb = _.get(b, FIELD_NAME, '');
  return aa.localeCompare(bb);
}

export function dbItemNameableNumberSort(a: TNameable, b: TNameable) {
  const aa = parseFloat(_.get(a, FIELD_NAME, '0'));
  const bb = parseFloat(_.get(b, FIELD_NAME, '0'));
  return aa - bb;
}

export function dbItemSortableItemSort(a: TSortable, b: TSortable) {
  const aa = _.get(a, FIELD_SORTABLE_INDEX, Infinity);
  const bb = _.get(b, FIELD_SORTABLE_INDEX, Infinity);
  return aa - bb;
}

export function dbItemSortableNextIndex(items: TSortable[]): number {
  const sorted = items
    .sort(dbItemSortableItemSort);
  return (<number>_.get(sorted, `[${sorted.length - 1}][${FIELD_SORTABLE_INDEX}]`, -1)) + 1;
}

export function dbItemLocalizableSupportedLocalesGet(localizable: TLocalizableObj<any>) {
  return _.keys(localizable)
    .filter(((key) => !_.isEmpty(Languages[key])))
    .sort((a: string, b: string) => {
      if (a == localizable[FIELD_DEFAULT_LOCALE]) {
        return -1;
      }
      return 0;
    }) as TLanguage[];
}

export function dbItemLocalizableGetLocalizedField<Obj, Key extends keyof Obj>(
  localizable: TLocalizableObj<Partial<Obj>>,
  fieldName: keyof Obj,
  locale: ELocale | TLanguage,
): TLocalizableObj<Obj>[Key] {
  return _.get(localizable, `${locale}.${String(fieldName)}`);
}

export function dbItemLocalizableGetLocalizedFieldWithDefault<Obj, Key extends keyof Obj>(
  localizable: TLocalizableObj<Partial<Obj>>,
  fieldName: keyof Obj,
  locale: ELocale | TLanguage,
): TLocalizableObj<Obj>[Key] {
  const value = dbItemLocalizableGetLocalizedField(localizable, fieldName, locale);
  return !_.isEmpty(value)
    ? value
    : _.get(localizable, fieldName) as any;
}

export function dbItemCreatedOnSort(a: TCreatedOn, b: TCreatedOn) {
  const aa = _.get(a, FIELD_CREATED_ON, 0);
  const bb = _.get(b, FIELD_CREATED_ON, 0);
  return aa - bb;
}

export function dbItemCreatedOnMap(createdOn: TCreatedOn) {
  return createdOn[FIELD_CREATED_ON];
}

export function dbItemIdableKeysObj(items: TIdable[]) {
  return _.keyBy(items.map(dbItemIdableMap));
}
