import * as _ from 'lodash';
import { TRKOrder, TRKOrderItem } from '../../../db/DbResources';
import { EOrderStatus, TOrder } from './OrderTypes';
import { FIELD_ID, FIELD_SORTABLE_INDEX } from '../../../db/DbDefs';
import { MObj } from '../../../lib/model/MObj';
import { OrderBuilder } from './OrderBuilder';
import { OrderItem } from '../orderItem/OrderItem';
import { OrderItemBuilder } from '../orderItem/OrderItemBuilder';
import { OrderItemCreator } from '../orderItem/OrderItemCreator';
import { Refs } from '../../../db/DbRefs';
import { TMObjDataCreator } from '../../../lib/model/ModelTypes';
import { TMenuItem } from '../menuItem/MenuItemTypes';
import { TOrderItem } from '../orderItem/OrderItemTypes';
import { nowMs } from '../../../lib/HelperFunctions';

export class Order extends MObj<TRKOrder> {
  static getTotalPrice(orderItemData: Pick<TOrder, 'items'>) {
    return _.sumBy(_.values(orderItemData.items), OrderItem.getTotalPrice);
  }

  static getOrderItemCount(orderData: Pick<TOrder, 'items'>) {
    return _.size(orderData.items);
  }

  static orderHasItems(orderData: Pick<TOrder, 'items'>) {
    return Order.getOrderItemCount(orderData) > 0;
  }

  static orderContainsOrderItem(orderData: Pick<TOrder, 'items'>, orderItem: { [FIELD_ID] }) {
    return _.find(orderData.items, (item: TOrderItem) => {
      return item[FIELD_ID] === orderItem[FIELD_ID];
    });
  }

  static orderIsOpen(orderData: Pick<TOrder, 'status'>) {
    return orderData.status == EOrderStatus.SELECTING;
  }

  static getMenuItemInOrderCount(orderData: Pick<TOrder, 'items'>, menuItem: TMenuItem) {
    const items = _.values(orderData.items);
    return items.reduce((sum: number, compareOrderItem: TOrderItem) => {
      return OrderItem.isOrderItemSameAsMenuItem(menuItem, compareOrderItem)
        ? sum + 1
        : sum;
    }, 0);
  }

  constructor(order: TOrder) {
    super(OrderBuilder, order);
  }

  async addOrderItems(orderItems: TMObjDataCreator<TRKOrderItem>[]): Promise<TOrderItem[]> {
    const update = {};
    for (let i = 0; i < orderItems.length; i++) {
      const created = OrderItemCreator.buildNew(this.pbp(), orderItems[i]);
      update[created[FIELD_ID]] = created;
    }

    return Refs.orderItems(this.pbp()).ref().update(update);
  }

  async setStatus(newStatus: EOrderStatus): Promise<TOrder> {
    return this.updateFields({ status: newStatus });
  }

  async updateOrderItemsPrecedenceNumbersAndSortableIndex(orderItems: TOrderItem[]) {
    const pbp = this.pbp();
    const updateObj = orderItems.reduce((acc, orderItem) => {
      const orderItemPbp = {
        ...pbp,
        orderItemId: orderItem[FIELD_ID],
      };

      _.set(acc, this.subPath(Refs.orderItemPrecedenceNumber(orderItemPbp)), orderItem.precedenceNumber);
      _.set(acc, this.subPath(Refs.orderItemPrecedenceNumber(orderItemPbp)), orderItem[FIELD_SORTABLE_INDEX]);
      return acc;
    }, {});
    return this.ref().update(updateObj);
  }

  deleteOrderItem(orderItem: TOrderItem) {
    const orderIsOpenAndContainsItem = Order.orderIsOpen(this.item)
      && Order.orderContainsOrderItem(this.item, orderItem);

    if (!orderIsOpenAndContainsItem) {
      throw new Error(`IllegalStateException: Order, deleteOrderItem, orderItem not in order or order closed, id=${this.item[FIELD_ID]}`);
    }

    return OrderItemBuilder.fromItem(orderItem)
      .deleteOrderItem();
  }

  async completeOrderToBatch() {
    return this.updateFields({ ...Order.orderToBatchUpdate() });
  }

  static orderToBatchUpdate(): Partial<TOrder> {
    return {
      status: EOrderStatus.BATCHING,
      orderedOn: nowMs(),
    };
  }
}
