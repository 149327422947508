import * as React from 'react';
import { Button } from '../../lib-components/buttons/Button';
import { Colors } from '../../config/Theme';
import { Strings } from '../../../../core/src/locale/Strings';
import { Menu } from '../../../../core/src/models/db/menu/Menu';
import styled from 'styled-components/native';
import { useActionSheetShow } from '../ActionSheet';
import { Languages, TLanguage } from '../../../../core/src/locale/Languages';
import { useChangeCurrentLocale, useCurrentHost, useCurrentLanguage, useCurrentLocale } from "../../hooks/hooks";

type TLangButtonProps = {
  height: number;
};

export const LangButton = ({
  height,
}: TLangButtonProps) => {
  const locale = useCurrentLocale();
  const language = useCurrentLanguage();

  const host = useCurrentHost();
  const menu = host?.menu;

  const onLocaleChange = useChangeCurrentLocale();

  const onLangButtonPress = React.useMemo(() => {
    const secondaryMenu = menu && Menu.getSecondaryMenu(menu);
    const allLanguages = secondaryMenu && Menu.getAllMenuLanguages(secondaryMenu);

    return useActionSheetShow({
      title: Strings.hMenuLanguageDdl()(locale),
      initialValue: language,
      onValueSelect: onLocaleChange,
      options: (allLanguages ?? []).map((language) => ({
        label: `${Languages[language].language} ${Languages[language].emoji}`,
        value: language,
      })),
    });

  }, [
    menu,
    locale,
    language,
    onLocaleChange
  ]);

  return (
    <HeaderOverlayLangButton
      bold={false}
      block={false}
      disableFeedback
      onPress={onLangButtonPress}
      height={height}
      backgroundColor={Colors.transparent}
      color={Colors.white}
      title={`${Strings.gLang()(locale)}`.toUpperCase()}
      titleProps={{
        fontSize: 11,
        fontWeight: 'bold',
        letterSpacing: 1,
      }}
    />
  );
};

const HeaderOverlayLangButton = styled(Button)`
  font-size: ${({ theme }) => theme.scaler.verticalScale(11)}px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: ${({ theme }) => theme.scaler.verticalScale(15)}px;
  text-align: right;
`;
