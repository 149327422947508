import { PackageSecrets } from './PackageSecrets';
import { Process } from '../../../core/src/config/Process';
import { ELocale } from '../../../core/src/locale/Locale';

export const Config = {
  appName: 'alacarte/cli-guest-rn',
  defaultLocale: ELocale['en-US'],
  confirmationScreenTimeoutMs: 2 * 1000, // 2 seconds
  splashScreenMinTimeMs: 1000,

  networkSagaConfig: {
    // amount of time (in ms) that the component should wait for the ping response.
    pingTimeout: 7500,
    // remote server to ping to
    pingServerUrl: 'https://www.google.com/',
    // Defaults to 0, and that means it is not going to check connectivity regularly.
    pingInterval: 2000,
  },

  // Process and secrets
  env: Process.env.NODE_ENV,
  isCI: Process.env.CI,
  COMMIT_HASH: Process.env.COMMIT_HASH,
  firebase: {
    apiKey: PackageSecrets.val.FIREBASE_API_KEY,
    authDomain: PackageSecrets.val.FIREBASE_AUTH_DOMAIN,
    databaseURL: PackageSecrets.val.FIREBASE_DB_URL,
    projectId: PackageSecrets.val.FIREBASE_PROJECT_ID,
    storageBucket: PackageSecrets.val.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: PackageSecrets.val.FIREBASE_MESSAGING_SENDER_ID,
    appId: PackageSecrets.val.FIREBASE_APP_ID,
    measurementId: PackageSecrets.val.FIREBASE_MEASUREMENT_ID,
  },
};

