import * as React from 'react';
import { StyleProp, View } from 'react-native';
import styled from 'styled-components/native';
import { Strings } from '../../../../core/src/locale/Strings';
import { AlaTouchableWithoutFeedback } from '../../lib-components/Touchable';
import { useOnExitPress } from '../confirmationDialogs/UseOnExitPress';
import { AlaTextBold } from '../Text';
import { useCurrentLocale } from "../../hooks/hooks";

export enum EExitHostButtonTheme {
  backgroundTransparent,
  backgroundDark,
  backgroundLight,
}

export type TExitHostButtonProps = {
  style?: StyleProp<View>;
  themeStyle: EExitHostButtonTheme;
};

export const ExitHostButton = ({
  style,
  themeStyle,
}: TExitHostButtonProps) => {
  const locale = useCurrentLocale();
  const onExitPress = useOnExitPress();

  return (
    <AlaTouchableWithoutFeedback onPress={onExitPress}>
      <Root themeStyle={themeStyle} style={style}>
        <ExitText themeStyle={themeStyle}>
          {Strings.gExitHost()(locale)}
        </ExitText>
      </Root>
    </AlaTouchableWithoutFeedback>
  );
};

function themeToColor(theme: any, themeStyle: EExitHostButtonTheme) {
  if (themeStyle == EExitHostButtonTheme.backgroundDark) {
    return theme.t.col.white;
  }

  if (themeStyle == EExitHostButtonTheme.backgroundLight) {
    return theme.t.col.accentDark;
  }

  return theme.t.col.white;
}

function themeToBackgroundColor(theme: any, themeStyle: EExitHostButtonTheme) {
  if (themeStyle == EExitHostButtonTheme.backgroundDark) {
    return theme.t.col.accentDark;
  }

  if (themeStyle == EExitHostButtonTheme.backgroundLight) {
    return theme.t.col.white;
  }

  return theme.t.col.transparent;
}

const Root = styled(View)<{ themeStyle: EExitHostButtonTheme }>`
  align-items: center;
  background-color: ${({ theme, themeStyle }) => themeToBackgroundColor(theme, themeStyle)};
  display: flex;
  flex-direction: row;
  padding: 20px 14px;
`;

const ExitText = styled(AlaTextBold)<{ themeStyle: EExitHostButtonTheme }>`
  color: ${({ theme, themeStyle }) => themeToColor(theme, themeStyle)};
  font-size: 14px;
  line-height: 24px;
  margin-left: 8px;
  text-transform: uppercase;
`;
