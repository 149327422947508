import { DbRes } from '../../../db/DbResources';
import { IngredientBuilder } from './IngredientBuilder';
import { MObjCreatorById } from '../../../lib/model/MObjCreator';

class IngredientCreatorBase extends MObjCreatorById<typeof DbRes.Ingredient, 'ingredientId'> {
  constructor() {
    super(IngredientBuilder);
  }

  protected readonly idKey = 'ingredientId';
}

export const IngredientCreator = new IngredientCreatorBase();
