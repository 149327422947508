// @ts-nocheck
import * as React from 'react';
import Svg,
{ Path } from 'react-native-svg';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg width="16px" height="20px" viewBox="0 0 16 20" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.31 17.222V5.862a1.761 1.761 0 01.451-3.464h2.361V1.82A1.812 1.812 0 015.948 0h4.095a1.812 1.812 0 011.826 1.821v.577h2.36a1.762 1.762 0 01.452 3.463v11.36c0 .672-.246 1.303-.676 1.755a2.27 2.27 0 01-1.648.713H3.633a2.27 2.27 0 01-1.647-.713 2.544 2.544 0 01-.676-1.754zm2.323 1.544h8.724c.789 0 1.402-.677 1.402-1.544V5.902H2.232v11.32c0 .867.613 1.544 1.401 1.544zM5.044 1.822a.888.888 0 01.904-.899h4.095a.888.888 0 01.904.9v.576H5.044V1.82zM14.23 3.32H1.761a.83.83 0 100 1.66H14.23a.83.83 0 100-1.66z"
        fill="#fff"
      />
    </Svg>
  );
}

export const SvgDelete = React.memo(SvgComponent);
