import {ELocale} from '../locale/Locale';
import {TLanguage} from '../locale/Languages';

export const FIELD_ID = 'id' as const;
export const FIELD_NAME = 'name' as const;
export const FIELD_DEFAULT_LOCALE = 'defaultLocale' as const;
export const FIELD_CREATED_ON = '__createdOn' as const;
export const FIELD_UPDATED_ON = '__updatedOn' as const;
export const FIELD_PATH = '__path' as const;
export const FIELD_EDITABLE = '__editable' as const;
export const FIELD_SORTABLE_INDEX = '__sortableIndex' as const;
export const FIELD_RESOURCE_TYPE = 'resourceType' as const;

export const UUID_VERSION_GLOBAL_DEFAULT = 4;

export type TSortable = {
  [FIELD_SORTABLE_INDEX]: number;
};

export type TIdable = {
  id: string;
};

export type TNameable = {
  name: string;
};

export type TEditable = {
  [FIELD_EDITABLE]: boolean;
};

export type TCreatedOn = {
  [FIELD_CREATED_ON]: number;
};

export type TObjList<Key, T> = {
  [keys: string]: T;
};

export type TLocalizableLocales = keyof ELocale;
export type TLocalizableObj<Obj> = Obj & {
  [key in TLanguage]?: Partial<Omit<Obj, TLocalizableLocales>>
} & {
  defaultLocale: TLanguage;
};
