import * as _ from 'lodash';
import { FIELD_ID } from '../../db/DbDefs';
import { MenuBuilder } from '../db/menu/MenuBuilder';
import { Refs } from '../../db/DbRefs';
import { TBuilderParamsBase } from './lib/buildRawUpdate';
import { TMenu } from '../db/menu/MenuTypes';
import { TRKMenu } from '../../db/DbResources';

type TMenuSubMenusSortableIndexSort = {
  sorted: Pick<TMenu, typeof FIELD_ID>[];
};

export async function menuSubMenusSortableIndexSort({
  item,
  sorted,
}: TBuilderParamsBase<TRKMenu> & TMenuSubMenusSortableIndexSort) {
  const menu = MenuBuilder.fromItem(item);
  const pbp = menu.pbp();
  return sorted.reduce((acc, sortedItem, index) => {
    const subMenuPbp = {
      hostId: pbp.hostId,
      menuIds: (pbp.menuIds || []).concat(sortedItem[FIELD_ID]),
    };

    return _.set(acc, menu.subPath(Refs.hostMenuSubMenusSortableIndex(subMenuPbp)), index);
  }, {} as any);
}
