import * as React from 'react';
import { usePrevious } from './usePrevious';

export function useChangedAtLeastOnce(value) {
  const changedOnce = React.useRef<boolean>(false);
  const previous = usePrevious(value);
  React.useEffect(() => {
    if (previous != null && previous != value) {
      changedOnce.current = true;
    }
  }, [value, previous]);
  return changedOnce.current;
}
