import * as _ from 'lodash';
import Dot from 'dot-object';

export type TPathItemObj = {
  [key: string]: any;
};

export function dotItemToItem(dotItem: any) {
  return Dot.object(dotItem);
}

export function itemToDotItem(item: any): Record<string, any> {
  return Dot.dot(item);
}

export function pathItemToItem(pathItem: any) {
  return new Dot('/').object(pathItem);
}

export function itemToPathItem(dotItem: any): Record<string, any> {
  return new Dot('/').dot(dotItem);
}

class PathParserBase {
  parse(template: string, path?: string): any {
    const templates = template.split('/');
    const paths = (path || '').split('/');
    return templates.reduce((acc, key, idx) => {
      const val = _.get(paths, `[${idx}]`);
      if (!_.isEmpty(val)) {
        return _.set(acc, key, val);
      }
      return acc;
    }, {});
  }
}

export const PathParser = new PathParserBase();
