import { PackageSecrets } from './PackageSecrets';
import { Process } from './Process';

export const Config = {
  appName: '@alacarte/core',

  // Process and secrets
  urls: {
    get BASE_URL_ARCHIVE_SYNC() {
      return PackageSecrets.val.BASE_URL_ARCHIVE_SYNC;
    },
    get BASE_URL_CLI_GUEST_WEB() {
      return PackageSecrets.val.BASE_URL_CLI_GUEST_WEB;
    },
    get BASE_URL_CLI_GUEST_RN() {
      return PackageSecrets.val.BASE_URL_CLI_GUEST_RN;
    },
    get BASE_URL_CLI_HOST_WEB() {
      return PackageSecrets.val.BASE_URL_CLI_HOST_WEB;
    },
    get BASE_URL_LANDING_PAGE() {
      return PackageSecrets.val.BASE_URL_LANDING_PAGE;
    },
    get BASE_URL_PUBLIC_API() {
      return PackageSecrets.val.BASE_URL_PUBLIC_API;
    },
    get BASE_URL_QR_MANAGER() {
      return PackageSecrets.val.BASE_URL_QR_MANAGER;
    },
    get BASE_URL_STRIPE() {
      return PackageSecrets.val.BASE_URL_STRIPE;
    },
    get BASE_URL_ALACARTE_LINKING() {
      return PackageSecrets.val.BASE_URL_ALACARTE_LINKING;
    },
  } as const,

  get CGR_APPLE_ID() {
    return PackageSecrets.val.CGR_APPLE_ID;
  },
  get CGR_ANDROID_ID() {
    return PackageSecrets.val.CGR_ANDROID_ID;
  },
  get CHM_RELEASES_MAC_DOWNLOAD() {
    return PackageSecrets.val.CHM_RELEASES_MAC_DOWNLOAD;
  },
  get CHM_RELEASES_WIN_DOWNLOAD() {
    return PackageSecrets.val.CHM_RELEASES_WIN_DOWNLOAD;
  },
  get CHM_RELEASES_LINUX_DOWNLOAD() {
    return PackageSecrets.val.CHM_RELEASES_LINUX_DOWNLOAD;
  },
  env: Process.env.NODE_ENV,
  isCI: Process.env.CI,
};

