import * as React from 'react';
import { LockedOrdersCollapsible } from './LockedOrdersCollapsible';
import { OpenOrderList } from './OpenOrderList';
import { TGuestSession } from '../../../../core/src/models/db/guestSession/GuestSessionTypes';
import { TOrder } from '../../../../core/src/models/db/order/OrderTypes';
import { TOrderItem } from '../../../../core/src/models/db/orderItem/OrderItemTypes';
import { View } from 'react-native';
import { useToggle } from '../../../../lib-react/src/hooks/useToggle';
import styled from 'styled-components/native';
import { GuestSessionBuilder } from '../../../../core/src/models/db/guestSession/GuestSessionBuilder';

type TCheckoutListProps = {
  guestSessionData: TGuestSession;
  openOrder: TOrder;
  billRequestEnabled: boolean;
  onActiveOrderItemPress: (orderItem: TOrderItem) => void;
};

export const CheckoutList = ({
  guestSessionData,
  openOrder,
  billRequestEnabled,
  onActiveOrderItemPress,
}: TCheckoutListProps) => {
  const [previousOrdersCollapsed, togglePreviousOrdersCollapsed] = useToggle(!billRequestEnabled);
  const previousOrders = GuestSessionBuilder.fromItem(guestSessionData)
    .getLockedOrdersWithItems();

  return (
    <Root>
      <OpenOrderList
        openOrder={openOrder}
        onOrderItemPress={onActiveOrderItemPress}
        layoutInvalidationKey={`${previousOrdersCollapsed}`}
        shouldRenderData={!billRequestEnabled}
        renderStaticListHeader={(
          <>
            {previousOrders.length > 0 && (
              <LockedOrdersCollapsible
                ordersCollapsed={previousOrdersCollapsed}
                toggleOrdersCollapsed={togglePreviousOrdersCollapsed}
                showCollapseButton={!billRequestEnabled}
                orderDatas={previousOrders}
              />
            )}
          </>
        )}
      />
    </Root>
  );
};

const Root = styled(View)`
  flex: 1
`;
