import * as React from 'react';
import { Num } from '../../../../core/src/lib/formatters/Num';
import { OrderItem } from '../../../../core/src/models/db/orderItem/OrderItem';
import { OrderItemEditorSectionDeleteButton } from './OrderItemEditorSectionDeleteButton';
import { OrderItemEditorSectionPrecedence } from './OrderItemEditorSectionPrecedence';
import { OrderItemEditorSectionUpdateButton } from './OrderItemEditorSectionUpdateButton';
import { OrderItemEditorSectionItemCount } from './OrderItemEditorSectionItemCount';
import { OrderItemEditorSectionOptionsAndShareCount } from './OrderItemEditorSectionOptionsAndShareCount';
import styled from 'styled-components/native';
import { View } from 'react-native';
import { Underline } from '../../lib-components/Underline';
import { addIfTrueArr } from '../../../../core/src/lib/HelperFunctions';
import { OrderItemEditorContext } from './OrderItemEditorContext';
import { OrderItemEditorFrame } from './OrderItemEditorFrame';
import { TOrderItemCreate } from '../../../../core/src/models/db/orderItem/OrderItemTypes';
import { TMenuItemId } from '../../../../core/src/models/db/menuItem/MenuItemTypes';
import { useCurrentHost } from "../../hooks/hooks";

export type TOrderItemEditorViewProps = {
  buttonText: string;
  onModifyOptions: (orderItem: TOrderItemCreate, menuItemId: TMenuItemId) => void;
};

export const OrderItemEditorView = ({
  buttonText,
  onModifyOptions,
}: TOrderItemEditorViewProps) => {
  const {
    orderItem,
    itemCount,
    allowMultiple,
    openOrderDelete,
    orderItemUpdate,
  } = React.useContext(OrderItemEditorContext);
  const host = useCurrentHost();
  return (
    <OrderItemEditorFrame
      imageUrl={orderItem.itemImg}
      sections={[
        ...addIfTrueArr(
          !!orderItemUpdate, (
            <>
              <ChildItem>
                <Underline/>
                <OrderItemEditorSectionOptionsAndShareCount
                  onModifyOptions={onModifyOptions}
                />
                <Underline/>
              </ChildItem>
            </>
          ),
        ),
        ...addIfTrueArr(
          !!orderItemUpdate, (
            <>
              <ChildItem>
                <OrderItemEditorSectionPrecedence/>
              </ChildItem>
            </>
          ),
        ),
        ...addIfTrueArr(
          !!orderItemUpdate && allowMultiple, (
            <>
              <Underline/>
              <ChildItem>
                <OrderItemEditorSectionItemCount/>
              </ChildItem>
            </>
          ),
        ),
        ...addIfTrueArr(
          !!orderItemUpdate && !!openOrderDelete, (
            <>
              <Underline/>
              <ChildItem>
                <OrderItemEditorSectionDeleteButton/>
              </ChildItem>
            </>
          ),
        ),
      ]}
      bottomActionButton={orderItemUpdate ? (
        <OrderItemEditorSectionUpdateButton
          leftText={buttonText}
          rightText={Num.formatPrice2(OrderItem.getTotalPrice(orderItem) * itemCount, host?.currency)}
          onPress={orderItemUpdate}
        />
      ) : undefined}
    />
  );
};

const ChildItem = styled(View)`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
`;
