import { TQrBuilderResult } from '../qr-manager/QrManagerBuilder';
import { Requestable } from '../define/requestable/Requestable';
import { AlacarteUrls } from '../AlacarteUrls';
import { b64FromClearText, b64ToClearText } from '../HelperFunctions';

export const AppQrManagerRoutes = {
  status: {
    method: 'get' as const,
    route: '/status',
    pathParams: undefined,
    params: undefined,
    body: undefined,
    response: { version: null as any as string },
  },
  qrCreate: {
    method: 'get' as const,
    params: {} as any as {
      b64Content: string;
      isRedirectable: boolean;
      count?: number;
      isUnique: boolean;
    },
    route: `/qr`,
    pathParams: undefined,
    body: undefined,
    response: null as any as TQrBuilderResult[],
  } as const,
  redirect: {
    method: 'get' as const,
    route: `/redirect/:redirectId`,
    pathParams: { redirectId: null as any as string },
    body: undefined,
    params: {} as {
      readonly?: boolean;
    },
    response: null,
  },
  redirectPatch: {
    method: 'patch' as const,
    params: null as any,
    route: `/redirect/:redirectId`,
    pathParams: { redirectId: null as any as string },
    body: {} as any as {
      b64Content: string;
      enabled?: boolean;
    },
    response: null,
  },
  defer: {
    method: 'get' as const,
    params: {} as {
      b64Link: string;
    },
    route: `/defer`,
    pathParams: undefined,
    body: undefined,
    response: null,
  },
} as const;

export const AppQrManagerRoutes_STANDARD_API = {
  redirect: {
    name: 'redirect' as const,
    path: `/redirect/:redirectId`,
    secondaryPaths: {} as const,
    hashes: {} as const,
    pathParams: { in: { redirectId: null as any as string } },
    params: { in: { readonly: false as (boolean | undefined) } },
    method: 'get' as const,
    body: undefined,
    response: null,
  },
  defer: {
    name: 'defer' as const,
    path: `/defer`,
    secondaryPaths: {} as const,
    hashes: {} as const,
    pathParams: { in: undefined },
    params: {
      in: { b64Link: '' as string },
      out: { b64Link: '' as string },
      map(params) {
        return { b64Link: b64FromClearText(params.b64Link) };
      },
      invert(params) {
        return { b64Link: b64ToClearText(params.b64Link) };
      },
    },
    method: 'get' as const,
    body: undefined,
    response: null,
  },
} as const;

export const AppQrManagerApiAccess = Requestable.wrapApi({
  baseUrl: `${AlacarteUrls.BASE_URL_QR_MANAGER.https}`,
  routes: AppQrManagerRoutes,
});
