import * as React from 'react';
import { Strings } from '../../../../core/src/locale/Strings';
import {
  Text,
  View,
} from 'react-native';
import styled from 'styled-components/native';
import { OrderItemEditorContext } from './OrderItemEditorContext';
import * as _ from 'lodash';
import { Touchable } from '../../lib-components/Touchable';
import { useCurrentLocale } from "../../hooks/hooks";

export type TOrderItemEditorSectionDeleteButtonProps = {
  //
};

export const OrderItemEditorSectionDeleteButton = (props: TOrderItemEditorSectionDeleteButtonProps) => {
  const locale = useCurrentLocale();
  const { openOrderDelete } = React.useContext(OrderItemEditorContext);
  return (
    <Touchable
      onPress={openOrderDelete || _.noop}>
      <Root>
        <RemoveText>
          {Strings.gItemDetailRemoveItem()(locale)}
        </RemoveText>
      </Root>
    </Touchable>
  );
};

const Root = styled(View)`
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.scaler.verticalScale(12)}px;
`;

const RemoveText = styled(Text)`
  color: ${({ theme }) => theme.t.col.red};
  font-family: ${({ theme }) => theme.t.font.nunitoSans};
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  text-align: center;
`;
