import {
  call,
  delay,
  put,
  race,
  select,
} from 'redux-saga/effects';
import { TState } from '../Store';
import { Log } from '../../config/Log';
import { actionResetSessionAndNavigate } from '../actions/NavigationActions';

export function* sagaEnsureStoreRehydrated() {
  // Check if the sagas missed the REHYDRATE action, the reducers can't have missed it
  Log.v('sagaEnsureRehydrated', 'sagaEnsureRehydrated', 'Ensuring store.session.storeRehydrated');

  // Wait for the rehydrate, but timeout in case or else we'll block the UI
  const { rehydrate } = yield race({
    rehydrate: call(function* () {
      while (true) {
        const rehydrated: boolean = yield select((state: TState) => state.session.storeRehydrated);
        if (rehydrated) {
          return true;
        }
        yield delay(0);
      }
    }),
    timeout: delay(3000),
  });

  if (rehydrate !== true) {
    Log.v('sagaEnsureRehydrated', 'sagaEnsureRehydrated', 'REHYDRATE action timed out, resetting');
    yield put(actionResetSessionAndNavigate() as any); // todo make put type compatible
    return false;
  }

  Log.v('sagaEnsureRehydrated', 'sagaEnsureRehydrated', 'REHYDRATE action complete, continue');
  return true;
}
