import { Platform } from 'react-native';

export const isAndroid = Platform.OS === 'android';
export const isIos = Platform.OS === 'ios';
export const isWeb = Platform.OS === 'web';

type TSupportedPlatforms<T> = {
  android: T;
  ios: T;
  web: T;
  other: T;
};

export function selectPlatform<T>(platforms: TSupportedPlatforms<T>) {
  const {
    android,
    ios,
    web,
    other,
  } = platforms;

  if (isAndroid) {
    return android;
  }

  if (isIos) {
    return ios;
  }

  if (isWeb) {
    return web;
  }

  return other != null
    ? other
    : platforms.android;
}
