import * as React from 'react';
import { Strings } from '../../../../core/src/locale/Strings';
import { Navigation } from '../../lib/navigation/Navigation';
import { RoutesCliGuest } from '../../../../core/src/lib/apis/routes/RoutableCliGuest';
import { useCurrentLocale } from "../../hooks/hooks";

export type TUseOnOrderPress = {
  onOrderRef: React.MutableRefObject<() => Promise<void>>;
};

export function useOnOrderPress({
                                  onOrderRef,
                                }: TUseOnOrderPress) {
  const locale = useCurrentLocale();
  return React.useCallback(async () => {
    Navigation.routePush(RoutesCliGuest.ModalConfirmationDialog.name, {
      params: {
        title: Strings.gConfirmOrderTitle()(locale),
        description: Strings.gConfirmOrderDescription()(locale),
        positiveButtonText: Strings.gConfirmOrderPositiveButtonText()(locale),
        negativeButtonText: Strings.gConfirmOrderNegativeButtonText()(locale),
        positiveButtonOnClick: () => {
          Navigation.hideRouteIfCurrent(RoutesCliGuest.ModalConfirmationDialog.name);
        },
        negativeButtonOnClick: () => {
          onOrderRef.current();
          Navigation.hideRouteIfCurrent(RoutesCliGuest.ModalConfirmationDialog.name);
        },
      },
      pathParams: {},
    });
  }, [locale]);
}
