import { Config } from '../config/Config';
import * as _ from 'lodash';

class AlacarteUrl {
  private readonly key;

  constructor(key: string) {
    this.key = key;
  }

  get raw() {
    return Config.urls[this.key] || '';
  }

  get https() {
    return this.raw.startsWith('https://')
      ? this.raw
      : `https://${this.raw}`;
  }

  get domain() {
    const url = new URL(this.https);
    return url.host;
  }
}

type TEnhancedAlacarteUrls = {
  [k in keyof typeof Config.urls]: AlacarteUrl
};

export const AlacarteUrls: TEnhancedAlacarteUrls = _.mapValues(Config.urls, (getter, key) => {
  return new AlacarteUrl(key);
});
