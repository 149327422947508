import * as React from 'react';
import { Colors } from '../config/Theme';
import { Badge as RnBadge } from 'react-native-elements';

type TBadgeProps = {
  value: string;
  valueColor?: string;
  backgroundColor?: string;
};

export const Badge = ({
  value,
  valueColor = Colors.white,
  backgroundColor = Colors.accentDark,
}: TBadgeProps) => {
  return (
    <RnBadge
      value={value}
      textStyle={{
        color: valueColor,
        fontSize: 10,
      }}
      badgeStyle={{ backgroundColor }}/>
  );
};
