import * as React from 'react';
import { FIELD_NAME } from '../../../../core/src/db/DbDefs';
import { DEFAULT_CURRENCY } from '../../../../core/src/locale/Currencies';
import { GenericListItem1 } from '../../lib-components/GenericListItem1';
import { ListRenderItemInfo, Text, View } from 'react-native';
import { Num } from '../../../../core/src/lib/formatters/Num';
import { Order } from '../../../../core/src/models/db/order/Order';
import { TMenuItem } from '../../../../core/src/models/db/menuItem/MenuItemTypes';
import { TOrder } from '../../../../core/src/models/db/order/OrderTypes';
import { localize } from '../../../../core/src/lib/formatters/LocaleLib';
import { useCurrentHost, useCurrentLanguage } from "../../hooks/hooks";
import { SvgRecommendedRound } from "../../img/SvgRecommendedRound";
import { AlaTextBold } from "../Text";
import { Theme } from "../../config/Theme";

export type TUseRendererMenuItemItemProps = {
  orderData?: TOrder;
  onItemPress?: (item: TMenuItem) => void;
};

export function useRendererMenuItemItem({
                                          orderData,
                                          onItemPress,
                                        }: TUseRendererMenuItemItemProps) {
  const host = useCurrentHost();
  const language = useCurrentLanguage();

  return React.useCallback(({ item }: ListRenderItemInfo<TMenuItem>) => {
    const name = localize(item, FIELD_NAME, language);
    const description = localize(item, 'description', language);
    const menuItemInOrderCount = orderData
      ? Order.getMenuItemInOrderCount(orderData, item)
      : 0;
    return (
      <GenericListItem1
        descriptionIsHTML
        item={{
          item,
          headerTextLeft: name,
          headerTopLeftNode: (item.tags?.includes('#new') || item.tags?.includes('#recommended'))
            ? (
              <View style={{
                display: 'flex',
                flexDirection: 'row',
              }}>
                {item.tags?.includes('#new') ? <AlaTextBold style={{color: Theme.col.red, fontSize: 11, marginRight: 5}}>(NEW)</AlaTextBold> : null}
                {item.tags?.includes('#recommended') ? (
                  <SvgRecommendedRound/>
                ) : null}
              </View>
            ) : undefined,

          headerTextRight: Num.formatPrice2(item.price, host?.currency ?? DEFAULT_CURRENCY),
          description,
          badgeValue: menuItemInOrderCount > 0
            ? `${menuItemInOrderCount}`
            : undefined,
        }}
        onItemPress={onItemPress}
        onReadMorePress={onItemPress}/>
    );
  }, [language, onItemPress, orderData, host]);
}
