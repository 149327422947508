import {
  DB_FIELD_ARCHIVE,
  TDb,
} from './Db';
import {
  FIELD_NAME,
  FIELD_SORTABLE_INDEX,
} from './DbDefs';
import { FirstParameter } from '../lib/Types';
import { SafeRef } from '../lib/model/SafeRef';
import { THostId } from '../models/db/host/HostTypes';
import { THostMessageId } from '../models/db/hostMessage/HostMessageTypes';
import { THostPrintQueueItemId } from '../models/db/hostPrintQueueItem/HostPrintQueueItemTypes';
import { TIngredientId } from '../models/db/ingredient/IngredientTypes';
import { TMenuId } from '../models/db/menu/MenuTypes';
import { TMenuItemId } from '../models/db/menuItem/MenuItemTypes';
import { TOrderId } from '../models/db/order/OrderTypes';
import { TOrderItemId } from '../models/db/orderItem/OrderItemTypes';
import { TRoomId } from '../models/db/room/RoomTypes';
import { TStationId } from '../models/db/station/StationTypes';
import { TTableId } from '../models/db/table/TableTypes';

type THostIdP = {
  hostId: string;
};

type TMenuIdP = {
  menuId: string;
};

type TMenuIdsP = {
  menuIds: string[];
};

type TStationIdP = {
  stationId: string;
};

type TTableIdP = {
  tableId: string;
};

type TRoomIdP = {
  roomId: string;
};

type TOrderIdP = {
  orderId: string;
};

type TOrderItemIdP = {
  orderItemId: string;
};

type TMenuItemIdP = {
  menuItemId: string;
};

type TPrintQueueItemIdP = {
  hostPrintQueueItemId: string;
};

type TMessageIdP = {
  messageId: string;
};

type TIngredientIdP = {
  ingredientId: string;
};

type TArchivedItemIdP = {
  archivedMObjId: string;
};

/**
 * Paths
 */
class DbPathsBase {
  /**
   * Root
   */
  readonly _firebaseConnected = () => {
    return '.info/connected';
  };

  readonly root = () => {
    return `/`;
  };

  /**
   * Archive
   */
  readonly archive = () => {
    return `/${DB_FIELD_ARCHIVE}`;
  };

  readonly archivedItem = (pbp: TArchivedItemIdP) => {
    return `${this.archive()}/${pbp.archivedMObjId}`;
  };

  /**
   * HostSubscriptions
   */
  readonly hostSubscriptions = () => {
    return '/hostSubscriptions';
  };

  /**
   * HostPublicSubscriptions
   */
  readonly hostPublicSubscriptions = () => {
    return '/hostPublicSubscriptions';
  };

  /**
   * Hosts
   */
  readonly hosts = () => {
    return `/hosts`;
  };

  readonly host = (pbp: THostIdP) => {
    return `${this.hosts()}/${pbp.hostId}`;
  };

  readonly hostSubscription = (pbp: THostIdP) => {
    return `${this.hostSubscriptions()}/${pbp.hostId}`;
  };

  readonly hostPublicSubscription = (pbp: THostIdP) => {
    return `${this.hostPublicSubscriptions()}/${pbp.hostId}`;
  };

  readonly hostMenu = (pbp: THostIdP) => {
    return `${this.host(pbp)}/menu`;
  };

  readonly hostMenuSubMenus = (pbp: THostIdP) => {
    return `${this.hostMenu(pbp)}/subMenus`;
  };

  readonly hostStations = (pbp: THostIdP) => {
    return `${this.host(pbp)}/stations`;
  };

  readonly hostTables = (pbp: THostIdP) => {
    return `${this.host(pbp)}/tables`;
  };

  readonly hostRooms = (pbp: THostIdP) => {
    return `${this.host(pbp)}/rooms`;
  };

  readonly hostName = (pbp: THostIdP) => {
    return `${this.host(pbp)}/${FIELD_NAME}`;
  };

  readonly station = (pbp: THostIdP & TStationIdP) => {
    return `${this.hostStations(pbp)}/${pbp.stationId}`;
  };

  readonly table = (pbp: THostIdP & TTableIdP) => {
    return `${this.hostTables(pbp)}/${pbp.tableId}`;
  };

  readonly room = (pbp: THostIdP & TRoomIdP) => {
    return `${this.hostRooms(pbp)}/${pbp.roomId}`;
  };

  readonly menuItems = (pbp: THostIdP) => {
    return `${this.host(pbp)}/items`;
  };

  readonly menuItem = (pbp: THostIdP & TMenuItemIdP) => {
    return `${this.menuItems(pbp)}/${pbp.menuItemId}`;
  };

  readonly menuItemMenuIds = (pbp: THostIdP & TMenuItemIdP) => {
    return `${this.menuItem(pbp)}/menuIds`;
  };

  readonly menuItemMenusMenuId = (pbp: THostIdP & TMenuItemIdP & TMenuIdP) => {
    return `${this.menuItemMenuIds(pbp)}/${pbp.menuId}`;
  };

  readonly menuItemIngredients = (pbp: THostIdP & TMenuItemIdP) => {
    return `${this.menuItem(pbp)}/ingredients`;
  };

  readonly ingredient = (pbp: THostIdP & TIngredientIdP & TMenuItemIdP) => {
    return `${this.menuItemIngredients(pbp)}/${pbp.ingredientId}`;
  };

  readonly hostMenus = (pbp: THostIdP & Partial<TMenuIdsP>) => {
    const subPath = pbp.menuIds && pbp.menuIds.reduce((accSubPath, subMenuId) => {
      return `${accSubPath}/subMenus/${subMenuId}`;
    }, '');
    return `${this.hostMenu(pbp)}${subPath}`;
  };

  readonly hostMenuSubMenusSortableIndex = (pbp: THostIdP & Partial<TMenuIdsP>) => {
    return `${this.hostMenus(pbp)}/${FIELD_SORTABLE_INDEX}`;
  };

  /**
   * GuestSessions
   */
  readonly guestSessions = () => {
    return `/guestSessions`;
  };

  readonly hostGuestSessions = (pbp: THostIdP) => {
    return `${this.guestSessions()}/${pbp.hostId}`;
  };

  readonly guestSession = (pbp: THostIdP & TTableIdP) => {
    return `${this.hostGuestSessions(pbp)}/${pbp.tableId}`;
  };

  readonly orders = (pbp: THostIdP & TTableIdP) => {
    return `${this.guestSession(pbp)}/orders`;
  };

  readonly order = (pbp: THostIdP & TTableIdP & TOrderIdP) => {
    return `${this.orders(pbp)}/${pbp.orderId}`;
  };

  readonly orderItems = (pbp: THostIdP & TTableIdP & TOrderIdP) => {
    return `${this.order(pbp)}/items`;
  };

  readonly orderStatus = (pbp: THostIdP & TTableIdP & TOrderIdP) => {
    return `${this.order(pbp)}/status`;
  };

  readonly orderItem = (pbp: THostIdP & TTableIdP & TOrderIdP & TOrderItemIdP) => {
    return `${this.orderItems(pbp)}/${pbp.orderItemId}`;
  };

  readonly orderItemPaid = (pbp: THostIdP & TTableIdP & TOrderIdP & TOrderItemIdP) => {
    return `${this.orderItem(pbp)}/paid`;
  };

  readonly orderItemPrecedenceNumber = (pbp: THostIdP & TTableIdP & TOrderIdP & TOrderItemIdP) => {
    return `${this.orderItem(pbp)}/precedenceNumber`;
  };

  readonly orderItemSortableIndex = (pbp: THostIdP & TTableIdP & TOrderIdP & TOrderItemIdP) => {
    return `${this.orderItem(pbp)}/${FIELD_SORTABLE_INDEX}`;
  };

  /**
   * HostMessages
   */
  readonly messages = () => {
    return `/hostMessages`;
  };

  readonly hostMessagesHost = (pbp: THostIdP) => {
    return `${this.messages()}/${pbp.hostId}`;
  };

  readonly hostStationMessages = (pbp: THostIdP & TStationIdP) => {
    return `${this.hostMessagesHost(pbp)}/${pbp.stationId}`;
  };

  readonly hostMessage = (pbp: THostIdP & TStationIdP & TMessageIdP) => {
    return `${this.hostStationMessages(pbp)}/${pbp.messageId}`;
  };

  /**
   * HostPrintQueue
   */
  readonly hostPrintQueue = () => {
    return `/hostPrintQueue`;
  };

  readonly printQueue = (pbp: THostIdP) => {
    return `${this.hostPrintQueue()}/${pbp.hostId}`;
  };

  readonly printQueueHost = (pbp: THostIdP & TPrintQueueItemIdP) => {
    return `${this.printQueue(pbp)}/${pbp.hostPrintQueueItemId}`;
  };
}

export const DbPaths = new DbPathsBase();

/**
 * Refs
 */
type TPathBuilderTypes = {
  [key in keyof DbPathsBase]: any
};

class PathBuilderTypes implements TPathBuilderTypes {
  /**
   * Root
   */
  _firebaseConnected: boolean = null as any;

  root: TDb = null as any;

  archive: PathBuilderTypes['root'][typeof DB_FIELD_ARCHIVE] = null as any;

  // @ts-ignore
  archivedItem: PathBuilderTypes['root']['__archive'][any] = null as any;

  /**
   * HostSubscriptions
   */
  hostSubscriptions: PathBuilderTypes['root']['hostSubscriptions'] = null as any;

  hostSubscription: PathBuilderTypes['hostSubscriptions'][string] = null as any;

  /**
   * HostPublicSubscriptions
   */
  hostPublicSubscriptions: PathBuilderTypes['root']['hostPublicSubscriptions'] = null as any;

  hostPublicSubscription: PathBuilderTypes['hostPublicSubscriptions'][string] = null as any;

  /**
   * Hosts
   */
  hosts: PathBuilderTypes['root']['hosts'] = null as any;

  host: PathBuilderTypes['hosts'][THostId] = null as any;

  hostMenu: PathBuilderTypes['host']['menu'] = null as any;

  hostMenuSubMenus: PathBuilderTypes['host']['menu']['subMenus'] = null as any;

  hostStations: PathBuilderTypes['host']['stations'] = null as any;

  hostTables: PathBuilderTypes['host']['tables'] = null as any;

  hostRooms: PathBuilderTypes['host']['rooms'] = null as any;

  hostName: PathBuilderTypes['host'][typeof FIELD_NAME] = null as any;

  // @ts-ignore
  station: PathBuilderTypes['hostStations'][TStationId] = null as any;

  // @ts-ignore
  table: PathBuilderTypes['hostTables'][TTableId] = null as any;

  // @ts-ignore
  room: PathBuilderTypes['room'][TRoomId] = null as any;

  // @ts-ignore
  menuItems: PathBuilderTypes['host']['items'] = null as any;

  // @ts-ignore
  menuItem: PathBuilderTypes['menuItems'][TMenuItemId] = null as any;

  // @ts-ignore
  menuItemMenuIds: PathBuilderTypes['menuItems'][TMenuItemId]['menuIds'] = null as any;

  // @ts-ignore
  menuItemMenusMenuId: PathBuilderTypes['menuItems'][TMenuItemId]['menuIds'][TMenuId] = null as any;

  // @ts-ignore
  menuItemIngredients: PathBuilderTypes['menuItem']['ingredients'] = null as any;

  ingredient: PathBuilderTypes['menuItemIngredients'][TIngredientId] = null as any;

  hostMenus: PathBuilderTypes['hostMenu'] = null as any;

  hostMenuSubMenusSortableIndex: PathBuilderTypes['hostMenu'][typeof FIELD_SORTABLE_INDEX] = null as any;

  /**
   * GuestSessions
   */
  guestSessions: PathBuilderTypes['root']['guestSessions'] = null as any;

  hostGuestSessions: PathBuilderTypes['guestSessions'][THostId] = null as any;

  guestSession: PathBuilderTypes['hostGuestSessions'][TTableId] = null as any;

  orders: PathBuilderTypes['guestSession']['orders'] = null as any;

  // @ts-ignore
  order: PathBuilderTypes['orders'][TOrderId] = null as any;

  orderItems: PathBuilderTypes['order']['items'] = null as any;

  orderStatus: PathBuilderTypes['order']['status'] = null as any;

  orderItem: PathBuilderTypes['orderItems'][TOrderItemId] = null as any;

  orderItemPrecedenceNumber: PathBuilderTypes['orderItems'][TOrderItemId]['precedenceNumber'] = null as any;

  orderItemSortableIndex: PathBuilderTypes['orderItems'][TOrderItemId][typeof FIELD_SORTABLE_INDEX] = null as any;

  orderItemPaid: PathBuilderTypes['orderItems'][TOrderItemId]['paid'] = null as any;

  /**
   * HostMessages
   */
  messages: PathBuilderTypes['root']['hostMessages'] = null as any;

  hostMessagesHost: PathBuilderTypes['messages'][THostId] = null as any;

  hostStationMessages: PathBuilderTypes['hostMessagesHost'][TStationId] = null as any;

  hostMessage: PathBuilderTypes['hostStationMessages'][THostMessageId] = null as any;

  /**
   * HostPrintQueue
   */
  hostPrintQueue: PathBuilderTypes['root']['hostPrintQueue'] = null as any;

  printQueue: PathBuilderTypes['hostPrintQueue'][THostId] = null as any;

  printQueueHost: PathBuilderTypes['printQueue'][THostPrintQueueItemId] = null as any;
}

const dbPathTypes = new PathBuilderTypes();
type TPathTypes = typeof dbPathTypes;
type TDbPaths = typeof DbPaths;
type TRefs = {
  [key in keyof TDbPaths]: (param?: FirstParameter<TDbPaths[key]>) => SafeRef<TPathTypes[key], FirstParameter<TDbPaths[key]>>
};

export const Refs: TRefs = Object.keys(DbPaths).reduce((acc, key) => {
  acc[key] = function (pbp: any = {}) {
    return new SafeRef(pbp, DbPaths[key]);
  };
  return acc;
}, {}) as any;
