import {
  ActionType,
  createAction as tsCreateAction,
  getType as tsGetType,
} from 'typesafe-actions';
import {
  Dispatch,
  Action,
  bindActionCreators as reduxBindActionCreators,
} from 'redux';
import { useDispatch as reduxUseDispatch } from 'react-redux';

export type TThunkAction<R = void> = (dispatch: TDispatch, getState: any) => R;
export type TDispatch = <R>(action: R | TThunkAction<R>) => (R | Promise<R>);
export type TReduxDispatch = Dispatch<Action>;
export type TAction<R> = TThunkAction<R> | {
  type: string;
};

export function bindActionCreators<T extends any[], R>(
  actionCreator: (...args: T) => TAction<R>,
  dispatch: Dispatch,
): (...args: T) => R {
  const res = reduxBindActionCreators<any, any>(actionCreator, dispatch);
  return res as (...args: T) => R;
}

export function useDispatch() {
  const dispatch = reduxUseDispatch();

  return function <T extends any[], R>(action: R | TThunkAction<R>): R {
    return dispatch(action) as any as R;
  };
}

export type TDefineReducerI<S, A> = {
  initialState: S;
  actions: A;
  reducer: (state: S, action: ActionType<A>, getType: typeof tsGetType) => S;
};

export function defineReducer<S, A>({
  initialState,
  actions,
  reducer,
}: TDefineReducerI<S, A>) {
  return {
    ...actions,
    reducer(state: S = initialState, action: ActionType<A>): S {
      return reducer(state, action, tsGetType);
    },
  };
}

export const createAction = tsCreateAction;
