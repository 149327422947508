// @ts-nocheck
import * as React from 'react';
import Svg,
{ Path } from 'react-native-svg';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Svg width={8} height={6} viewBox="0 0 8 6" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0h8L4 6 0 0z"
        fill="#2B2C33"
      />
    </Svg>
  );
}

export const SvgArrowDown = React.memo(SvgComponent);
