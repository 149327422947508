import { MObjCreatorById } from '../../../lib/model/MObjCreator';
import { TRKTable } from '../../../db/DbResources';
import { TableBuilder } from './TableBuilder';

class TableCreatorBase extends MObjCreatorById<TRKTable, 'tableId'> {
  constructor() {
    super(TableBuilder);
  }

  protected readonly idKey = 'tableId';
}

export const TableCreator = new TableCreatorBase();
