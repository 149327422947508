import { ObjOfType } from '../../../../../core/src/lib/Types';
import { RouteContract } from '../../routeContract/RouteContract';
import { RouteDevFunctionsContract } from '../../../screens/RouteDevFunctionsContract';
import { RouteSetupScanHostQRContract } from '../../../screens/RouteSetupScanHostQRContract';
import { RouteSetupGuestSessionContract } from '../../../screens/RouteSetupGuestSessionContract';
import { RouteHostHomeContract } from '../../../screens/RouteHostHomeContract';
import { RouteHostMenuItemSearchContract } from '../../../screens/RouteHostMenuItemSearchContract';
import { RouteHostMenuContract } from '../../../screens/RouteHostMenuContract';
import { RouteHostMenuItemViewContract } from '../../../screens/RouteHostMenuItemViewContract';
import { RouteHostOrderItemEditContract } from '../../../screens/RouteHostOrderItemEditContract';
import { RouteHostBillRequestCompleteContract } from '../../../screens/RouteHostBillRequestCompleteContract';
import { RouteHostOrderCheckoutContract } from '../../../screens/RouteHostOrderCheckoutContract';
import { HostOrderItemModifyOptionsRouteContract } from '../../../screens/HostOrderItemModifyOptionsRouteContract';
import { RouteHostOrderRequestCompleteContract } from '../../../screens/RouteHostOrderRequestCompleteContract';
import { OfflineOverlayRouteContract } from '../../../screens/OfflineOverlayRouteContract';
import { LockOverlayRouteContract } from '../../../screens/LockOverlayRouteContract';
import { ModalConfirmationRouteContract } from '../../../screens/ConfirmationOverlayRouteContract';
import { TRoutesCliGuestNames } from '../../../../../core/src/lib/apis/routes/RoutableCliGuest';

export type TRCCompContracts = ObjOfType<TRoutesCliGuestNames, RouteContract<any>, {
  ScreenDevFunctions: RouteDevFunctionsContract;
  ScreenSetupScanHostQR: RouteSetupScanHostQRContract;
  ScreenSetupGuestSession: RouteSetupGuestSessionContract;
  ScreenHostHome: RouteHostHomeContract;
  ScreenHostMenuItemSearch: RouteHostMenuItemSearchContract;
  ScreenHostMenu: RouteHostMenuContract;
  ScreenHostMenuItemView: RouteHostMenuItemViewContract;
  ScreenHostOrderItemEdit: RouteHostOrderItemEditContract;
  ScreenHostOrderItemModifyOptions: HostOrderItemModifyOptionsRouteContract;
  ScreenHostOrderCheckout: RouteHostOrderCheckoutContract;
  ScreenHostOrderRequestComplete: RouteHostOrderRequestCompleteContract;
  ScreenHostBillRequestComplete: RouteHostBillRequestCompleteContract;
  ModalOfflineOverlay: OfflineOverlayRouteContract;
  ModalLockScreenOverlay: LockOverlayRouteContract;
  ModalConfirmationDialog: ModalConfirmationRouteContract;

  // todo fix types
  DeferredDeeplink: ModalConfirmationRouteContract;
}>;

export const RCCompContracts: TRCCompContracts = {
  ScreenDevFunctions: new RouteDevFunctionsContract(),
  ScreenSetupScanHostQR: new RouteSetupScanHostQRContract(),
  ScreenSetupGuestSession: new RouteSetupGuestSessionContract(),
  ScreenHostHome: new RouteHostHomeContract(),
  ScreenHostMenuItemSearch: new RouteHostMenuItemSearchContract(),
  ScreenHostMenu: new RouteHostMenuContract(),
  ScreenHostMenuItemView: new RouteHostMenuItemViewContract(),
  ScreenHostOrderItemEdit: new RouteHostOrderItemEditContract(),
  ScreenHostOrderItemModifyOptions: new HostOrderItemModifyOptionsRouteContract(),
  ScreenHostOrderCheckout: new RouteHostOrderCheckoutContract(),
  ScreenHostOrderRequestComplete: new RouteHostOrderRequestCompleteContract(),
  ScreenHostBillRequestComplete: new RouteHostBillRequestCompleteContract(),
  ModalOfflineOverlay: new OfflineOverlayRouteContract(),
  ModalLockScreenOverlay: new LockOverlayRouteContract(),
  ModalConfirmationDialog: new ModalConfirmationRouteContract(),

  // todo fix types
  DeferredDeeplink: new ModalConfirmationRouteContract(),
};

