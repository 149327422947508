/**
 * To get around cyclic imports don't import any models in this file
 */
import {
  FIELD_CREATED_ON,
  FIELD_DEFAULT_LOCALE,
  FIELD_EDITABLE,
  FIELD_ID,
  FIELD_NAME,
  FIELD_PATH,
  FIELD_RESOURCE_TYPE,
  FIELD_SORTABLE_INDEX,
  FIELD_UPDATED_ON,
} from '../../db/DbDefs';
import {
  ESubscriptionType,
  FIELD_STRIPE_CUSTOMER_ID,
} from './hostSubscription/HostSubscriptionTypes';
import { ELOCALE_GLOBAL_DEFAULT } from '../../locale/Locale';

const DEFAULT_ID = '-1';
const DEFAULT_NAME = '';
const DEFAULT_TIMESTAMP = 0;
const DEFAULT_UNKNOWN_STR_VALUE = '';
const DEFAULT_UNKNOWN_COORDINATES = '40.80159240798404, -73.33405325882296';

export const defArchivedMObj = Object.freeze({
  [FIELD_ID]: DEFAULT_ID,
  [FIELD_PATH]: DEFAULT_UNKNOWN_STR_VALUE,
  [FIELD_CREATED_ON]: DEFAULT_TIMESTAMP,
  [FIELD_UPDATED_ON]: DEFAULT_TIMESTAMP,
  [FIELD_RESOURCE_TYPE]: DEFAULT_UNKNOWN_STR_VALUE as any,
  ownerUid: DEFAULT_ID,
  archivedItemOriginalPath: DEFAULT_UNKNOWN_STR_VALUE,
  archivedItem: {} as any,
});

export const defGuestSession = Object.freeze({
  [FIELD_ID]: DEFAULT_ID,
  [FIELD_PATH]: DEFAULT_UNKNOWN_STR_VALUE,
  [FIELD_CREATED_ON]: DEFAULT_TIMESTAMP,
  [FIELD_UPDATED_ON]: DEFAULT_TIMESTAMP,
  tableId: DEFAULT_ID,
  numberOfGuests: 0,
  orders: {},
  status: 'CLOSED' as any,
});

export const defHostSubscription = Object.freeze({
  id: DEFAULT_ID,
  [FIELD_PATH]: DEFAULT_UNKNOWN_STR_VALUE,
  [FIELD_CREATED_ON]: 1589524243,
  [FIELD_UPDATED_ON]: 1589524243,
  endsOn: 1589524243,
  startedOn: 1589524243,
  [FIELD_STRIPE_CUSTOMER_ID]: '',
});

export const defHostPublicSubscription = Object.freeze({
  id: DEFAULT_ID,
  [FIELD_PATH]: DEFAULT_UNKNOWN_STR_VALUE,
  [FIELD_CREATED_ON]: 1589524243,
  [FIELD_UPDATED_ON]: 1589524243,
  endsOn: 1589524243,
  startedOn: 1589524243,
  subscriptionType: ESubscriptionType.Starter,
});

export const defMenu = Object.freeze({
  [FIELD_ID]: DEFAULT_ID,
  [FIELD_PATH]: DEFAULT_UNKNOWN_STR_VALUE,
  [FIELD_CREATED_ON]: DEFAULT_TIMESTAMP,
  [FIELD_UPDATED_ON]: DEFAULT_TIMESTAMP,
  [FIELD_NAME]: DEFAULT_NAME,
  [FIELD_SORTABLE_INDEX]: 0,
  [FIELD_DEFAULT_LOCALE]: ELOCALE_GLOBAL_DEFAULT,
  coverCharge: 0,
  isHidden: true,
  description: '',
  subMenus: {},
});

export const defHost = Object.freeze({
  [FIELD_ID]: DEFAULT_ID,
  [FIELD_PATH]: DEFAULT_UNKNOWN_STR_VALUE,
  [FIELD_CREATED_ON]: DEFAULT_TIMESTAMP,
  [FIELD_UPDATED_ON]: DEFAULT_TIMESTAMP,
  email: '',
  menu: defMenu,
  items: {},
  tables: {},
  stations: {},

  [FIELD_NAME]: DEFAULT_NAME,
  description: '',
  coverImg: undefined,
  logoImg: undefined,
  denomination: DEFAULT_UNKNOWN_STR_VALUE,
  address: DEFAULT_UNKNOWN_STR_VALUE,
  phone: DEFAULT_UNKNOWN_STR_VALUE,
  vatId: DEFAULT_UNKNOWN_STR_VALUE,
  placeId: DEFAULT_UNKNOWN_STR_VALUE,
  coordinates: DEFAULT_UNKNOWN_COORDINATES,
});

export const defHostMessage = Object.freeze({
  [FIELD_ID]: DEFAULT_ID,
  [FIELD_PATH]: DEFAULT_UNKNOWN_STR_VALUE,
  [FIELD_CREATED_ON]: DEFAULT_TIMESTAMP,
  [FIELD_UPDATED_ON]: DEFAULT_TIMESTAMP,
  text: '',
  sender: 'SYSTEM' as any,
  delayMs: 0,
});

export const defStation = Object.freeze({
  [FIELD_ID]: DEFAULT_ID,
  [FIELD_PATH]: DEFAULT_UNKNOWN_STR_VALUE,
  [FIELD_CREATED_ON]: DEFAULT_TIMESTAMP,
  [FIELD_UPDATED_ON]: DEFAULT_TIMESTAMP,
  [FIELD_EDITABLE]: false,
  [FIELD_NAME]: DEFAULT_NAME,
  description: '',
  type: 'SECONDARY' as any,
  adapter: {
    name: 'Console',
    params: {},
  } as any,
});

export const defHostPrintQueueItem = Object.freeze({
  [FIELD_ID]: DEFAULT_ID,
  [FIELD_PATH]: DEFAULT_UNKNOWN_STR_VALUE,
  [FIELD_CREATED_ON]: DEFAULT_TIMESTAMP,
  [FIELD_UPDATED_ON]: DEFAULT_TIMESTAMP,
  ops: JSON.stringify([]),
  station: defStation,
});

export const defIngredient = Object.freeze({
  [FIELD_ID]: DEFAULT_ID,
  [FIELD_PATH]: DEFAULT_UNKNOWN_STR_VALUE,
  [FIELD_CREATED_ON]: DEFAULT_TIMESTAMP,
  [FIELD_UPDATED_ON]: DEFAULT_TIMESTAMP,
  [FIELD_NAME]: DEFAULT_NAME,
  [FIELD_DEFAULT_LOCALE]: ELOCALE_GLOBAL_DEFAULT,
  type: 'ADDABLE' as any,
  price: 0,
});

export const defMenuItem = Object.freeze({
  [FIELD_ID]: DEFAULT_ID,
  [FIELD_PATH]: DEFAULT_UNKNOWN_STR_VALUE,
  [FIELD_CREATED_ON]: DEFAULT_TIMESTAMP,
  [FIELD_UPDATED_ON]: DEFAULT_TIMESTAMP,
  [FIELD_DEFAULT_LOCALE]: ELOCALE_GLOBAL_DEFAULT,
  [FIELD_NAME]: DEFAULT_NAME,
  description: '',
  allergens: '',
  tags: '',
  price: 0,
  stationId: DEFAULT_ID,
  itemImg: '',
  status: 'INACTIVE' as any,
  shareable: true,
  menuIds: {},
});

export const defOrder = Object.freeze({
  [FIELD_ID]: DEFAULT_ID,
  [FIELD_PATH]: DEFAULT_UNKNOWN_STR_VALUE,
  [FIELD_CREATED_ON]: DEFAULT_TIMESTAMP,
  [FIELD_UPDATED_ON]: DEFAULT_TIMESTAMP,
  status: 'CLOSED' as any,
  items: {},
  archivedItems: {},
});

export const defOrderItem = Object.freeze({
  [FIELD_ID]: DEFAULT_ID,
  [FIELD_PATH]: DEFAULT_UNKNOWN_STR_VALUE,
  [FIELD_CREATED_ON]: DEFAULT_TIMESTAMP,
  [FIELD_UPDATED_ON]: DEFAULT_TIMESTAMP,
  [FIELD_SORTABLE_INDEX]: 0,
  [FIELD_NAME]: DEFAULT_NAME,
  [FIELD_DEFAULT_LOCALE]: ELOCALE_GLOBAL_DEFAULT,
  menuItemId: DEFAULT_ID,
  description: '',
  allergens: '',
  tags: '',
  price: 0,
  stationId: DEFAULT_ID,
  itemImg: '',
  precedenceNumber: 0,
  discount: 0,
  paid: false,
  shareCount: 0,
  ingredients: {},
});

export const defTable = Object.freeze({
  [FIELD_ID]: DEFAULT_ID,
  [FIELD_PATH]: DEFAULT_UNKNOWN_STR_VALUE,
  [FIELD_CREATED_ON]: DEFAULT_TIMESTAMP,
  [FIELD_UPDATED_ON]: DEFAULT_TIMESTAMP,
  [FIELD_NAME]: DEFAULT_NAME,
  maxGuests: 0,
  roomId: DEFAULT_ID,
});

export const defRoom = Object.freeze({
  [FIELD_ID]: DEFAULT_ID,
  [FIELD_PATH]: DEFAULT_UNKNOWN_STR_VALUE,
  [FIELD_CREATED_ON]: DEFAULT_TIMESTAMP,
  [FIELD_UPDATED_ON]: DEFAULT_TIMESTAMP,
  [FIELD_NAME]: '',
  [FIELD_SORTABLE_INDEX]: 0,
});
