import * as React from 'react';
import { Colors } from '../config/Theme';
import {
  TextInput as RnTextInput,
  TextInputProps as RnTextInputProps,
} from 'react-native';
import styled from 'styled-components/native';

type TextInputProps = RnTextInputProps & {
  //
};

export const TextInput = (props: TextInputProps) => {
  return (
    <StyledInput
      {...props}
    />
  );
};

const StyledInput = styled(RnTextInput)`
  background-color: ${({ theme }) => theme.t.col.white};
  border: 1px solid ${Colors.accentLight};
  font-family: ${({ theme }) => theme.t.font.nunitoSans};
  letter-spacing: 0.5;
  padding-horizontal: 4px;
  padding-vertical: 16px;
`;
