import {
  defineReducer,
  TThunkAction,
} from '../../../../lib-react/src/redux/redux';
import { actions } from '../Actions';

export type TStateSession = {
  storeRehydrated: boolean;
  isConnected: boolean;
  error?: Error;
  appLoadingComplete: boolean;
};

const initialState: TStateSession = {
  storeRehydrated: false,
  isConnected: true,
  error: undefined,
  appLoadingComplete: false,
};

export const {
  actionTypeAppSetError,
  reducer: reducerSession,
} = defineReducer<TStateSession, typeof actions>({
  actions,
  initialState,
  reducer: (state, action, getType) => {
    switch (action.type) {
      case getType(actions.actionTypeOnStoreRehydrated):
        return {
          ...state,
          storeRehydrated: true,
        };
      case getType(actions.pureActionTypeResetSession):
        return {
          ...state,
          appLoadingComplete: state.appLoadingComplete,
          error: initialState.error,
        };
      case getType(actions.actionTypeSessionSetConnectionState):
        return {
          ...state,
          isConnected: action.payload.isConnected,
        };
      case getType(actions.actionTypeOnSagasLoaded):
        return {
          ...state,
          appLoadingComplete: true,
        };
    }
    return state;
  },
});

export function actionAppSetError(error: Error, errorInfo: any): TThunkAction {
  return async (dispatch) => {
    dispatch(actionTypeAppSetError({
      error,
      errorInfo,
    }));
  };
}

