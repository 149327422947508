import * as _ from 'lodash';
import { all, call, fork } from 'redux-saga/effects';
import { PayloadAction } from 'typesafe-actions/dist/type-helpers';
import { sagaMonitorSelector } from '../sagas/sagaMonitorSelector';
import { Log } from '../config/Instance';
import { TDispatch, TThunkAction } from './redux';
import { uuid } from '../../../core/src/lib/UUID';
import { SagaMiddleware } from 'redux-saga';
import { Task } from '@redux-saga/types';

type TReactiveAction<OutParams = any, InParams = OutParams> = {
  action: PayloadAction<any, InParams>;
  mapOutput: (actionParams: InParams) => Generator<any, OutParams, any>;
};

type TReactiveSelector<Store = any, OutParams = any, InParams = OutParams> = {
  select: (store: Store) => InParams;
  mapOutput: (actionParams: InParams) => Generator<any, OutParams, any>;
};

/**
 * buildReactiveAction provides an easy way to couple together
 * redux-thunk, redux-saga, and redux-persist
 */

type TBuildReactiveActionParams<Store, TriggerParams> = {
  /**
   * Unique name to identify this runner, useful for debugging
   */
  runnerName: string;

  /**
   * An array of actions or selectors that will trigger the reaction
   * - If an action, then the reaction is triggered when that action is dispatched
   * - If a selector, then the reaction is triggered when the selector value deeply changes
   */
  react: Array<TReactiveAction<any, TriggerParams> | TReactiveSelector<Store, TriggerParams, any>>;

  /**
   * The saga that gets triggered
   */
  reaction: (params: TriggerParams) => Generator<any, any, any>;
};

type TBuildReactiveActionResult<TriggerParams> = {
  /**
   * Dispatching this action directly triggers the reaction saga
   */
  trigger: (params: TriggerParams) => TThunkAction<Promise<any>>;
};

type TReactiveActionDispatchParams<T> = {
  callerId: string;
  data: T;
};

let sagaMiddleware: SagaMiddleware = null as any;

const binders: Record<string, Task | (() => Task)> = {};

export function startReactiveAction<Store, TriggerParams>(params: TBuildReactiveActionParams<Store, TriggerParams>): TBuildReactiveActionResult<TriggerParams> {
  const runnerId = `reactive-action/${params.runnerName}`;
  Log.v('buildReactiveAction', 'buildReactiveAction', `${runnerId} Starting`);

  function* enhancedReaction({
    callerId,
    data,
  }: TReactiveActionDispatchParams<TriggerParams>) {
    const dispatchId = uuid();
    try {
      Log.v('buildReactiveAction', 'buildReactiveAction', `${runnerId}/${dispatchId} ${callerId} Reacting begin`);
      const result = yield call(params.reaction, data);
      Log.v('buildReactiveAction', 'buildReactiveAction', `${runnerId}/${dispatchId} ${callerId} Reacting done`);
      return result;
    } catch (e) {
      Log.v('buildReactiveAction', 'buildReactiveAction', `${runnerId}/${dispatchId} ${callerId} Reacting, error: ${e && e.message}`);
    }
  }

  // Ensure no previous task
  _.invoke(binders[runnerId], 'cancel');

  binders[runnerId] = function start() {
    const task = sagaMiddleware.run(function* () {
      yield all([
        // Bind to all specified actions
        // todo not implemented as not needed for now
        // ...params.react
        //   .filter((react): react is TReactiveAction => (react as TReactiveAction).action != null)
        //   .map((react) => takeEvery(react.action.type, function* (triggerParams: PayloadAction<any, any>) {
        //     yield call(enhancedReaction, yield react.mapOutput(triggerParams.payload));
        //   })),

        // Bind to all specified selectors
        ...params.react
          .filter((react): react is TReactiveSelector => (react as TReactiveSelector).select != null)
          .map((react) => {
            return fork(function* () {
              yield sagaMonitorSelector(['*'], react.select, function* (inParams) {
                yield call(enhancedReaction, {
                  callerId: `bindedSelector`,
                  data: yield react.mapOutput(inParams),
                });
              });
            });
          }),
      ]);
    });
    binders[runnerId] = task;
    return task;
  };

  return {
    trigger(data: TriggerParams) {
      return async function (dispatch: TDispatch, getState: any) {
        const task = sagaMiddleware.run(function* () {
          return yield call(enhancedReaction, {
            callerId: `trigger`,
            data,
          });
        });

        return task.toPromise();
      };
    },
  };
}

export namespace startReactiveAction {
  export function start(sm: SagaMiddleware): Task[] {
    sagaMiddleware = sm;
    return Object.values(binders)
      .filter((funcOrTask): funcOrTask is (() => Task) => _.isFunction(funcOrTask))
      .map((func) => {
        return func();
      });
  }
}
