import { GuestSession } from '../../../../core/src/models/db/guestSession/GuestSession';
import { Log } from '../../config/Log';
import { TGuestSession } from '../../../../core/src/models/db/guestSession/GuestSessionTypes';
import { GuestSessionBuilder } from '../../../../core/src/models/db/guestSession/GuestSessionBuilder';

export async function ensureOpenOrderExists(guestSession: TGuestSession) {
  const {
    hostId,
    tableId,
  } = GuestSessionBuilder.getPathBuilderParamsFromDataPath(guestSession);

  Log.v(
    'ensureOpenOrderExists',
    'ensureOpenOrderExists',
    `hostId=${hostId} tableId=${tableId}, Running`,
  );

  const openOrder = GuestSession.getOpenOrder(guestSession);
  if (!openOrder) {
    Log.v(
      'ensureOpenOrderExists',
      'ensureOpenOrderExists',
      `Open Order doesn't exist for hostId=${hostId} tableId=${tableId}`,
    );
    try {
      await GuestSession.getOrSetGuestOpenOrder({
        hostId,
        tableId,
      });
    } catch (e) {
      Log.e(
        'ensureOpenOrderExists',
        'ensureOpenOrderExists',
        `hostId=${hostId} tableId=${tableId} Error, ${e?.message}`,
      );
    }
  }

  Log.v(
    'ensureOpenOrderExists',
    'ensureOpenOrderExists',
    `hostId=${hostId} tableId=${tableId} Done`,
  );
}
