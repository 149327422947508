import { FIELD_ID } from '../../../../core/src/db/DbDefs';
import { Log } from '../../config/Log';
import {
  TableBuilder,
  TTablePathBuilderParams,
} from '../../../../core/src/models/db/table/TableBuilder';
import { Table } from '../../../../core/src/models/db/table/Table';
import { ensureOpenOrderExists } from './ensureOpenOrderExists';

export async function ensureGuestSessionExists(callerId: string, tablePbp: TTablePathBuilderParams) {
  const { tableId, hostId } = tablePbp;
  Log.v(
    'ensureGuestSessionExists',
    'ensureGuestSessionExists',
    `${callerId} Running for hostId=${hostId} tableId=${tableId}`,
  );

  const table = await TableBuilder.remoteDataFromPath(tablePbp);
  if (!table) {
    Log.wtf(
      'ensureGuestSessionExists',
      'ensureGuestSessionExists',
      `${callerId} Table not found, tableId=${tableId}, hostId=${hostId}`,
    );
    return;
  }

  const guestSession = await Table.getOrSetOpenGuestSession(tablePbp);
  await ensureOpenOrderExists(guestSession);
  Log.v(
    'ensureGuestSessionExists',
    'ensureGuestSessionExists',
    `${callerId} Done for hostId=${hostId} tableId=${tableId}, guestSessionId=${guestSession[FIELD_ID]}`,
  );
  return guestSession;
}
