import * as React from 'react';
import { Colors } from '../config/Theme';
import { View } from 'react-native';
import styled from 'styled-components/native';

type TUnderlineProps = {
  style?: string;
};

export const Underline = ({ style }: TUnderlineProps) => (
  <StyledView style={style}/>
);

const StyledView = styled(View)`
  background: ${Colors.lightGray5};
  height: 1px;
  width: 100%;
`;

type TMarginedUnderlineProps = {
  style?: string;
};

export const MarginedUnderline = ({ style }: TMarginedUnderlineProps) => (
  <MarginedUnderlineContainer>
    <Underline style={style}/>
  </MarginedUnderlineContainer>
);

const MarginedUnderlineContainer = styled(View)`
  padding-horizontal: 20px;
  width: 100%;
`;

export const VerticalUnderline = ({ height }: { height?: number }) => (
  <StyledVerticalView height={height}/>
);

const StyledVerticalView = styled(View)<{ height?: number }>`
  background: ${Colors.accentDark};
  height: ${({ height }) => (height ? `${height}px` : '100%')};
  opacity: 0.2;
  width: 1px;
`;

