import * as _ from 'lodash';
import { Base64 } from 'js-base64';
import { Log } from '../config/Instance';

export function b64FromClearText(clearText: string) {
  return Base64.btoa(clearText);
}

export function b64ToClearText(b64: string) {
  return Base64.atob(b64);
}

export function pushAll<T>(dest: T[], src: T[]) {
  src.forEach((val) => dest.push(val));
  return dest;
}

export function tryCatch<R>(runTry: () => R, fail: R): R {
  try {
    return runTry();
  } catch (e) {
    return fail;
  }
}

export function orderedClamp(val: number, min: number, max: number) {
  const realMin = min < max ? min : max;
  const realMax = max > min ? max : min;
  return _.clamp(val, realMin, realMax);
}

export function filterNull<T>(items: (T | null | undefined)[]): T[] {
  // @ts-ignore
  return items.filter((item) => item != null);
}

export async function reducePromises(arr: (() => Promise<any>)[]): Promise<any> {
  await arr.reduce(async (previousPromise, promiseBuilder) => {
    await previousPromise;
    await promiseBuilder();
  }, Promise.resolve());
}

export function ifTrueStub<T extends (...any: any[]) => any>(val: boolean, func: T): T {
  return val
    ? ((...any: any[]) => undefined) as T
    : func;
}

export function safeDelay(timeMs: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, timeMs));
}

export function resolveWithTimeout<T>(waitMs: number, result?: T): Promise<T> {
  return new Promise((resolve) => {
    setTimeout(() => resolve(result as any), waitMs);
  });
}

export function randomInt(min, max) {
  return Math.floor(Math.random() * max + 1);
}

export function mapRange(num: number, inMin: number, inMax: number, outMin: number, outMax: number): number {
  return (num - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
}

export function promisify(result): Promise<void> {
  return new Promise((resolve) => resolve(result));
}

export function nowMs() {
  return new Date().getTime();
}

export function randomString(length: number) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charactersLength = characters.length;
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function safe<T>(
  run: () => T,
  errFn: ((err: Error) => T | undefined) | undefined = undefined,
): T | undefined {
  try {
    return run();
  } catch (e) {
    Log.e('HelperFunctions', 'safe', e.message);
    return errFn && errFn(e);
  }
}

export async function safeWait<T>(
  run: () => Promise<T>,
  errFn: ((err: Error) => T | undefined) | undefined = undefined,
): Promise<T | undefined> {
  try {
    return await run();
  } catch (e) {
    Log.e('HelperFunctions', 'safeWait', e.message);
    return errFn && errFn(e);
  }
}

const throwError = (e: Error) => {
  throw e;
};

export function parseIntOrUndefined(str: string | undefined): number | undefined {
  return parseIntOrDefault(str, undefined);
}

export function parseIntOrDefault<T>(str: string | undefined, def: T): number | T {
  const int = parseInt(`${str}`, 10);
  if (Number.isNaN(int)) {
    return def;
  }
  return int;
}

export function parseIntOrThrow<T>(str: string | undefined): number {
  const int = parseInt(`${str}`, 10);
  if (Number.isNaN(int)) {
    throw new Error(['HelperFunctions', 'parseIntOrThrow', `Invalid Int ${str}`].join(', '));
  }
  return int;
}

export function parseBoolOrThrow<T>(str: string | undefined): boolean {
  const boolOrUndefined = parseBoolOrUndefined(`${str}`);
  if (boolOrUndefined == null) {
    throw new Error(['HelperFunctions', 'parseIntOrThrow', `Invalid bool ${str}`].join(', '));
  }
  return boolOrUndefined;
}

export function parseFloatOrDefault<T>(str: string | undefined, def: T): number | T {
  const float = parseFloat(`${str}`);
  if (Number.isNaN(float)) {
    return def;
  }
  return float;
}

export function parseBoolOrUndefined(str: string | undefined): boolean | undefined {
  return parseBoolOrDefault(str, undefined);
}

export function parseBoolOrDefault<T>(_str: string | undefined, def: T): boolean | T {
  const str = `${_str}`.toLowerCase();
  return str !== 'true' && str !== 'false'
    ? def
    : str === 'true';
}

export function hexToRGB(hex, alpha) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
}

export function parseJsonOrDefault<T>(str: string, def: T | undefined = undefined): any {
  try {
    return JSON.parse(str);
  } catch (e) {
    return def;
  }
}

export async function safeWaitLogExpectDefined<T>(
  file: string,
  method: string,
  run: () => Promise<T | undefined | null>,
  errFn: ((err: Error) => Promise<T> | T) = throwError,
): Promise<T> {
  try {
    const result = await run();
    if (!result) {
      throw new Error('safeWaitAndLog: Function returned undefined');
    }

    Log.v(file, method, 'Success');
    return result;
  } catch (e) {
    Log.e(file, method, 'Failed', e.message);
    return errFn && errFn(e);
  }
}

export function push(arr: any[], element: any) {
  arr.push(element);
  return arr;
}

export function fpPush(arr: any[] | undefined, element: any) {
  return push(_.cloneDeep(arr || []), element);
}

export function boolToInt(bool: boolean) {
  return bool ? 1 : 0;
}

export function fpRemove<T>(arr: T[], index: number): T[] {
  const dup = _.cloneDeep(arr);
  index > -1 && dup.splice(index, 1);
  return dup;
}

export function mutateRemoveAtIndex<T>(arr: T[], index: number): T[] {
  index > -1 && arr.splice(index, 1);
  return arr;
}

export function rotate<T>(array: T[], start: T): T[] {
  const res = [_.findIndex(array, (item) => item == start)];
  for (let i = 0; i < array.length - 1; i++) {
    const num = _.last(res) as any as number;
    res.push((num + 1) % array.length);
  }
  return res
    .map((idx) => array[idx]);
}

export function deleteProps(obj: any, ...propNames: string[]) {
  const duplicate = { ...obj };
  propNames.forEach((propName) => {
    delete duplicate[propName];
  });
  return duplicate;
}

export function runnerOrDefault<T>(value: T | undefined, defaultVal: T): T {
  return value || defaultVal;
}

export function toggleValueInDataByKey<V>(val: V, _data: { [k: string]: V } | undefined, key: keyof V): { [k: string]: V } {
  const data = _data || {};
  const found = _.findKey(_.values(data), (v: V) => {
    return v[key] === val[key];
  });
  return found
    ? { ..._.omit(data, val[key] as any) }
    : { ..._.set(data, val[key] as any, val) };
}

type TFO<T> = {
  [key: string]: T;
};

export function mapAndFilterNull<T, R1 = T>(
  obj: TFO<T> = {},
  map: (val: T) => R1 | undefined,
): TFO<R1> {
  return _.reduce(obj, (acc, item, key) => {
    const mapped = map(item);
    return mapped != null
      ? _.set(acc, key, mapped)
      : acc;
  }, {} as TFO<R1>);
}

export function filterObj<T>(
  obj: TFO<T> = {},
  filter: (val: T) => boolean,
): TFO<T> {
  return _.reduce(obj, (acc, item, key) => {
    const addToResult = filter(item);
    return addToResult
      ? _.set(acc, key, item)
      : acc;
  }, {} as TFO<T>);
}

export function objToUrlParams(obj: any): string {
  const url = Object.keys(obj).reduce((acc, key) => {
    return obj[key] != null && obj[key] !== ''
      ? `${acc}&${key}=${obj[key]}`
      : acc;
  }, '');
  return url.startsWith('&')
    ? url.substring(1)
    : url;
}

export function urlParamsToObj(params: string): any {
  if (params == null || `${params}`.length <= 0) {
    return {};
  }

  return (params || '')
    .split('&')
    .reduce((acc, p) => {
      const [key, val] = p.split('=');
      acc[key] = val;
      return acc;
    }, {});
}

export function cachedValue<T>(generate: () => T, lazy = false) {
  let val: T | null = null;
  let generated = false;

  function get(): T {
    if (!generated) {
      val = generate();
      generated = true;
    }
    return val as any;
  }

  if (!lazy) {
    get();
  }

  return { get };
}

export function strHashCode(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

export function intToRGB(int: number) {
  // eslint-disable-next-line
  const c = (int & 0x00FFFFFF)
    .toString(16)
    .toUpperCase();

  return `#${'00000'.substring(0, 6 - c.length) + c}`;
}

export function isBoolean(val: any): val is boolean {
  return _.isBoolean(val);
}

export function deterministicHex(seed: string) {
  return intToRGB(strHashCode(seed));
}

export function valuesOfObjSortedByKeys(obj: any): any[] {
  return _.chain(obj)
    .keys()
    .sort()
    .map((key) => obj[key])
    .value();
}

export function joinArrBy<T, X>(arr: T[], getJoiner: (idx: number) => X) {
  return arr.reduce((acc, el, index) => {
    return index !== arr.length - 1
      ? [...acc, el, getJoiner(index)]
      : [...acc, el];
  }, [] as (T | X)[]);
}

export function joinArrMap<T, X>(arr: T[], mapper: (el: T, idx: number) => X, getJoiner: (idx: number) => X): X[] {
  return joinArrBy<T, X>(arr, getJoiner)
    .map((el, index) => (index % 2 === 0 ? mapper(el as T, index) : el as X));
}

export function addIfDefined<Key extends string, Val>(
  propName: Key,
  toBeDefined: any,
  value: Val = toBeDefined,
): { [key in Key]?: Val } {
  return toBeDefined != null
    ? { [propName]: value }
    : {} as any;
}

export function addIfDefined2<Key extends string, Val, TBD = any>(
  propName: Key,
  tbd: TBD | undefined,
  value?: (tbd: TBD) => Val,
): { [key in Key]?: Val } {
  if (tbd != null) {
    const res = value != null
      ? value(tbd)
      : tbd;
    return { [propName]: res } as any;
  }

  return {};
}

export function addIfTrue<Key extends string, Val>(
  propName: Key,
  toBeTrue: boolean,
  value: Val,
): { [key in Key]?: Val } {
  return addIfDefined<Key, Val>(propName, !toBeTrue && undefined, value);
}

export function addIfDefinedArr<Val>(
  toBeDefined: any,
  value: Val = toBeDefined,
): [] | [Val] {
  return toBeDefined != null
    ? [value]
    : [] as any;
}

export function addIfTrueArr<Val>(
  toBeTrue: boolean,
  value: Val,
): [] | [Val] {
  return addIfDefinedArr(toBeTrue || undefined, value);
}

export function enumToArray<E>(enumme: any): E[] {
  return Object.keys(enumme)
    // Take all keys which are not numbers
    .filter((key) => Number.isNaN(parseFloat(key)))
    // Map to values
    .map((key) => enumme[key]);
}

export function selectNotEmpty<T>(selectors: (T | undefined | null)[], def: T) {
  for (let i = 0; i < selectors.length; i++) {
    if (!_.isEmpty(selectors[i])) {
      return selectors[i];
    }
  }
  return def;
}

export function selectNotEmpty2<T>(def: T, ...selectors: (T | undefined | null)[]) {
  for (let i = 0; i < selectors.length; i++) {
    if (!_.isEmpty(selectors[i])) {
      return selectors[i];
    }
  }
  return def;
}

export function isNotNull(value: any) {
  return value != null;
}

export function isNotEmpty(value: any) {
  return !_.isEmpty(value);
}

export function isNotEmpty2<T>(value: T | undefined | null): value is T {
  return !_.isEmpty(value);
}

export function isNotEmptyString(value: any): value is string {
  return !_.isEmpty(value);
}

export async function addAndMap<K extends string, T, R = T>(
  key: K,
  data?: T,
  map?: (t: T) => Promise<R> | R,
): Promise<{ [k: string]: R | T }> {
  if (data == null) {
    return {};
  }

  const value = map != null
    ? await map(data)
    : data;

  return { [key]: value };
}

export function sortObj<T>(unordered: T): T {
  // @ts-expect-error
  return Object.keys(unordered).sort().reduce(
    (obj, key) => {
      obj[key] = unordered[key];
      return obj;
    },
    {},
  ) as T;
}

export function truncate(input: string, max: number) {
  if (input.length > max) {
    return `${input.substring(0, max)}...`;
  }
  return input;
}

export function partitionByComparison<T>(_arr: T[], same: (comp1: T, comp2: T) => boolean): T[][] {
  // Duplicate to avoid mutations
  const arr = [..._arr];

  const result: T[][] = [];
  for (let i = 0; i < arr.length; i++) {
    const groupLead = arr[i];
    if (!groupLead) {
      continue;
    }

    const group = [groupLead];
    for (let x = i + 1; x < arr.length; x++) {
      if (arr[x] == null) {
        // If arr[x] is null then this element was already put into a previous group
        continue;
      }

      if (!same(groupLead, arr[x])) {
        continue;
      }

      // Items are the same, add to group and pin as undefined
      // so the i loop knows to skip this item as a groupLead
      // @ts-expect-error
      group.push(arr[x]);

      // @ts-ignore
      arr[x] = undefined;
    }

    result.push(group);
  }

  return result;
}

export function notEmptyOrUndefined<T>(value: T | undefined): T | undefined {
  return _.isEmpty(value)
    ? undefined
    : value;
}

export function notEmptyOrUndefined2<T>(value: T | undefined | null): value is T {
  if (!_.isEmpty(value)) {
    return true;
  }
  return false;
}

export function cleanObj(object: any) {
  Object
    .entries(object)
    .forEach(([k, v]) => {
      if (v && typeof v === 'object') {
        cleanObj(v);
      }
      if (v && typeof v === 'object' && !Object.keys(v).length || v === null || v === undefined) {
        if (Array.isArray(object)) {
          object.splice(k as any, 1);
        } else {
          delete object[k];
        }
      }
    });
  return object;
}

export function parseCookie(cookie: string): any {
  return Object.fromEntries((cookie || '').split('; ').map((x) => x.split('=')));
}

export function buildMaxAttemptsFunction<T extends (...any) => Promise<any>>(maxAttempts: number, resetIntervalMs: number, func: T): T {
  let attempt = 0;
  return (async (...params: Parameters<T>) => {
    try {
      if (attempt >= maxAttempts) {
        setTimeout(() => {
          Log.e('HelperFunctions', 'buildMaxAttemptsFunction', `[attempt=${attempt}] Resetting attempts`);
          attempt = 0;
        }, resetIntervalMs);
        return undefined;
      }
      return await func(...params);
    } catch (e) {
      Log.e('HelperFunctions', 'buildMaxAttemptsFunction', `[attempt=${attempt}] ${e.message}`);
      attempt++;
      throw e;
    }
  }) as T;
}

export function addMemoizedGetter<Key extends string, Val>(key: Key, build: () => Val) {
  let val: any = null;
  let initalized = false;
  return {
    get [key]() {
      if (!initalized) {
        val = build();
        initalized = true;
      }
      return val;
    },
  } as { [Key: string]: Val };
}

export function memoizeAsync<T extends (...any) => Promise<any>>(func: T) {
  const cache = {};
  return async function (...args: any[]) {
    const argsStr = JSON.stringify(args);

    // @ts-ignore
    cache[argsStr] = cache[argsStr] || func.apply(this, args);
    return cache[argsStr];
  } as any as T;
}

export function deepMerge<T, X>(t: T, x: X): T & X {
  return _.mergeWith(_.cloneDeep(t), _.cloneDeep(x), (objValue, srcValue) => {
    return _.isArray(objValue)
      ? objValue.concat(srcValue)
      : undefined;
  });
}

export function setIfPropertyNotEmpty<T, V = T>(
  setter: (val: V) => void,
  toBeNotEmpty: T | undefined,
  mapper: (val: T) => V = _.identity,
) {
  if (!_.isEmpty(toBeNotEmpty)) {
    // @ts-ignore
    setter(mapper(toBeNotEmpty));
  }
}

export function memoizeFunc<T extends (...any) => any>(func: T) {
  const cache = {};
  return function (...args: any[]) {
    const argsStr = JSON.stringify(args);

    // @ts-ignore
    cache[argsStr] = cache[argsStr] || func.apply(this, args);
    return cache[argsStr];
  } as any as T;
}

export function makeReffable<T>(obj: T): T & { ref: { current: T } } {
  return {
    ...obj,
    ref: { current: obj },
  };
}
