import { FirebaseApi } from '../../FirebaseApi';
import { FirstParameter } from '../Types';
import { StPaths } from '../../db/StPaths';
import SparkMD5 from 'spark-md5';

export class MStObj<K extends keyof typeof StPaths> {
  private readonly bucketKey: K;

  private readonly bucketParams: FirstParameter<typeof StPaths[K]>;

  static arrayBufferToId(arrayBuffer: ArrayBuffer) {
    return SparkMD5.ArrayBuffer.hash(arrayBuffer);
  }

  get ref() {
    const path = StPaths[this.bucketKey](this.bucketParams as any);
    return FirebaseApi.instance.storageRef(path);
  }

  constructor(bucketKey: K, bucketParams: FirstParameter<typeof StPaths[K]>) {
    this.bucketKey = bucketKey;
    this.bucketParams = bucketParams;
  }

  uploadAndGetDownloadUrl = async (arrayBuffer: ArrayBuffer) => {
    await this.upload(arrayBuffer);
    return this.getDownloadUrl();
  };

  /**
   * Wraps the uploader in a promise
   * The firebase upload API doesn't expose a real promise but a generic thenable
   */
  upload = async (arrayBuffer: ArrayBuffer) => {
    return new Promise((resolve, reject) => {
      return this.ref.put(arrayBuffer)
        .then(resolve)
        .catch(reject);
    });
  };

  getDownloadUrl = () => {
    return this.ref.getDownloadURL();
  };
}
