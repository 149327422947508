import { GuestSession } from '../../../../core/src/models/db/guestSession/GuestSession';
import { Strings } from '../../../../core/src/locale/Strings';
import {
  TDispatch,
  TThunkAction,
} from '../../../../lib-react/src/redux/redux';
import { TGetState } from '../Store';
import { TGuestSession } from '../../../../core/src/models/db/guestSession/GuestSessionTypes';
import { actionSnackbarsAddNegativeSnackbar } from '../reducers/Snackbars';
import { dispatchWithErrorHandler } from '../ReduxErrorHandlers';
import { GuestSessionBuilder } from '../../../../core/src/models/db/guestSession/GuestSessionBuilder';
import { TOrder } from '../../../../core/src/models/db/order/OrderTypes';
import { OrderBuilder } from '../../../../core/src/models/db/order/OrderBuilder';

type TWithGuestSession = {
  guestSession: TGuestSession;
};

type TWithOpenOrder = {
  openOrder: TOrder;
};

export const actionGuestSessionRequestBill = dispatchWithErrorHandler(({ guestSession }: TWithGuestSession): TThunkAction<Promise<void>> => {
  return async (dispatch: TDispatch, getState: TGetState) => {
    const { locale } = getState().app;

    if (GuestSession.isBillRequested(guestSession)) {
      dispatch(actionSnackbarsAddNegativeSnackbar({ text: Strings.gOrderBillAlreadyRequestedMsg()(locale) }));
    }

    await GuestSessionBuilder.fromItem(guestSession).requestBill();
  };
});

export const actionGuestSessionCompleteOrderToBatching = dispatchWithErrorHandler(({ openOrder }: TWithOpenOrder): TThunkAction<Promise<void>> => {
  return async (dispatch: TDispatch, getState: TGetState) => {
    await OrderBuilder.fromItem(openOrder).completeOrderToBatch();
  };
});
