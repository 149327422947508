/**
 * No imports or they will cause cyclic-requires
 * Process variables that are not part of secrets
 * ===
 */

type TEnv = 'production' | 'development' | 'test';

function parseIntOrDefault<T>(str: string | undefined, def: T): number | T {
  const int = parseInt(`${str}`, 10);
  if (Number.isNaN(int)) {
    return def;
  }
  return int;
}

function parseBoolOrDefault<T>(_str: string | undefined, def: T): boolean | T {
  const str = `${_str}`.toLowerCase();
  return str !== 'true' && str !== 'false'
    ? def
    : str === 'true';
}

type TProcessEnv = {
  NODE_ENV: TEnv;
  CI: boolean;
  PORT: number;
  ALACARTE_SECRETS_JSON: string;
  COMMIT_HASH: string;
};

class ProcessBase {
  private envVars: TProcessEnv;

  get env(): TProcessEnv {
    return this.envVars;
  }

  constructor(processEnvObj: any = {}) {
    this.envVars = this.envUpdate(processEnvObj);
  }

  readonly envUpdate = (processEnvObj: any) => {
    this.envVars = {
      NODE_ENV: <TEnv>processEnvObj.NODE_ENV || 'development',
      CI: parseBoolOrDefault(processEnvObj.CI, false),
      PORT: parseIntOrDefault(<string>processEnvObj.PORT, 8080),
      ALACARTE_SECRETS_JSON: processEnvObj.ALACARTE_SECRETS_JSON || '',
      COMMIT_HASH: processEnvObj.COMMIT_HASH || '',
    };

    return this.envVars;
  };
}

export const Process = new ProcessBase(process.env);

