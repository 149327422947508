import * as React from 'react';
import { ScreenContext } from './ScreenContext';

export function withScreenContext(Component: any): any {
  return (props) => {
    const { screen } = React.useContext(ScreenContext);
    return (
      <Component
        {...props}
        screen={screen}
      />
    );
  };
}
