import * as React from 'react';
import { getActiveOrInactiveUnderlay } from '../components/orderUnderlays/ActiveOrInActiveUnderlay';
import { Colors } from '../config/Theme';
import { FIELD_ID } from '../../../core/src/db/DbDefs';
import { Screen } from '../lib-components/screen/Screen';
import { HomeHeader } from '../components/host/HomeHeader';
import { HostMainMenu } from '../components/hostMainMenu/HostMainMenu';
import { TMenuId } from '../../../core/src/models/db/menu/MenuTypes';
import { TMenuItem } from '../../../core/src/models/db/menuItem/MenuItemTypes';
import { AbsoluteExitHostButtonOverlay } from '../components/host/AbsoluteExitHostButtonOverlay';
import { EExitHostButtonTheme } from '../components/host/ExitHostButton';
import { Navigation } from '../lib/navigation/Navigation';
import { RCComp } from '../lib/routeContract/RCComp';
import { RoutesCliGuest } from '../../../core/src/lib/apis/routes/RoutableCliGuest';

export class RouteHostHomeComp extends RCComp<'ScreenHostHome'> {
  protected readonly routeName = 'ScreenHostHome' as const;

  constructor(props, context) {
    super(props, context);
  }

  render() {
    const {
      locale,
      hostData,
      openOrder,
      guestSession,
    } = this.props;
    return (
      <Screen
        backgroundColor={Colors.red}
        usesReactNavigationHeader={false}
        hasStatusBar={false}
        statusBarBackgroundColor={Colors.transparent}
        statusBarBackgroundColorIsLight={false}
        underlayHeight={120}
        underlay={guestSession && openOrder && getActiveOrInactiveUnderlay({
          guestSession,
          onCheckoutPress: this.navigateToScreenHostOrderCheckout,
          onRecapPress: this.navigateToScreenHostOrderCheckout,
        })}
      >
        <AbsoluteExitHostButtonOverlay
          themeStyle={EExitHostButtonTheme.backgroundTransparent}
        >
          <HomeHeader
            hostData={hostData}
            onSearchPress={this.onSearchPress}
          />
          <HostMainMenu
            hostData={hostData}
            onMenuPress={this.onMenuPress}
            onMenuItemPress={this.onMenuItemPress}
          />
        </AbsoluteExitHostButtonOverlay>
      </Screen>
    );
  }

  private readonly navigateToScreenHostOrderCheckout = () => {
    Navigation.routePush(RoutesCliGuest.ScreenHostOrderCheckout.name);
  };

  private readonly onMenuPress = (menuId: TMenuId) => {
    Navigation.routePush(RoutesCliGuest.ScreenHostMenu.name, {
      pathParams: { menuId },
      params: {},
    });
  };

  private readonly onMenuItemPress = (menuItem: TMenuItem) => {
    Navigation.routePush(RoutesCliGuest.ScreenHostMenuItemView.name, {
      pathParams: { menuItemId: menuItem[FIELD_ID] },
      params: {},
    });
  };

  private readonly onSearchPress = () => {
    Navigation.routePush(RoutesCliGuest.ScreenHostMenuItemSearch.name, {
      pathParams: {},
      params: {},
    });
  };
}
