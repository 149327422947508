/**
 * For use with react-native-web we have to mock @react-native-firebase
 * keep all imports in this file so we only have to replace one file in web
 */
import firebase from 'firebase';
import * as React from 'react';

// @ts-ignore
import { useWindowDimensions } from 'react-native';

export const ReactX = {
  firebase,
  database: firebase.database,
  auth: firebase.auth,
  storage: firebase.storage,
  dynamicLinks() {
    return {
      async getInitialLink() {
        return undefined;
      },
      onLink() {
        return function unsubscribe() {
          return undefined;
        };
      },
    };
  },

  crashlytics() {
    return {
      crash() {
      },
      log() {
      },
      setUserId() {
      },
    };
  },
  Snackbar: {
    show() {
    },
    LENGTH_SHORT: 3000,
  },

  useWindowDimensions: () => {
    const dim = useWindowDimensions();
    const dimRef = React.useRef<any>();
    dimRef.current = dim;

    function inViewport() {
      const el = document.getElementById('root');
      // @ts-ignore
      const elH = $(el).outerHeight();
      // @ts-ignore
      const H = $(window).height();
      // @ts-ignore
      const r = el.getBoundingClientRect(); const t = r.top; const
        b = r.bottom;
      return Math.max(0, t > 0 ? Math.min(elH, H - t) : Math.min(b, H));
    }

    function getSize() {
      return {
        ...dimRef.current,
        // @ts-ignore
        height: inViewport(),
      };
    }

    const [scaledSize, setScaledSize] = React.useState(getSize());

    React.useLayoutEffect(() => {
      function updateSize() {
        setScaledSize(getSize());
      }

      updateSize();
      window.addEventListener('resize', updateSize);
      return () => window.removeEventListener('resize', updateSize);
    }, []);

    return scaledSize;
  },
  window: {
    location: {
      get href() {
        return window.location.href;
      },
    },
    history: {
      back(...params) {
        // @ts-ignore
        history.back();
      },
      pushState(...params) {
        // @ts-ignore
        history.pushState(...params);
      },
      replaceState(...params) {
        // @ts-ignore
        history.replaceState(...params);
      },
      reset(...params) {
        // Not available on web
      },
    },
  },
};
