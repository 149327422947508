import * as _ from 'lodash';
import {
  FIELD_CREATED_ON,
  FIELD_ID,
  FIELD_PATH,
  FIELD_UPDATED_ON,
} from '../../db/DbDefs';
import { Log } from '../../config/Instance';
import { MObjDef } from './MObjDef';
import {
  TDbResData,
  TDbResName,
  TDbResPbp,
} from '../../db/DbResources';
import {
  TMObjDataCreator,
  TMObjDataUpdater,
} from './ModelTypes';
import {cleanObj, nowMs} from '../HelperFunctions';
import { uuid } from '../UUID';
import {Utils} from '../Utils';

export function dataCreatorToMObj<RK extends TDbResName>(
  builder: MObjDef<RK>,
  pbp: TDbResPbp[RK],
  data: TMObjDataCreator<RK>,
  id: string = uuid(),
): TDbResData[RK] {
  return builder.makeConsistentTModelObj(
    pbp,
    {
      ...data as TDbResData[RK],
      [FIELD_ID]: id,
      [FIELD_PATH]: '',
      [FIELD_CREATED_ON]: nowMs(),
      [FIELD_UPDATED_ON]: nowMs(),
    },
  );
}

export function mObjToMObjDataCreator<RK extends TDbResName>(data: TDbResData[RK]): TMObjDataCreator<RK> {
  return _.omit(data, [FIELD_ID, FIELD_PATH, FIELD_CREATED_ON, FIELD_UPDATED_ON]);
}

export function mObjToMObjDataUpdater<RK extends TDbResName>(data: TDbResData[RK]): TMObjDataUpdater<RK> {
  return _.omit(data, [FIELD_ID, FIELD_PATH, FIELD_CREATED_ON]);
}

export abstract class AbsMObjCreator<RK extends TDbResName, InitialPbp> {
  protected readonly builder: MObjDef<RK>;

  protected constructor(builder: MObjDef<RK>) {
    this.builder = builder;
  }

  abstract buildNew(
    pbp: InitialPbp,
    data: TMObjDataCreator<RK>,
    id?: string,
  ): TDbResData[RK];

  async remoteSaveNewToPath(
    initialPbp: InitialPbp,
    data: TMObjDataCreator<RK>,
    id?: string,
  ): Promise<TDbResData[RK]> {
    const newItemData = this.buildNew(initialPbp, data, id);
    const pbp = this.builder.getPathBuilderParamsFromDataPath(newItemData);

    if (await this.builder.dataExistsById(pbp)) {
      throw new Error(`AbsMObjCreator, remoteSaveNewToPath, ERR: data already exists @ ${JSON.stringify(pbp)}`);
    }
    Log.v('MObjDef', 'remoteSaveNewToPath', `Saving`);
    return this.builder.remoteSaveToPath(pbp, newItemData);
  }

  async remoteUpsertNewToPath(
    initialPbp: InitialPbp,
    data: TMObjDataCreator<RK>,
    id?: string,
  ): Promise<TDbResData[RK]> {
    const newItemData = this.buildNew(initialPbp, data, id);
    const pbp = this.builder.getPathBuilderParamsFromDataPath(newItemData);

    const previousData = await this.builder.remoteFromPathOrUndefined(pbp);
    if (previousData != null) {
      Log.v('MObjDef', 'remoteUpsertNewToPath', `Updating`);
      return this.builder.remoteSaveToPath(pbp, {
        ...previousData,
        ...data,
      });
    }

    Log.v('MObjDef', 'remoteUpsertNewToPath', `Creating`);
    return this.builder.remoteSaveToPath(pbp, newItemData);
  }
}

/**
 * Builder for TMObj's that cannot be built with the
 * {...pbp, [idKey]: id } pattern (All pbp's must be supplied)
 */
export abstract class MObjCreator<RK extends TDbResName> extends AbsMObjCreator<RK, TDbResPbp[RK]> {
  protected constructor(builder: MObjDef<RK>) {
    super(builder);
  }

  buildNew(pbp: TDbResPbp[RK], data: TMObjDataCreator<RK>, id = uuid()): TDbResData[RK] {
    return dataCreatorToMObj(this.builder, pbp, data, id);
  }
}

/**
 * Builder for MObj's that can be built with the
 * {...pbp, [idKey]: id } pattern
 */
export abstract class MObjCreatorById<RK extends TDbResName, IdKey extends keyof TDbResPbp[RK]> extends AbsMObjCreator<RK, Omit<TDbResPbp[RK], IdKey>> {
  protected constructor(builder: MObjDef<RK>) {
    super(builder);
    this.buildNew = this.buildNew.bind(this);
  }

  protected readonly abstract idKey: IdKey;

  buildNew(
    subPbp: Omit<TDbResPbp[RK], IdKey>,
    data: TMObjDataCreator<RK>,
    id: string = uuid(),
  ): TDbResData[RK] {
    const pbp = {
      ...subPbp,
      [this.idKey]: id,
    } as any;
    return cleanObj(dataCreatorToMObj(this.builder, pbp, data, id));
  }
}
