import { DbRes } from '../../../db/DbResources';
import { MObjCreatorById } from '../../../lib/model/MObjCreator';
import { StationBuilder } from './StationBuilder';

class StationCreatorBase extends MObjCreatorById<typeof DbRes.Station, 'stationId'> {
  constructor() {
    super(StationBuilder);
  }

  protected readonly idKey = 'stationId';
}

export const StationCreator = new StationCreatorBase();
