import * as React from 'react';
import { BackHandler } from 'react-native';
import { Screen } from '../lib-components/screen/Screen';
import { RCComp } from '../lib/routeContract/RCComp';
import { Navigation } from '../lib/navigation/Navigation';
import { RoutesCliGuest } from '../../../core/src/lib/apis/routes/RoutableCliGuest';
import { Colors } from '../config/Theme';

export class LockOverlayRCComp extends RCComp<'ModalLockScreenOverlay'> {
  protected readonly routeName = 'ModalLockScreenOverlay' as const;

  componentDidMount() {
    super.componentDidMount();
    BackHandler.addEventListener('hardwareBackPress', this.handleBackButton);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    BackHandler.removeEventListener('hardwareBackPress', this.handleBackButton);
  }

  handleBackButton = () => {
    // Return true to disable back button
    return true;
  };

  componentDidUpdate() {
    if (!this.props.lockScreen) {
      Navigation.hideRouteIfCurrent(RoutesCliGuest.ModalLockScreenOverlay.name);
    }
  }

  render() {
    return (
      <Screen
        backgroundColor={Colors.transparent}
        usesReactNavigationHeader={false}
        hasStatusBar={true}
        statusBarBackgroundColor={Colors.transparent}
        statusBarBackgroundColorIsLight={false}
      />
    );
  }
}
