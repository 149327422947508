import { TGenericRequestableRoute } from './RequestableTypes';
import { UndefinedOptional } from '../../Types';
import { URLToolbox } from '../url-toolbox/URLToolbox';

export type TRequestableBaseParams = {
  baseUrl: string;
};

type TRequestableRouteParams<Def extends TGenericRequestableRoute> = TRequestableBaseParams & {
  route: Def;
};

export type TRouteRunnerParams<Route extends TGenericRequestableRoute> = {
  body: Route['body'];
  pathParams: Route['pathParams'];
  params: Route['params'];
};

export class RequestableRoute<Def extends TGenericRequestableRoute> {
  protected readonly baseUrl: string;
  protected readonly route: TGenericRequestableRoute;

  constructor(params: TRequestableRouteParams<Def>) {
    this.baseUrl = params.baseUrl;
    this.route = params.route;
  }

  readonly url = (_opts: UndefinedOptional<TRouteRunnerParams<Def>>) => {
    const opts = _opts as TRouteRunnerParams<Def>;
    return new URLToolbox({
      pathTemplate: this.route.route,
      pathParams: {
        // todo allow maps
        in: this.route.pathParams,
      },
      searchParams: { in: this.route.params },
    }).constructURL({
      baseURL: this.baseUrl,
      pathParams: opts.pathParams,
      searchParams: opts.params,
    });
  };
}
