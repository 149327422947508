import { TState } from '../redux/Store';
import {
  RCStackScreenProps,
  RouteContract,
  TOutNavParams,
  TRCMergedCheckedProps,
  TRCOwnProps,
} from '../lib/routeContract/RouteContract';
import { StackNavigationOptions } from '@react-navigation/stack';
import { TNavigationParamsWithMethod } from '../lib/navigation/Navigation';
import { bindActionCreators } from '../../../lib-react/src/redux/redux';
import { actionOrderRemoveOrderItem } from '../redux/actions/Order';
import { NavConst } from '../lib/navigation/NavConst';
import { CheckPropsResultBuild } from '../lib/routeContract/RouteContractCheckProps';
import { Order } from '../../../core/src/models/db/order/Order';
import { Log } from '../config/Log';
import { RoutesCliGuest } from '../../../core/src/lib/apis/routes/RoutableCliGuest';
import { GuestSession } from '../../../core/src/models/db/guestSession/GuestSession';

export class RouteHostOrderItemEditContract extends RouteContract<'ScreenHostOrderItemEdit'> {
  protected readonly routeName = 'ScreenHostOrderItemEdit' as const;

  onBeforeRemoveShouldAllowBack(props: TRCMergedCheckedProps<'ScreenHostOrderItemEdit'>) {
    return true;
  }

  navigationOptions(props: RCStackScreenProps<TOutNavParams<'ScreenHostOrderItemEdit'>>, navParams: TOutNavParams<'ScreenHostOrderItemEdit'>): StackNavigationOptions {
    return {
      headerShown: true,
      ...NavConst.stackNavOptionsForCenteredUppercaseTitle,
      ...NavConst.stackNavOptions,
    };
  }

  shouldSkipToRoute(state: TState, ownProps: TOutNavParams<'ScreenHostOrderItemEdit'>): TNavigationParamsWithMethod<any> | undefined {
    return undefined;
  }

  mapStateToProps(state: TState, navParams: TOutNavParams<'ScreenHostOrderItemEdit'>) {
    const guestSession = state.guestSession.guestSessionData || { orders: {} };

    const orderItemId = navParams.pathParams.orderItemId ?? '__';
    const orderItem = GuestSession.findOrderItemById(guestSession, orderItemId);

    const menuItem = state.host?.hostData?.items?.[orderItem?.menuItemId ?? '__'];
    return {
      language: state.app.language,
      locale: state.app.locale,
      openOrder: state.guestSession.openOrder,
      menuItem,
      orderItem,
    };
  }

  mapDispatchToProps(dispatch: any, ownProps: TRCOwnProps<'ScreenHostOrderItemEdit'>) {
    return { orderRemoveOrderItem: bindActionCreators(actionOrderRemoveOrderItem, dispatch) };
  }

  // Loads forever when directly accessing this screen
  checkProps(props: ReturnType<this['mapStateToProps']>) {
    const {
      openOrder,
      orderItem,
      menuItem,
    } = props;

    function onInvalidItem() {
      return CheckPropsResultBuild.REDIRECT({
        routeName: RoutesCliGuest.ScreenHostOrderCheckout.name,
        params: {
          params: {},
          pathParams: {},
        },
        method: 'replace',
      });
    }

    if (menuItem == null) {
      return onInvalidItem();
    }

    if (openOrder == null) {
      return CheckPropsResultBuild.WAIT();
    }

    if (orderItem == null) {
      return onInvalidItem();
    }

    // If the orderItem is at any point deleted this screen will no longer be valid
    // Ensure orderItem is still in openOrder
    const valid = Order.orderIsOpen(openOrder) && Order.orderContainsOrderItem(openOrder, orderItem);
    if (!valid) {
      Log.v('RouteHostOrderItemEditContract', 'checkProps', 'OrderItem is invalid');
      return CheckPropsResultBuild.REDIRECT({
        routeName: RoutesCliGuest.ScreenHostOrderCheckout.name,
        params: {
          params: {},
          pathParams: {},
        },
        method: 'replace',
      });
    }

    return CheckPropsResultBuild.READY({
      ...props,
      openOrder,
      orderItem,
      menuItem,
    });
  }
}
