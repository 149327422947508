import * as React from 'react';
import { View } from 'react-native';
import Spinner from 'react-native-spinkit';
import styled from 'styled-components/native';
import { StyledComponentsTheme } from './theme/StyledComponentsTheme';

type TFullScreenLoaderProps = {
  //
};

export const FullScreenLoader = (props: TFullScreenLoaderProps) => {
  const { theme } = React.useContext(StyledComponentsTheme.Context);
  return (
    <Screen
      backgroundColor={theme.t.col.aLaCarteTheme}>
      <Spinner
        isVisible
        type={'WanderingCubes'}
        color={theme.t.col.red}
        size={40}
      />
    </Screen>
  );
};

const Screen = styled(View)<{backgroundColor: string}>`
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  width: 100%;
`;
