import { Navigation } from './Navigation';

export class NavTitleHandler<Props> {
  private readonly getTitle: (props: Props) => string;

  private prevTitle: string | undefined;

  constructor(getTitle: (props: Props) => string) {
    this.getTitle = getTitle;
  }

  apply = (props: Props): NavTitleHandler<Props> => {
    const newTitle = this.getTitle(props);
    if (this.prevTitle != newTitle) {
      this.applyBase(props);
    }
    return this;
  };

  private applyBase = (props: Props): NavTitleHandler<Props> => {
    const title = this.getTitle(props);
    Navigation.setScreenTitle(title);
    this.prevTitle = title;
    return this;
  };
}
