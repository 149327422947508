import * as React from 'react';
import { Colors } from '../../config/Theme';
import { Loader } from '../../lib-components/Loader';
import { View } from 'react-native';
import { Touchable } from '../../lib-components/Touchable';
import { useLoadingCallback } from '../../../../lib-react/src/hooks/useLoadingCallback';
import styled from 'styled-components/native';
import { AlaTextBold } from '../Text';

type TOrderItemEditorSectionUpdateButtonProps = {
  leftText: string;
  rightText: string;
  onPress: () => Promise<void>;
  style?: any;
};

export const OrderItemEditorSectionUpdateButton = ({
  leftText,
  rightText,
  onPress,
  style,
}: TOrderItemEditorSectionUpdateButtonProps) => {
  const [onTouchablePress, loading] = useLoadingCallback(onPress);
  return (
    <Touchable
      onPress={onTouchablePress}>
      <ActionContainer style={style}>
        <ActionText>
          {leftText}
        </ActionText>
        {loading && (
          <Loader
            color={Colors.white}/>
        )}
        {!loading && (
          <ActionText>
            {rightText}
          </ActionText>
        )}
      </ActionContainer>
    </Touchable>
  );
};

const ActionContainer = styled(View)`
  align-items: center;
  background-color: ${({ theme }) => theme.t.col.red};
  border-radius: 2px;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 14px;
  padding: 13px 20px;
`;

const ActionText = styled(AlaTextBold)`
  align-items: center;
  color: ${({ theme }) => theme.t.col.white};
  font-size: 14px;
  height: 22px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;
