export type TPrintOp = {
  func: string;
  params: any;
};

class PrintOpsBase {
  readonly printOpRaw = (content: string) => {
    return {
      func: 'raw' as const,
      params: { content },
    };
  };

  readonly printOpNewLine = (count = 1) => {
    return {
      func: 'newLine' as const,
      params: { count },
    };
  };

  readonly printOpAlign = (align: 'LT' | 'CT' | 'RT') => {
    return {
      func: 'align' as const,
      params: { align },
    };
  };

  readonly printOpTableCustom = (items: Array<{ text: string; align: 'LEFT' | 'CENTER' | 'RIGHT'; width: number; style?: 'B' }>) => {
    return {
      func: 'tableCustom' as const,
      params: { items },
    };
  };

  readonly printOpSize = (width: number, height: number = width) => {
    return {
      func: 'size' as const,
      params: {
        width,
        height,
      },
    };
  };

  readonly printOpText = (content: string, encoding?: string) => {
    return {
      func: 'text' as const,
      params: {
        content,
        encoding,
      },
    };
  };

  readonly printOpStyle = (type: 'NORMAL' | 'B' | 'I' | 'U' | 'U2' | 'BI' | 'BIU' | 'BIU2' | 'BU' | 'BU2' | 'IU' | 'IU2') => {
    return {
      func: 'style' as const,
      params: { type },
    };
  };

  readonly printOpDrawLine = () => {
    return {
      func: 'drawLine' as const,
      params: {},
    };
  };

  readonly printOpEmitSound = (numberOfBuzzer: number, time: number) => {
    return {
      func: 'emitSound' as const,
      params: {
        numberOfBuzzer,
        time,
      },
    };
  };
}

export const PrintOps = new PrintOpsBase();

export type TEscposAdapterRaw = ReturnType<typeof PrintOps.printOpRaw>;
export type TEscposAdapterNewLine = ReturnType<typeof PrintOps.printOpNewLine>;
export type TEscposAdapterText = ReturnType<typeof PrintOps.printOpText>;
export type TEscposAdapterStyle = ReturnType<typeof PrintOps.printOpStyle>;
export type TEscposAdapterDrawLine = ReturnType<typeof PrintOps.printOpDrawLine>;
export type TEscposAdapterEmitSound = ReturnType<typeof PrintOps.printOpEmitSound>;
export type TEscposAdapterAlign = ReturnType<typeof PrintOps.printOpAlign>;
export type TEscposAdapterTableCustom = ReturnType<typeof PrintOps.printOpTableCustom>;
export type TEscposAdapterSize = ReturnType<typeof PrintOps.printOpSize>;

export type TPrintOps = TEscposAdapterRaw
| TEscposAdapterNewLine
| TEscposAdapterText
| TEscposAdapterStyle
| TEscposAdapterDrawLine
| TEscposAdapterEmitSound
| TEscposAdapterAlign
| TEscposAdapterTableCustom
| TEscposAdapterSize;

