import * as React from 'react';
import { Bluebird } from '../../../core/src/lib/Bluebird';
import { safe } from '../../../core/src/lib/HelperFunctions';

type TUseCancelableAsyncEffectParams<T> = (...params: any[]) => Promise<T>;

export function useCancelableAsyncEffect(runner: TUseCancelableAsyncEffectParams<void>, deps: any[] = []) {
  const cancelRef = React.useRef<() => void>();

  React.useEffect(() => {
    return () => safe(() => cancelRef.current && cancelRef.current());
  }, []);

  React.useEffect(() => {
    const bluebirdPromise = Bluebird.resolve()
      .then(runner);
    cancelRef.current = bluebirdPromise.cancel;
  }, deps);

  return { cancelRef };
}
