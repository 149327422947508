import * as React from 'react';
import { Colors } from '../../config/Theme';
import { FullScreenSplash } from './FullScreenSplash';
import { SvgALaCarteLogo } from '../../img/SvgALaCarteLogo';
import { ELocale } from '../../../../core/src/locale/Locale';

export type TFullScreenSplashAlacarteProps = {
  locale: ELocale;
};

export const FullScreenSplashAlacarte = ({ locale }: TFullScreenSplashAlacarteProps) => (
  <FullScreenSplash
    locale={locale}
    backgroundColorIsLight={true}
    backgroundColor={Colors.aLaCarteTheme}
    topLogo={(
      <SvgALaCarteLogo/>
    )}
  />
);
