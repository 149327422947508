import * as _ from 'lodash';
import {
  ConnectedClass,
  TNavigationProp,
} from './ConnectedClass';
import { Log } from '../../config/Log';
import { TALCDLReceiver } from '../../../../core/src/lib/deeplinks/ALCDeepLinks';
import { TDeepLinks } from '../../../../core/src/lib/deeplinks/DeepLinkHandler';
import { TDispatch } from '../../../../lib-react/src/redux/redux';
import { actionInitializeHostSession } from '../../redux/actions/actionInitializeHostSession';

class ConnectedDeepLinkHandlerBase implements ConnectedClass, TALCDLReceiver {
  dispatch = _.noop as TDispatch;

  navigation = null as any as TNavigationProp;

  constructor() {
    this.ScreenSetupGuestSession = this.ScreenSetupGuestSession.bind(this);
  }

  setDispatch = (dispatch: TDispatch) => {
    this.dispatch = dispatch;
  };

  setNavigation = (navigation: TNavigationProp) => {
    this.navigation = navigation;
  };

  async ScreenSetupGuestSession(params: TDeepLinks['ScreenSetupGuestSession']['Receiver']): Promise<boolean> {
    const {
      hostId,
      tableId,
      tableNumber,
      skipGuestsCheck,
    } = params;
    Log.v('ConnectedDeepLinkHandler', 'ScreenSetupGuestSession', `Handling hostId=${hostId}`);

    await this.dispatch(actionInitializeHostSession({
      hostId,
      tableId,
      tableNumber,
      skipGuestsCheck,
    }));

    return true;
  }
}

export const ConnectedDeepLinkHandler = new ConnectedDeepLinkHandlerBase();
