import { Config } from '../../config/Config';
import { Firebase } from '../../lib/Firebase';
import { Log } from '../../config/Log';
import { TFirebaseUser } from '../../../../core/src/FirebaseApi';
import {
  defineReducer,
  TThunkAction,
} from '../../../../lib-react/src/redux/redux';
import { actions } from '../Actions';
import { ELocale } from '../../../../core/src/locale/Locale';
import { TLanguage } from '../../../../core/src/locale/Languages';

export type TStateApp = {
  user?: TFirebaseUser;

  // This is the locale the app is viewing in, it has to be one of ELocale or else we wouldn't have translations
  locale: ELocale;

  // This is the locale the user has selected, it's only available based on the menu languages in the menu
  language: TLanguage;

  lockScreen: boolean;
};

const initialState: TStateApp = {
  locale: Config.defaultLocale,
  language: Config.defaultLocale,
  lockScreen: false,
};

export const {
  actionTypeAppSetUser,
  reducer: reducerApp,
} = defineReducer<TStateApp, typeof actions>({
  actions,
  initialState,
  reducer: (state, action, getType) => {
    switch (action.type) {
      case getType(actions.actionTypeAppSetUser):
        return {
          ...state,
          user: action.payload.user,
        };
      case getType(actions.actionTypeAppSetLocale):
        const locale = ELocale[action.payload.locale] != null
          ? action.payload.locale as ELocale
          : Config.defaultLocale;
        return {
          ...state,
          locale,
          language: action.payload.locale,
        };
      case getType(actions.actionTypeAppSetLockScreen):
        return {
          ...state,
          lockScreen: action.payload.lockScreen,
        };
    }
    return state;
  },
});

export function actionAppSignInAnonymously(): TThunkAction<Promise<void>> {
  return async (dispatch) => {
    const authUser = await Firebase.authSignInAnonymously();
    Log.v('App', 'actionAppSignInAnonymously', `authUser.uid=${authUser?.uid}`);

    if (!authUser?.uid) {
      return;
    }

    // Set authenticated
    dispatch(actionTypeAppSetUser({
      user: {
        uid: authUser.uid,
        isAnonymous: authUser.isAnonymous,
        displayName: authUser.displayName,
        email: authUser.email,
      },
    }));
  };
}
