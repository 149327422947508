import { TDispatch, TThunkAction } from '../../../../lib-react/src/redux/redux';
import { actions } from '../Actions';
import { Navigation } from '../../lib/navigation/Navigation';
import { RoutesCliGuest } from '../../../../core/src/lib/apis/routes/RoutableCliGuest';

export const actionResetSessionAndNavigate = (): TThunkAction<Promise<void>> => {
  return async (dispatch: TDispatch) => {
    dispatch(actions.pureActionTypeResetSession());
    return Navigation.routePush(RoutesCliGuest.ScreenSetupScanHostQR.name);
  };
};
