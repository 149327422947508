import { orderedClamp } from '../../../core/src/lib/HelperFunctions';

export type TCalculateRatioParams = {
  width: number;
  ratio: number;
  maxHeight?: number;
  maxWidth?: number;
};

export function calculateRatio({
  width,
  ratio,
  maxHeight,
  maxWidth,
}: TCalculateRatioParams) {
  const ratioedWidth = orderedClamp(width, 0, maxWidth || width);
  const ratioedHeight = ratioedWidth * 1 / ratio;
  const height = orderedClamp(ratioedHeight, 0, maxHeight || ratioedHeight);
  return {
    height,
    width: ratioedWidth,
  };
}
