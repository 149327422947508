import * as React from 'react';
import { useSelector } from 'react-redux';
import { TState } from '../redux/Store';
import { FullScreenSplashAlacarte } from './splash/FullScreenSplashAlacarte';

export type TSplashScreenMiddlewareComponent = {
  children: React.ReactNode;
};

export const SplashScreenMiddlewareComponent = ({ children }: TSplashScreenMiddlewareComponent) => {
  const locale = useSelector((state: TState) => state.app.locale);
  const appLoadingComplete = useSelector((state: TState) => state.session.appLoadingComplete);

  if (!appLoadingComplete) {
    return (
      <FullScreenSplashAlacarte
        locale={locale}
      />
    );
  }

  return (
    <>
      {children}
    </>
  );
};
