import { DbPaths } from '../../../db/DbRefs';
import { DbRes } from '../../../db/DbResources';
import { MObjDef } from '../../../lib/model/MObjDef';
import { TTable } from './TableTypes';
import { Table } from './Table';
import { defTable } from '../ModelDefaults';

const pathBuilderKeys = [
  'hostId',
  'tableId',
] as const;

export type TTablePathBuilderParams = {
  [key in ((typeof pathBuilderKeys)[number])]: string
};

class TableBuilderBase extends MObjDef<typeof DbRes.Table> {
  readonly resourceKey = DbRes.Table;

  protected readonly pathBuilderKeys = [...pathBuilderKeys];

  protected readonly _default = defTable;

  protected newChild(item: TTable) {
    return new Table(item);
  }

  pathBuilder(params: TTablePathBuilderParams): string {
    return DbPaths.table(params);
  }
}

export const TableBuilder = new TableBuilderBase();

