import * as React from 'react';

export type TScreenContextParams = {
  screen: {
    contentTopOffset: number;
    contentHeight: number;
  };
};

const defaultScreenContext: TScreenContextParams = {
  screen: {
    contentTopOffset: 0,
    contentHeight: 0,
  },
};

export const ScreenContext = React.createContext(defaultScreenContext);

type TScreenContextProviderProps = {
  children: React.ReactNode;
  screen: TScreenContextParams['screen'];
};

export const ScreenContextProvider = (props: TScreenContextProviderProps) => {
  const {
    children,
    screen,
  } = props;
  return (
    <ScreenContext.Provider
      value={{ screen }}>
      {children}
    </ScreenContext.Provider>
  );
};
