import {
  ESecretInspectionLevel,
  Secrets,
} from '../secrets/Secrets';
import { corePackageSecrets } from './corePackageSecrets';

type TSecrets = typeof corePackageSecrets;

export const PackageSecrets = new Secrets<TSecrets>({
  packageSecretKeys: corePackageSecrets,
  secretInspectionLevel: ESecretInspectionLevel.NONE,
});
