import { StackNavigationOptions, StackNavigationProp } from '@react-navigation/stack';
import { TNavigationParamsWithMethod } from '../navigation/Navigation';
import { TState } from '../../redux/Store';
import { TDispatch } from '../../../../lib-react/src/redux/redux';
import { RouteProp } from '@react-navigation/native';
import * as _ from 'lodash';
import { TRCCompContracts } from '../navigation/routes/RCCompContracts';
import { TCheckPropsResult } from './RouteContractCheckProps';
import {
  RoutesCliGuest,
  TRoutesCliGuestNames,
  TRoutesCliGuestOutParams,
  TRoutesCliGuestOutPathParams,
} from '../../../../core/src/lib/apis/routes/RoutableCliGuest';
import { URLParams } from '../../../../core/src/lib/define/url-toolbox/URLParams';

export type TOutNavParams<RK extends TRoutesCliGuestNames> = {
  pathParams: TRoutesCliGuestOutPathParams[RK];
  params: TRoutesCliGuestOutParams[RK];
};

export type RCStackScreenProps<OutNavParams extends Record<string, any>,
  OutNavParamKey extends keyof OutNavParams = string> = {
    navigation: StackNavigationProp<OutNavParams, OutNavParamKey>;
    route: RouteProp<OutNavParams, OutNavParamKey>;
  };

export type TRCOwnProps<RK extends TRoutesCliGuestNames> = RCStackScreenProps<TOutNavParams<RK>> & {
  //
};

export type TRCStateProps<RK extends TRoutesCliGuestNames> = ReturnType<TRCCompContracts[RK]['mapStateToProps']>;

export type TRCDispatchProps<RK extends TRoutesCliGuestNames> = ReturnType<TRCCompContracts[RK]['mapDispatchToProps']>;

export type TRCMergedProps<RK extends TRoutesCliGuestNames> =
  TRCStateProps<RK>
  & TRCDispatchProps<RK>
  & TRCOwnProps<RK>;

export type TRCStateCheckedProps<RK extends TRoutesCliGuestNames> = Extract<ReturnType<TRCCompContracts[RK]['checkProps']>, { props: {} }>['props'];

export type TRCMergedCheckedProps<RK extends TRoutesCliGuestNames> =
  TRCStateCheckedProps<RK>
  & TRCDispatchProps<RK>
  & TRCOwnProps<RK>;

export abstract class RouteContract<RK extends TRoutesCliGuestNames> {
  static ownPropsToNavParams<RK extends TRoutesCliGuestNames>(routeName: RK, props: TRCOwnProps<RK>) {
    const route = RoutesCliGuest[routeName];
    const params = new URLParams(route.params);
    const pathParams = new URLParams(route.pathParams);
    return {
      params: _.pick(props.route.params, params.keysOut),
      pathParams: _.pick(props.route.params, pathParams.keysOut),
    } as any;
  }

  protected abstract routeName: RK;

  constructor() {
    this.onBeforeRemoveShouldAllowBack = this.onBeforeRemoveShouldAllowBack.bind(this);
    this.navigationOptions = this.navigationOptions.bind(this);
    this.shouldSkipToRoute = this.shouldSkipToRoute.bind(this);
    this.mapStateToProps = this.mapStateToProps.bind(this);
    this.mapDispatchToProps = this.mapDispatchToProps.bind(this);
    this.checkProps = this.checkProps.bind(this);
    this.navOptions = this.navOptions.bind(this);
  }

  abstract onBeforeRemoveShouldAllowBack(props: TRCMergedCheckedProps<RK>): boolean | TNavigationParamsWithMethod<any>;

  abstract navigationOptions(props: RCStackScreenProps<TOutNavParams<RK>>, navParams: TOutNavParams<RK>): StackNavigationOptions;

  abstract shouldSkipToRoute(state: TState, navParams: TOutNavParams<RK>): TNavigationParamsWithMethod<any> | undefined;

  abstract mapStateToProps(state: TState, navParams: TOutNavParams<RK>);

  abstract mapDispatchToProps(dispatch: TDispatch, ownProps: TRCOwnProps<RK>);

  abstract checkProps(mergeProps: ReturnType<this['mapStateToProps']>): TCheckPropsResult;

  navOptions(props: RCStackScreenProps<TOutNavParams<RK>>): StackNavigationOptions {
    const nav = RouteContract.ownPropsToNavParams(this.routeName, props);
    return this.navigationOptions(props, nav);
  }
}
